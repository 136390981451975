import { customWagmiConfig } from '@components/Marketplace';
import { useDynamicContext } from '@dynamic-labs/sdk-react-core';
import { trackEvent } from '@utils/analytics/events';
import { Wallet } from '@utils/wallet';
import { createContext, useEffect, useMemo, useState } from 'react';
import { useAccount, usePublicClient } from 'wagmi';
import { getWalletClient } from 'wagmi/actions';

export const WalletContext = createContext({
  wallet: undefined as Wallet | undefined,
  disconnect: () => {},
  connect: () => {},
});

export const WalletProvider = ({ children }: { children: React.ReactNode }) => {
  const { address } = useAccount();
  const publicClient = usePublicClient();
  const { handleLogOut, setShowAuthFlow } = useDynamicContext();
  const [wallet, setWallet] = useState<Wallet | undefined>();

  useEffect(() => {
    (async () => {
      if (!address) {
        if (wallet) {
          wallet.signOut();
        }
        setWallet(new Wallet(undefined, publicClient));
      } else {
        const walletClient = await getWalletClient(customWagmiConfig);
        const newWallet = new Wallet(walletClient, publicClient);
        setWallet(newWallet);
        newWallet.signIn();
        trackEvent('step_wallet_connected');
      }
    })();
  }, [address]);

  const contextValue = useMemo(() => {
    return {
      wallet,
      disconnect: handleLogOut,
      connect: () => {
        trackEvent('step_wallet_connecting');
        setShowAuthFlow(true)
      },
    };
  }, [wallet]);

  return (
    <WalletContext.Provider value={contextValue}>
      {children}
    </WalletContext.Provider>
  );
};
