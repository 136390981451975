import mixpanel from 'mixpanel-browser';

const mixPanelRoute = `/${process.env.NEXT_PUBLIC_MIXPANEL_TRACK_ID}`;
export const mixpanelToken = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN;
export const useProxy = !!process.env.NEXT_PUBLIC_USE_MIXPANEL_PROXY;

export function initMixPanel() {
  if (!mixpanelToken) {
    console.warn('mixpanel not configured. skipping.');
    return;
  }

  mixpanel.init(mixpanelToken, {
    ignore_dnt: true,
    api_host: useProxy ? mixPanelRoute : undefined,
  });
}
