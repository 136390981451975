import {
  Collection,
  Currency,
  FeeOnTop,
  Token,
} from '@api/orderbook_api/v1/types.pb';
import { int } from '@api/utils';
import { useMarketplaceChain } from '@hooks';
import {
  Flex,
  FormatCryptoCurrency,
  FormatCurrency,
  Img,
  Text,
} from '@reservoir-kit-ui-overrides/primitives';

interface Props {
  token?: Token;
  collection?: Collection;
  price: string;
  fees?: FeeOnTop[];
  usdPrice: number;
  currency: Currency;
  quantity: number;
}

export const TokenIconWithPrice = ({
  token,
  collection,
  price,
  usdPrice,
  currency,
  fees,
  quantity,
}: Props) => {
  const additionalFees =
    fees?.reduce((acc, fee) => acc + int(fee.amount), 0) || 0;
  // TODO: check that this logic works
  let profit = Number(price) * quantity - additionalFees;
  // let profit =
  //   (1 -
  //     (marketplace?.fee?.percent || 0) / 100 -
  //     (collection?.royalties?.[0].bps || 0) * 0.0001) *
  //   Number(price) *
  //   quantity;
  const marketplaceChainId = useMarketplaceChain().id;
  return (
    <Flex align='center' css={{ gap: '$3', w: '100%' }}>
      <Img
        src={token?.imageUrlSmall || collection?.config?.imageUrl}
        alt={token?.name || collection?.name}
        css={{
          borderRadius: '$radiusMedium',
          objectFit: 'cover',
          height: 62,
          width: 62,
          aspectRatio: '1/1',
        }}
      />
      <Flex direction='column' css={{ overflow: 'hidden' }}>
        <Text style='subtitle1' ellipsify>
          {token ? token?.name : collection?.name}
        </Text>
        <Flex css={{ gap: '$2' }}>
          <FormatCryptoCurrency
            chainId={
              Number(token?.chainId) ||
              Number(collection?.chainId) ||
              marketplaceChainId
            }
            amount={Number(price)}
            address={currency?.address}
            symbol={currency?.symbol}
            textStyle='body1'
          />
          <FormatCurrency
            amount={profit * usdPrice}
            style='body1'
            color='subtle'
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
