import React, { FC } from 'react';
import { Button, Flex, Text } from '../primitives';
import { formatUnits } from 'viem';
import { formatNumber } from '../lib/numbers';
import { EnhancedCurrency } from '@hooks/usePaymentTokensv2';
import { int } from '@api/utils';

type Props = {
  paymentTokens: EnhancedCurrency[];
  setCurrency: React.Dispatch<
    React.SetStateAction<EnhancedCurrency | undefined>
  >;
  goBack: () => void;
  currency?: EnhancedCurrency;
  itemAmount: number;
  chainId: number;
};

const PaymentTokenRow = ({
  setCurrency,
  paymentToken,
  goBack,
  currency,
}: {
  setCurrency: Props['setCurrency'];
  paymentToken: EnhancedCurrency;
  goBack: Props['goBack'];
  currency?: EnhancedCurrency;
}) => {
  const isSelectedCurrency =
    currency?.address?.toLowerCase() === paymentToken?.address &&
    currency?.chainId === paymentToken?.chainId;
  const formattedBalance = formatUnits(
    BigInt(paymentToken?.balance || 0),
    paymentToken?.decimals || 18,
  );

  return (
    <Button
      color={isSelectedCurrency ? 'selected' : 'onlyBorder'}
      onClick={() => {
        setCurrency(paymentToken);
        goBack();
      }}
    >
      <Flex
        align='center'
        css={{
          gap: '$3',
          width: '100%',
        }}
      >
        {paymentToken?.symbol}{' '}
        <Text>Balance: {formatNumber(Number(formattedBalance), 6)}</Text>
      </Flex>
    </Button>
  );
};

export const SelectPaymentTokenv2: FC<Props> = ({
  paymentTokens,
  setCurrency,
  goBack,
  currency,
  itemAmount,
  chainId,
}) => {
  // TODO: confirm that not filtering out any tokens is fine
  const availablePaymentTokens = paymentTokens;
  // const availablePaymentTokens = paymentTokens.filter((paymentToken) => {
  //   const isSelectedCurrency =
  //     currency?.address?.toLowerCase() === paymentToken?.address &&
  //     currency?.chainId === paymentToken?.chainId;
  //   const hasMaxItemAmount = paymentToken?.maxItems != undefined;
  //   const hasMaxPricePerItem = paymentToken?.maxPricePerItem != undefined;
  //   const hasCurrencyTotalRaw = paymentToken?.currencyTotalRaw != undefined;

  //   const maxPurchasablePrice = paymentToken?.capacityPerRequest;
  //   const maxItemAmount = paymentToken?.maxItems
  //     ? BigInt(paymentToken?.maxItems)
  //     : undefined;

  //   return Boolean(
  //     isSelectedCurrency ||
  //       (!hasMaxPricePerItem && !hasMaxItemAmount && hasCurrencyTotalRaw) ||
  //       (maxPurchasablePrice &&
  //         paymentToken?.currencyTotalRaw !== undefined &&
  //         maxPurchasablePrice >= paymentToken?.currencyTotalRaw &&
  //         maxItemAmount &&
  //         maxItemAmount >= itemAmount),
  //   );
  // });

  const nativeCurrencies = availablePaymentTokens.filter(
    (token) => int(token.chainId) === chainId,
  );
  const crossChainCurrencies = availablePaymentTokens.filter(
    (token) => int(token.chainId) !== chainId,
  );

  return (
    <Flex direction='column' css={{ width: '100%', gap: '$4' }}>
      <Flex direction='column' css={{ width: '100%', gap: '$2' }}>
        {nativeCurrencies.map((paymentToken, idx) => (
          <PaymentTokenRow
            key={idx}
            currency={currency}
            goBack={goBack}
            setCurrency={setCurrency}
            paymentToken={paymentToken}
          />
        ))}
      </Flex>
      {crossChainCurrencies.length > 0 ? (
        <Flex direction='column' align='start' css={{ gap: '$1' }}>
          <Text style='body1'>CrossChain ETH</Text>
          <Text color='subtle' style='body2'>
            *CrossChain payment is currently limited to a single item.
          </Text>
        </Flex>
      ) : null}
      {crossChainCurrencies.map((paymentToken, idx) => (
        <PaymentTokenRow
          key={idx}
          currency={currency}
          goBack={goBack}
          setCurrency={setCurrency}
          paymentToken={paymentToken}
        />
      ))}
    </Flex>
  );
};
