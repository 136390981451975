/* eslint-disable react-hooks/rules-of-hooks */
import React, {
  ReactElement,
  useEffect,
  ComponentPropsWithoutRef,
} from 'react';
import {
  Flex,
  Text,
  Box,
  Button,
  Loader,
  Select,
  ErrorWell,
} from '../../primitives';
import {
  EditListingModalRenderer,
  EditListingStep,
} from './EditListingModalRenderer';
import { Modal } from '../Modal';
import TokenPrimitive from '../TokenPrimitive';
import Progress from '../Progress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import PriceInput from '../../primitives/PriceInput';
import InfoTooltip from '../../primitives/InfoTooltip';
import { zeroAddress } from 'viem';
import { formatNumber } from '../../lib/numbers';
import { Dialog } from '../../primitives/Dialog';
import { getTimeSince } from '@reservoir-kit-ui-overrides/lib/getTimeSince';
import { useMarketplaceChain } from '@hooks';
import { int } from '@api/utils';
import { OrderStatus } from '@api/orderbook_api/v1/types.pb';
import { useOpenState } from '@hooks/useOpenState';
import { setModalState } from '@signals/modalState';
import { trackEvent } from '@utils/analytics/events';

const ModalCopy = {
  title: 'Edit Listing',
  ctaClose: 'Close',
  ctaConfirm: 'Confirm',
  ctaConvertManually: 'Convert Manually',
  ctaConvertAutomatically: '',
  ctaAwaitingApproval: 'Waiting for approval...',
  ctaAwaitingValidation: 'Waiting for transaction to be validated',
};

type Props = Pick<Parameters<typeof Modal>['0'], 'trigger'> & {
  listingId?: string;
  tokenId?: string;
  collectionId?: string;
  normalizeRoyalties?: boolean;
  enableOnChainRoyalties?: boolean;
  copyOverrides?: Partial<typeof ModalCopy>;
  onClose?: (data: any, currentStep: EditListingStep) => void;
  onEditListingComplete?: (data: any) => void;
  onEditListingError?: (error: Error, data: any) => void;
  onPointerDownOutside?: ComponentPropsWithoutRef<
    typeof Dialog
  >['onPointerDownOutside'];
};

const MINIMUM_AMOUNT = 0.000001;
const MAXIMUM_AMOUNT = Infinity;

export function EditListingModal({
  listingId,
  tokenId,
  collectionId,
  trigger,
  normalizeRoyalties,
  enableOnChainRoyalties = false,
  copyOverrides,
  onClose,
  onEditListingComplete,
  onEditListingError,
  onPointerDownOutside,
}: Props): ReactElement {
  const copy: typeof ModalCopy = { ...ModalCopy, ...copyOverrides };
  const { isOpen, open, setIsOpen, createCloseHandler } = useOpenState({
    onChange: setModalState,
  });
  const modalChain = useMarketplaceChain();

  useEffect(() => {
    if (isOpen) {
      trackEvent('edit_listing_modal_opened');
    }
  }, [isOpen]);

  if (!isOpen) {
    return <div onClick={open}>{trigger}</div>;
  }

  return (
    <EditListingModalRenderer
      listingId={listingId}
      chainId={modalChain?.id}
      tokenId={tokenId}
      collectionId={collectionId}
      normalizeRoyalties={normalizeRoyalties}
      enableOnChainRoyalties={enableOnChainRoyalties}
    >
      {({
        loading,
        listing,
        token,
        price,
        currency,
        isOracleOrder,
        quantityAvailable,
        collection,
        quantity,
        expirationOption,
        expirationOptions,
        editListingStep,
        transactionError,
        usdPrice,
        totalUsd,
        royaltyBps,
        stepData,
        setPrice,
        setQuantity,
        setExpirationOption,
        editListing,
      }) => {
        const expires = getTimeSince(int(listing?.expiration));
        const profit =
          (1 - (collection?.royalties?.[0]?.bps || 0) * 0.0001) *
          (price || 0) *
          quantity;
        100;

        const updatedTotalUsd = profit * usdPrice;

        const close = createCloseHandler(() => {
          trackEvent('edit_listing_modal_closed');
          onClose?.(
            {
              listing,
              stepData: stepData,
            },
            editListingStep,
          );
        });

        useEffect(() => {
          if (
            editListingStep === EditListingStep.Complete &&
            onEditListingComplete
          ) {
            const data = {
              listing,
              stepData: stepData,
            };
            onEditListingComplete(data);
          }
        }, [editListingStep, listing, stepData]);

        useEffect(() => {
          if (transactionError && onEditListingError) {
            const data = {
              listing,
              stepData: stepData,
            };
            onEditListingError(transactionError, data);
          }
        }, [listing, stepData, transactionError]);

        const isListingAvailable =
          listing &&
          (listing.status === OrderStatus.ACTIVE ||
            listing.status === OrderStatus.PENDING_APPROVAL) &&
          !loading;

        const isListingEditable =
          listing &&
          listing.status === OrderStatus.ACTIVE &&
          !loading &&
          isOracleOrder;

        const minimumAmount = MINIMUM_AMOUNT;
        const maximumAmount = MAXIMUM_AMOUNT;
        const withinPricingBounds =
          price !== 0 &&
          Number(price) <= maximumAmount &&
          Number(price) >= minimumAmount;

        const canPurchase = price && price !== 0 && withinPricingBounds;

        return (
          <Modal
            trigger={trigger}
            title={copy.title}
            open={true}
            onOpenChange={setIsOpen}
            loading={loading}
            onPointerDownOutside={(e) => {
              if (onPointerDownOutside) {
                onPointerDownOutside(e);
              }
            }}
          >
            {!isListingAvailable && !loading && (
              <Flex
                direction='column'
                justify='center'
                css={{ px: '$4', py: '$6' }}
              >
                <Text style='h6' css={{ textAlign: 'center' }}>
                  Selected listing is no longer available
                </Text>
              </Flex>
            )}
            {!isListingEditable && isListingAvailable && (
              <Flex
                direction='column'
                justify='center'
                css={{ px: '$4', py: '$6' }}
              >
                <Text style='h6' css={{ textAlign: 'center' }}>
                  Selected listing is not an oracle order, so cannot be edited.
                </Text>
              </Flex>
            )}
            {isListingEditable && editListingStep === EditListingStep.Edit && (
              <Flex direction='column'>
                {transactionError && <ErrorWell error={transactionError} />}
                <Box css={{ p: '$4', borderBottom: '1px solid $borderColor' }}>
                  <TokenPrimitive
                    img={token?.imageUrlSmall}
                    name={listing.token?.name}
                    price={listing?.price?.amount?.decimal}
                    priceSubtitle='Price'
                    royaltiesBps={royaltyBps}
                    usdPrice={totalUsd}
                    collection={collection?.name || ''}
                    currencyContract={listing.price?.currency?.address}
                    currencyDecimals={listing?.price?.currency?.decimals}
                    currencySymbol={listing?.price?.currency?.symbol}
                    expires={expires}
                    source={listing?.source?.iconUrl || ''}
                    quantity={int(listing?.amount) - int(listing?.amountFilled)}
                  />
                </Box>
                <Flex direction='column' css={{ px: '$4', py: '$2' }}>
                  {quantityAvailable > 1 && (
                    <>
                      <Box css={{ mb: '$2' }}>
                        <Text
                          as='div'
                          css={{ mb: '$2' }}
                          style='subtitle3'
                          color='subtle'
                        >
                          Quantity
                        </Text>
                        <Select
                          value={`${quantity}`}
                          onValueChange={(value: string) => {
                            setQuantity(Number(value));
                          }}
                        >
                          {[...Array(quantityAvailable)].map((_a, i) => (
                            <Select.Item key={i} value={`${i + 1}`}>
                              <Select.ItemText>{i + 1}</Select.ItemText>
                            </Select.Item>
                          ))}
                        </Select>
                      </Box>
                      <Text
                        style='body3'
                        css={{ mb: 24, display: 'inline-block' }}
                      >
                        {quantityAvailable} items available
                      </Text>
                    </>
                  )}
                  <Flex css={{ mb: '$2' }} justify='between'>
                    <Text style='subtitle3' color='subtle' as='p'>
                      Set New Price
                    </Text>
                    <Flex css={{ alignItems: 'center', gap: 8 }}>
                      <Text style='subtitle3' color='subtle' as='p'>
                        You Get
                      </Text>
                      <InfoTooltip
                        side='left'
                        width={200}
                        content={`How much ${currency?.symbol} you will receive after creator royalties are subtracted.`}
                      />
                    </Flex>
                  </Flex>
                  <Flex direction='column' css={{ gap: '$2' }}>
                    <PriceInput
                      chainId={modalChain?.id}
                      price={price}
                      collection={collection}
                      currency={currency}
                      usdPrice={usdPrice}
                      quantity={quantity}
                      placeholder={'Enter a listing price'}
                      onChange={(e) => {
                        if (e.target.value === '') {
                          setPrice(undefined);
                        } else {
                          setPrice(Number(e.target.value));
                        }
                      }}
                      onBlur={() => {
                        if (price === undefined) {
                          setPrice(0);
                        }
                      }}
                    />
                    {price && price !== 0 && !withinPricingBounds && (
                      <Box>
                        <Text style='body3' color='error'>
                          {maximumAmount !== Infinity
                            ? `Amount must be between ${formatNumber(
                                minimumAmount,
                              )} - ${formatNumber(maximumAmount)}`
                            : `Amount must be higher than ${formatNumber(
                                minimumAmount,
                              )}`}
                        </Text>
                      </Box>
                    )}

                    {collection &&
                      collection?.floorPrice?.amount !== undefined &&
                      canPurchase &&
                      currency?.address === zeroAddress &&
                      price <
                        (collection?.floorPrice?.amount.decimal as number) && (
                        <Box>
                          <Text style='body3' color='error'>
                            Price is{' '}
                            {Math.round(
                              (((collection.floorPrice.amount
                                .decimal as number) -
                                price) /
                                (((collection.floorPrice.amount
                                  .decimal as number) +
                                  price) /
                                  2)) *
                                100 *
                                1000,
                            ) / 1000}
                            % below the floor
                          </Text>
                        </Box>
                      )}
                  </Flex>
                  <Box css={{ mb: '$3', mt: '$4' }}>
                    <Text
                      as='div'
                      css={{ mb: '$2' }}
                      style='subtitle3'
                      color='subtle'
                    >
                      Expiration Date
                    </Text>
                    <Select
                      value={expirationOption?.text || ''}
                      onValueChange={(value: string) => {
                        const option = expirationOptions.find(
                          (option) => option.value == value,
                        );
                        if (option) {
                          setExpirationOption(option);
                        }
                      }}
                    >
                      {expirationOptions
                        .filter(({ value }) => value !== 'custom')
                        .map((option) => (
                          <Select.Item key={option.text} value={option.value}>
                            <Select.ItemText>{option.text}</Select.ItemText>
                          </Select.Item>
                        ))}
                    </Select>
                  </Box>
                  <Flex
                    css={{
                      gap: '$3',
                      py: '$3',
                    }}
                  >
                    <Button onClick={close} color='secondary' css={{ flex: 1 }}>
                      {copy.ctaClose}
                    </Button>
                    <Button
                      disabled={!canPurchase}
                      onClick={editListing}
                      css={{ flex: 1 }}
                    >
                      {copy.ctaConfirm}
                    </Button>
                  </Flex>
                </Flex>
              </Flex>
            )}
            {editListingStep === EditListingStep.Approving && (
              <Flex direction='column'>
                <Box css={{ p: '$4', borderBottom: '1px solid $borderColor' }}>
                  <TokenPrimitive
                    img={token?.imageUrlSmall}
                    name={token?.name}
                    price={profit}
                    usdPrice={updatedTotalUsd}
                    collection={collection?.name || ''}
                    currencyContract={listing?.price?.currency?.address}
                    currencyDecimals={listing?.price?.currency?.decimals}
                    currencySymbol={listing?.price?.currency?.symbol}
                    expires={`in ${expirationOption.text.toLowerCase()}`}
                    source={listing?.source?.iconUrl || ''}
                    quantity={quantity}
                  />
                </Box>
                {!stepData && <Loader css={{ height: 206 }} />}
                {stepData && (
                  <Progress
                    title={
                      stepData?.currentStepItem.txHashes
                        ? 'Finalizing on blockchain'
                        : 'Approve Reservoir Oracle to update the listing'
                    }
                    txList={stepData?.currentStepItem?.txHashes}
                  />
                )}
                <Button disabled={true} css={{ m: '$4' }}>
                  <Loader />
                  {stepData?.currentStepItem?.txHashes
                    ? copy.ctaAwaitingValidation
                    : copy.ctaAwaitingApproval}
                </Button>
              </Flex>
            )}
            {editListingStep === EditListingStep.Complete && (
              <Flex direction='column' align='center' css={{ width: '100%' }}>
                <Flex
                  direction='column'
                  align='center'
                  css={{
                    p: '$4',
                    py: '$5',
                    textAlign: 'center',
                  }}
                >
                  <Box css={{ color: '$successAccent', mb: 24 }}>
                    <FontAwesomeIcon icon={faCheckCircle} size='3x' />
                  </Box>
                  <Text style='h5' css={{ mb: '$4' }}>
                    Listing Updated!
                  </Text>
                  <Text style='body2' color='subtle' css={{ mb: 24 }}>
                    Your listing for{' '}
                    <Text style='body2' color='base'>
                      {token?.name}
                    </Text>{' '}
                    has been updated.
                  </Text>
                </Flex>
                <Button onClick={close} css={{ m: '$4' }}>
                  {copy.ctaClose}
                </Button>
              </Flex>
            )}
          </Modal>
        );
      }}
    </EditListingModalRenderer>
  );
}

EditListingModal.Custom = EditListingModalRenderer;
