import React, { FC, useState, useEffect, RefObject, useMemo } from 'react';
import { Button } from '../../primitives';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import { logInfo } from '@utils/logger';

type MediaPlayButtonProps = {
  mediaRef: RefObject<HTMLAudioElement> | RefObject<HTMLVideoElement>;
  buttonSide?: 'left' | 'right';
};

const MediaPlayButton: FC<MediaPlayButtonProps> = ({
  mediaRef,
  buttonSide = 'right',
}) => {
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    const media = mediaRef.current;
    if (media) {
      media.onplaying = () => {
        setPlaying(true);
      };
      media.onpause = () => {
        setPlaying(false);
      };
    }
    return () => {
      if (media) {
        media.onplaying = null;
        media.onpause = null;
      }
    };
  }, [mediaRef]);

  const positionCss = useMemo(() => {
    return buttonSide === 'left' ? { left: 16 } : { right: 16 };
  }, [buttonSide]);

  return (
    <Button
      css={{
        ...positionCss,
        zIndex: 5,
        position: 'absolute',
        bottom: 16,
        backdropFilter: 'blur(2px)',
        background: 'rgba(105, 113, 119, 0.5)',
        width: 48,
        height: 48,
        borderRadius: '50%',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
        '&:hover': {
          background: 'rgba(105, 113, 119, 0.7)',
        },
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (mediaRef.current) {
          if (mediaRef.current.paused) {
            mediaRef.current.play();
          } else {
            mediaRef.current.pause();
          }
        }
      }}
    >
      <FontAwesomeIcon
        icon={playing ? faPause : faPlay}
        width={24}
        height={24}
      />
    </Button>
  );
};

export default MediaPlayButton;
