import { Text, Flex } from '@reservoir-kit-ui-overrides/primitives';
import { TxReceipt } from '../TxReceipt/TxReceipt';
import { TxHash } from '@utils/wallet';

export type Tx = {
  txHash: TxHash | string;
  chainId: number;
};

interface Props {
  txList?: Tx[];
}

export const TxSummary = ({ txList }: Props) => {
  if (!txList || txList.length === 0) {
    return null;
  }

  const mode = txList.length > 1 ? 'compact' : 'full';

  return (
    <Flex css={{ gap: '$2' }} direction='column'>
      {mode === 'compact' && <Text style='subtitle1'>Transactions</Text>}
      {txList.map((tx) => (
        <TxReceipt
          key={tx.txHash}
          txHash={tx.txHash as TxHash}
          chainId={tx.chainId}
        />
      ))}
    </Flex>
  );
};
