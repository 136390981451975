/* eslint-disable */
// @ts-nocheck
/*
* This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
*/

export enum OrderProtocol {
  ERC721_FILL_OR_KILL = "ERC721_FILL_OR_KILL",
  ERC1155_FILL_OR_KILL = "ERC1155_FILL_OR_KILL",
  ERC1155_FILL_PARTIAL = "ERC1155_FILL_PARTIAL",
}

export enum PaymentProcessorVersion {
  UNKNOWN = "UNKNOWN",
  VERSION_1_0 = "VERSION_1_0",
  VERSION_2_0 = "VERSION_2_0",
  VERSION_2_0_1 = "VERSION_2_0_1",
  VERSION_2_1_0 = "VERSION_2_1_0",
}

export enum PaymentSettings {
  DefaultPaymentMethodWhitelist = "DefaultPaymentMethodWhitelist",
  AllowAnyPaymentMethod = "AllowAnyPaymentMethod",
  CustomPaymentMethodWhitelist = "CustomPaymentMethodWhitelist",
  PricingConstraints = "PricingConstraints",
  Paused = "Paused",
}

export type BannedAccountAddedForCollectionEvent = {
  tokenAddress?: string
  account?: string
}

export type BannedAccountRemovedForCollectionEvent = {
  tokenAddress?: string
  account?: string
}

export type BuyListingERC721Event = {
  buyer?: string
  seller?: string
  tokenAddress?: string
  beneficiary?: string
  paymentCoin?: string
  tokenId?: string
  salePrice?: string
}

export type BuyListingERC1155Event = {
  buyer?: string
  seller?: string
  tokenAddress?: string
  beneficiary?: string
  paymentCoin?: string
  tokenId?: string
  amount?: string
  salePrice?: string
}

export type AcceptOfferERC721Event = {
  seller?: string
  buyer?: string
  tokenAddress?: string
  beneficiary?: string
  paymentCoin?: string
  tokenId?: string
  salePrice?: string
}

export type AcceptOfferERC1155Event = {
  seller?: string
  buyer?: string
  tokenAddress?: string
  beneficiary?: string
  paymentCoin?: string
  tokenId?: string
  amount?: string
  salePrice?: string
}

export type CreatedPaymentMethodWhitelistEvent = {
  paymentMethodWhitelistId?: string
  whitelistOwner?: string
  whitelistName?: string
}

export type DestroyedCosignerEvent = {
  cosigner?: string
}

export type MasterNonceInvalidatedEvent = {
  account?: string
  nonce?: string
}

export type NonceInvalidatedEvent = {
  nonce?: string
  account?: string
  wasCancellation?: boolean
}

export type OrderDigestInvalidatedEvent = {
  orderDigest?: string
  account?: string
  wasCancellation?: boolean
}

export type NonceRestoredEvent = {
  nonce?: string
  account?: string
}

export type OrderDigestOpenedEvent = {
  orderDigest?: string
  account?: string
  orderStartAmount?: string
}

export type OrderDigestItemsFilledEvent = {
  orderDigest?: string
  account?: string
  amountFilled?: string
}

export type OrderDigestItemsRestoredEvent = {
  orderDigest?: string
  account?: string
  amountRestoredToOrder?: string
}

export type PaymentMethodAddedToWhitelistEvent = {
  paymentMethodWhitelistId?: string
  paymentMethod?: string
}

export type PaymentMethodRemovedFromWhitelistEvent = {
  paymentMethodWhitelistId?: string
  paymentMethod?: string
}

export type ReassignedPaymentMethodWhitelistOwnershipEvent = {
  id?: string
  newOwner?: string
}

export type TrustedChannelAddedForCollectionEvent = {
  tokenAddress?: string
  channel?: string
}

export type TrustedChannelRemovedForCollectionEvent = {
  tokenAddress?: string
  channel?: string
}

export type UpdatedCollectionLevelPricingBoundariesEvent = {
  tokenAddress?: string
  floorPrice?: string
  ceilingPrice?: string
}

export type UpdatedCollectionPaymentSettingsEvent = {
  tokenAddress?: string
  paymentSettings?: PaymentSettings
  paymentMethodWhitelistId?: string
  constrainedPricingPaymentMethod?: string
  royaltyBackfillNumerator?: string
  royaltyBackfillReceiver?: string
  royaltyBountyNumerator?: string
  exclusiveBountyReceiver?: string
  blockTradesFromUntrustedChannels?: boolean
  blockBannedAccounts?: boolean
}

export type UpdatedTokenLevelPricingBoundariesEvent = {
  tokenAddress?: string
  tokenId?: string
  floorPrice?: string
  ceilingPrice?: string
}