import React, { FC, useEffect, useState } from 'react';
import { TokenCheckout } from './TokenCheckout';
import { ApproveCollapsible } from './ApproveCollapisble';
import { Flex } from '../primitives';
import {
  Order,
  StepItem,
  StepItemStatus,
} from '@api/orderbook_api/v1/types.pb';
import { LimitBreakChain } from '@api/utils/chains';

type Props = {
  title?: string;
  item: StepItem;
  orderMap: Record<string, Order>;
  usdPrice: number;
  chain?: LimitBreakChain | null;
  open?: boolean;
};

export const ApprovePurchasingCollapsible: FC<Props> = ({
  item,
  orderMap,
  usdPrice,
  chain,
  open,
}) => {
  const [collapsibleOpen, setCollapsibleOpen] = useState(false);

  const isComplete = item && item?.status == StepItemStatus.COMPLETE;
  const orders =
    item?.data?.buyListings?.items || item?.data?.sweepCollection?.items || [item?.data?.buyListing?.order];
  const itemCount = orders?.length || 1;

  function processOrders(orderIds: string[]) {
    let totalPrice = 0;
    let images: string[] = [];

    orderIds.forEach((orderId: string) => {
      const path = orderMap[orderId];

      if (path) {
        let imageRedirect = path.token?.imageUrl || '';
        images.push(imageRedirect);
        totalPrice += path.price?.amount?.decimal || 0;
      }
    });

    images = images.slice(0, 2);

    return { totalPrice, images };
  }

  const { totalPrice, images } = processOrders(
    orders?.map((order) => order?.id) as string[],
  );

  useEffect(() => {
    if (open !== undefined && open !== collapsibleOpen) {
      setCollapsibleOpen(open);
    }
  }, [open]);

  return (
    <ApproveCollapsible
      title={'Approve transaction'}
      open={collapsibleOpen}
      onOpenChange={setCollapsibleOpen}
      isComplete={isComplete}
    >
      <Flex
        justify='between'
        align='center'
        css={{
          p: '$4'
        }}
      >
        <TokenCheckout
          usdPrice={usdPrice}
          itemCount={itemCount}
          totalPrice={totalPrice}
          images={images}
          size={0}
        />
      </Flex>
    </ApproveCollapsible>
  );
};
