import { Mode } from '@api/orderbook_api/v1/types.pb';
import { useSignals } from '@preact/signals-react/runtime';
import { usePrevious } from '@react-hookz/web';
import { sMarketplace } from '@signals/marketplace';
import { sReady } from '@signals/ready';
import { dark } from '@themes/dark';
import { light } from '@themes/light';
import { valeria } from '@themes/valeria';
import { useEffect } from 'react';

const Themes: Record<Mode, any> = {
  [Mode.UNKNOWN_MODE]: light,
  [Mode.LIGHT]: light,
  [Mode.DARK]: dark,
  [Mode.VALERIA]: valeria,
};

export const useTheme = () => {
  useSignals();
  const mode: Mode = sMarketplace.value?.mode ?? Mode.DARK;
  const theme = Themes[mode];
  const previousTheme = usePrevious(theme);

  useEffect(() => {
    if (!theme || sReady.value === false) {
      return;
    }
    document.body.classList.toggle(previousTheme, false);
    document.body.classList.toggle(theme, true);
  }, [JSON.stringify(theme), sReady.value]);
};
