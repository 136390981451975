import { OrderbookService } from '@api/orderbook_api/v1/orderbook.pb';
import useSWR from 'swr/immutable';
import { SWRConfiguration } from 'swr';
import { sSelectedCollection } from '@signals/collection';
import { getRequestOpts } from '@utils/wallet';
import { sSelectedChain } from '@signals/chains';

export default function useCollectionAttributes(
  address?: string,
  chainId?: number,
  config?: SWRConfiguration,
) {
  const chain = chainId ? chainId : sSelectedChain?.value?.id;
  const collectionAddress = address
    ? address
    : sSelectedCollection?.value?.contractAddress;
  const fetcher = async () => {
    return await OrderbookService.GetCollectionTokenAttributes(
      {
        chainId: String(chain),
        collection: collectionAddress,
      },
      getRequestOpts(),
    );
  };
  return useSWR(
    `useCollectionAttributes/${chain}/${collectionAddress}`,
    fetcher,
    config,
  );
}
