import React, { FC, useMemo } from 'react';
import { styled } from '@stitches.config';
import {
  Flex,
  FormatCryptoCurrency,
  FormatCurrency,
  Text,
} from '../../primitives';
import {
  AcceptBidPrice,
  EnhancedAcceptBidTokenData,
} from './AcceptBidModalRenderer';
import { useCoinConversion } from '@hooks';
import { int } from '@api/utils';
import { LimitBreakChain } from '@api/utils/chains';
import { useMediaQuery } from 'react-responsive';

type Props = {
  tokensData: EnhancedAcceptBidTokenData[];
  prices: AcceptBidPrice[];
  usdPrices: Record<string, ReturnType<typeof useCoinConversion>[0]>;
  chain?: LimitBreakChain | null;
};

const Img = styled('img', {
  borderRadius: '$radiusMedium',
  aspectRatio: '1/1',
});

const largeScreenImgSize = 86;
const smallScreenImgSize = 62;

const AcceptBidSummaryLineItem: FC<Props> = ({
  tokensData,
  prices,
  usdPrices,
  chain,
}) => {
  const isSmallDevice = useMediaQuery({ maxWidth: 600 });
  const imageSize = isSmallDevice ? smallScreenImgSize : largeScreenImgSize;
  const imgs = useMemo(() => {
    if (!tokensData || tokensData.length <= 0) {
      return [];
    }
    const imgs: string[] = [];
    for (var i = 0; i < tokensData.length; i++) {
      const token = tokensData[i];
      if (token?.tokenData?.imageUrl) {
        imgs.push(token?.tokenData?.imageUrl);
      }
      if (imgs.length > 2) {
        break;
      }
    }
    return imgs;
  }, [tokensData]);

  const itemCount = useMemo(
    () =>
      tokensData.reduce((total, tokenData) => {
        tokenData.bidOrders.forEach((path) => {
          total += int(path.amount) || 0;
        });
        return total;
      }, 0),
    [tokensData],
  );
  const usdPrice = useMemo(() => {
    let missingConversion = false;
    const totalUsd = prices.reduce((total, { amount, currency }) => {
      const conversion = usdPrices[currency.symbol as string];
      if (conversion) {
        total += conversion.price * amount;
      } else {
        missingConversion = true;
      }
      return total;
    }, 0);
    return missingConversion ? 0 : totalUsd;
  }, [prices, usdPrices]);

  return (
    <Flex direction='column' css={{ gap: '$2' }}>
      {/* <Flex justify="between">
        <Text color="subtle" style="subtitle3">
          Item
        </Text>
        <Text color="subtle" style="subtitle3">
          You Get
        </Text>
      </Flex> */}
      <Flex align='center'>
        <Flex css={{ mr: '$4', position: 'relative' }}>
          <Img
            src={imgs[0]}
            alt={'Token Image'}
            css={{
              visibility: !imgs[0] ? 'hidden' : 'visible',
              mr: itemCount > 1 && imgs[1] ? 8 : 0,
              width: imageSize,
              height: imageSize,
            }}
          />
          {itemCount > 1 && imgs[1] ? (
            <Img
              src={imgs[1]}
              alt={'Token Image'}
              css={{
                position: 'absolute',
                right: -5,
                zIndex: -1,
                opacity: 0.5,
                width: imageSize,
                height: imageSize,
              }}
            />
          ) : null}
        </Flex>
        {tokensData.length === 1 ? (
          <Flex
            direction='column'
            align='start'
            justify='center'
            css={{ gap: 2, mr: 'auto', overflow: 'hidden' }}
          >
            <Text style='subtitle1' ellipsify>
              {tokensData[0].tokenData?.name ||
                `#${tokensData[0].tokenData?.tokenId}`}
            </Text>
            <Text ellipsify style='h6' color='subtle'>
              {tokensData[0].tokenData?.collection?.name}
            </Text>
          </Flex>
        ) : (
          <Text style='subtitle1'>
            {itemCount}x {itemCount > 1 ? 'Items' : 'Item'}
          </Text>
        )}

        <Flex align='end' justify='end' direction='column' css={{ ml: 'auto' }}>
          <Flex align='center'>
            {prices.map(({ netAmount, currency }, i) => {
              return (
                <span key={i}>
                  {i > 0 ? (
                    <Text color='subtle' style='subtitle1' css={{ mx: '$1' }}>
                      +
                    </Text>
                  ) : null}
                  <FormatCryptoCurrency
                    chainId={chain?.id}
                    amount={netAmount}
                    address={currency.address}
                    decimals={currency.decimals}
                    textStyle='subtitle1'
                  />
                </span>
              );
            })}
          </Flex>
          <FormatCurrency amount={usdPrice} style='body1' color='subtle' />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AcceptBidSummaryLineItem;
