import Flex from './Flex';
import Box from './Box';
import React, {
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  ReactNode,
} from 'react';
import { CSS } from '@stitches/react';
import { styled } from '@stitches.config';

const StyledInput = styled('input', {
  boxSizing: 'border-box',
  all: 'unset',
  width: '100%',
  height: '48px',
  px: 16,
  // py: '$2',
  borderRadius: '$radiusMedium',
  fontFamily: '$body',
  fontSize: 18,
  color: '$neutralTextContrast',
  backgroundColor: '$inputBackground',
  $$focusColor: '$colors$neutralBorderHover',
  border: '1px solid $borderColor',
  '&:placeholder': { color: '$neutralText' },
  '&:focus': { boxShadow: '0 0 0 1px $$focusColor' },

  '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },

  '&[type=number]': {
    '-moz-appearance': 'textfield',
  },
});

// eslint-disable-next-line react/display-name
export const Input = forwardRef<
  ElementRef<typeof StyledInput>,
  ComponentPropsWithoutRef<typeof StyledInput> & {
    icon?: ReactNode;
    containerCss?: CSS;
    iconCss?: CSS;
    rightSlot?: ReactNode;
  }
>(
  (
    { children, icon, containerCss, iconCss, rightSlot, ...props },
    forwardedRef,
  ) => (
    <Flex css={{ ...containerCss, position: 'relative', width: '100%' }}>
      {icon && (
        <Box
          css={{
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            left: 16,
            color: '$neutralTextContrast',
            pointerEvents: 'none',
            ...iconCss,
          }}
        >
          {icon}
        </Box>
      )}
      <StyledInput
        css={{ pl: icon ? 48 : 16, pr: rightSlot ? 48 : 16, ...props.css }}
        ref={forwardedRef}
        {...props}
      />
      {rightSlot && (
        <Box
          css={{
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            right: 16,
            color: '$neutralTextContrast',
            pointerEvents: 'none',
            ...iconCss,
          }}
        >
          {rightSlot}
        </Box>
      )}
    </Flex>
  ),
);

export default Input;
