/* eslint-disable react-hooks/rules-of-hooks */
import React, { FC } from 'react';
import { Flex, FormatCryptoCurrency, Img, Text } from '../../primitives';
import { CSS } from '@stitches/react';
import { ExpirationOption } from '../../types/ExpirationOption';
import { getTimeSince } from '@reservoir-kit-ui-overrides/lib/getTimeSince';
import { Token, Collection, Currency } from '@api/orderbook_api/v1/types.pb';
import { LimitBreakChain } from '@api/utils/chains';
import { useMediaQuery } from 'react-responsive';

type ListCheckoutProps = {
  collection?: Collection;
  token?: Token;
  price?: string;
  currency?: Currency;
  quantity?: number;
  chain?: LimitBreakChain | null;
  expirationOption?: ExpirationOption;
  containerCss?: CSS;
};

const ListCheckout: FC<ListCheckoutProps> = ({
  collection,
  token,
  price,
  currency,
  quantity,
  chain,
  expirationOption,
  containerCss,
}) => {
  const isSmallDevice = useMediaQuery({ maxWidth: 600 });
  const expirationDisplay =
    expirationOption?.value === 'custom' && expirationOption.relativeTime
      ? getTimeSince(expirationOption.relativeTime)
      : `in ${expirationOption?.text.toLowerCase()}`;

  const hasPrice = price && currency;
  const imageSize = isSmallDevice ? 62 : 86;

  const priceSlot = price && currency && (
    <FormatCryptoCurrency
      chainId={Number(token?.chainId)}
      amount={Number(price)}
      address={currency?.address}
      symbol={currency?.symbol}
      textStyle='subtitle1'
    />
  );

  const priceSummarySlot = hasPrice && (
    <Flex
      css={{
        width: '100%',
        alignItems: 'center',
        gap: '$2',
        '@sm': { flexDirection: 'column', alignItems: 'end', gap: 0 },
      }}
    >
      {priceSlot}
      {expirationOption && (
        <Text style='body1' color='subtle'>
          Expires {expirationDisplay}
        </Text>
      )}
    </Flex>
  );

  return (
    <Flex
      align='center'
      justify='between'
      css={{ gap: '$3', p: '$modalContentPadding', ...containerCss }}
    >
      <Img
        src={token?.imageUrl || collection?.config?.imageBanner}
        alt={token?.name || collection?.name}
        css={{
          width: imageSize,
          height: imageSize,
          borderRadius: '$radiusMedium',
          aspectRatio: '1/1',
        }}
      />

      <Flex direction='column' css={{ width: '100%', overflow: 'hidden' }}>
        <Text style='subtitle1'>{token?.name}</Text>
        {quantity && quantity > 1 && (
          <Flex
            css={{
              width: 'max-content',
              backgroundColor: '$neutralBg',
              borderRadius: '$radiusTiny',
              py: '$1',
              px: '$2',
            }}
          >
            <Text style='body3' color='subtle'>
              {quantity} items
            </Text>
          </Flex>
        )}
        {isSmallDevice && priceSummarySlot}
      </Flex>

      {!hasPrice && (
        <Flex direction='column' align='end' css={{ width: '100%' }}>
          <Text style='subtitle1' css={{ textAlign: 'right' }}>
            {collection?.name}
          </Text>
          {chain && !expirationOption && (
            <Text style='body1' color='subtle'>
              {chain.name}
            </Text>
          )}
        </Flex>
      )}

      {!isSmallDevice && priceSummarySlot}
    </Flex>
  );
};

export default ListCheckout;
