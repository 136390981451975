/* eslint-disable react-hooks/rules-of-hooks */
import { styled } from '@stitches.config';
import {
  Flex,
  Text,
  Button,
  FormatCryptoCurrency,
  FormatCurrency,
  Loader,
} from '../../primitives';
import React, {
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { ProviderOptionsContext } from '../../ReservoirKitProvider';
import CartItem from './CartItem';
import CartToast from './CartToast';
import CartPopoverRenderer from './CartPopoverRenderer';
import { CheckoutStatus } from '../../context/CartProvider';
import { CartCheckoutModal } from './CartCheckoutModal';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { AnimatePresence, motion } from 'framer-motion';
import { AnimatedOverlay, Content } from '../../primitives/Dialog';
import { IconClose } from '@v2/icons/IconClose';
import { useAccount } from 'wagmi';
import { ButtonWithAuth } from '@v2/components/ButtonWithAuth/ButtonWithAuth';
import { ToastContext } from '@context/ToastContextProvider';
import { useOpenState } from '@hooks/useOpenState';
import { setModalState } from '@signals/modalState';

type Props = {
  trigger: ReactNode;
  tokenUrl?: string;
};

const Container = styled('div', {
  display: 'flex',
  '> div': {
    transform: 'none !important',
  },
});

export function CartPopover({ trigger, tokenUrl }: Props): ReactElement {
  const { isOpen, open, setIsOpen, createCloseHandler } = useOpenState({
    onChange: setModalState,
  });
  const {
    isOpen: isOpenCheckout,
    open: openCheckout,
    setIsOpen: setIsOpenCheckout,
  } = useOpenState({
    onChange: setModalState,
  });
  const providerOptionsContext = useContext(ProviderOptionsContext);
  const [displayPendingTransaction, setDisplayPendingTransaction] =
    useState(false);
  const [purchaseComplete, setPurchaseComplete] = useState(false);
  const { isConnected } = useAccount();
  const { addToast } = useContext(ToastContext);

  const onRemove = () => {
    addToast?.({
      title: 'Removed from cart',
    });
  };

  useEffect(() => {
    if (!open) {
      setDisplayPendingTransaction(false);
      setPurchaseComplete(false);
    }
  }, [open]);

  if (!isOpen) {
    return <div onClick={open}>{trigger}</div>;
  }

  return (
    <Container>
      <CartPopoverRenderer>
        {({
          loading,
          items,
          unavailableItems,
          totalPrice,
          feeOnTop,
          usdPrice,
          hasEnoughCurrency,
          balance,
          currency,
          cartCurrencyConverted,
          transaction,
          cartChain,
          remove,
          clear,
          checkout,
        }) => {
          useEffect(() => {
            if (transaction?.status === CheckoutStatus.Complete) {
              setDisplayPendingTransaction(false);
              setPurchaseComplete(true);
            }
          }, [transaction?.status]);

          const close = createCloseHandler(() => {});

          const unavailableItemsSubject =
            unavailableItems.length > 1 ? 'items' : 'item';
          const isCartEmpty = items.length === 0;
          const hasValidItems = items.length > unavailableItems.length;

          return (
            <DialogPrimitive.Root onOpenChange={setIsOpen} open={true}>
              {trigger && (
                <DialogPrimitive.DialogTrigger asChild>
                  {trigger}
                </DialogPrimitive.DialogTrigger>
              )}
              <AnimatePresence>
                <DialogPrimitive.DialogPortal forceMount>
                  @ts-ignore: Property css does not exist
                  <AnimatedOverlay
                    css={{ backgroundColor: '#000000aa' }}
                    style={{ opacity: 0.6 }}
                  />
                  <Content
                    forceMount
                    asChild
                    css={{
                      right: 0,
                      top: 0,
                      bottom: 0,
                      transform: 'none',
                      left: 'unset',
                      width: 495,
                      maxWidth: '100%',
                      minWidth: 'initial',
                      maxHeight: '100dvh',
                      background: '$contentBackground',
                      border: 0,
                      borderRadius: '$radiusNone',
                      padding: '2rem 2rem 2.5rem 2.5rem',
                      overflowY: 'auto',
                    }}
                  >
                    <motion.div
                      transition={{ type: 'tween', duration: 0.4 }}
                      initial={{
                        opacity: 0,
                        right: '-100%',
                      }}
                      animate={{
                        opacity: 1,
                        right: 0,
                      }}
                      exit={{
                        opacity: 0,
                        right: '-100%',
                      }}
                    >
                      {loading && (
                        <Loader
                          css={{
                            backgroundColor: '$contentBackground',
                            position: 'absolute',
                            inset: 0,
                            opacity: 0.6,
                            zIndex: 10000,
                          }}
                        />
                      )}
                      <Flex align='center' css={{ mb: '$5', gap: '$1' }}>
                        <Text css={{ fontSize: 18, fontWeight: 600 }}>
                          Cart
                        </Text>
                        {!isCartEmpty && (
                          <Text
                            color='subtle'
                            css={{ fontSize: 18, fontWeight: 600 }}
                          >
                            {items.length}
                          </Text>
                        )}
                        {!isCartEmpty && (
                          <Text
                            color='subtle'
                            css={{
                              fontSize: 16,
                              fontWeight: 500,
                              cursor: 'pointer',
                              ml: 32,
                              '&:hover': { color: '$accentSolidHover' },
                            }}
                            onClick={clear}
                          >
                            Clear All
                          </Text>
                        )}
                        <Button
                          size='none'
                          color='ghost'
                          css={{ color: '$neutralSolid', ml: 'auto' }}
                          onClick={close}
                        >
                          <IconClose />
                        </Button>
                      </Flex>
                      {cartCurrencyConverted && (
                        <CartToast
                          kind='warning'
                          message={`Mixed currency items are only available to be checked out with ${currency?.symbol}`}
                        />
                      )}
                      {unavailableItems.length > 0 && (
                        <CartToast
                          kind='error'
                          message={`${unavailableItems.length} ${unavailableItemsSubject} no longer available`}
                          link={
                            <Text
                              color='accent'
                              style='subtitle3'
                              css={{ ml: 'auto', mt: 3, cursor: 'pointer' }}
                              onClick={(e) => {
                                e.preventDefault();
                                remove(
                                  unavailableItems.map(
                                    (item) =>
                                      `${item.collection}:${item.token?.tokenId}`,
                                  ),
                                );
                                onRemove();
                              }}
                            >
                              Remove {unavailableItemsSubject}
                            </Text>
                          }
                        />
                      )}
                      {!isCartEmpty && (
                        <Flex
                          direction='column'
                          css={{
                            gap: '$4',
                            mb: '$4',
                            // overflowY: 'auto', we should ideally add scroll here
                          }}
                        >
                          {items.map((item) => (
                            <CartItem
                              key={`${item.collection}:${item.token?.tokenId}`}
                              item={item}
                              usdConversion={usdPrice}
                              tokenUrl={tokenUrl}
                              onRemove={onRemove}
                              onClick={close}
                            />
                          ))}
                        </Flex>
                      )}
                      {isCartEmpty &&
                        !(
                          displayPendingTransaction &&
                          transaction?.status === CheckoutStatus.Finalizing
                        ) && (
                          <Flex
                            direction='column'
                            align='center'
                            justify='center'
                            css={{
                              color: '$neutralBorderHover',
                              flex: 1,
                              gap: '$4',
                            }}
                          >
                            <Text style='body1' color='subtle'>
                              No items in your cart
                            </Text>
                            {isCartEmpty && !displayPendingTransaction && (
                              <Button
                                size='small'
                                color='onlyBorder'
                                onClick={close}
                              >
                                Select Items to Buy
                              </Button>
                            )}
                          </Flex>
                        )}
                      <Flex
                        direction='column'
                        css={{
                          pt: 10,
                          pb: 15,
                          borderTop: isCartEmpty
                            ? 'unset'
                            : '1px solid $borderColor',
                          borderBottom: isCartEmpty
                            ? 'unset'
                            : '1px solid $borderColor',
                        }}
                      >
                        {!isCartEmpty && feeOnTop ? (
                          <Flex css={{ mb: '$4' }}>
                            <Text style='subtitle3'>Referrer Fee</Text>
                            <Flex
                              direction='column'
                              justify='center'
                              css={{
                                ml: 'auto',
                                gap: '$1',
                                '> div': { ml: 'auto' },
                              }}
                            >
                              <FormatCryptoCurrency
                                textStyle='subtitle3'
                                amount={feeOnTop}
                                address={currency?.address}
                                decimals={currency?.decimals}
                                symbol={currency?.symbol}
                                logoWidth={12}
                                chainId={cartChain?.id}
                              />
                              {usdPrice && (
                                <FormatCurrency
                                  amount={usdPrice * feeOnTop}
                                  style='subtitle3'
                                  color='subtle'
                                  css={{ textAlign: 'end' }}
                                />
                              )}
                            </Flex>
                          </Flex>
                        ) : null}
                        {!isCartEmpty && (
                          <Flex direction='column' css={{ gap: '$3' }}>
                            <Text style='h6' color='subtle'>
                              Total
                            </Text>
                            <Flex align='center' css={{ gap: '$3' }}>
                              <Flex
                                justify='between'
                                align='center'
                                css={{ gap: '$3', flexGrow: 1 }}
                              >
                                {providerOptionsContext.preferDisplayFiatTotal &&
                                usdPrice ? (
                                  <>
                                    <FormatCurrency
                                      amount={usdPrice * totalPrice}
                                      style='subtitle1'
                                      color='base'
                                      css={{ textAlign: 'end' }}
                                    />
                                    <FormatCryptoCurrency
                                      textStyle='h3'
                                      textColor='subtle'
                                      amount={totalPrice}
                                      address={currency?.address}
                                      decimals={currency?.decimals}
                                      symbol={currency?.symbol}
                                      logoWidth={12}
                                      chainId={cartChain?.id}
                                      css={{ fontWeight: 500, lineHeight: 1 }}
                                      isSmall
                                    />
                                  </>
                                ) : (
                                  <>
                                    <FormatCryptoCurrency
                                      textStyle='h3'
                                      amount={totalPrice}
                                      address={currency?.address}
                                      decimals={currency?.decimals}
                                      symbol={currency?.symbol}
                                      logoWidth={18}
                                      chainId={cartChain?.id}
                                      css={{ fontWeight: 500, lineHeight: 1 }}
                                      isSmall
                                    />
                                    {usdPrice && (
                                      <FormatCurrency
                                        amount={usdPrice * totalPrice}
                                        style='subtitle2'
                                        color='subtle'
                                        css={{ textAlign: 'end' }}
                                      />
                                    )}
                                  </>
                                )}
                              </Flex>
                              {!isCartEmpty &&
                                hasValidItems &&
                                (transaction?.status === CheckoutStatus.Idle ||
                                  !displayPendingTransaction) && (
                                  <ButtonWithAuth
                                    css={{
                                      h: 38,
                                      borderRadius: '$radiusSmall',
                                      px: 16,
                                    }}
                                    chainId={cartChain?.id}
                                    disabled={!hasEnoughCurrency && isConnected}
                                    onClick={async () => {
                                      openCheckout();
                                      checkout()
                                        .then(() => {
                                          setDisplayPendingTransaction(true);
                                        })
                                        .catch((e) => {
                                          console.error(e);
                                          setDisplayPendingTransaction(false);
                                        });
                                    }}
                                  >
                                    Buy now
                                  </ButtonWithAuth>
                                )}
                              {!isCartEmpty && !hasValidItems && (
                                <Button
                                  color='secondary'
                                  onClick={() => {
                                    clear();
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faRefresh}
                                    width='16'
                                    height='16'
                                  />
                                  Refresh Cart
                                </Button>
                              )}
                            </Flex>
                          </Flex>
                        )}
                        <CartCheckoutModal
                          open={isOpenCheckout}
                          items={items}
                          balance={balance}
                          currency={currency}
                          totalPrice={totalPrice}
                          usdPrice={usdPrice || 0}
                          transaction={transaction}
                          cartChain={cartChain}
                          setCartPopoverOpen={setIsOpen}
                          hasEnoughCurrency={hasEnoughCurrency}
                          onClose={() => {
                            setIsOpenCheckout(false);
                          }}
                        />
                      </Flex>
                      {!isCartEmpty && !hasEnoughCurrency && isConnected && (
                        <Flex
                          align='center'
                          justify='start'
                          css={{ mb: '$2', mt: '$3', gap: '$2' }}
                        >
                          <Text style='body3' color='error'>
                            Insufficient balance
                          </Text>
                          <FormatCryptoCurrency
                            textStyle='body3'
                            textColor='error'
                            chainId={cartChain?.id}
                            amount={balance}
                            address={currency?.address}
                            decimals={currency?.decimals}
                            symbol={currency?.symbol}
                            logoWidth={10}
                          />
                        </Flex>
                      )}
                    </motion.div>
                  </Content>
                </DialogPrimitive.DialogPortal>
              </AnimatePresence>
            </DialogPrimitive.Root>
          );
        }}
      </CartPopoverRenderer>
    </Container>
  );
}

CartPopover.Custom = CartPopoverRenderer;

export default CartPopover;
