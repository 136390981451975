import { logError } from '@utils/logger';
import React, {
  createContext,
  useCallback,
  useRef,
  ReactNode,
  useEffect,
  FC,
  useState,
} from 'react';
import { useAccount, useSwitchChain } from 'wagmi';
import { Address, formatUnits, parseUnits } from 'viem';
import appPackage from '../../package.json';
import {
  Currency,
  Order,
  OrderStatus,
  StepKind,
  Token,
  TokenRef,
} from '@api/orderbook_api/v1/types.pb';
import {
  ConvertCurrencyResponse,
  OrderbookService,
} from '@api/orderbook_api/v1/orderbook.pb';
import { getChainById, sSelectedChain } from '@signals/chains';
import { LimitBreakChain } from '@api/utils/chains';
import { useSignals } from '@preact/signals-react/runtime';
import { getChainCurrency } from '@hooks/useChainCurrency';
import { Wallet, getRequestOpts } from '@utils/wallet';
import { useMarketplaceChain } from '@hooks';
import { EnhancedStep, EnhancedStepItem } from '@types';
import { int } from '@api/utils';
import { useWallet } from '@hooks/useWallet';
import { getCollection } from '@signals/collection';
import { getTokenActions } from '@utils/getTokenActions';

export type CartTransactionItem = {
  collection?: string;
  token?: string;
  quantity?: number;
  orderId?: string;
};

export enum CheckoutStatus {
  Idle,
  Approving,
  Finalizing,
  Complete,
}

export enum CheckoutTransactionError {
  Unknown,
  PiceMismatch,
  InsufficientBalance,
  UserDenied,
}

export type CartItem = Order & { quantity?: number };

export type Cart = {
  totalPrice: number;
  currency?: Currency;
  feeOnTop?: number;
  feesOnTopBps?: string[];
  feesOnTopUsd?: string[];
  items: CartItem[];
  isValidating: boolean;
  chain?: LimitBreakChain;
  pendingTransactionId?: string;
  transaction: {
    id?: string;
    txHashes?: {
      txHash: `0x${string}`;
      chainId: number;
    }[];
    chain: LimitBreakChain;
    items: CartItem[];
    error?: Error;
    errorType?: CheckoutTransactionError;
    status: CheckoutStatus;
    steps?: EnhancedStep[];
    path?: Order[];
    currentStep?: EnhancedStep;
  } | null;
};

const CartStorageKey = `reservoirkit.cart.${appPackage.version}`;

type AsyncAddToCartOrder = { orderId: string };
type AsyncAddToCartToken = { id: string };
export type AddToCartToken = AsyncAddToCartToken | AsyncAddToCartOrder | Token;
type BuyTokenOptions = Parameters<Wallet['buyListing']>[0];

type CartContextType = {
  get: () => Cart;
  set: (value: Partial<Cart>) => void;
  subscribe: (callback: () => void) => () => void;
  setQuantity: (orderId: string, quantity: number) => void;
  add: (orders: Order[], chainId: number) => Promise<void>;
  remove: (ids: string[]) => void;
  clear: () => void;
  clearTransaction: () => void;
  validate: () => Promise<boolean>;
  checkout: (options?: BuyTokenOptions) => Promise<void>;
  openCheckout: () => void;
};

export const CartContext = createContext<CartContextType | null>(null);

type CartProviderProps = {
  children: ReactNode;
  feesOnTopBps?: string[];
  feesOnTopUsd?: string[];
  persist?: boolean;
};

export const CartProvider: FC<CartProviderProps> = function ({
  children,
  feesOnTopBps,
  feesOnTopUsd,
  persist = true,
}) {
  useSignals();
  const chain = useMarketplaceChain();
  const { wallet } = useWallet();
  const { address } = useAccount();

  const { switchChainAsync } = useSwitchChain();
  const [cartCurrency, setCartCurrency] = useState<Cart['currency']>();
  const [cartChain, setCartChain] = useState<Cart['chain']>();
  const cartData = useRef<Cart>({
    totalPrice: 0,
    feesOnTopBps: undefined,
    feesOnTopUsd: undefined,
    items: [],
    isValidating: false,
    transaction: null,
  });

  const subscribers = useRef(new Set<() => void>());
  const [usdFeeConversion, setUsdFeeConversion] =
    useState<ConvertCurrencyResponse | null>(null);
  useEffect(() => {
    if (!cartChain?.id) return;
    wallet
      ?.convertCurrency({
        chainId: cartChain?.id.toString(),
        fromCurrency: cartCurrency?.address,
        toCurrency: 'usd',
        amount: 1,
      })
      .then((price) => {
        setUsdFeeConversion(price);
      });
  }, [cartChain?.id, cartCurrency?.address, wallet]);

  const usdPrice = Number(usdFeeConversion?.usd || 0);

  const calculatePricing = useCallback(
    (
      items: Cart['items'],
      currency?: Cart['currency'],
      feesOnTopBps?: Cart['feesOnTopBps'],
      feesOnTopUsd?: Cart['feesOnTopUsd'],
      usdPrice?: number,
    ) => {
      let feeOnTop = 0;

      const isMixCurrencies =
        items.reduce((acc, order) => {
          acc.add(order.price?.currency?.symbol!);
          return acc;
        }, new Set<string>()).size > 1;

      let subtotal = items.reduce((total, order) => {
        let amount = isMixCurrencies
          ? order.price?.amount?.native
          : order.price?.amount?.decimal;

        if (amount && order.amount && order.quantity) {
          amount = amount * (order.quantity / int(order.amount));
        }
        return (total += amount || 0);
      }, 0);

      if (feesOnTopBps) {
        feeOnTop = feesOnTopBps.reduce((total, feeOnTopBps) => {
          const [_, feeBps] = feeOnTopBps.split(':');
          total += (Number(feeBps || 0) / 10000) * subtotal;
          return total;
        }, 0);
      } else if (feesOnTopUsd && usdPrice && currency && currency?.decimals) {
        feeOnTop = feesOnTopUsd.reduce((totalFees, feeOnTop) => {
          const [_, fee] = feeOnTop.split(':');
          const atomicUsdPrice = parseUnits(`${usdPrice}`, 6);
          const atomicFee = BigInt(fee);
          const convertedAtomicFee =
            atomicFee * BigInt(10 ** (currency?.decimals || 18));
          const currencyFee = convertedAtomicFee / atomicUsdPrice;
          const parsedFee = formatUnits(currencyFee, currency.decimals || 18);
          return totalFees + Number(parsedFee);
        }, 0);
      }
      subtotal = subtotal + feeOnTop;
      return {
        totalPrice: subtotal,
        feeOnTop,
      };
    },
    [],
  );

  const getCartCurrency = useCallback(
    (items: CartItem[], chainId: number): Currency | undefined => {
      let currencies = new Set<string>();
      let currenciesData: Record<string, Currency> = {};
      for (let i = 0; i < items.length; i++) {
        const currency = items[i].price?.currency;
        if (currency?.address) {
          currencies.add(currency.address);
          currenciesData[currency.address] = currency;
        }
        if (currencies.size > 1) {
          break;
        }
      }
      if (currencies.size > 1) {
        const chainCurrency = getChainCurrency(chainId);
        return {
          ...chainCurrency,
          chainId: chainCurrency.chainId.toString(),
          address: chainCurrency.address,
        };
      } else if (currencies.size > 0) {
        return Object.values(currenciesData)[0];
      }
    },
    [],
  );

  const subscribe = useCallback((callback: () => void) => {
    subscribers.current.add(callback);
    return () => subscribers.current.delete(callback);
  }, []);

  const fetchOrders = useCallback(
    async (orderIds: string[], chainId: number) => {
      return OrderbookService.QueryOrders(
        {
          orderIds,
          chainId: chainId.toString(),
        },
        getRequestOpts(),
      );
    },
    [],
  );

  const fetchTokens = useCallback(
    async (tokenIds: string[]) => {
      const refs = tokenIds.map((id) => `${chain.id}:${id}`); // `1:${id
      return await OrderbookService.QueryCrossChainTokens(
        {
          refs,
          pageSize: (25).toString(),
        },
        getRequestOpts(),
      );
    },
    [chain.id],
  );

  const commit = useCallback(() => {
    subscribers.current.forEach((callback) => callback());
    if (persist && typeof window !== 'undefined' && window.localStorage) {
      window.localStorage.setItem(
        CartStorageKey,
        JSON.stringify(cartData.current),
      );
    }
  }, [persist]);

  const validate = useCallback(async () => {
    try {
      if (cartData.current.items.length === 0) {
        return false;
      }
      cartData.current = { ...cartData.current, isValidating: true };
      commit();

      const items = cartData.current.items
        .filter((order) => {
          const tokenRef = order.token as TokenRef;
          const collection = getCollection(tokenRef.collection as Address);

          const { canBuy } = getTokenActions({
            token: {
              ...tokenRef,
              stats: {
                floorAsk: order,
              },
            } as Token,
            collection,
            walletAddress: address,
          });
          return canBuy;
        })
        .map((order) => {
          if (order.status === OrderStatus.ACTIVE) {
            return order;
          }
          return {
            ...order,
            price: undefined,
          };
        });

      const currency = getCartCurrency(items, cartData.current.chain?.id || 1);
      const { totalPrice, feeOnTop } = calculatePricing(
        items,
        currency,
        cartData.current.feesOnTopBps,
        cartData.current.feesOnTopUsd,
        usdPrice,
      );
      cartData.current = {
        ...cartData.current,
        items,
        isValidating: false,
        totalPrice,
        feeOnTop,
        currency,
      };

      commit();
      return true;
    } catch (e) {
      if (cartData.current.isValidating) {
        cartData.current.isValidating = false;
        commit();
      }
      throw e;
    }
  }, [
    commit,
    getCartCurrency,
    calculatePricing,
    usdPrice,
    fetchOrders,
    fetchTokens,
    address,
  ]);

  useEffect(() => {
    subscribe(() => {
      setCartCurrency(cartData.current.currency);
      setCartChain(cartData.current.chain);
    });

    if (persist && typeof window !== 'undefined' && window.localStorage) {
      const storedCart = window.localStorage.getItem(CartStorageKey);
      if (storedCart) {
        const rehydratedCart: Cart = JSON.parse(storedCart);
        const currency = getCartCurrency(
          rehydratedCart.items,
          rehydratedCart.chain?.id || 1,
        );

        const { totalPrice, feeOnTop } = calculatePricing(
          rehydratedCart.items,
          currency,
          cartData.current.feesOnTopBps,
          cartData.current.feesOnTopUsd,
          usdPrice,
        );
        cartData.current = {
          ...cartData.current,
          chain:
            rehydratedCart.items.length > 0 ? rehydratedCart.chain : undefined,
          items: rehydratedCart.items,
          totalPrice,
          feeOnTop,
          currency,
        };
        subscribers.current.forEach((callback) => callback());
        validate();
      }
    }
  }, [
    calculatePricing,
    getCartCurrency,
    persist,
    subscribe,
    usdPrice,
    validate,
  ]);

  useEffect(() => {
    const currency = getCartCurrency(
      cartData.current.items,
      cartData.current.chain?.id || 1,
    );
    const { totalPrice, feeOnTop } = calculatePricing(
      cartData.current.items,
      currency,
      feesOnTopBps,
      feesOnTopUsd,
      usdPrice,
    );
    cartData.current = {
      ...cartData.current,
      totalPrice,
      feesOnTopBps,
      feesOnTopUsd,
      feeOnTop,
      currency,
    };
    commit();
  }, [feesOnTopBps, feesOnTopUsd, usdPrice]);

  const get = useCallback(() => cartData.current, []);
  const set = useCallback((value: Partial<Cart>) => {
    cartData.current = { ...cartData.current, ...value };
    commit();
  }, []);

  const clear = useCallback(() => {
    cartData.current = {
      ...cartData.current,
      items: [],
      totalPrice: 0,
      feeOnTop: 0,
      chain: undefined,
    };
    commit();
  }, [commit]);

  const clearTransaction = useCallback(() => {
    cartData.current = {
      ...cartData.current,
      transaction: null,
      pendingTransactionId: undefined,
    };
    commit();
  }, [commit]);

  const setQuantity = useCallback(
    (orderId: string, quantity: number) => {
      const updatedItems = [...cartData.current.items];
      let cartItemIndex = updatedItems.findIndex(
        (item) => item?.id === orderId,
      );
      let cartItem = updatedItems[cartItemIndex];
      if (cartItem && (quantity > 0 || quantity == -1)) {
        if (quantity > int(cartItem?.amount)) {
          quantity = int(cartItem?.amount);
        }
        {
          cartItem = {
            ...cartItem,
            quantity,
          };
          updatedItems[cartItemIndex] = cartItem;
        }
      }
      if (quantity == -1) {
        cartData.current = {
          ...cartData.current,
          items: updatedItems,
        };
      } else {
        const currency = getCartCurrency(
          updatedItems,
          cartData.current.chain?.id || 1,
        );
        const { totalPrice, feeOnTop } = calculatePricing(
          updatedItems,
          currency,
          cartData.current.feesOnTopBps,
          cartData.current.feesOnTopUsd,
          usdPrice,
        );

        cartData.current = {
          ...cartData.current,
          items: updatedItems,
          totalPrice,
          feeOnTop,
          currency,
        };
      }

      commit();
    },
    [calculatePricing, commit, getCartCurrency, usdPrice],
  );

  const add = useCallback(
    async (orders: Order[], chainId: number) => {
      try {
        if (cartData.current.chain && chainId != cartData.current.chain?.id) {
          throw `ChainId: ${chainId}, is different than the cart chainId (${cartData.current.chain?.id})`;
        }
        if (cartData.current.isValidating) {
          throw 'Currently validating, adding items temporarily disabled';
        }

        const updatedItems = [...cartData.current.items];
        orders.forEach((order) => {
          if (!updatedItems.find((item) => item.id === order.id)) {
            updatedItems.push({ ...order, quantity: 1 });
          }
        });

        const currency = getCartCurrency(updatedItems, chainId);
        const { totalPrice, feeOnTop } = calculatePricing(
          updatedItems,
          currency,
          cartData.current.feesOnTopBps,
          cartData.current.feesOnTopUsd,
          usdPrice,
        );

        cartData.current = {
          ...cartData.current,
          isValidating: false,
          items: updatedItems,
          totalPrice,
          feeOnTop,
          currency,
        };

        if (!cartData.current.chain) {
          cartData.current.chain = getChainById(chainId) as LimitBreakChain;
        }
        commit();
      } catch (e) {
        if (cartData.current.isValidating) {
          cartData.current.isValidating = false;
          commit();
        }
        throw e;
      }
    },
    [fetchTokens, commit, address, usdPrice],
  );

  /**
   * @param ids An array of order ids or token keys. Tokens should be in the format `collection:token`
   */

  const remove = useCallback(
    (ids: string[]) => {
      if (cartData.current.isValidating) {
        console.warn(
          'Currently validating, removing items temporarily disabled',
        );
        return;
      }
      const updatedItems: CartItem[] = [];
      const removedItems: CartItem[] = [];
      cartData.current.items.forEach((order) => {
        const orderId = order?.id;
        if (orderId && ids.includes(orderId)) {
          removedItems.push(order);
        } else {
          updatedItems.push(order);
        }
      });
      const currency = getCartCurrency(
        updatedItems,
        cartData.current.chain?.id || 1,
      );
      const { totalPrice, feeOnTop } = calculatePricing(
        updatedItems,
        currency,
        cartData.current.feesOnTopBps,
        cartData.current.feesOnTopUsd,
        usdPrice,
      );

      cartData.current = {
        ...cartData.current,
        items: updatedItems,
        totalPrice,
        feeOnTop,
        currency,
      };
      if (updatedItems.length === 0) {
        cartData.current.chain = undefined;
      }
      commit();
    },
    [usdPrice],
  );

  const openCheckout = useCallback(() => {
    cartData.current = {
      ...cartData.current,
      transaction: {
        chain,
        items: cartData.current.items,
        status: CheckoutStatus.Idle,
      },
    };
    commit();
  }, []);

  const checkout = useCallback(async () => {
    const activeChain = sSelectedChain.value;

    if (
      cartData.current.chain &&
      cartData.current.chain?.id !== activeChain?.id
    ) {
      const chain = await switchChainAsync?.({
        chainId: cartData.current.chain.id,
      });
      if (chain?.id !== cartData.current.chain.id) {
        throw 'Active chain does not match cart chain';
      }
    }

    if (cartData?.current?.items?.length === 0) {
      throw 'Cart is empty';
    }

    // TODO: determine if there are fees

    const feeOnTop = cartData.current.feeOnTop ? cartData.current.feeOnTop : 0;

    const transactionId = `${new Date().getTime()}`;
    cartData.current = {
      ...cartData.current,
      pendingTransactionId: transactionId,
      transaction: {
        id: transactionId,
        chain,
        items: cartData.current.items,
        status: CheckoutStatus.Approving,
      },
    };
    commit();

    return wallet
      ?.buyCrossChainListings({
        request: {
          orderIds: cartData.current.items.map((item) => item.id as string),
          orderAmounts: cartData.current.items.reduce(
            (acc, item) => {
              if (item.id) {
                acc[item.id as string] = item.quantity?.toString() || '1';
              }
              return acc;
            },
            {} as { [key: string]: string },
          ),
          paymentMethod: cartData.current.currency?.address,
        },
        onAfter: async (steps, step, item, output) => {
          if (!steps) {
            return;
          }
          if (transactionId != cartData.current.pendingTransactionId) {
            return;
          }

          let status =
            cartData.current.transaction?.status || CheckoutStatus.Approving;

          const currentStepItem = item as EnhancedStepItem;

          const currentStep = step;

          if (step.kind === StepKind.TRANSACTION) {
            status = CheckoutStatus.Complete;
            cartData.current.items = [];
            cartData.current.totalPrice = 0;
            cartData.current.currency = undefined;
            cartData.current.chain = undefined;
          }

          if (
            cartData.current.transaction?.status != status &&
            (status === CheckoutStatus.Finalizing ||
              status === CheckoutStatus.Complete)
          ) {
            cartData.current.items = [];
            cartData.current.totalPrice = 0;
            cartData.current.currency = undefined;
            cartData.current.chain = undefined;
          }

          if (cartData.current.transaction) {
            cartData.current.transaction.status = status;
            cartData.current.transaction.currentStep =
              currentStep as EnhancedStep;
            if (currentStepItem && output) {
              cartData.current.transaction.txHashes = [
                { txHash: output as `0x{string}`, chainId: chain.id },
              ];
              cartData.current.transaction.path = cartData.current.items;
            }
          }
          commit();
        },
      })
      .then((response) => {
        cartData.current.items = [];
        cartData.current.totalPrice = 0;
        cartData.current.currency = undefined;
        cartData.current.chain = undefined;
        if (cartData.current.transaction) {
          cartData.current.transaction.status = CheckoutStatus.Complete;
        }
        commit();
      })
      .catch((e) => {
        if (transactionId != cartData.current.pendingTransactionId) {
          return;
        }
        let error = e as any;
        let errorType = CheckoutTransactionError.Unknown;
        const errorStatus = (error as any)?.statusCode;
        const errorCode = (error as any)?.code || (error as any)?.cause?.code;

        if (error?.message && error?.message.includes('ETH balance')) {
          errorType = CheckoutTransactionError.InsufficientBalance;
        } else if (errorCode == 4001) {
          errorType = CheckoutTransactionError.UserDenied;
        } else {
          let message = 'Oops, something went wrong. Please try again.';
          if (errorStatus >= 400 && errorStatus < 500) {
            message = error.message;
          }
          if (error?.type && error?.type === 'price mismatch') {
            errorType = CheckoutTransactionError.PiceMismatch;
            message = error.message;
          }

          //@ts-ignore: Should be fixed in an update to typescript
          error = new Error(message, {
            cause: error,
          });
        }
        if (cartData.current.transaction) {
          cartData.current.transaction.status = CheckoutStatus.Idle;
          cartData.current.transaction.error = error;
          cartData.current.transaction.errorType = errorType;
          if (
            cartData.current.chain?.id == cartData.current.transaction.chain.id
          ) {
            const items = [...cartData.current.transaction.items];
            const currency = getCartCurrency(
              items,
              cartData.current.transaction.chain.id,
            );
            const { totalPrice, feeOnTop } = calculatePricing(
              items,
              currency,
              cartData.current.feesOnTopBps,
              cartData.current.feesOnTopUsd,
              usdPrice,
            );
            cartData.current.items = items;
            cartData.current.currency = currency;
            cartData.current.totalPrice = totalPrice;
            cartData.current.feeOnTop = feeOnTop;
            cartData.current.chain = cartData.current.transaction.chain;
          }
          commit();
          validate();
        }
        logError('Buy Listings Error', e.message);
        if (cartData.current.transaction) {
          cartData.current.transaction.error = e;
          cartData.current.transaction.status = CheckoutStatus.Idle;
        }
        throw e;
      });
  }, [commit, validate, getCartCurrency, calculatePricing, usdPrice]);

  return (
    <CartContext.Provider
      value={{
        get,
        set,
        subscribe,
        setQuantity,
        add,
        remove,
        clear,
        clearTransaction,
        validate,
        checkout,
        openCheckout,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
