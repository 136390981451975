import { Flex, Img, Text } from '@reservoir-kit-ui-overrides/primitives';
import { useMediaQuery } from 'react-responsive';
import { Collection, Token } from '@api/orderbook_api/v1/types.pb';

interface Props {
  token?: Token;
  collection?: Collection;
}

export const TokenOpSuccessPreview = ({ token, collection }: Props) => {
  const isSmallDevice = useMediaQuery({ maxWidth: 600 });

  const imageSize = isSmallDevice ? 62 : 129;
  const borderRadius = isSmallDevice ? '$radiusMedium' : '$radiusLarge';

  return (
    <Flex
      align='center'
      css={{
        width: '100%',
        gap: '$4',
        overflow: 'hidden',
        '@sm': {
          flexDirection: 'column',
        },
      }}
    >
      <Img
        src={token?.imageUrl || collection?.config?.imageUrl}
        alt={token?.name || token?.tokenId}
        css={{
          width: imageSize,
          height: imageSize,
          aspectRatio: '1/1',
          borderRadius,
        }}
      />
      <Flex direction='column' css={{ '@sm': { alignItems: 'center' } }}>
        <Text style='subtitle1' ellipsify>
          {token?.name}
        </Text>
        <Text style='subtitle1' color='subtle' ellipsify>
          {collection?.name}
        </Text>
      </Flex>
    </Flex>
  );
};
