/* eslint-disable react-hooks/rules-of-hooks */
import React, {
  ComponentPropsWithoutRef,
  ReactElement,
  useEffect,
} from 'react';
import {
  Flex,
  Box,
  Text,
  Button,
  FormatCryptoCurrency,
  Loader,
  ErrorWell,
} from '../../primitives';
import Progress from '../Progress';
import { Modal } from '../Modal';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BuyModalRenderer, BuyStep } from './BuyModalRenderer';
import ProgressBar from '../ProgressBar';
import QuantitySelector from '../QuantitySelector';
import { formatNumber } from '../../lib/numbers';
import { truncateAddress } from '../../lib/truncate';
import { SelectPaymentTokenv2 } from '../SelectPaymentTokenv2';
import getChainBlockExplorerUrl from '../../lib/getChainBlockExplorerUrl';
import { Dialog } from '../../primitives/Dialog';
import { TokenInfo, PaymentDetails } from '../../common';
import { useSignals } from '@preact/signals-react/runtime';
import { StepItem, Steps } from '@api/orderbook_api/v1/types.pb';
import { TokenOpSuccessPreview } from '@v2/components/TokenOpSuccessPreview/TokenOpSuccessPreview';
import { ViewTx } from '@v2/components/ViewTx/ViewTx';
import { useMediaQuery } from 'react-responsive';
import { TokenIconWithPriceListing } from '@reservoir-kit-ui-overrides/components/TokenIconWithPriceListing/TokenIconWithPriceListing';
import { getChainById } from '@signals/chains';
import { setModalState } from '@signals/modalState';
import { getCollection } from '@signals/collection';
import { useOpenState } from '@hooks/useOpenState';
import { trackEvent } from '@utils/analytics/events';
import { formatUnits } from 'viem';
import { TxSummary } from '@v2/components/TxSummary/TxSummary';
import { int } from '@api/utils';

type PurchaseData = {
  token?: string;
  maker?: string;
  steps?: Steps;
};

const ModalCopy = {
  titleInsufficientFunds: 'Add Funds',
  titleDefault: 'Buy',
  ctaClose: 'Close',
  ctaCheckout: 'Checkout',
  ctaConnect: 'Connect',
  ctaInsufficientFunds: 'Add Funds',
  ctaGoToToken: '',
  ctaAwaitingValidation: 'Waiting for transaction to be validated',
  ctaAwaitingApproval: 'Waiting for approval',
  ctaCopyAddress: 'Copy Wallet Address',
};

type Props = Pick<Parameters<typeof Modal>['0'], 'trigger'> & {
  token?: string;
  collectionAddress?: string;
  orderId?: string;
  creditCardCheckoutButton?: JSX.Element;
  chainId?: number;
  defaultQuantity?: number;
  feesOnTopBps?: string[] | null;
  feesOnTopUsd?: string[] | null;
  normalizeRoyalties?: boolean;
  copyOverrides?: Partial<typeof ModalCopy>;
  usePermit?: boolean;
  onGoToToken?: () => any;
  onPurchaseComplete?: (data: PurchaseData) => void;
  onPurchaseError?: (error: Error, data: PurchaseData) => void;
  onClose?: (currentStep: BuyStep) => void;
  onPointerDownOutside?: ComponentPropsWithoutRef<
    typeof Dialog
  >['onPointerDownOutside'];
};

export function BuyModal({
  trigger,
  token,
  collectionAddress,
  orderId,
  chainId,
  feesOnTopBps,
  feesOnTopUsd,
  normalizeRoyalties,
  defaultQuantity,
  copyOverrides,
  usePermit,
  onPurchaseComplete,
  onPurchaseError,
  onClose,
  onGoToToken,
  onPointerDownOutside,
  creditCardCheckoutButton,
}: Props): ReactElement {
  useSignals();
  const copy: typeof ModalCopy = { ...ModalCopy, ...copyOverrides };
  const { isOpen, open, setIsOpen, createCloseHandler } = useOpenState({
    onChange: setModalState,
  });

  const isSmallDevice = useMediaQuery({ maxWidth: 600 });
  const collection = getCollection(collectionAddress);
  const modalChain = getChainById(Number(collection?.chainId));

  useEffect(() => {
    if (isOpen) {
      trackEvent('buy_modal_opened');
    }
  }, [isOpen]);

  if (!isOpen) {
    return <div onClick={open}>{trigger}</div>;
  }

  return (
    <BuyModalRenderer
      chainId={modalChain?.id}
      defaultQuantity={defaultQuantity}
      token={token}
      collectionAddress={collectionAddress}
      orderId={orderId}
      feesOnTopBps={feesOnTopBps}
      feesOnTopUsd={feesOnTopUsd}
      normalizeRoyalties={normalizeRoyalties}
      usePermit={usePermit}
    >
      {({
        loading,
        isFetchingPath,
        tokenData,
        collection,
        quantityAvailable,
        quantity,
        averageUnitPrice,
        totalIncludingFees,
        buyResponseFees,
        feeOnTop,
        paymentCurrency,
        paymentTokens,
        buyStep,
        transactionError,
        hasEnoughCurrency,
        addFundsLink,
        steps,
        stepData,
        feeUsd,
        totalUsd,
        usdPrice,
        balance,
        address,
        blockExplorerBaseName,
        isConnected,
        isOwner,
        setPaymentCurrency,
        setQuantity,
        setBuyStep,
        buyToken,
      }) => {
        useEffect(() => {
          if (buyStep === BuyStep.Complete && onPurchaseComplete) {
            const data: PurchaseData = {
              token,
              maker: address,
            };
            if (steps) {
              data.steps = steps;
            }
            onPurchaseComplete(data);
          }
        }, [buyStep]);

        const usdTotal = formatUnits(
          ((paymentCurrency?.currencyTotalRaw || 0n) + feeOnTop) *
            (paymentCurrency?.usdPriceRaw || 0n),
          (paymentCurrency?.decimals || 18) + 6,
        );

        const close = createCloseHandler(() => {
          trackEvent('buy_modal_closed');
          onClose?.(buyStep);
        });

        useEffect(() => {
          if (transactionError && onPurchaseError) {
            const data: PurchaseData = {
              token,
              maker: address,
            };
            onPurchaseError(transactionError, data);
          }
        }, [transactionError]);

        const executableSteps =
          steps?.steps?.filter((step) => step.items && step.items.length > 0) ||
          [];
        const lastStepItems =
          executableSteps[executableSteps.length - 1]?.items || [];

        const totalPurchases =
          stepData?.currentStep?.items?.reduce(
            (total: number, item: StepItem) => {
              if (item.data?.buyListing) {
                total += int(item.data.buyListing?.order?.takerAmount || '1');
              }
              item.data?.buyListings?.items?.forEach((transferData) => {
                total += int(transferData.takerAmount || '1');
              });
              return total;
            },
            0,
          ) || 0;

        const failedPurchases = quantity - totalPurchases;
        const successfulPurchases = quantity - failedPurchases;
        const finalTxHashes = lastStepItems[lastStepItems.length - 1]?.txHashes;

        return (
          <Modal
            trigger={trigger}
            title={copy.titleDefault}
            open={true}
            onPointerDownOutside={(e) => {
              const dismissableLayers = Array.from(
                document.querySelectorAll('div[data-radix-dismissable]'),
              );
              const clickedDismissableLayer = dismissableLayers.some((el) =>
                e.target ? el.contains(e.target as Node) : false,
              );

              if (!clickedDismissableLayer && dismissableLayers.length > 0) {
                e.preventDefault();
              }
              if (onPointerDownOutside) {
                onPointerDownOutside(e);
              }
            }}
            onOpenChange={setIsOpen}
            loading={loading}
          >
            {buyStep === BuyStep.Unavailable && !loading && (
              <Flex direction='column' css={{ height: '100%' }}>
                <Flex
                  direction='column'
                  align='center'
                  css={{ py: '$6', px: '$4', gap: '$4' }}
                >
                  <Text style='subtitle1' css={{ textAlign: 'center' }}>
                    {isOwner
                      ? 'You already own this token.'
                      : 'Item is no longer available.'}
                  </Text>
                </Flex>
                <Box css={{ flexGrow: 1 }} />
                <Box
                  css={{
                    p: '$modalContentPadding',
                    borderTop: '1px solid $borderColor',
                  }}
                >
                  <Button
                    color='onlyBorder'
                    onClick={close}
                    css={{
                      width: '100%',
                    }}
                  >
                    {copy.ctaClose}
                  </Button>
                </Box>
              </Flex>
            )}

            {buyStep === BuyStep.SelectPayment && (
              <Flex direction='column' css={{ height: '100%' }}>
                <Flex
                  direction='column'
                  css={{ p: '$modalContentPadding', gap: '$4' }}
                >
                  <Text style='subtitle1'>Select Payment Method</Text>
                  <SelectPaymentTokenv2
                    paymentTokens={paymentTokens}
                    currency={paymentCurrency}
                    setCurrency={setPaymentCurrency}
                    goBack={() => setBuyStep(BuyStep.Checkout)}
                    itemAmount={quantity}
                    chainId={modalChain?.id || 1}
                  />
                </Flex>
                <Box css={{ flexGrow: 1 }} />
                <Box
                  css={{
                    p: '$modalContentPadding',
                    borderTop: '1px solid $borderColor',
                  }}
                >
                  <Button
                    color='onlyBorder'
                    onClick={() => setBuyStep(BuyStep.Checkout)}
                    css={{
                      width: '100%',
                    }}
                  >
                    {copy.ctaClose}
                  </Button>
                </Box>
              </Flex>
            )}

            {buyStep === BuyStep.Checkout && !loading && (
              <Flex direction='column' css={{ height: '100%' }}>
                {transactionError && <ErrorWell error={transactionError} />}
                <Flex
                  direction='row'
                  justify='between'
                  align='center'
                  css={{
                    gap: '$2',
                    p: '$modalContentPadding',
                    borderBottom: '1px solid $neutralBorder',
                  }}
                >
                  {isSmallDevice ? (
                    <TokenIconWithPriceListing
                      chainId={modalChain.id}
                      img={tokenData?.imageUrlSmall}
                      name={tokenData?.name}
                      price={{
                        amount: {
                          decimal: paymentCurrency?.currencyTotalRaw as any,
                        },
                        currency: paymentCurrency as any,
                      }}
                      totalUsd={usdTotal as any}
                    />
                  ) : (
                    <>
                      <TokenInfo
                        token={tokenData}
                        chain={modalChain}
                        collection={collection}
                      />
                      <PaymentDetails
                        feeOnTop={feeOnTop}
                        feeUsd={feeUsd}
                        crosschainFees={buyResponseFees}
                        chainId={modalChain?.id}
                        paymentCurrency={paymentCurrency}
                        loading={isFetchingPath}
                        css={{ width: 'auto' }}
                        isCompact
                      />
                    </>
                  )}
                </Flex>
                {quantityAvailable > 1 && (
                  <Flex
                    css={{
                      p: '$modalContentPadding',
                      borderBottom: '1px solid $borderColor',
                    }}
                    justify='between'
                  >
                    <Flex direction='column' css={{ gap: '$1' }}>
                      <Text style='subtitle1'>Quantity</Text>
                      <Text style='body2' color='subtle'>
                        {formatNumber(quantityAvailable)} items available
                      </Text>
                    </Flex>
                    <QuantitySelector
                      min={1}
                      max={quantityAvailable}
                      quantity={quantity}
                      setQuantity={(quantity) => {
                        setQuantity(quantity);
                      }}
                    />
                  </Flex>
                )}
                <Box css={{ flexGrow: 1 }} />
                <Flex direction='column'>
                  {paymentTokens.length > 1 ? (
                    <Flex
                      direction='column'
                      css={{
                        gap: '$2',
                        p: '$modalContentPadding',
                        py: '$4',
                        borderRadius: '$radiusMedium',
                        borderBottom: '1px solid $borderColor',
                        '&:hover': {
                          backgroundColor: '$neutralBgHover',
                        },
                      }}
                      onClick={() => setBuyStep(BuyStep.SelectPayment)}
                    >
                      <Flex
                        justify='between'
                        align='center'
                        css={{
                          gap: '$1',
                        }}
                      >
                        <Text style='body1' color='subtle'>
                          Payment Method
                        </Text>
                        <Flex
                          align='center'
                          css={{ gap: '$3', cursor: 'pointer' }}
                        >
                          <Flex align='center'>
                            <Text style='subtitle1'>
                              {paymentCurrency?.symbol}
                            </Text>
                          </Flex>
                          <Box>
                            <FontAwesomeIcon icon={faChevronRight} width={10} />
                          </Box>
                        </Flex>
                      </Flex>
                    </Flex>
                  ) : null}
                  <Box css={{ p: '$modalContentPadding', pb: '$2' }}>
                    <PaymentDetails
                      feeOnTop={feeOnTop}
                      feeUsd={feeUsd}
                      crosschainFees={buyResponseFees}
                      chainId={modalChain?.id}
                      paymentCurrency={paymentCurrency}
                      loading={isFetchingPath}
                    />
                  </Box>
                </Flex>

                <Box css={{ p: '$modalContentPadding', width: '100%' }}>
                  {hasEnoughCurrency || !isConnected ? (
                    <>
                      <Button
                        disabled={!hasEnoughCurrency && isConnected}
                        onClick={buyToken}
                        css={{ width: '100%' }}
                        color='primary'
                      >
                        {!isConnected ? copy.ctaConnect : copy.ctaCheckout}
                      </Button>
                      {creditCardCheckoutButton && creditCardCheckoutButton}
                    </>
                  ) : (
                    <Flex direction='column' align='center'>
                      <Flex align='center' css={{ mb: '$3' }}>
                        <Text css={{ mr: '$3' }} color='error' style='body3'>
                          Insufficient Balance
                          {paymentTokens.length > 1
                            ? ', select another token or add funds'
                            : null}
                        </Text>

                        <FormatCryptoCurrency
                          chainId={modalChain?.id}
                          amount={
                            (paymentCurrency?.currencyTotalRaw ?? 0n) + feeOnTop
                          }
                          address={paymentCurrency?.address}
                          decimals={paymentCurrency?.decimals}
                          symbol={paymentCurrency?.symbol}
                          textStyle='body3'
                        />
                      </Flex>

                      <Button
                        color='onlyBorder'
                        onClick={close}
                        css={{ width: '100%' }}
                      >
                        {copy.ctaClose}
                      </Button>
                    </Flex>
                  )}
                </Box>
              </Flex>
            )}

            {buyStep === BuyStep.Approving && token && (
              <Flex direction='column' css={{ height: '100%' }}>
                <Flex
                  direction='row'
                  justify='between'
                  align='center'
                  css={{
                    gap: '$2',
                    p: '$modalContentPadding',
                    borderBottom: '1px solid $neutralBorder',
                  }}
                >
                  {isSmallDevice ? (
                    <TokenIconWithPriceListing
                      chainId={modalChain.id}
                      img={tokenData?.imageUrlSmall}
                      name={tokenData?.name}
                      price={{
                        amount: {
                          decimal: paymentCurrency?.currencyTotalRaw as any,
                        },
                        currency: paymentCurrency as any,
                      }}
                      totalUsd={usdTotal as any}
                    />
                  ) : (
                    <>
                      <TokenInfo
                        token={tokenData}
                        chain={modalChain}
                        collection={collection}
                      />
                      <PaymentDetails
                        feeOnTop={feeOnTop}
                        feeUsd={feeUsd}
                        crosschainFees={buyResponseFees}
                        chainId={modalChain?.id}
                        paymentCurrency={paymentCurrency}
                        loading={isFetchingPath}
                        css={{ width: 'auto' }}
                        isCompact
                      />
                    </>
                  )}
                </Flex>
                {stepData && stepData.totalSteps > 1 && (
                  <ProgressBar
                    css={{ m: '$modalContentPadding' }}
                    value={stepData?.stepProgress || 0}
                    max={stepData?.totalSteps || 0}
                  />
                )}
                {!stepData && <Loader css={{ height: 206 }} />}
                {stepData && (
                  <Flex
                    direction='column'
                    css={{ p: '$modalContentPadding', overflowY: 'auto' }}
                  >
                    <Flex
                      direction='column'
                      align='center'
                      justify='center'
                      css={{
                        color: '$neutralBorderHover',
                        flex: 1,
                        gap: '$5',
                      }}
                    >
                      <Flex css={{ py: '$4' }}>
                        <Loader />
                      </Flex>
                    </Flex>
                    <Flex
                      align='center'
                      justify='center'
                      css={{
                        width: '100%',
                      }}
                    >
                      <Progress
                        title='Confirm transaction in your wallet'
                        txList={stepData?.currentStepItem.txHashes}
                      />
                    </Flex>
                  </Flex>
                )}
                <Box css={{ flexGrow: 1 }} />
                <Box
                  css={{
                    p: '$modalContentPadding',
                    borderTop: '1px solid $borderColor',
                  }}
                >
                  <Button disabled={true} css={{ width: '100%' }}>
                    {stepData?.currentStepItem?.txHashes
                      ? copy.ctaAwaitingValidation
                      : copy.ctaAwaitingApproval}
                  </Button>
                </Box>
              </Flex>
            )}

            {buyStep === BuyStep.Complete && token && (
              <Flex direction='column' css={{ height: '100%' }}>
                <Flex
                  css={{
                    p: '$modalContentPadding',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                  }}
                >
                  {isSmallDevice ? (
                    <TokenIconWithPriceListing
                      chainId={modalChain.id}
                      img={tokenData?.imageUrlSmall}
                      name={tokenData?.name}
                      price={{
                        amount: {
                          decimal: paymentCurrency?.currencyTotalRaw as any,
                        },
                        currency: paymentCurrency as any,
                      }}
                      totalUsd={usdTotal as any}
                    />
                  ) : (
                    <TokenOpSuccessPreview
                      token={tokenData}
                      collection={collection}
                    />
                  )}
                </Flex>
                <Flex
                  direction='column'
                  align='start'
                  css={{
                    width: '100%',
                    p: '$modalContentPadding',
                    borderTop: '1px solid $borderColor',
                    gap: '$1',
                  }}
                >
                  <Flex direction='column' align='center' css={{ gap: '$2' }}>
                    <TxSummary txList={stepData?.currentStepItem?.txHashes} />
                  </Flex>
                </Flex>
                <Box css={{ flexGrow: 1 }} />
                <Flex
                  css={{
                    p: '$modalContentPadding',
                    width: '100%',
                    flexDirection: 'column',
                    borderTop: '1px solid $borderColor',
                    gap: '$3',
                    '@bp1': {
                      flexDirection: 'row',
                    },
                  }}
                >
                  {!!onGoToToken ? (
                    <>
                      <Button
                        color='onlyBorder'
                        onClick={close}
                        css={{ flex: 1 }}
                      >
                        {copy.ctaClose}
                      </Button>
                      <Button
                        style={{ flex: 1 }}
                        color='primary'
                        onClick={() => {
                          onGoToToken();
                        }}
                      >
                        {copy.ctaGoToToken.length > 0
                          ? copy.ctaGoToToken
                          : `Go to ${
                              successfulPurchases > 1 ? 'Tokens' : 'Token'
                            }`}
                      </Button>
                    </>
                  ) : (
                    <Button
                      color='onlyBorder'
                      onClick={close}
                      css={{
                        width: '100%',
                      }}
                    >
                      {copy.ctaClose}
                    </Button>
                  )}
                </Flex>
              </Flex>
            )}
          </Modal>
        );
      }}
    </BuyModalRenderer>
  );
}

BuyModal.Custom = BuyModalRenderer;
