import React, { FC, useEffect, useState, useCallback, ReactNode } from 'react';
import {
  useAccount,
  useBalance,
  useReadContracts,
  useSwitchChain,
} from 'wagmi';
import { mainnet, goerli } from 'wagmi/chains';
import { ExpirationOption } from '../../types/ExpirationOption';
import expirationOptions from '../../lib/defaultExpirationOptions';
import dayjs from 'dayjs';
import wrappedContractNames from '../../constants/wrappedContractNames';
import wrappedContracts from '../../constants/wrappedContracts';
import { BidRequestData, FetchBalanceResult } from '../bid/BidModalRenderer';
import { formatBN } from '../../lib/numbers';
import { Address, erc20Abi, parseUnits, zeroAddress } from 'viem';
import useCollectionAttributes from '@hooks/useCollectionAttributes';
import paymentProcessorExchange from '@utils/paymentProcessorExchange';
import { EnhancedStep, EnhancedStepItem, EnhancedSteps } from '@types';
import {
  Collection,
  Currency,
  Order,
  StepItemStatus,
  Token,
  TokenAttribute,
  TokenAttributeValue,
} from '@api/orderbook_api/v1/types.pb';
import {
  useChainCurrency,
  useCoinConversion,
  useMarketplaceChain,
} from '@hooks';
import useCollectionOrders from '@hooks/useCollectionOrders';
import { int } from '@api/utils';
import * as allChains from 'viem/chains';
import { getCollection } from '@signals/collection';
import useCollectionTokens from '@hooks/useCollectionTokens';
import { useWallet } from '@hooks/useWallet';
import { useWatchQueryKey } from '@hooks/useWatchQueryKey';
import { OrderProtocol } from '@api/payment_processor/v2/payment_processor.pb';

type Exchange = NonNullable<typeof paymentProcessorExchange>;

export enum EditBidStep {
  Edit,
  Approving,
  Complete,
}

export type EditBidStepData = {
  totalSteps: number;
  stepProgress: number;
  currentStep: EnhancedStep;
  currentStepItem: NonNullable<EnhancedStepItem>;
};

type ChildrenProps = {
  loading: boolean;
  bid?: NonNullable<Order>;
  attributes?: TokenAttribute[];
  trait?: TokenAttributeValue;
  tokenId?: string;
  contract?: string;
  isOracleOrder: boolean;
  isTokenBid: boolean;
  quantity: number;
  bidAmountPerUnit: string;
  totalBidAmount: number;
  totalBidAmountUsd: number;
  token?: NonNullable<Token>;
  currency?: Currency;
  collection?: NonNullable<Collection>;
  editBidStep: EditBidStep;
  transactionError?: Error | undefined;
  hasEnoughNativeCurrency: boolean;
  hasEnoughWrappedCurrency: boolean;
  balance?: FetchBalanceResult;
  wrappedBalance?: BigInt;
  wrappedCurrency?: Currency;
  wrappedContractName: string;
  wrappedContractAddress: string;
  amountToWrap: string;
  canAutomaticallyConvert: boolean;
  expirationOptions: ExpirationOption[];
  expirationOption: ExpirationOption;
  steps: EnhancedSteps | undefined;
  stepData: EditBidStepData | undefined;
  traitBidSupported: boolean;
  collectionBidSupported: boolean;
  setTrait: React.Dispatch<
    React.SetStateAction<TokenAttributeValue | undefined>
  >;
  setBidAmountPerUnit: React.Dispatch<React.SetStateAction<string>>;
  setExpirationOption: React.Dispatch<React.SetStateAction<ExpirationOption>>;
  setEditBidStep: React.Dispatch<React.SetStateAction<EditBidStep>>;
  editBid: () => void;
};

type Props = {
  bidId?: string;
  tokenId?: string;
  attribute?: TokenAttributeValue;
  collectionId?: string;
  children: (props: ChildrenProps) => ReactNode;
};

export const EditBidModalRenderer: FC<Props> = ({
  bidId,
  tokenId,
  collectionId,
  attribute,
  children,
}) => {
  const rendererChain = useMarketplaceChain();
  const { wallet } = useWallet();
  const { address } = useAccount();
  // const wallet = account.wallet;
  const { switchChainAsync } = useSwitchChain();
  let { chain: activeWalletChain } = useAccount();

  const [editBidStep, setEditBidStep] = useState<EditBidStep>(EditBidStep.Edit);
  const [transactionError, setTransactionError] = useState<Error | undefined>();
  const [stepData, setStepData] = useState<EditBidStepData | undefined>();
  const [steps, setSteps] = useState<EnhancedSteps | undefined>();

  const [bidAmountPerUnit, setBidAmountPerUnit] = useState<string>('');
  const [quantity, setQuantity] = useState(1);

  const [expirationOption, setExpirationOption] = useState<ExpirationOption>(
    expirationOptions[5],
  );
  const [hasEnoughNativeCurrency, setHasEnoughNativeCurrency] = useState(false);
  const [hasEnoughWrappedCurrency, setHasEnoughWrappedCurrency] =
    useState(false);
  const [amountToWrap, setAmountToWrap] = useState('');
  const [trait, setTrait] = useState<TokenAttributeValue | undefined>(
    attribute,
  );
  const [attributes, setAttributes] = useState<TokenAttribute[]>();

  const { data: bids } = useCollectionOrders(
    {
      orderIds: [bidId as string],
    },
    {
      revalidateFirstPage: true,
    },
  );

  const bid = bids && bids[0] ? bids[0] : undefined;

  const collection = getCollection(bid?.collection || collectionId);
  const chainCurrency = useChainCurrency(Number(collection?.chainId));

  const nativeWrappedContractAddress =
    chainCurrency.chainId in wrappedContracts
      ? wrappedContracts[int(chainCurrency.chainId)]
      : wrappedContracts[1];
  const nativeWrappedContractName =
    chainCurrency.chainId in wrappedContractNames
      ? wrappedContractNames[int(chainCurrency.chainId)]
      : wrappedContractNames[1];

  const exchange = paymentProcessorExchange;

  const traitBidSupported = Boolean(exchange?.traitBidSupported);
  const collectionBidSupported = Boolean(exchange?.collectionBidSupported);

  const contract = bid?.collection;
  const currency = bid?.price?.currency;

  const isOracleOrder = bid?.useOffChainCancellation || false;

  const wrappedContractAddress = currency
    ? (currency.address as string)
    : nativeWrappedContractAddress;

  const wrappedContractName = currency
    ? (currency.symbol as string)
    : nativeWrappedContractName;

  useEffect(() => {
    if (bid?.price?.amount?.decimal) {
      setBidAmountPerUnit(bid?.price?.amount?.decimal.toString());
    }
    const quantityRemaining = int(bid?.amount) - int(bid?.amountFilled);
    if (quantityRemaining > 1) {
      setQuantity(quantityRemaining);
    }
    //@ts-ignore
    if (bid?.criteria?.kind == 'attribute' && bid?.criteria?.data.attribute) {
      //@ts-ignore
      setTrait(bid?.criteria?.data?.attribute);
    }
  }, [bid]);

  const coinConversion = useCoinConversion(
    bid ? 'USD' : undefined,
    wrappedContractName,
  );
  const usdPrice = coinConversion.length > 0 ? coinConversion[0].price : null;

  const totalBidAmount = Number(bidAmountPerUnit) * Math.max(1, quantity);
  const totalBidAmountUsd = totalBidAmount * (usdPrice || 0);

  const { data: dataBalance, queryKey: queryKeyBalance } = useBalance({
    address: address,
    chainId: rendererChain?.id,
  });

  const { data: dataWrappedBalance, queryKey: queryKeyWrappedBalance } =
    useReadContracts({
      allowFailure: false,
      contracts: [
        {
          address: wrappedContractAddress as Address,
          abi: erc20Abi,
          functionName: 'balanceOf',
          chainId: rendererChain?.id,
          args: [address as Address],
        },
      ],
      query: {
        enabled: address && wrappedContractAddress !== zeroAddress,
      },
    });

  useWatchQueryKey({ queryKey: queryKeyBalance });
  useWatchQueryKey({ queryKey: queryKeyWrappedBalance });

  const wrappedBalanceValue = dataWrappedBalance?.[0] ?? BigInt(0);

  const canAutomaticallyConvert =
    !currency || currency.address === nativeWrappedContractAddress;
  let convertLink: string = '';
  const wagmiChain: allChains.Chain | undefined = Object.values(allChains).find(
    ({ id }) => rendererChain?.id === id,
  );
  if (canAutomaticallyConvert) {
    convertLink =
      wagmiChain?.id === mainnet.id || wagmiChain?.id === goerli.id
        ? `https://app.uniswap.org/#/swap?theme=dark&exactAmount=${amountToWrap}&chain=${
            wagmiChain?.name || 'mainnet'
          }&inputCurrency=eth&outputCurrency=${wrappedContractAddress}`
        : `https://app.uniswap.org/#/swap?theme=dark&exactAmount=${amountToWrap}`;
  } else {
    convertLink = `https://jumper.exchange/?toChain=${wagmiChain?.id}&toToken=${wrappedContractAddress}`;
  }

  const isTokenBid = !!bid?.token;

  const { data } = useCollectionAttributes(
    !isTokenBid ? (collectionId as Address) : undefined,
    rendererChain?.id,
  );
  const collectionAttributes = data?.attributes;

  const { data: tokens } = useCollectionTokens(
    contract && tokenId
      ? {
          tokenIds: [tokenId],
        }
      : {},
    {
      revalidateFirstPage: true,
    },
  );

  const token = tokens && tokens.length > 0 ? tokens[0] : undefined;

  useEffect(() => {
    if (totalBidAmount !== 0) {
      const bid = parseUnits(`${totalBidAmount}`, currency?.decimals || 18);

      if (wrappedBalanceValue < bid) {
        setHasEnoughWrappedCurrency(false);
        const amountToWrap = bid - wrappedBalanceValue;
        setAmountToWrap(formatBN(amountToWrap, 5));

        if (!dataBalance?.value || dataBalance.value < amountToWrap) {
          setHasEnoughNativeCurrency(false);
        } else {
          setHasEnoughNativeCurrency(true);
        }
      } else {
        setHasEnoughWrappedCurrency(true);
        setHasEnoughNativeCurrency(true);
        setAmountToWrap('');
      }
    } else {
      setHasEnoughNativeCurrency(true);
      setHasEnoughWrappedCurrency(true);
      setAmountToWrap('');
    }
  }, [totalBidAmount, dataBalance, dataWrappedBalance]);

  useEffect(() => {
    const validAttributes = collectionAttributes
      ? collectionAttributes.filter(
          (attribute) => attribute.values && attribute.values.length > 0,
        )
      : undefined;
    setAttributes(validAttributes);
  }, [collectionAttributes]);

  useEffect(() => {
    setTrait(attribute);
  }, [attribute]);

  const editBid = useCallback(async () => {
    if (!wallet) {
      const error = new Error('Missing a wallet/signer');
      setTransactionError(error);
      throw error;
    }

    if (activeWalletChain && rendererChain?.id !== activeWalletChain?.id) {
      activeWalletChain = await switchChainAsync({
        chainId: rendererChain?.id as number,
      });
    }

    if (rendererChain?.id !== activeWalletChain?.id) {
      const error = new Error(`Mismatching chainIds`);
      setTransactionError(error);
      throw error;
    }

    if (!bidId) {
      const error = new Error('Missing bid id to edit');
      setTransactionError(error);
      throw error;
    }

    if (!isOracleOrder) {
      const error = new Error('Not an oracle powered offer');
      setTransactionError(error);
      throw error;
    }

    if (!exchange) {
      const error = new Error('Missing Exchange');
      setTransactionError(error);
      throw error;
    }

    setTransactionError(undefined);

    let expirationTime: string | null = null;

    if (expirationOption.relativeTime && expirationOption.relativeTimeUnit) {
      expirationTime = dayjs()
        .add(expirationOption.relativeTime, expirationOption.relativeTimeUnit)
        .unix()
        .toString();
    }

    const atomicBidAmount = parseUnits(
      `${totalBidAmount}`,
      currency?.decimals || 18,
    ).toString();

    // TODO: check if we can do trait bids
    const bidRequest: BidRequestData = {
      itemPrice: atomicBidAmount,
      orderbook: bid?.orderbook,
      kind: bid?.kind as any,
      // attributeKey: trait?.key,
      // attributeValue: trait?.value,
      protocol: token?.isErc1155
        ? OrderProtocol.ERC1155_FILL_OR_KILL
        : OrderProtocol.ERC721_FILL_OR_KILL,
    };

    bidRequest.tokenId = tokenId;
    bidRequest.collection = collectionId;

    if (nativeWrappedContractAddress != wrappedContractAddress) {
      bidRequest.paymentMethod = wrappedContractAddress;
    } else {
      bidRequest.paymentMethod = currency?.address;
    }

    if (expirationTime) {
      bidRequest.expiration = expirationTime;
    }

    setEditBidStep(EditBidStep.Approving);

    wallet
      .createBid({
        request: bidRequest,
        beforeStep: async (steps, step, item) => {
          if (!steps) {
            return;
          }
          setSteps(steps.steps as EnhancedSteps);

          const executableSteps = steps.steps?.filter(
            (step) => step.items && step.items.length > 0,
          );
          const stepCount = executableSteps?.length || 0;
          const currentStepItem = item as EnhancedStepItem;
          const currentStep = step as EnhancedStep;

          const currentStepIndex = executableSteps?.findIndex((step) => {
            step.items?.find(
              (item) => item.status === StepItemStatus.INCOMPLETE,
            );
            return currentStepItem;
          });

          if (currentStepItem) {
            setStepData({
              totalSteps: stepCount,
              stepProgress: currentStepIndex || 0,
              currentStep,
              currentStepItem,
            });
          }
        },
      })
      .catch((error: Error) => {
        setTransactionError(error);
        setEditBidStep(EditBidStep.Edit);
        setStepData(undefined);
        setSteps(undefined);
      })
      .finally(() => {
        setEditBidStep(EditBidStep.Complete);
      });
  }, [
    wallet,
    rendererChain?.id,
    bidId,
    isOracleOrder,
    exchange,
    expirationOption.relativeTime,
    expirationOption.relativeTimeUnit,
    totalBidAmount,
    currency?.decimals,
    currency?.address,
    bid?.orderbook,
    bid?.kind,
    tokenId,
    collectionId,
    nativeWrappedContractAddress,
    wrappedContractAddress,
  ]);

  return (
    <>
      {children({
        loading: !bid || !collection,
        bid,
        attributes,
        tokenId,
        contract,
        quantity,
        bidAmountPerUnit,
        totalBidAmount,
        totalBidAmountUsd,
        token,
        collection,
        editBidStep,
        transactionError,
        hasEnoughNativeCurrency,
        hasEnoughWrappedCurrency,
        balance: dataBalance,
        wrappedBalance: wrappedBalanceValue,
        wrappedCurrency: currency,
        wrappedContractName,
        wrappedContractAddress,
        amountToWrap,
        canAutomaticallyConvert,
        expirationOptions,
        expirationOption,
        steps,
        stepData,
        traitBidSupported,
        collectionBidSupported,
        setTrait,
        setBidAmountPerUnit,
        setExpirationOption,
        setEditBidStep,
        editBid,
        trait, // Add trait property
        isOracleOrder, // Add isOracleOrder property
        isTokenBid, // Add isTokenBid property
        currency, // Add currency property
      })}
    </>
  );
};
