import React, { FC, useMemo } from 'react';
import { Dropdown, DropdownMenuItem } from '../../primitives/Dropdown';
import { Button, Flex, FormatCryptoCurrency, Text } from '../../primitives';
import { IconDot } from '@v2/icons/IconDot';
import { Currency, Token } from '@api/orderbook_api/v1/types.pb';
import { trackEvent } from '@utils/analytics/events';

type FloorDropdownProps = {
  token?: Token;
  currency: Currency;
  defaultCurrency?: Currency;
  setPrice: React.Dispatch<React.SetStateAction<string>>;
  setCurrency: (currency: Currency) => void;
};

const FloorDropdown: FC<FloorDropdownProps> = ({
  token,
  currency,
  defaultCurrency,
  setPrice,
  setCurrency,
}) => {
  const highestTraitFloor = useMemo(() => {
    if (token?.attributes && token?.attributes?.length < 2000) {
      return Math.max(
        ...token.attributes.map((attr: any) => Number(attr.floorAskPrice)),
        0,
      );
    }
  }, [token?.attributes]);

  const recentSalePrice = token?.stats?.lastSale?.price;
  const decimalSalePrice = recentSalePrice?.amount?.decimal;
  // TODO: see if we can get native currency added to the API
  // const nativeSalePrice = recentSalePrice?.amount?.native;
  const usdSalePrice = recentSalePrice?.amount?.usd;

  const recentSale = useMemo(() => {
    // If currency matches recent sale currency, use decimal floor price
    if (
      currency.address === recentSalePrice?.currency?.address &&
      decimalSalePrice
    ) {
      return {
        address: currency.address,
        amount: decimalSalePrice,
      };
    }

    // If currency is USDC, use usd recent sale price
    else if (currency.symbol === 'USDC' && usdSalePrice) {
      return {
        address: currency.address,
        amount: usdSalePrice,
      };
    }
    // Fallback to native sale price
    // else if (nativeSalePrice && defaultCurrency) {
    //   return {
    //     address: defaultCurrency.address,
    //     amount: nativeSalePrice,
    //   };
    // }
  }, [token, currency]);

  if ((highestTraitFloor || recentSale) && defaultCurrency)
    return (
      <Dropdown
        contentProps={{ align: 'end', sideOffset: 10, style: { width: 265 } }}
        trigger={
          <Button
            color='ghost'
            size='none'
            css={{
              height: 50,
              px: '$2',
              borderRadius: '$radiusSmall',
              fontSize: 24,
            }}
          >
            <Flex align='center' css={{ color: '$textColor', gap: '$1' }}>
              <IconDot width={8} height={8} />
              <IconDot width={8} height={8} />
              <IconDot width={8} height={8} />
            </Flex>
          </Button>
        }
      >
        <Flex direction='column' css={{ width: '100%', gap: '$3' }}>
          {highestTraitFloor ? (
            <DropdownMenuItem
              onClick={() => {
                setPrice(highestTraitFloor.toString());
                setCurrency(defaultCurrency);
              }}
            >
              <Flex justify='between' align='center' css={{ gap: '$2' }}>
                <Text style='subtitle1' css={{ whiteSpace: 'nowrap' }}>
                  Highest Trait
                </Text>
                <FormatCryptoCurrency
                  chainId={Number(token?.chainId)}
                  amount={highestTraitFloor}
                  textStyle='h6'
                />
              </Flex>
            </DropdownMenuItem>
          ) : null}
          {recentSale ? (
            <DropdownMenuItem
              onClick={() => {
                trackEvent('listing_modal_price_set_to_recent');
                setPrice(recentSale?.amount?.toString());
                if (currency.address != recentSale.address) {
                  setCurrency(defaultCurrency);
                }
              }}
            >
              <Flex justify='between' align='center' css={{ gap: '$2' }}>
                <Text style='subtitle1' css={{ whiteSpace: 'nowrap' }}>
                  Recent Sale
                </Text>
                <FormatCryptoCurrency
                  chainId={Number(token?.chainId)}
                  address={recentSale?.address}
                  amount={recentSale?.amount}
                  textStyle='h6'
                />
              </Flex>
            </DropdownMenuItem>
          ) : null}
        </Flex>
      </Dropdown>
    );

  return null;
};

export default FloorDropdown;
