const WalletEvents = [
  { action: 'step_wallet_connecting', category: 'wallet' },
  { action: 'step_wallet_connected', category: 'wallet' },
  { action: 'step_wallet_canceled', category: 'wallet' },
  { action: 'step_message_signing', category: 'message' },
  { action: 'step_message_signed', category: 'message' },
  { action: 'step_message_rejected', category: 'message' },
  { action: 'step_wrap_currency', category: 'wallet' },
  { action: 'step_unwrap_currency', category: 'wallet' },
  { action: 'step_swap_currency', category: 'wallet' },
  { action: 'step_accept_offers', category: 'wallet' },
  { action: 'step_buy_listing', category: 'wallet' },
  { action: 'step_buy_listings', category: 'wallet' },
  { action: 'step_revoke_nonce', category: 'wallet' },
  { action: 'step_sweep_collection', category: 'wallet' },
  { action: 'step_sign', category: 'wallet' },
  { action: 'step_approve_nft_contract', category: 'wallet' },
  { action: 'step_add_allowance', category: 'wallet' },
] as const;

const BuyEvents = [
  { action: 'buy_modal_opened', category: 'modal' },
  { action: 'buy_modal_payment_currency_set', category: 'modal' },
  { action: 'buy_modal_checkout_button_clicked', category: 'modal' },
  { action: 'buy_modal_transaction_success', category: 'modal' },
  { action: 'buy_modal_transaction_failed', category: 'modal' },
  { action: 'buy_modal_closed', category: 'modal' },
  { action: 'sweep_modal_opened', category: 'modal' },
  { action: 'sweep_modal_quantity_changed', category: 'modal' },
  { action: 'sweep_modal_payment_currency_set', category: 'modal' },
  { action: 'sweep_modal_sweep_button_clicked', category: 'modal' },
  { action: 'sweep_modal_transaction_success', category: 'modal' },
  { action: 'sweep_modal_transaction_failed', category: 'modal' },
  { action: 'sweep_modal_closed', category: 'modal' },
] as const;

const OfferEvents = [
  { action: 'offer_modal_opened', category: 'modal' },
  { action: 'offer_modal_closed', category: 'modal' },
  { action: 'offer_modal_duration_set', category: 'modal' },
  { action: 'offer_modal_make_offer_clicked', category: 'modal' },
  { action: 'offer_modal_edit_offer_clicked', category: 'modal' },
  { action: 'offer_modal_set_to_best_offer_clicked', category: 'modal' },
  { action: 'offer_modal_transaction_succeeded', category: 'modal' },
  { action: 'offer_modal_transaction_failed', category: 'modal' },
  { action: 'accept_offer_modal_opened', category: 'modal' },
  { action: 'accept_offer_modal_closed', category: 'modal' },
  { action: 'cancel_offer_modal_opened', category: 'modal' },
  { action: 'cancel_offer_modal_closed', category: 'modal' },
  { action: 'edit_offer_modal_opened', category: 'modal' },
  { action: 'edit_offer_modal_closed', category: 'modal' },
  { action: 'instant_sell_modal_opened', category: 'modal' },
  { action: 'instant_sell_modal_closed', category: 'modal' },
] as const;

const ListingEvents = [
  { action: 'listing_modal_opened', category: 'modal' },
  { action: 'listing_modal_price_set_to_recent', category: 'modal' },
  { action: 'listing_modal_payment_currency_set', category: 'modal' },
  { action: 'listing_modal_list_button_clicked', category: 'modal' },
  { action: 'listing_modal_transaction_succeeded', category: 'modal' },
  { action: 'listing_modal_transaction_failed', category: 'modal' },
  { action: 'listing_modal_expiration_set', category: 'modal' },
  { action: 'listing_modal_closed', category: 'modal' },
  { action: 'cancel_listing_modal_opened', category: 'modal' },
  { action: 'cancel_listing_modal_closed', category: 'modal' },
  { action: 'edit_listing_modal_opened', category: 'modal' },
  { action: 'edit_listing_modal_closed', category: 'modal' },
] as const;

const PageEvents = [
  { action: 'home_page_viewed', category: 'view' },
  { action: 'collection_page_viewed', category: 'view' },
  { action: 'collection_page_items_tab_clicked', category: 'view' },
  { action: 'collection_page_activity_tab_clicked', category: 'view' },
  { action: 'portfolio_page_viewed', category: 'view' },
  { action: 'portfolio_page_items_tab_clicked', category: 'view' },
  { action: 'portfolio_page_listings_tab_clicked', category: 'view' },
  { action: 'portfolio_page_offers_made_tab_clicked', category: 'view' },
  { action: 'portfolio_page_activity_tab_clicked', category: 'view' },
  { action: 'token_modal_opened', category: 'modal' },
  { action: 'token_modal_closed', category: 'modal' },
  { action: 'token_modal_activity_tab_clicked', category: 'modal' },
  { action: 'token_modal_details_tab_clicked', category: 'modal' },
] as const;

export const AllEvents = [
  ...WalletEvents,
  ...BuyEvents,
  ...OfferEvents,
  ...ListingEvents,
  ...PageEvents,
];

type AllEventsType =
  | typeof WalletEvents
  | typeof BuyEvents
  | typeof OfferEvents
  | typeof ListingEvents
  | typeof PageEvents;

export type AnalyticsEventName = AllEventsType[number]['action'];
