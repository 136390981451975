import useSWR from 'swr/immutable';
import useCoinIds from './useCoinIds';

export default function useCoinConversion(
  vs_currency?: string,
  symbols: string = 'eth',
  id: string = '',
): { price: number; symbol: string; id: string }[] {
  const { data: coinIds } = useCoinIds();
  const baseUrl = 'https://api.coingecko.com/api/v3/coins/markets?';

  if (id.length === 0) {
    id = symbols
      .split(',')
      .reduce((ids: string[], symbol: string) => {
        const normalizedSymbol = symbol.toLowerCase();
        const coins = coinIds[normalizedSymbol];
        if (coins?.length === 1) {
          ids.push(coins[0].id);
        }

        return ids;
      }, [])
      .join(',');
  }

  const { data } = useSWR(
    vs_currency ? `${baseUrl}vs_currency=${vs_currency}&ids=${id}` : null,
    null,
    {
      refreshInterval: 300000, //5m Interval
    },
  );

  if (data && data.length > 0) {
    return data
      .filter((conversion: any) =>
        symbols
          .split(',')
          .some((symbol) => symbol.toLowerCase().includes(conversion.symbol)),
      )
      .map((conversion: any) => ({
        price: conversion.current_price,
        symbol: (conversion.symbol || '').toUpperCase(),
        coinGeckoId: conversion.id,
      }));
  }
  return [];
}
