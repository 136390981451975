/* eslint-disable react-hooks/rules-of-hooks */
import React, {
  ReactElement,
  useEffect,
  ComponentPropsWithoutRef,
} from 'react';
import { Flex, Text, Box, Button, Loader, ErrorWell } from '../../primitives';
import {
  CancelListingModalRenderer,
  CancelStep,
} from './CancelListingModalRenderer';
import { Modal } from '../Modal';
import TokenPrimitive from '../TokenPrimitive';
import Progress from '../Progress';
import { Dialog } from '../../primitives/Dialog';
import { getTimeSince } from '@reservoir-kit-ui-overrides/lib/getTimeSince';
import { getCollection } from '@signals/collection';
import { int } from '@api/utils';
import { OrderStatus } from '@api/orderbook_api/v1/types.pb';
import { TokenIconWithPriceListing } from '@reservoir-kit-ui-overrides/components/TokenIconWithPriceListing/TokenIconWithPriceListing';
import { useMediaQuery } from 'react-responsive';
import { useOpenState } from '@hooks/useOpenState';
import { setModalState } from '@signals/modalState';
import { trackEvent } from '@utils/analytics/events';

const ModalCopy = {
  title: 'Cancel Listing',
  ctaCancel: 'Continue to cancel',
  ctaAwaitingApproval: 'Waiting for approval',
  ctaAwaitingValidation: 'Waiting for transaction to be validated',
  ctaClose: 'Close',
};

type Props = Pick<Parameters<typeof Modal>['0'], 'trigger'> & {
  listingId: string;
  copyOverrides?: Partial<typeof ModalCopy>;
  onClose?: (data: any, currentStep: CancelStep) => void;
  onCancelComplete?: (data: any) => void;
  onCancelError?: (error: Error, data: any) => void;
  onPointerDownOutside?: ComponentPropsWithoutRef<
    typeof Dialog
  >['onPointerDownOutside'];
};

export function CancelListingModal({
  listingId,
  trigger,
  copyOverrides,
  onClose,
  onCancelComplete,
  onCancelError,
  onPointerDownOutside,
}: Props): ReactElement {
  const copy: typeof ModalCopy = { ...ModalCopy, ...copyOverrides };
  const { isOpen, open, setIsOpen, createCloseHandler } = useOpenState({
    onChange: setModalState,
  });

  useEffect(() => {
    setModalState(isOpen);
  }, [isOpen]);

  const isSmallDevice = useMediaQuery({ maxWidth: 600 });

  useEffect(() => {
    if (isOpen) {
      trackEvent('cancel_listing_modal_opened');
    }
  }, [isOpen]);

  if (!isOpen) {
    return <div onClick={open}>{trigger}</div>;
  }

  return (
    <CancelListingModalRenderer listingId={listingId}>
      {({
        loading,
        listing,
        token,
        cancelStep,
        transactionError,
        stepData,
        totalUsd,
        cancelOrder,
      }) => {
        const listingCollection = getCollection(listing?.collection);
        const expires = getTimeSince(int(listing?.expiration));
        const listingImg =
          token?.imageUrl || listingCollection?.config?.imageUrl;

        const close = createCloseHandler(() => {
          trackEvent('cancel_listing_modal_closed');
          onClose?.(
            {
              listing,
              stepData: stepData,
            },
            cancelStep,
          );
        });

        useEffect(() => {
          if (cancelStep === CancelStep.Complete && onCancelComplete) {
            const data = {
              listing,
              stepData: stepData,
            };
            onCancelComplete(data);
          }
        }, [cancelStep, listing, stepData]);

        useEffect(() => {
          if (transactionError && onCancelError) {
            const data = {
              listing,
              stepData: stepData,
            };
            onCancelError(transactionError, data);
          }
        }, [listing, stepData, transactionError]);

        const isListingAvailable =
          listing &&
          (listing.status === OrderStatus.ACTIVE ||
            listing.status === OrderStatus.PENDING_APPROVAL) &&
          !loading;

        const isOracleOrder = listing?.useOffChainCancellation;
        const hasTxHashes =
          stepData?.currentStepItem?.txHashes &&
          stepData?.currentStepItem?.txHashes.length > 0;

        return (
          <Modal
            trigger={trigger}
            title={copy.title}
            open={true}
            onOpenChange={setIsOpen}
            loading={loading}
            onPointerDownOutside={(e) => {
              if (onPointerDownOutside) {
                onPointerDownOutside(e);
              }
            }}
          >
            {!isListingAvailable && !loading && (
              <Flex
                direction='column'
                justify='center'
                css={{ px: '$4', py: '$6' }}
              >
                <Text style='h6' css={{ textAlign: 'center' }}>
                  Selected listing is no longer available
                </Text>
              </Flex>
            )}
            {isListingAvailable && cancelStep === CancelStep.Cancel && (
              <Flex direction='column' css={{ height: '100%' }}>
                {transactionError && <ErrorWell error={transactionError} />}
                <Box
                  css={{
                    p: '$modalContentPadding',
                    borderBottom: '1px solid $borderColor',
                  }}
                >
                  {isSmallDevice ? (
                    <TokenIconWithPriceListing
                      chainId={Number(listing?.chainId)}
                      img={listingImg}
                      name={listing.token?.name}
                      price={listing?.price as any}
                      totalUsd={totalUsd}
                    />
                  ) : (
                    <TokenPrimitive
                      img={listingImg}
                      name={listing.token?.name}
                      price={listing?.price?.amount?.decimal}
                      usdPrice={totalUsd}
                      collection={listingCollection?.name || ''}
                      currencyContract={listing.price?.currency?.address}
                      currencyDecimals={listing?.price?.currency?.decimals}
                      currencySymbol={listing?.price?.currency?.symbol}
                      expires={expires}
                      source={listing?.source?.iconUrl || ''}
                    />
                  )}
                </Box>
                <Box css={{ flexGrow: 1 }}></Box>
                <Flex
                  css={{
                    p: '$modalContentPadding',
                    gap: '$3',
                    borderTop: '1px solid $borderColor',
                  }}
                  direction='column'
                >
                  <Button onClick={cancelOrder} color='error'>
                    {copy.ctaCancel}
                  </Button>
                  <Text
                    style='body2'
                    color='subtleContrast'
                    css={{ textAlign: 'center', fontSize: 13 }}
                  >
                    {!isOracleOrder
                      ? 'This action will cancel your listing. You will be prompted to confirm this cancellation from your wallet. A gas fee is required.'
                      : 'This will cancel your listing for free. You will be prompted to confirm this cancellation from your wallet.'}
                  </Text>
                </Flex>
              </Flex>
            )}
            {cancelStep === CancelStep.Approving && (
              <Flex direction='column' css={{ height: '100%' }}>
                <Box
                  css={{
                    p: '$modalContentPadding',
                    borderBottom: '1px solid $borderColor',
                  }}
                >
                  {isSmallDevice ? (
                    <TokenIconWithPriceListing
                      chainId={Number(listing?.chainId)}
                      img={listingImg}
                      name={listing?.token?.name}
                      price={listing?.price as any}
                      totalUsd={totalUsd}
                    />
                  ) : (
                    <TokenPrimitive
                      img={listingImg}
                      name={listing?.token?.name}
                      price={listing?.price?.amount?.decimal}
                      usdPrice={totalUsd}
                      collection={listingCollection?.name || ''}
                      currencyContract={listing?.price?.currency?.address}
                      currencyDecimals={listing?.price?.currency?.decimals}
                      currencySymbol={listing?.price?.currency?.symbol}
                      expires={expires}
                      source={listing?.source?.iconUrl || ''}
                    />
                  )}
                </Box>
                {!stepData && <Loader css={{ height: 206 }} />}
                {stepData && (
                  <>
                    <Progress
                      title={
                        stepData?.currentStepItem.txHashes
                          ? 'Finalizing on blockchain'
                          : 'Confirm cancelation in your wallet'
                      }
                      subtitle={
                        hasTxHashes
                          ? undefined
                          : 'You need to sign a message to approve the cancellation.'
                      }
                      txList={stepData?.currentStepItem?.txHashes}
                    />
                  </>
                )}
                <Box css={{ flexGrow: 1 }}></Box>
                <Box
                  css={{
                    p: '$modalContentPadding',
                    borderTop: '1px solid $borderColor',
                  }}
                >
                  <Button color='error' disabled={true} css={{ width: '100%' }}>
                    {stepData?.currentStepItem.txHashes
                      ? copy.ctaAwaitingValidation
                      : copy.ctaAwaitingApproval}
                  </Button>
                </Box>
              </Flex>
            )}
            {cancelStep === CancelStep.Complete && (
              <Flex direction='column' css={{ height: '100%' }}>
                <Box
                  css={{
                    p: '$modalContentPadding',
                    borderBottom: '1px solid $borderColor',
                  }}
                >
                  {isSmallDevice ? (
                    <TokenIconWithPriceListing
                      chainId={Number(listing?.chainId)}
                      img={listingImg}
                      name={listing?.token?.name}
                      price={listing?.price as any}
                      totalUsd={totalUsd}
                    />
                  ) : (
                    <TokenPrimitive
                      img={listingImg}
                      name={listing?.token?.name}
                      price={listing?.price?.amount?.decimal}
                      usdPrice={totalUsd}
                      collection={listingCollection?.name || ''}
                      currencyContract={listing?.price?.currency?.address}
                      currencyDecimals={listing?.price?.currency?.decimals}
                      currencySymbol={listing?.price?.currency?.symbol}
                      expires={expires}
                      source={listing?.source?.iconUrl || ''}
                    />
                  )}
                </Box>
                {!stepData && <Loader css={{ height: 206 }} />}
                {stepData && (
                  <Progress
                    title='Listing canceled'
                    subtitle={
                      <Text style='body2' color='subtle'>
                        Your {listing?.source?.name as string} listing has been
                        canceled
                      </Text>
                    }
                    txList={stepData?.currentStepItem?.txHashes}
                  />
                )}
                <Box css={{ flexGrow: 1 }}></Box>
                <Box
                  css={{
                    p: '$modalContentPadding',
                    borderTop: '1px solid $borderColor',
                  }}
                >
                  <Button
                    color='onlyBorder'
                    css={{ width: '100%' }}
                    onClick={close}
                  >
                    {copy.ctaClose}
                  </Button>
                </Box>
              </Flex>
            )}
          </Modal>
        );
      }}
    </CancelListingModalRenderer>
  );
}

CancelListingModal.Custom = CancelListingModalRenderer;
