import React, { FC, useState, useCallback, ReactNode } from 'react';
import { useAccount, useSwitchChain } from 'wagmi';
import { useCoinConversion } from '@hooks';
import { EnhancedStep, EnhancedStepItem, EnhancedSteps } from '@types';
import {
  Order,
  StepItemStatus,
  TokenRef,
} from '@api/orderbook_api/v1/types.pb';
import useOrderQuery from '@hooks/useOrderQuery';
import { useWallet } from '@hooks/useWallet';
import { getChainById } from '@signals/chains';

export enum CancelStep {
  Cancel,
  Approving,
  Complete,
}

export type CancelListingStepData = {
  totalSteps: number;
  stepProgress: number;
  currentStep: EnhancedStep;
  currentStepItem: NonNullable<EnhancedStepItem>;
};

type ChildrenProps = {
  loading: boolean;
  listing?: NonNullable<Order>;
  token?: TokenRef;
  cancelStep: CancelStep;
  transactionError?: Error | undefined;
  totalUsd: number;
  usdPrice: number;
  steps: EnhancedSteps | undefined;
  stepData: CancelListingStepData | undefined;
  setCancelStep: React.Dispatch<React.SetStateAction<CancelStep>>;
  cancelOrder: () => void;
};

type Props = {
  listingId: string;
  children: (props: ChildrenProps) => ReactNode;
};

export const CancelListingModalRenderer: FC<Props> = ({
  listingId,
  children,
}) => {
  const [cancelStep, setCancelStep] = useState<CancelStep>(CancelStep.Cancel);
  const [transactionError, setTransactionError] = useState<Error | undefined>();
  const [stepData, setStepData] = useState<CancelListingStepData | undefined>();
  const [steps, setSteps] = useState<EnhancedSteps | undefined>();

  const { wallet } = useWallet();

  const { switchChainAsync } = useSwitchChain();
  let { chain: activeWalletChain } = useAccount();

  const { data: listings, isFetchingPage } = useOrderQuery(
    {
      orderIds: [listingId],
    },
    {
      revalidateFirstPage: true,
    },
  );

  const listing = listings && listings[0] ? listings[0] : undefined;
  const rendererChain = getChainById(Number(listing?.chainId));

  const currency = listing?.price?.currency;

  const coinConversion = useCoinConversion(
    listing ? 'USD' : undefined,
    currency?.symbol,
  );
  const usdPrice = coinConversion.length > 0 ? coinConversion[0].price : 0;
  const totalUsd = usdPrice * (listing?.price?.amount?.decimal || 0);

  const cancelOrder = useCallback(async () => {
    if (!wallet) {
      const error = new Error('Missing a wallet/signer');
      setTransactionError(error);
      throw error;
    }

    if (!listingId) {
      const error = new Error('Missing list id to cancel');
      setTransactionError(error);
      throw error;
    }

    if (activeWalletChain && rendererChain?.id !== activeWalletChain?.id) {
      activeWalletChain = await switchChainAsync({
        chainId: rendererChain?.id as number,
      });
    }

    if (rendererChain?.id !== activeWalletChain?.id) {
      const error = new Error(`Mismatching chainIds`);
      setTransactionError(error);
      throw error;
    }

    setCancelStep(CancelStep.Approving);

    wallet
      .cancelOrder(
        {
          chainId: listing?.chainId,
          collection: listing?.collection,
          orderId: listingId,
        },
        async (steps, step, item) => {
          if (!steps) {
            return;
          }
          setSteps(steps.steps as EnhancedSteps);

          const executableSteps = steps.steps?.filter(
            (step) => step.items && step.items.length > 0,
          );

          let stepCount = executableSteps?.length || 0;

          let currentStepItem: NonNullable<EnhancedStepItem> | undefined;

          const currentStepIndex = executableSteps?.findIndex((step) => {
            currentStepItem = step.items?.find(
              (item) => item.status === StepItemStatus.INCOMPLETE,
            );
            return currentStepItem;
          });

          const currentStep = step as EnhancedStep;
          if (currentStepItem) {
            setStepData({
              totalSteps: stepCount,
              stepProgress: currentStepIndex || 0,
              currentStep,
              currentStepItem,
            });
          }
        },
      )
      .then(() => {
        setCancelStep(CancelStep.Complete);
      })
      .catch((error: Error) => {
        setTransactionError(error);
        setCancelStep(CancelStep.Cancel);
        setStepData(undefined);
        setSteps(undefined);
      });
  }, [listing, listingId, rendererChain?.id, wallet]);

  const token = listing?.token;

  return (
    <>
      {children({
        loading: isFetchingPage !== undefined ? isFetchingPage : true,
        listing,
        token,
        cancelStep,
        transactionError,
        usdPrice,
        totalUsd,
        steps,
        stepData,
        setCancelStep,
        cancelOrder,
      })}
    </>
  );
};
