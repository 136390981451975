import { computed } from '@preact/signals-react';
import { sSelectedCollection } from './collection';
import { sMarketplace } from '@signals/marketplace';
import { sSelectedChain } from './chains';

export const sReady = computed(() => {
  return (
    !!sMarketplace.value &&
    !!sSelectedCollection.value &&
    !!sSelectedChain.value
  );
});
