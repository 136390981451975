import { DefaultChain } from '@api/utils/chains';
import { useSignals } from '@preact/signals-react/runtime';
import { sSelectedChain } from '@signals/chains';

const useMarketplaceChain = () => {
  useSignals();
  return sSelectedChain.value || DefaultChain;
};

export default useMarketplaceChain;
