import { Anchor, Flex, Text } from '@reservoir-kit-ui-overrides/primitives';
import { truncateAddress } from '@utils/truncate';
import getChainBlockExplorerUrl from '@reservoir-kit-ui-overrides/lib/getChainBlockExplorerUrl';
import { useTransactionReceipt } from 'wagmi';
import { TxHash } from '@utils/wallet';
import { Loader } from '@reservoir-kit-ui-overrides/primitives';
import { IconCheck } from '@v2/icons/IconCheck';
import { createContext, useContext } from 'react';
import { IconClose } from '@v2/icons/IconClose';

interface Props {
  txHash?: TxHash;
  chainId: number;
  mode?: 'full' | 'compact';
}

export const ReceiptContext = createContext({
  useTransactionReceipt,
});

export const TxReceipt = ({ txHash, chainId, mode = 'full' }: Props) => {
  const { useTransactionReceipt } = useContext(ReceiptContext);
  const { data: receipt, isLoading } = useTransactionReceipt({ hash: txHash });
  const isSuccess = receipt?.status === 'success';

  if (!txHash) {
    return null;
  }

  const truncatedTxHash = truncateAddress(txHash);
  const blockExplorerUrl = getChainBlockExplorerUrl(chainId);

  return (
    <Flex css={{ gap: '$2' }} direction='column'>
      {mode === 'full' && (
        <Text style='subtitle1'>
          {isLoading
            ? 'Processing your transaction...'
            : isSuccess
            ? 'Your transaction went through successfully'
            : "Your transaction didn't go through"}
        </Text>
      )}
      <Flex css={{ gap: '$2' }} align='center'>
        {mode === 'full' && <Text>View:</Text>}
        <Anchor
          href={`${blockExplorerUrl}/tx/${txHash}`}
          color='primary'
          weight='medium'
          target='_blank'
          css={{ fontSize: 14, textDecoration: 'underline' }}
        >
          {truncatedTxHash}
        </Anchor>
        <Flex align='center' justify='center' css={{ width: 24, height: 24 }}>
          {isLoading ? (
            <Loader />
          ) : isSuccess ? (
            <Text color='accent'>
              <IconCheck width={24} height={24} />
            </Text>
          ) : (
            <Text color='error'>
              <IconClose />
            </Text>
          )}
        </Flex>
        {mode === 'compact' && (
          <Text style='body2' color='subtle'>
            {isLoading ? 'Pending' : isSuccess ? 'Success' : 'Failed'}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};
