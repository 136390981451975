import { Inter } from '@next/font/google';
import {
  crimson,
  slate,
  iris,
  gray,
  green,
  whiteA,
  red,
  blackA,
  violet,
  violetA,
  indigo,
  blue,
} from '@radix-ui/colors';
import { createTheme } from '@stitches.config';

const inter = Inter({
  subsets: ['latin'],
});

export const light = createTheme({
  fonts: {
    body: inter.style.fontFamily,
  },
  radii: {
    radiusNone: '0px',
    radiusTiny: '4px',
    radiusSmall: '8px',
    radiusMedium: '12px',
    radiusLarge: '16px',
  },
  colors: {
    ...gray,
    ...crimson,
    ...violet,
    ...violetA,
    ...slate,
    ...red,
    ...whiteA,
    ...blackA,
    ...iris,
    ...green,
    ...indigo,
    ...blue,

    //Aliases
    primary1: '$iris1',
    primary2: '$iris2',
    primary3: '$iris3',
    primary4: '$iris4',
    primary5: '$iris5',
    primary6: '$iris6',
    primary7: '$iris7',
    primary8: '$iris8',
    primary9: '$iris9',
    primary10: '$iris10',
    primary11: '$iris11',
    primary12: '$iris12',

    //Secondary
    secondary1: '$violetA1',
    secondary2: '$violetA2',
    secondary3: '$violetA3',
    secondary4: '$violetA4',
    secondary5: '$violetA5',
    secondary6: '$violetA6',
    secondary7: '$violetA7',
    secondary8: '$violetA8',
    secondary9: '$violetA9',
    secondary10: '$violetA10',
    secondary11: '$violetA11',
    secondary12: '$violetA12',

    //Gray
    gray1: '$slate1',
    gray2: '$slate2',
    gray3: '$slate3',
    gray4: '$slate4',
    gray5: '$slate5',
    gray6: '$slate6',
    gray7: '$slate7',
    gray8: '$slate8',
    gray9: '$slate9',
    gray10: '$slate10',
    gray11: '$slate11',
    gray12: '$slate12',

    //Red
    red1: '$crimson1',
    red2: '$crimson2',
    red3: '$crimson3',
    red4: '$crimson4',
    red5: '$crimson5',
    red6: '$crimson6',
    red7: '$crimson7',
    red8: '$crimson8',
    red9: '$crimson9',
    red10: '$crimson10',
    red11: '$crimson11',
    red12: '$crimson12',

    neutralBg: 'white',
    neutralBgTransparent20: 'rgba(255, 255, 255, 0.2)',
    neutralBgTransparent40: 'rgba(255, 255, 255, 0.4)',
    neutralBgSubtle: 'white',
    panelShadow: 'rgba(0,0,0,0.1)',
    panelBg: '$gray2',
    dropdownBg: 'white',
    sidebarOverlay: 'black',
    fancyBg: '#eeeeee90',
    fancyColor: 'black',
    borderColor: '$gray4',
    btnPrimaryBg: 'black',
    btnPrimaryColor: 'white',
    btnSecondaryBg: '#f7f7f7',
    btnSecondaryColor: 'black',
    btnSubtleColor: '#00000066',
    textColor: '$gray12',
    dropdownActiveBg: '$gray5',
    dropdownCountColor: '$gray11',
    btnDisabledBg: '#00000066',
    btnDisabledText: 'white',
    btnSubtleText: '$gray9',
    linkColor: '#70CBFF',
    accentColor: '#70CBFF',
    tabSelectedBg: '$gray3',
    checkboxBg: '$gray5',
    textError: '#c34d5f',

    contentBackground: '$neutralBgSubtle',
    neutralBgHover: '$gray2',
    neutralBgActive: '$gray2',
    neutralLine: '$gray6',
    neutralBorder: '#0000000D',
    neutralBorderHover: '$gray11',
    neutralSolid: '$gray9',
    neutralSolidHover: '$gray10',
    neutralText: '#00000066',
    neutralTextContrast: '$gray12',
    focusColor: '$neutralTextContrast',
    errorAccent: '$red9',
    buttonTextColor: 'white',
    inputBackground: '$neutralBgSubtle',
    overlayBackground: '$blackA10',
    wellBackground: '$gray3',
    popoverBackground: '$neutralBg',
    invertedTextColor: '#fff',
    subtleTextContrast: '$gray10',
    selectedBg: '$gray3',

    brandColor: '$textColor',
    ghostBg: 'transparent',
    ghostText: '$btnSubtleText',
    dialogBg: '$neutralBg',
    ghostBrandBorder: '$borderColor',
    ghostBrandText: '$btnSubtleText',
  },
});
