import React, { FC } from 'react';
import { Flex, Img, Text } from '../primitives';
import { CSS } from '@stitches/react';
import { LimitBreakChain } from '@api/utils/chains';
import { Collection, Token } from '@api/orderbook_api/v1/types.pb';
import { useMediaQuery } from 'react-responsive';

type Props = {
  token?: Token;
  collection?: Collection;
  chain?: LimitBreakChain | null;
  css?: CSS;
};

const largeScreenImgSize = 86;
const smallScreenImgSize = 62;

export const TokenInfo: FC<Props> = ({ token, collection, chain, css }) => {
  const isSmallDevice = useMediaQuery({ maxWidth: 600 });
  const imageSize = isSmallDevice ? smallScreenImgSize : largeScreenImgSize;
  const hasName = token?.name || token?.tokenId;

  return (
    <Flex justify='between' align='center' css={{ gap: '$4', ...css }}>
      <Flex align='center' css={{ gap: 22 }}>
        <Img
          src={token?.imageUrl || collection?.config?.imageBanner}
          css={{
            borderRadius: '$radiusMedium',
            objectFit: 'cover',
            height: imageSize,
            width: imageSize,
          }}
        />
        <Flex direction='column'>
          {hasName && (
            <Text style='subtitle1'>{token?.name || `#${token?.tokenId}`}</Text>
          )}
          <Text style='h6' color='subtle'>
            {collection?.name}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
