import { useCallback, useRef, useState } from 'react';

interface Props {
  onChange?: (isOpen: boolean) => void;
}

export const useOpenState = ({ onChange }: Props) => {
  const [isOpen, __setIsOpen] = useState(false);
  const onCloseRef = useRef<() => void>();

  const setIsOpen = useCallback((value: boolean) => {
    __setIsOpen(value);
    onChange?.(value);
    if (!value) {
      onCloseRef.current?.();
    }
  }, []);

  const open = useCallback(() => {
    setIsOpen(true);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const createCloseHandler = useCallback((onClose: () => void) => {
    onCloseRef.current = onClose;
    return () => {
      setIsOpen(false);
    };
  }, []);

  return {
    isOpen,
    open,
    toggle,
    setIsOpen,
    close,
    createCloseHandler,
  };
};
