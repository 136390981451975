/* eslint-disable */
// @ts-nocheck
/*
 * This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
 */

type Absent<T, K extends keyof T> = { [k in Exclude<keyof T, K>]?: undefined };
type OneOf<T> =
  | { [k in keyof T]?: undefined }
  | (keyof T extends infer K
      ? K extends string & keyof T
        ? { [k in K]: T[K] } & Absent<T, K>
        : never
      : never);

export enum ContractType {
  CONTRACT_TYPE_UNKNOWN = "CONTRACT_TYPE_UNKNOWN",
  CONTRACT_TYPE_ERC721 = "CONTRACT_TYPE_ERC721",
  CONTRACT_TYPE_ERC1155 = "CONTRACT_TYPE_ERC1155",
  CONTRACT_TYPE_ERC721C = "CONTRACT_TYPE_ERC721C",
  CONTRACT_TYPE_ERC1155C = "CONTRACT_TYPE_ERC1155C",
  CONTRACT_TYPE_ERC20 = "CONTRACT_TYPE_ERC20",
  CONTRACT_TYPE_PAYMENT_PROCESSOR_2_0_0 = "CONTRACT_TYPE_PAYMENT_PROCESSOR_2_0_0",
  CONTRACT_TYPE_PAYMENT_PROCESSOR_2_0_1 = "CONTRACT_TYPE_PAYMENT_PROCESSOR_2_0_1",
  CONTRACT_TYPE_PAYMENT_PROCESSOR_2_1_0 = "CONTRACT_TYPE_PAYMENT_PROCESSOR_2_1_0",
  CONTRACT_TYPE_TRUSTED_FORWARDER_2_0_0 = "CONTRACT_TYPE_TRUSTED_FORWARDER_2_0_0",
  CONTRACT_TYPE_CREATOR_TOKEN_2_0_0 = "CONTRACT_TYPE_CREATOR_TOKEN_2_0_0",
  CONTRACT_TYPE_MULTISIG_WALLET_1_0_0 = "CONTRACT_TYPE_MULTISIG_WALLET_1_0_0",
}

export enum AnyKind {
  ANY_KIND_STRING = "ANY_KIND_STRING",
  ANY_KIND_FLOAT = "ANY_KIND_FLOAT",
  ANY_KIND_BOOL = "ANY_KIND_BOOL",
  ANY_KIND_JSON_OBJECT = "ANY_KIND_JSON_OBJECT",
  ANY_KIND_JSON_ARRAY = "ANY_KIND_JSON_ARRAY",
}

export type Message = {
  channel?: string;
  data?: Uint8Array;
  timestamp?: string;
  id?: string;
};

export type TypedDataField = {
  fieldType?: string;
  fieldName?: string;
  fieldValue?: string;
  indexed?: boolean;
};

export type EcdsaSignature = {
  r?: string;
  s?: string;
  v?: number;
};

export type OrderBy = {
  field?: string;
  desc?: boolean;
};

export type TokenAttribute = {
  key?: string;
  values?: TokenAttributeValue[];
};

export type TokenAttributeValue = {
  value?: string;
  count?: string;
  percentage?: number;
};

export type TokenAttributeFilter = {
  key?: string;
  values?: string[];
};

export type RoyaltyReceiver = {
  receiver?: string;
  bps?: number;
};

export type Empty = {};

type BaseAny = {
  kind?: AnyKind;
};

export type Any = BaseAny &
  OneOf<{
    stringValue: string;
    floatValue: number;
    boolValue: boolean;
    jsonObjectValue: string;
    jsonArrayValue: string;
  }>;

export type Point = {
  x?: number;
  y?: number;
  label?: string;
};

export type PointTime = {
  timestamp?: string;
  value?: number;
  label?: string;
};

export type NetworkConfig = {
  chainId?: string;
  name?: string;
  rpcUrl?: string;
  wsUrl?: string;
  backupRpcUrl?: string;
  backupWsUrl?: string;
};
