import { styled } from '@stitches.config';
import React, { FC } from 'react';
import {
  Box,
  Flex,
  Text,
  Grid,
  FormatCurrency,
  FormatCryptoCurrency,
} from '../primitives';
import InfoTooltip from '../primitives/InfoTooltip';
import { useMarketplaceChain } from '@hooks';

type Props = {
  img?: string;
  name?: string;
  collection: string;
  currencyContract?: string;
  currencyDecimals?: number;
  currencySymbol?: string;
  source?: string;
  price?: number;
  usdPrice?: number | string;
  expires?: string;
  warning?: string;
  isOffer?: boolean;
  isUnavailable?: boolean;
  priceSubtitle?: string;
  royaltiesBps?: number;
  quantity?: number;
};

const Img = styled('img', {
  width: 86,
  height: 86,
  borderRadius: '$radiusMedium',
  aspectRatio: '1/1',
});

const TokenPrimitive: FC<Props> = ({
  img,
  name,
  collection,
  currencyContract,
  currencyDecimals,
  currencySymbol,
  expires,
  warning,
  source,
  usdPrice,
  price,
  isUnavailable,
  priceSubtitle,
  royaltiesBps,
  quantity,
}) => {
  const royaltyPercent = royaltiesBps ? royaltiesBps / 100 : royaltiesBps;
  const chain = useMarketplaceChain();
  return (
    <Box>
      <Flex
        css={{ justifyContent: 'space-between', alignItems: 'center' }}
      ></Flex>
      <Flex justify='between'>
        <Flex css={{ alignItems: 'center', gap: 22 }}>
          <Img
            src={img}
            alt={name}
            css={{
              overflow: 'hidden',
              visibility: !img || img.length === 0 ? 'hidden' : 'visible',
              flexShrink: 0,
              objectFit: 'cover',
            }}
          />
          <Grid css={{ rowGap: 1 }}>
            <Flex
              align='center'
              css={{ gap: '$2', mr: '$4', overflow: 'hidden' }}
            >
              <Text style='subtitle1' ellipsify color='base'>
                {name ? name : collection}
              </Text>
              {expires && quantity && quantity > 1 && !name ? (
                <Flex align='center'>
                  <Text style='h6' color='subtle'>
                    {chain?.name}
                  </Text>
                </Flex>
              ) : null}
              {expires && quantity && quantity > 1 ? (
                <Flex
                  css={{
                    p: '$1',
                    background: '$neutralBgHover',
                    borderRadius: '$radiusTiny',
                    mr: 'auto',
                  }}
                >
                  <Text
                    style='tiny'
                    color='base'
                    css={{ minWidth: 'max-content' }}
                  >
                    {quantity} items
                  </Text>
                </Flex>
              ) : null}
            </Flex>
            {!name && !quantity && expires ? (
              <Flex align='center'>
                {/* TODO: uncomment when icons are fixed
                <ChainIcon chainId={chain?.id} height={12} css={{ mr: 5 }} /> */}
                <Text style='h6' color='subtle'>
                  {chain?.name}
                </Text>
              </Flex>
            ) : null}
            {name && (
              <Flex
                css={{
                  maxWidth: '370px',
                }}
              >
                <Text ellipsify style='h6' color='subtle'>
                  {collection}
                </Text>
              </Flex>
            )}
            {/* {!!expires && (
              <Text style='tiny' color='subtle' css={{ color: '$neutralText' }}>
                Expires {expires}
              </Text>
            )} */}
            {!expires && quantity && quantity > 1 ? (
              <Flex
                css={{
                  p: '$1 ',
                  background: '$neutralBgHover',
                  borderRadius: '$radiusTiny',
                  mr: 'auto',
                }}
              >
                <Text style='tiny' color='base'>
                  {quantity} {quantity > 1 ? 'items' : 'item'}
                </Text>
              </Flex>
            ) : null}
            {!expires && !quantity && royaltiesBps ? (
              <Text
                style='body3'
                color='subtle'
                css={{ display: 'flex', gap: '$1' }}
              >
                Creator Royalties: {royaltyPercent}%
                <InfoTooltip
                  side='right'
                  width={200}
                  content='A fee on every order that goes to the collection creator.'
                />
              </Text>
            ) : null}
          </Grid>
        </Flex>
        <Grid css={{ justifyItems: 'end', alignContent: 'center', rowGap: 1 }}>
          {price ? (
            <FormatCryptoCurrency
              amount={price}
              chainId={chain?.id}
              textStyle='subtitle1'
              textColor='base'
              address={currencyContract}
              decimals={currencyDecimals}
              symbol={currencySymbol}
              logoWidth={14.5}
            />
          ) : (
            <Text style='subtitle3' color='base'>
              --
            </Text>
          )}
          {usdPrice ? (
            <FormatCurrency amount={usdPrice} style='body1' color='subtle' />
          ) : null}
          {warning && (
            <Text style='subtitle3' color='error'>
              {warning}
            </Text>
          )}
        </Grid>
      </Flex>
    </Box>
  );
};

export default TokenPrimitive;
