import {
  useState,
  createContext,
  SetStateAction,
  Dispatch,
  FC,
  ReactNode,
  useEffect,
  useCallback,
} from 'react';
import { Provider as ToastProvider } from '@radix-ui/react-toast';
import Toast, { ToastViewport } from 'components/primitives/Toast';
import { Anchor, Flex } from 'components/primitives';
import { v4 as uuidv4 } from 'uuid';
import * as allChains from 'wagmi/chains';
import { useSignals } from '@preact/signals-react/runtime';
import { OrderEvent, sEvent } from '@signals/events';
import { Transfer } from '@api/orderbook_api/v1/types.pb';
import { int } from '@api/utils';

type ToastType = {
  id?: string;
  title?: string;
  description?: string;
  action?: ReactNode;
  status?: 'success' | 'error';
};

export const ToastContext = createContext<{
  toasts: Array<ToastType>;
  setToasts: Dispatch<SetStateAction<Array<ToastType>>> | null;
  addToast: ((toast: ToastType) => void) | null;
}>({
  toasts: [],
  setToasts: null,
  addToast: null,
});

const ToastContextProvider: FC<any> = ({ children }) => {
  useSignals();
  const [toasts, setToasts] = useState<Array<ToastType>>([]);
  const addToast = useCallback(
    (toast: ToastType) => {
      toast.id = uuidv4();
      setToasts([...toasts, toast]);
    },
    [toasts],
  );

  useEffect(() => {
    if (!sEvent.value) return;
    const event = sEvent.value as OrderEvent;
    switch (event.name) {
      case 'purchase_error':
        addToast?.({
          title: 'Purchase Failure',
          status: 'error',
          description:
            event.data.error.length < 100
              ? event.data.error
              : 'Failed to complete purchase',
        });
        break;

      case 'accept_offer_error':
        addToast?.({
          title: 'Failed to sell',
          status: 'error',
          description:
            event.data.error.length < 100
              ? event.data.error
              : 'Failed to accept offer(s)',
        });
        break;

      case 'purchase_complete':
      case 'accept_offer_complete':
        // const executableSteps: Steps = event?.data?.steps.filter(
        //   (step) => step.items && step.items.length > 0,
        // );

        // let stepCount = executableSteps.length;

        // let currentStepItem: NonNullable<Step['items']>[0] | undefined;

        // const currentStepIndex = executableSteps.findIndex((step: Step) => {
        //   currentStepItem = step.items?.find(
        //     (item) => item.status === 'incomplete',
        //   );
        //   return currentStepItem;
        // });

        // const currentStep =
        //   currentStepIndex > -1
        //     ? executableSteps[currentStepIndex]
        //     : executableSteps[stepCount - 1];

        // const purchaseTxHashes =
        //   currentStep?.items?.reduce((txHashes, item) => {
        //     item.transfersData?.forEach((transferData) => {
        //       if (transferData.txHash) {
        //         txHashes.add(transferData.txHash);
        //       }
        //     });
        //     return txHashes;
        //   }, new Set<string>()) || [];
        // TODO: figure out how to get total purchases
        // const totalPurchases = Array.from(purchaseTxHashes).length;
        const totalPurchases = 1;

        // TODO: figure out how to get failed purchases
        // const failedPurchases =
        //   totalPurchases - (currentStep?.items?.length || 0);
        const failedPurchases = 0;

        const action =
          event.name === 'accept_offer_complete' ? 'sold' : 'purchased';

        const ToastAction = () => {
          const { chainId, transactionHash } = event?.data?.path
            ?.transfer as Transfer;
          const chains: allChains.Chain[] | undefined = Object.values({
            ...allChains,
          });
          const chain = chains.find(({ id }) => id === int(chainId));
          const blockExplorerBaseUrl =
            chain?.blockExplorers?.default?.url || 'https://etherscan.io';
          const formattedTxHash = transactionHash
            ? `${transactionHash.slice(0, 4)}...${transactionHash.slice(-4)}`
            : '';
          return (
            <Anchor
              key={transactionHash}
              href={`${blockExplorerBaseUrl}/tx/${transactionHash}`}
              color='primary'
              weight='medium'
              target='_blank'
              css={{ fontSize: 12 }}
            >
              View transaction: {formattedTxHash}
            </Anchor>
          );
        };

        addToast?.({
          title: failedPurchases
            ? `${totalPurchases} ${
                totalPurchases > 1 ? 'items' : 'item'
              } ${action}, ${failedPurchases} ${
                failedPurchases > 1 ? 'items' : 'item'
              } ${action}`
            : `${totalPurchases} ${
                totalPurchases > 1 ? 'items' : 'item'
              } ${action}.`,
          status: failedPurchases ? 'error' : 'success',
          action: (
            <Flex direction='column' css={{ gap: '$1' }}>
              {<ToastAction />}
            </Flex>
          ),
        });
        break;
    }
  }, [addToast]);

  return (
    <ToastContext.Provider value={{ toasts, addToast, setToasts }}>
      <ToastProvider duration={5000}>
        {children}
        {toasts.map((toast, idx) => {
          return (
            <Toast
              key={idx}
              id={toast.id}
              title={toast.title}
              description={toast.description}
              action={toast.action}
              status={toast.status}
            />
          );
        })}
        <ToastViewport />
      </ToastProvider>
    </ToastContext.Provider>
  );
};

export default ToastContextProvider;
