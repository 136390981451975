import ErrorTrackingProvider from 'components/ErrorTrackingProvider';
import type { AppProps } from 'next/app';
import { WagmiProvider, createConfig, fallback } from 'wagmi';
import * as Tooltip from '@radix-ui/react-tooltip';
import {
  CartProvider,
  ReservoirKitProvider,
} from '@/reservoir-kit-ui-overrides';
import { useContext } from 'react';
import { HotkeysProvider } from 'react-hotkeys-hook';
import ToastContextProvider from 'context/ToastContextProvider';
import { WebsocketContextProvider } from 'context/WebsocketContextProvider';
import { ReferralContext } from 'context/ReferralContextProvider';
import { AnalyticsProvider } from '@utils/analytics/provider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { http } from 'wagmi';
import { mainnet, sepolia, polygon } from 'wagmi/chains';
import { EthereumWalletConnectors } from '@dynamic-labs/ethereum';
import { DynamicContextProvider } from '@dynamic-labs/sdk-react-core';
import { DynamicWagmiConnector } from '@dynamic-labs/wagmi-connector';
import { PageWrapper } from './PageWrapper';

const queryClient = new QueryClient();

const INFURA_DOMAINS = {
  [mainnet.id]: 'mainnet',
  [sepolia.id]: 'sepolia',
  [polygon.id]: 'polygon-mainnet',
};

export const NORMALIZE_ROYALTIES = process.env.NEXT_PUBLIC_NORMALIZE_ROYALTIES
  ? process.env.NEXT_PUBLIC_NORMALIZE_ROYALTIES === 'true'
  : false;

const INFURA_API_KEY = process.env.NEXT_PUBLIC_INFURA_ID || '';

const preferInfuraProvider =
  process.env.NEXT_PUBLIC_PREFER_INFURA_PROVIDER === 'true';

const getTransports = (chainId: keyof typeof INFURA_DOMAINS) => {
  const transports = [
    http(),
    http(`https://${INFURA_DOMAINS[chainId]}.infura.io/v3/${INFURA_API_KEY}`),
  ];
  if (preferInfuraProvider) {
    transports.reverse();
  }
  return fallback(transports);
};

export const customWagmiConfig = createConfig({
  chains: [mainnet, sepolia, polygon],
  multiInjectedProviderDiscovery: false,
  transports: {
    [mainnet.id]: getTransports(mainnet.id),
    [sepolia.id]: getTransports(sepolia.id),
    [polygon.id]: getTransports(polygon.id),
  },
});

const dynamicContextProps = {
  environmentId: process.env.NEXT_PUBLIC_DYNAMIC_ENVIRONMENT_ID!,
  walletConnectors: [EthereumWalletConnectors],
  mobileExperience: 'in-app-browser',
  initialAuthenticationMode: 'connect-only',
};

export const Marketplace = (props: AppProps) => {
  const { feesOnTop } = useContext(ReferralContext);

  return (
    <DynamicContextProvider settings={dynamicContextProps as any}>
      <WagmiProvider config={customWagmiConfig}>
        <QueryClientProvider client={queryClient}>
          <AnalyticsProvider>
            <ErrorTrackingProvider>
              <DynamicWagmiConnector>
                <HotkeysProvider>
                  <ReservoirKitProvider>
                    <CartProvider feesOnTopUsd={feesOnTop}>
                      <WebsocketContextProvider>
                        <Tooltip.Provider>
                          <ToastContextProvider>
                            <PageWrapper {...props} />
                          </ToastContextProvider>
                        </Tooltip.Provider>
                      </WebsocketContextProvider>
                    </CartProvider>
                  </ReservoirKitProvider>
                </HotkeysProvider>
              </DynamicWagmiConnector>
            </ErrorTrackingProvider>
          </AnalyticsProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </DynamicContextProvider>
  );
};
