import { Flex, FormatCryptoCurrency, Text } from '../../primitives';
import { Collection, Token } from '@api/orderbook_api/v1/types.pb';
import { useMemo } from 'react';
import { getTopOffer } from '@utils/getTopOffer';
import { useAccount } from 'wagmi';

interface Props {
  token?: NonNullable<NonNullable<Token>>;
  collection: NonNullable<Collection>;
}

export const PriceInfo = ({ token, collection }: Props) => {
  const floorAsk =
    token?.stats?.floorAsk?.price ?? collection?.stats?.floorAsk?.price;
  const { address } = useAccount();
  const topBid = useMemo(() => {
    return getTopOffer({
      token,
      collection,
      address,
    })?.price;
  }, [token, collection, address]);

  return (
    <Flex>
      <Flex
        align='center'
        css={{
          gap: '$2',
          rowGap: 0,
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        {floorAsk ? (
          <Flex css={{ gap: '$1' }}>
            <Text style='body1' color='subtle' css={{ whiteSpace: 'nowrap' }}>
              Floor Price:
            </Text>
            <FormatCryptoCurrency
              chainId={Number(collection.chainId)}
              amount={floorAsk?.amount?.decimal}
              address={floorAsk?.currency?.address}
              symbol={floorAsk?.currency?.symbol}
              decimals={floorAsk?.currency?.decimals}
              textStyle='body1'
              textColor='subtle'
            />
          </Flex>
        ) : null}
        {floorAsk && topBid && (
          <Text style='body1' color='subtle'>
            ·
          </Text>
        )}
        {topBid ? (
          <Flex css={{ gap: '$1' }}>
            <Text style='body1' color='subtle' css={{ whiteSpace: 'nowrap' }}>
              Best Offer:
            </Text>
            <FormatCryptoCurrency
              chainId={Number(collection.chainId)}
              amount={topBid?.amount?.decimal}
              address={topBid?.currency?.address}
              symbol={topBid?.currency?.symbol}
              decimals={topBid?.currency?.decimals}
              textStyle='body1'
              textColor='subtle'
            />
          </Flex>
        ) : null}
      </Flex>
    </Flex>
  );
};
