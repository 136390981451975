import type { AppProps } from 'next/app';
import { globalReset } from '@stitches.config';
import { useEffect } from 'react';
import { useSignals } from '@preact/signals-react/runtime';
import { loadMarketplace } from '@signals/marketplace';
import { sReady } from '@signals/ready';
import { useTheme } from '@v2/hooks/useTheme';
import { updateSelectedCollection } from '@signals/collection';
import { useRouter } from 'next/router';
import { WalletProvider } from '@context/WalletContext';

export const PageWrapper = ({ Component, pageProps }: AppProps) => {
  globalReset();
  useSignals();
  useTheme();

  const { query } = useRouter();

  useEffect(() => {
    const contract = query.contract as string;
    updateSelectedCollection(contract);
  }, [query]);

  useEffect(() => {
    loadMarketplace(pageProps.config);
  }, []);

  if (sReady.value === false) {
    return null;
  }

  return (
    <WalletProvider>
      <Component {...pageProps} />
    </WalletProvider>
  );
};
