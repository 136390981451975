import React, { FC, useContext, useMemo } from 'react';
import {
  Button,
  Flex,
  FormatCryptoCurrency,
  FormatCurrency,
  Text,
} from '../../primitives';
import { styled } from '@stitches.config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowDown,
  faArrowUp,
  faClose,
} from '@fortawesome/free-solid-svg-icons';
import { formatNumber } from '../../lib/numbers';
import QuantitySelector from '../../modal/QuantitySelector';
import { ProviderOptionsContext } from '../../ReservoirKitProvider';
import { useCart } from '@reservoir-kit-ui-overrides/hooks';
import useCollectionToken from '@hooks/useCollectionToken';
import { getCollection } from '@signals/collection';
import { int } from '@api/utils';
import { Order } from '@api/orderbook_api/v1/types.pb';
import { useOpenToken } from '@hooks/useOpenToken';
import { CartItem as CartItemType } from '@reservoir-kit-ui-overrides/context/CartProvider';

type Props = {
  item: CartItemType;
  usdConversion: number | null;
  tokenUrl?: string;
  onRemove?: () => void;
  onClick?: () => void;
};

const CartItemImage = styled('img', {
  width: 56,
  height: 56,
  borderRadius: '$radiusTiny',
  objectFit: 'cover',
});

const CloseButton = styled(Button, {
  position: 'absolute',
  width: 24,
  height: 24,
  top: -8,
  right: -8,
  flexShrink: 0,
  defaultVariants: {
    size: 'none',
    corners: 'circle',
  },
});

const CartItem: FC<Props> = ({
  item,
  usdConversion,
  tokenUrl,
  onRemove,
  onClick,
}) => {
  const { openToken } = useOpenToken();
  const providerOptionsContext = useContext(ProviderOptionsContext);
  const order = item;
  const {
    remove,
    data: cartCurrency,
    setQuantity,
  } = useCart((cart) => cart.currency);
  const { data: cartChain } = useCart((cart) => cart.chain);

  const currencyConverted =
    item.price && item.price?.currency?.address !== cartCurrency?.address;
  let price = currencyConverted
    ? item.price?.amount?.native
    : item.price?.amount?.decimal;

  let priceDiff = 0;
  let priceIncrease = false;
  let priceDecrease = false;
  let usdPrice = (usdConversion || 0) * (price || 0);

  const maxQuantity = useMemo(() => int(order.amountRemaining), []);

  if (price && order?.amount) {
    price = price * int(order.amount);
    usdPrice = usdPrice * int(order.amount);
  }
  const { data: fullToken } = useCollectionToken({
    tokenId: order?.token?.tokenId,
    collection: order?.collection,
    chainId: order?.chainId,
  });
  const is1155 = fullToken?.isErc1155;
  const fullCollection = getCollection(order.collection);
  const quantityRemaining = int(order.amount) - int(order.amountFilled);
  return (
    <Flex direction='column'>
      <Flex
        onClick={() => {
          onClick?.();
          openToken(order.token?.collection, order.token?.tokenId);
        }}
        css={{
          width: '100%',
          py: 8,
          cursor: 'pointer',
        }}
      >
        <Flex css={{ position: 'relative', minWidth: 0, flexShrink: 0 }}>
          <CartItemImage
            src={fullToken?.imageUrl}
            css={!price ? { filter: 'grayscale(1)' } : {}}
            onError={({ currentTarget }) => {
              const collectionImage = fullCollection?.config?.imageUrl || '';
              if (currentTarget.src != collectionImage) {
                currentTarget.src = collectionImage;
              }
            }}
          />
          <CloseButton
            css={{
              '&:hover': {
                background: '$neutralBgActive',
              },
              background: '$neutralBgSubtle',
              border: '1px solid $neutralBorder',
              color: '$neutralText',
              w: 22,
              h: 22,
            }}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onRemove?.();
              if (order?.id) {
                remove([order?.id]);
              }
            }}
          >
            <FontAwesomeIcon icon={faClose} width='10' height='10' />
          </CloseButton>
        </Flex>
        <Flex
          direction='column'
          justify='center'
          css={{ gap: 2, ml: '$2', minWidth: 0 }}
        >
          <Flex align='center' css={{ gap: '$1' }}>
            <Text style='h6' color={price ? undefined : 'subtle'} ellipsify>
              {order?.token?.name ?? `#${order?.token?.tokenId}`}
            </Text>
          </Flex>
          <Text style='body3' color='subtle' ellipsify>
            {fullCollection?.name}
          </Text>
          {!price && !order?.id && (
            <Text style='body3' color='error'>
              Item no longer available
            </Text>
          )}
          {!price && order?.id && (
            <Text style='body3' color='error'>
              Listing no longer available
            </Text>
          )}
          {!priceIncrease && !priceDecrease && currencyConverted && (
            <Flex
              css={{ gap: '$1', color: '$accentSolidHover' }}
              align='center'
            >
              <Text style='body3' color='accent'>
                Currency converted
              </Text>
            </Flex>
          )}
          {priceIncrease && (
            <Flex
              css={{ gap: '$1', color: '$accentSolidHover' }}
              align='center'
            >
              <FontAwesomeIcon width='11' icon={faArrowUp} />
              <Text style='body2' color='accent'>
                Price has gone up {formatNumber(priceDiff)}%
              </Text>
            </Flex>
          )}
          {priceDecrease && (
            <Flex
              css={{ gap: '$1', color: '$accentSolidHover' }}
              align='center'
            >
              <FontAwesomeIcon width='11' icon={faArrowDown} />
              <Text style='body3' color='accent'>
                Price went down {formatNumber(priceDiff)}%
              </Text>
            </Flex>
          )}
        </Flex>
        {price ? (
          <Flex
            direction='column'
            justify='center'
            css={{
              ml: 'auto',
              flexShrink: 0,
              gap: '$1',
              '> div': { ml: 'auto' },
            }}
          >
            {providerOptionsContext.preferDisplayFiatTotal &&
            usdPrice &&
            usdPrice > 0 ? (
              <>
                <FormatCurrency
                  amount={usdPrice}
                  style='subtitle3'
                  color='base'
                  css={{ textAlign: 'end' }}
                />
                <FormatCryptoCurrency
                  textStyle='subtitle2'
                  textColor='subtle'
                  amount={price}
                  address={cartCurrency?.address}
                  decimals={cartCurrency?.decimals}
                  symbol={cartCurrency?.symbol}
                  logoWidth={10}
                  chainId={cartChain?.id || int(order?.chainId)}
                />
              </>
            ) : (
              <>
                <FormatCryptoCurrency
                  textStyle='subtitle2'
                  amount={price}
                  address={cartCurrency?.address}
                  decimals={cartCurrency?.decimals}
                  symbol={cartCurrency?.symbol}
                  logoWidth={12}
                  chainId={cartChain?.id || int(order?.chainId)}
                />
                {usdPrice && usdPrice > 0 ? (
                  <FormatCurrency
                    amount={usdPrice}
                    color='subtle'
                    css={{ textAlign: 'end' }}
                  />
                ) : null}
              </>
            )}
          </Flex>
        ) : null}
      </Flex>
      {order && is1155 && price ? (
        <Flex
          justify='between'
          align='center'
          css={{ width: '100%', px: 24, pb: 8, gap: '$3' }}
        >
          <Flex
            direction='column'
            align='start'
            css={{ gap: '$1', overflow: 'hidden', minWidth: 0 }}
          >
            <Text style='body3'>Quantity</Text>
            <Text
              style='body3'
              color='subtle'
              ellipsify
              css={{ width: '100%' }}
            >
              {formatNumber(quantityRemaining)} items available
            </Text>
          </Flex>
          <QuantitySelector
            min={1}
            max={int(order.amountRemaining)}
            quantity={order.quantity || 1}
            setQuantity={(number) => setQuantity(order?.id as string, number)}
            css={{
              border: '1px solid $neutralBorder',
              background: 'none',
            }}
          />
        </Flex>
      ) : null}
    </Flex>
  );
};

export default CartItem;
