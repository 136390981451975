export default function getLocalMarketplaceData() {
  let reservoirTitleEl = document.querySelector(
    "meta[property='reservoir:title']",
  );
  let title: null | string = null;
  if (reservoirTitleEl) {
    title = reservoirTitleEl.getAttribute('content');
  }

  const reservoirIconEl = document.querySelector(
    "meta[property='reservoir:icon']",
  );
  let icon: null | string = null;
  if (reservoirIconEl) {
    icon = reservoirIconEl.getAttribute('content');
  }

  if (!icon) {
    const favicon = document.querySelector("link[rel*='icon']");
    if (favicon) {
      icon = favicon.getAttribute('href');
    }
  }

  return {
    title,
    icon,
  };
}
