import React, { FC } from 'react';
import { truncateAddress } from '../lib/truncate';
import getChainBlockExplorerUrl from '../lib/getChainBlockExplorerUrl';
import { Anchor, Flex } from '../primitives';
import { CSS } from '@stitches/react';
import { EnhancedStepItem } from '@types';

type CurrentStepTxHashesProps = {
  currentStepItem?: EnhancedStepItem;
  css?: CSS;
};

export const CurrentStepTxHashes: FC<CurrentStepTxHashesProps> = ({
  currentStepItem,
  css,
}) => {
  const hasValidTxHashes =
    (Array.isArray(currentStepItem?.txHashes) &&
      currentStepItem.txHashes.length > 0) ||
    (Array.isArray(currentStepItem?.txHashes) &&
      currentStepItem.txHashes.length > 0);

  // Return null if there are no valid txHashes or internalTxHashes
  if (!hasValidTxHashes) {
    return null;
  }

  const ItemComponent = () => {
    if (
      currentStepItem?.txHashes?.length &&
      currentStepItem.txHashes.length > 0
    ) {
      return (
        <>
          {currentStepItem.txHashes?.map((hash, txHashIndex) => {
            const truncatedTxHash = truncateAddress(hash.txHash);
            const blockExplorerBaseUrl = getChainBlockExplorerUrl(hash.chainId);
            return (
              <Anchor
                key={`${txHashIndex}`}
                href={`${blockExplorerBaseUrl}/tx/${hash.txHash}`}
                color='primary'
                weight='medium'
                target='_blank'
                css={{ fontSize: 12 }}
              >
                View transaction: {truncatedTxHash}
              </Anchor>
            );
          })}
        </>
      );
    } else {
      return <></>;
    }
  };

  return (
    <Flex
      direction='column'
      align='center'
      css={{ width: '100%', gap: '$2', mb: '$3', px: '$5', ...css }}
    >
      <ItemComponent />
    </Flex>
  );
};
