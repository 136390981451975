import { ComponentProps, PropsWithChildren } from 'react';
import { Button } from 'components/primitives';
import { useAuthGuard } from '@hooks/useAuthGuard';

type Props = ComponentProps<typeof Button> & {
  chainId?: number;
};

export const ButtonWithAuth = ({
  children,
  chainId,
  ...props
}: PropsWithChildren<Props>) => {
  const { ensureAuth } = useAuthGuard({ chainId });

  const onClick = async (e: any) => {
    if (await ensureAuth()) {
      props.onClick?.(e);
    }
  };

  return (
    <Button {...props} onClick={onClick}>
      {children}
    </Button>
  );
};
