import { styled } from '@stitches/react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { IconCheckbox } from '@v2/icons/IconCheckbox';
import { IconBox } from '@v2/icons/IconBox';
import { ComponentProps } from 'react';

const CheckboxRoot = styled(CheckboxPrimitive.Root, {
  all: 'unset',
  variants: {
    checked: {
      true: {
        color: '$accentColor',
      },
      false: {
        color: '$checkboxBg',
      },
    },
  },
  defaultVariants: {
    checked: false,
  },
});

const Checkbox = (props?: ComponentProps<typeof CheckboxRoot>) => (
  <CheckboxRoot {...props}>
    {props?.checked ? <IconCheckbox /> : <IconBox />}
  </CheckboxRoot>
);

export default Checkbox;
