import { Currency } from '@api/orderbook_api/v1/types.pb';
import {
  Flex,
  Text,
  FormatCurrency,
  FormatCryptoCurrency,
} from '@reservoir-kit-ui-overrides/primitives';

interface Props {
  price?: number | bigint;
  usdPrice?: number | string;
  chain: any;
  currency: Currency;
  expirationDisplay?: string;
}

export const PriceTotal = ({
  price,
  usdPrice,
  chain,
  currency: { address, symbol, decimals },
  expirationDisplay,
}: Props) => {
  return (
    <Flex direction='column' align='end'>
      {price ? (
        <FormatCryptoCurrency
          amount={price}
          chainId={chain.id}
          textStyle='subtitle1'
          textColor='base'
          address={address}
          decimals={decimals}
          symbol={symbol}
          logoWidth={14.5}
        />
      ) : (
        <Text style='subtitle3' color='base'>
          --
        </Text>
      )}
      {usdPrice ? (
        <FormatCurrency amount={usdPrice} style='body1' color='subtle' />
      ) : null}
      {expirationDisplay ? (
        <Text style='body2' color='subtle'>
          Expires in {expirationDisplay}
        </Text>
      ) : null}
    </Flex>
  );
};
