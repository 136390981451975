/* eslint-disable */
// @ts-nocheck
/*
 * This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
 */

import * as AsyncV1Async from "../../async/v1/async.pb";
import * as CommonV1Common from "../../common/v1/common.pb";
import * as fm from "../../fetch.pb";
import * as Payment_processorV2Payment_processor from "../../payment_processor/v2/payment_processor.pb";
import * as Orderbook_apiV1Types from "./types.pb";
export type QueryDailyTokenPriceRequest = {
  chainId?: string;
  collection?: string;
  tokenId?: string;
  fromTimestamp?: string;
  toTimestamp?: string;
};

export type QueryDailyCollectionPriceRequest = {
  chainId?: string;
  collection?: string;
  fromTimestamp?: string;
  toTimestamp?: string;
};

export type QueryDailyTokenSalesVolumeRequest = {
  chainId?: string;
  collection?: string;
  tokenId?: string;
  fromTimestamp?: string;
  toTimestamp?: string;
};

export type QueryDailyCollectionSalesVolumeRequest = {
  chainId?: string;
  collection?: string;
  fromTimestamp?: string;
  toTimestamp?: string;
};

export type SweepCollectionRequest = {
  chainId?: string;
  collection?: string;
  protocol?: Payment_processorV2Payment_processor.OrderProtocol;
  paymentMethod?: string;
  beneficiary?: string;
  orderIds?: string[];
};

export type BuyCrossChainListingsRequest = {
  orderIds?: string[];
  orderAmounts?: { [key: string]: string };
  paymentMethod?: string;
  beneficiary?: string;
};

export type BuyListingsRequest = {
  chainId?: string;
  collection?: string;
  orderIds?: string[];
  orderAmounts?: { [key: string]: string };
  paymentMethod?: string;
  beneficiary?: string;
};

export type BuyAttributeListingsRequest = {
  chainId?: string;
  collection?: string;
  orderIds?: string[];
  orderAmounts?: { [key: string]: string };
};

export type BuyListingRequest = {
  chainId?: string;
  collection?: string;
  orderId?: string;
  tokenId?: string;
  paymentMethod?: string;
  beneficiary?: string;
  amount?: string;
};

export type AcceptOffersRequest = {
  chainId?: string;
  collection?: string;
  offers?: AcceptOffersItem[];
  paymentMethod?: string;
};

export type AcceptOffersItem = {
  orderId?: string;
  tokenId?: string;
  amount?: string;
};

export type AcceptOfferRequest = {
  chainId?: string;
  collection?: string;
  orderId?: string;
  tokenId?: string;
  paymentMethod?: string;
  amount?: string;
};

export type SubmitOrderSignatureRequest = {
  chainId?: string;
  collection?: string;
  orderId?: string;
  signature?: string;
};

export type CreateListingRequest = {
  orderbook?: Orderbook_apiV1Types.Orderbook;
  kind?: Orderbook_apiV1Types.OrderKind;
  chainId?: string;
  collection?: string;
  protocol?: Payment_processorV2Payment_processor.OrderProtocol;
  paymentMethod?: string;
  tokenId?: string;
  amount?: string;
  itemPrice?: string;
  marketplace?: string;
  cosigner?: string;
  fallbackRoyaltyRecipient?: string;
  marketplaceFeeNumerator?: string;
  maxRoyaltyFeeNumerator?: string;
  expiration?: string;
  validAt?: string;
  labels?: { [key: string]: string };
};

export type UpdateListingRequest = {
  id?: string;
  kind?: Orderbook_apiV1Types.OrderKind;
  chainId?: string;
  collection?: string;
  protocol?: Payment_processorV2Payment_processor.OrderProtocol;
  paymentMethod?: string;
  tokenId?: string;
  amount?: string;
  itemPrice?: string;
  marketplace?: string;
  cosigner?: string;
  fallbackRoyaltyRecipient?: string;
  marketplaceFeeNumerator?: string;
  maxRoyaltyFeeNumerator?: string;
  expiration?: string;
  validAt?: string;
  labels?: { [key: string]: string };
};

export type CreateBidRequest = {
  orderbook?: Orderbook_apiV1Types.Orderbook;
  kind?: Orderbook_apiV1Types.OrderKind;
  chainId?: string;
  collection?: string;
  protocol?: Payment_processorV2Payment_processor.OrderProtocol;
  paymentMethod?: string;
  beneficiary?: string;
  tokenId?: string;
  tokenIds?: string[];
  amount?: string;
  itemPrice?: string;
  marketplace?: string;
  cosigner?: string;
  fallbackRoyaltyRecipient?: string;
  marketplaceFeeNumerator?: string;
  attributes?: CommonV1Common.TokenAttributeFilter[];
  expiration?: string;
  validAt?: string;
  labels?: { [key: string]: string };
  wrapAmount?: string;
};

export type CreateAttributeBidRequest = {
  orderbook?: Orderbook_apiV1Types.Orderbook;
  kind?: Orderbook_apiV1Types.OrderKind;
  chainId?: string;
  collection?: string;
  protocol?: Payment_processorV2Payment_processor.OrderProtocol;
  paymentMethod?: string;
  beneficiary?: string;
  attributes?: CommonV1Common.TokenAttributeFilter[];
  amount?: string;
  itemPrice?: string;
  marketplace?: string;
  cosigner?: string;
  fallbackRoyaltyRecipient?: string;
  marketplaceFeeNumerator?: string;
  expiration?: string;
  validAt?: string;
  labels?: { [key: string]: string };
  wrapAmount?: string;
};

export type UpdateBidRequest = {
  id?: string;
  kind?: Orderbook_apiV1Types.OrderKind;
  chainId?: string;
  collection?: string;
  protocol?: Payment_processorV2Payment_processor.OrderProtocol;
  paymentMethod?: string;
  beneficiary?: string;
  tokenId?: string;
  tokenIds?: string[];
  amount?: string;
  itemPrice?: string;
  marketplace?: string;
  cosigner?: string;
  fallbackRoyaltyRecipient?: string;
  marketplaceFeeNumerator?: string;
  expiration?: string;
  validAt?: string;
  labels?: { [key: string]: string };
  wrapAmount?: string;
};

export type AwaitSalesRequest = {
  orderIds?: string[];
  ttlSeconds?: string;
};

export type AwaitSalesResponse = {
  sales?: Orderbook_apiV1Types.Order[];
};

export type AwaitTxRequest = {
  chainId?: string;
  txHash?: string;
  ttlSeconds?: string;
};

export type AwaitSaleRequest = {
  chainId?: string;
  collection?: string;
  orderId?: string;
  tokenId?: string;
  ttlSeconds?: string;
};

export type QueryWalletTokensRequest = {
  walletAddress?: string;
  chainIds?: string[];
  collections?: string[];
  attributes?: CommonV1Common.TokenAttributeFilter[];
  floorAskGte?: Orderbook_apiV1Types.PriceFilter;
  floorAskLte?: Orderbook_apiV1Types.PriceFilter;
  ceilingOfferGte?: Orderbook_apiV1Types.PriceFilter;
  ceilingOfferLte?: Orderbook_apiV1Types.PriceFilter;
  nameLike?: string;
  descriptionLike?: string;
  isListed?: boolean;
  notListed?: boolean;
  lastSalePriceGte?: Orderbook_apiV1Types.PriceFilter;
  lastSalePriceLte?: Orderbook_apiV1Types.PriceFilter;
  hasOffers?: boolean;
  hasNoOffers?: boolean;
  currencyFilter?: Orderbook_apiV1Types.CurrencyFilter;
  isErc1155?: boolean;
  expandAttributes?: boolean;
  expandStats?: boolean;
  erc1155Owner?: string;
  page?: string;
  pageSize?: string;
  orderBy?: CommonV1Common.OrderBy[];
};

export type QueryWalletActivityRequest = {
  walletAddress?: string;
  chainIds?: string[];
  collections?: string[];
  types?: Orderbook_apiV1Types.ActivityType[];
  transactionHash?: string;
  tokenIds?: string[];
  createdBefore?: string;
  createdAfter?: string;
  notTypes?: Orderbook_apiV1Types.ActivityType[];
  page?: string;
  pageSize?: string;
  orderBy?: CommonV1Common.OrderBy[];
};

export type QueryWalletOrdersRequest = {
  walletAddress?: string;
  chainIds?: string[];
  collections?: string[];
  types?: Orderbook_apiV1Types.OrderType[];
  orderIds?: string[];
  beneficiaries?: string[];
  marketplaces?: string[];
  tokenIds?: string[];
  itemPriceGte?: string;
  itemPriceLte?: string;
  amountGte?: string;
  amountLte?: string;
  merkleRoot?: string;
  status?: Orderbook_apiV1Types.OrderStatus[];
  attributes?: CommonV1Common.TokenAttributeFilter[];
  isMaker?: boolean;
  isTaker?: boolean;
  takerOptions?: Orderbook_apiV1Types.TakerOptions;
  orderBy?: CommonV1Common.OrderBy[];
  page?: number;
  pageSize?: number;
};

export type RefreshTokenRequest = {
  chainId?: string;
  collection?: string;
  tokenId?: string;
  erc1155Owners?: string[];
};

export type GetBidMerkleRootRequest = {
  chainId?: string;
  collection?: string;
  tokenIds?: string[];
};

export type GetCollectionTokenRequest = {
  chainId?: string;
  collection?: string;
  tokenId?: string;
  erc1155Owner?: string;
  expandAttributes?: boolean;
  expandStats?: boolean;
};

export type GetTokensByRefRequest = {
  refs?: string[];
  erc1155Owner?: string;
};

export type GetTokensByRefResponse = {
  tokens?: Orderbook_apiV1Types.Token[];
};

export type QueryCollectionTransfersRequest = {
  chainId?: string;
  collection?: string;
  transactionHash?: string;
  tokenId?: string;
  blockNumber?: string;
  fromAddress?: string;
  toAddress?: string;
  isMint?: boolean;
  after?: string;
  page?: string;
  pageSize?: string;
  orderBy?: CommonV1Common.OrderBy[];
};

export type QueryTokensResponse = {
  tokens?: Orderbook_apiV1Types.Token[];
  total?: string;
};

export type QueryTransfersResponse = {
  transfers?: Orderbook_apiV1Types.Transfer[];
  total?: string;
};

export type QueryCollectionTokensRequest = {
  chainId?: string;
  collection?: string;
  tokenIds?: string[];
  ownerAddresses?: string[];
  attributes?: CommonV1Common.TokenAttributeFilter[];
  nameLike?: string;
  descriptionLike?: string;
  floorAskGte?: Orderbook_apiV1Types.PriceFilter;
  floorAskLte?: Orderbook_apiV1Types.PriceFilter;
  ceilingOfferGte?: Orderbook_apiV1Types.PriceFilter;
  ceilingOfferLte?: Orderbook_apiV1Types.PriceFilter;
  notOwnerAddress?: string;
  isListed?: boolean;
  notListed?: boolean;
  lastSalePriceGte?: Orderbook_apiV1Types.PriceFilter;
  lastSalePriceLte?: Orderbook_apiV1Types.PriceFilter;
  hasOffers?: boolean;
  hasNoOffers?: boolean;
  currencyFilter?: Orderbook_apiV1Types.CurrencyFilter;
  isErc1155?: boolean;
  expandAttributes?: boolean;
  expandStats?: boolean;
  page?: string;
  pageSize?: string;
  orderBy?: CommonV1Common.OrderBy[];
};

export type QueryCollectionsRequest = {
  chainId?: string;
  collections?: string[];
  name?: string;
  symbol?: string;
  nameLike?: string;
  page?: string;
  pageSize?: string;
  orderBy?: CommonV1Common.OrderBy[];
};

export type QueryCollectionsResponse = {
  collections?: Orderbook_apiV1Types.Collection[];
  total?: string;
};

export type GetCollectionRequest = {
  chainId?: string;
  collection?: string;
};

export type QueryOrdersRequest = {
  chainId?: string;
  collection?: string;
  types?: Orderbook_apiV1Types.OrderType[];
  orderIds?: string[];
  makers?: string[];
  beneficiaries?: string[];
  marketplaces?: string[];
  tokenIds?: string[];
  itemPriceGte?: string;
  itemPriceLte?: string;
  amountGte?: string;
  amountLte?: string;
  merkleRoot?: string;
  status?: Orderbook_apiV1Types.OrderStatus[];
  attributes?: CommonV1Common.TokenAttributeFilter[];
  takers?: string[];
  walletAddresses?: string[];
  takerOptions?: Orderbook_apiV1Types.TakerOptions;
  filledBefore?: string;
  filledAfter?: string;
  notMaker?: string;
  orderBy?: CommonV1Common.OrderBy[];
  page?: number;
  pageSize?: number;
};

export type QueryCrossChainOrdersRequest = {
  chainIds?: string[];
  collections?: string[];
  types?: Orderbook_apiV1Types.OrderType[];
  orderIds?: string[];
  makers?: string[];
  beneficiaries?: string[];
  marketplaces?: string[];
  tokenIds?: string[];
  itemPriceGte?: string;
  itemPriceLte?: string;
  amountGte?: string;
  amountLte?: string;
  merkleRoot?: string;
  status?: Orderbook_apiV1Types.OrderStatus[];
  attributes?: CommonV1Common.TokenAttributeFilter[];
  takers?: string[];
  walletAddresses?: string[];
  takerOptions?: Orderbook_apiV1Types.TakerOptions;
  filledBefore?: string;
  filledAfter?: string;
  notMaker?: string;
  orderBy?: CommonV1Common.OrderBy[];
  page?: number;
  pageSize?: number;
};

export type QueryOrdersResponse = {
  orders?: Orderbook_apiV1Types.Order[];
  total?: number;
};

export type GetOrderRequest = {
  chainId?: string;
  collection?: string;
  orderId?: string;
};

export type GetCosignatureRequest = {
  chainId?: string;
  collection?: string;
  orderId?: string;
};

export type CancelOrderRequest = {
  chainId?: string;
  collection?: string;
  orderId?: string;
};

export type GetAuthNonceRequest = {
  publicAddress?: string;
};

export type GetAuthNonceResponse = {
  nonce?: string;
};

export type AuthRequest = {
  publicAddress?: string;
  signature?: string;
};

export type AuthResponse = {
  token?: string;
};

export type QueryCrossChainActivityRequest = {
  chainIds?: string[];
  collections?: string[];
  tokenIds?: string[];
  types?: Orderbook_apiV1Types.ActivityType[];
  transactionHash?: string;
  fromAddress?: string;
  toAddress?: string;
  createdBefore?: string;
  createdAfter?: string;
  walletAddress?: string;
  notTypes?: Orderbook_apiV1Types.ActivityType[];
  orderBy?: CommonV1Common.OrderBy[];
  page?: number;
  pageSize?: number;
};

export type QueryCollectionActivityRequest = {
  chainId?: string;
  collection?: string;
  tokenId?: string;
  types?: Orderbook_apiV1Types.ActivityType[];
  transactionHash?: string;
  fromAddress?: string;
  toAddress?: string;
  createdBefore?: string;
  createdAfter?: string;
  notTypes?: Orderbook_apiV1Types.ActivityType[];
  orderBy?: CommonV1Common.OrderBy[];
  page?: number;
  pageSize?: number;
};

export type QueryActivityResponse = {
  activities?: Orderbook_apiV1Types.Activity[];
  total?: number;
};

export type GetCollectionTokenAttributesRequest = {
  chainId?: string;
  collection?: string;
};

export type GetCollectionTokenAttributesResponse = {
  attributes?: Orderbook_apiV1Types.TokenAttribute[];
};

export type GetTokenAttributesRequest = {
  chainId?: string;
  collection?: string;
  tokenId?: string;
};

export type GetTokenAttributesResponse = {
  attributes?: Orderbook_apiV1Types.TokenAttributeValue[];
};

export type GetWalletNonceRequest = {};

export type GetWalletNonceResponse = {
  nonce?: string;
};

export type GetWalletMasterNonceRequest = {};

export type GetWalletMasterNonceResponse = {
  nonce?: string;
};

export type StreamActivityRequest = {
  chainIds?: string[];
  collections?: string[];
  types?: Orderbook_apiV1Types.ActivityType[];
  tokenIds?: string[];
  walletAddresses?: string[];
};

export type GetChainRequest = {
  chainId?: string;
};

export type QueryCrossChainTransfersRequest = {
  chainIds?: string[];
  collections?: string[];
  transactionHash?: string;
  tokenId?: string;
  blockNumber?: string;
  fromAddress?: string;
  toAddress?: string;
  isMint?: boolean;
  after?: string;
  page?: string;
  pageSize?: string;
  orderBy?: CommonV1Common.OrderBy[];
};

export type QueryCrossChainCollectionsRequest = {
  chainIds?: string[];
  collections?: string[];
  name?: string;
  symbol?: string;
  nameLike?: string;
  refs?: string[];
  page?: string;
  pageSize?: string;
  orderBy?: CommonV1Common.OrderBy[];
};

export type QueryCrossChainTokensRequest = {
  chainIds?: string[];
  collections?: string[];
  tokenIds?: string[];
  ownerAddresses?: string[];
  attributes?: CommonV1Common.TokenAttributeFilter[];
  nameLike?: string;
  descriptionLike?: string;
  floorAskGte?: Orderbook_apiV1Types.PriceFilter;
  floorAskLte?: Orderbook_apiV1Types.PriceFilter;
  ceilingOfferGte?: Orderbook_apiV1Types.PriceFilter;
  ceilingOfferLte?: Orderbook_apiV1Types.PriceFilter;
  refs?: string[];
  isListed?: boolean;
  notListed?: boolean;
  lastSalePriceGte?: Orderbook_apiV1Types.PriceFilter;
  lastSalePriceLte?: Orderbook_apiV1Types.PriceFilter;
  hasOffers?: boolean;
  hasNoOffers?: boolean;
  currencyFilter?: Orderbook_apiV1Types.CurrencyFilter;
  notOwnerAddress?: string;
  isErc1155?: boolean;
  expandAttributes?: boolean;
  expandStats?: boolean;
  erc1155Owner?: string;
  page?: string;
  pageSize?: string;
  orderBy?: CommonV1Common.OrderBy[];
};

export type ConvertCurrencyRequest = {
  chainId?: string;
  fromCurrency?: string;
  toCurrency?: string;
  amount?: number;
  amountRaw?: string;
};

export type ConvertCurrencyResponse = {
  conversion?: string;
  usd?: number;
  conversionDecimal?: number;
};

export type GetExchangeRateRequest = {
  chainId?: string;
  currency?: string;
  toCurrency?: string;
};

export type GetExchangeRateResponse = {
  rate?: number;
  usdRate?: number;
};

export type GetUsdExchangeRateRequest = {
  chainId?: string;
  currency?: string;
};

export type GetUsdExchangeRateResponse = {
  rate?: number;
};

export type GetCurrenciesResponse = {
  currencies?: Orderbook_apiV1Types.Currency[];
};

export type GetJobRequest = {
  jobId?: string;
  queue?: string;
};

export type GetMarketplaceConfigRequest = {
  marketplaceId?: string;
  slug?: string;
};

export type GetErc1155MetaRequest = {
  chainId?: string;
  collection?: string;
  tokenId?: string;
  walletAddress?: string;
};

export class OrderbookService {
  static GetAuthNonce(
    req: GetAuthNonceRequest,
    initReq?: fm.InitReq,
  ): Promise<GetAuthNonceResponse> {
    return fm.fetchReq<GetAuthNonceRequest, GetAuthNonceResponse>(
      `/auth/nonce`,
      { ...initReq, method: "POST", body: JSON.stringify(req, fm.replacer) },
    );
  }
  static Auth(req: AuthRequest, initReq?: fm.InitReq): Promise<AuthResponse> {
    return fm.fetchReq<AuthRequest, AuthResponse>(`/auth/signin`, {
      ...initReq,
      method: "POST",
      body: JSON.stringify(req, fm.replacer),
    });
  }
  static GetChains(
    req: CommonV1Common.Empty,
    initReq?: fm.InitReq,
  ): Promise<Orderbook_apiV1Types.Chains> {
    return fm.fetchReq<CommonV1Common.Empty, Orderbook_apiV1Types.Chains>(
      `/v1/chains?${fm.renderURLSearchParams(req, [])}`,
      { ...initReq, method: "GET" },
    );
  }
  static GetChain(
    req: GetChainRequest,
    initReq?: fm.InitReq,
  ): Promise<Orderbook_apiV1Types.Chain> {
    return fm.fetchReq<GetChainRequest, Orderbook_apiV1Types.Chain>(
      `/v1/chains/${req["chainId"]}?${fm.renderURLSearchParams(req, ["chainId"])}`,
      { ...initReq, method: "GET" },
    );
  }
  static SubmitOrderSignature(
    req: SubmitOrderSignatureRequest,
    initReq?: fm.InitReq,
  ): Promise<Orderbook_apiV1Types.Order> {
    return fm.fetchReq<SubmitOrderSignatureRequest, Orderbook_apiV1Types.Order>(
      `/v1/orderbook/${req["chainId"]}/collections/${req["collection"]}/orders/${req["orderId"]}/signature`,
      { ...initReq, method: "POST", body: JSON.stringify(req, fm.replacer) },
    );
  }
  static CreateListing(
    req: CreateListingRequest,
    initReq?: fm.InitReq,
  ): Promise<Orderbook_apiV1Types.Steps> {
    return fm.fetchReq<CreateListingRequest, Orderbook_apiV1Types.Steps>(
      `/v1/orderbook/${req["chainId"]}/collections/${req["collection"]}/listings`,
      { ...initReq, method: "POST", body: JSON.stringify(req, fm.replacer) },
    );
  }
  static UpdateListing(
    req: UpdateListingRequest,
    initReq?: fm.InitReq,
  ): Promise<Orderbook_apiV1Types.Steps> {
    return fm.fetchReq<UpdateListingRequest, Orderbook_apiV1Types.Steps>(
      `/v1/orderbook/${req["chainId"]}/collections/${req["collection"]}/listings/${req["id"]}`,
      { ...initReq, method: "PUT", body: JSON.stringify(req, fm.replacer) },
    );
  }
  static CreateBid(
    req: CreateBidRequest,
    initReq?: fm.InitReq,
  ): Promise<Orderbook_apiV1Types.Steps> {
    return fm.fetchReq<CreateBidRequest, Orderbook_apiV1Types.Steps>(
      `/v1/orderbook/${req["chainId"]}/collections/${req["collection"]}/bids`,
      { ...initReq, method: "POST", body: JSON.stringify(req, fm.replacer) },
    );
  }
  static CreateAttributeBid(
    req: CreateAttributeBidRequest,
    initReq?: fm.InitReq,
  ): Promise<Orderbook_apiV1Types.Steps> {
    return fm.fetchReq<CreateAttributeBidRequest, Orderbook_apiV1Types.Steps>(
      `/v1/orderbook/${req["chainId"]}/collections/${req["collection"]}/bids:attributes`,
      { ...initReq, method: "POST", body: JSON.stringify(req, fm.replacer) },
    );
  }
  static UpdateBid(
    req: UpdateBidRequest,
    initReq?: fm.InitReq,
  ): Promise<Orderbook_apiV1Types.Steps> {
    return fm.fetchReq<UpdateBidRequest, Orderbook_apiV1Types.Steps>(
      `/v1/orderbook/${req["chainId"]}/collections/${req["collection"]}/bids/${req["id"]}`,
      { ...initReq, method: "PUT", body: JSON.stringify(req, fm.replacer) },
    );
  }
  static GetCosignature(
    req: GetCosignatureRequest,
    initReq?: fm.InitReq,
  ): Promise<Orderbook_apiV1Types.Cosignature> {
    return fm.fetchReq<GetCosignatureRequest, Orderbook_apiV1Types.Cosignature>(
      `/v1/orderbook/${req["chainId"]}/collections/${req["collection"]}/orders/${req["orderId"]}/cosignature?${fm.renderURLSearchParams(req, ["chainId", "collection", "orderId"])}`,
      { ...initReq, method: "GET" },
    );
  }
  static QueryOrders(
    req: QueryOrdersRequest,
    initReq?: fm.InitReq,
  ): Promise<QueryOrdersResponse> {
    return fm.fetchReq<QueryOrdersRequest, QueryOrdersResponse>(
      `/v1/orderbook/${req["chainId"]}/collections/${req["collection"]}/orders:query`,
      { ...initReq, method: "POST", body: JSON.stringify(req, fm.replacer) },
    );
  }
  static GetOrder(
    req: GetOrderRequest,
    initReq?: fm.InitReq,
  ): Promise<Orderbook_apiV1Types.Order> {
    return fm.fetchReq<GetOrderRequest, Orderbook_apiV1Types.Order>(
      `/v1/orderbook/${req["chainId"]}/collections/${req["collection"]}/orders/${req["orderId"]}?${fm.renderURLSearchParams(req, ["chainId", "collection", "orderId"])}`,
      { ...initReq, method: "GET" },
    );
  }
  static CancelOrder(
    req: CancelOrderRequest,
    initReq?: fm.InitReq,
  ): Promise<Orderbook_apiV1Types.Steps> {
    return fm.fetchReq<CancelOrderRequest, Orderbook_apiV1Types.Steps>(
      `/v1/orderbook/${req["chainId"]}/collections/${req["collection"]}/orders/${req["orderId"]}:cancel`,
      { ...initReq, method: "POST", body: JSON.stringify(req, fm.replacer) },
    );
  }
  static AwaitTx(
    req: AwaitTxRequest,
    initReq?: fm.InitReq,
  ): Promise<CommonV1Common.Empty> {
    return fm.fetchReq<AwaitTxRequest, CommonV1Common.Empty>(
      `/v1/orderbook/${req["chainId"]}/txs/${req["txHash"]}:await`,
      { ...initReq, method: "POST", body: JSON.stringify(req, fm.replacer) },
    );
  }
  static AwaitSale(
    req: AwaitSaleRequest,
    initReq?: fm.InitReq,
  ): Promise<Orderbook_apiV1Types.Order> {
    return fm.fetchReq<AwaitSaleRequest, Orderbook_apiV1Types.Order>(
      `/v1/orderbook/${req["chainId"]}/collections/${req["collection"]}/orders/${req["orderId"]}/sales:await`,
      { ...initReq, method: "POST", body: JSON.stringify(req, fm.replacer) },
    );
  }
  static AwaitSales(
    req: AwaitSalesRequest,
    initReq?: fm.InitReq,
  ): Promise<AwaitSalesResponse> {
    return fm.fetchReq<AwaitSalesRequest, AwaitSalesResponse>(
      `/v1/orderbook/cross/orders/sales:await`,
      { ...initReq, method: "POST", body: JSON.stringify(req, fm.replacer) },
    );
  }
  static GetCollection(
    req: GetCollectionRequest,
    initReq?: fm.InitReq,
  ): Promise<Orderbook_apiV1Types.Collection> {
    return fm.fetchReq<GetCollectionRequest, Orderbook_apiV1Types.Collection>(
      `/v1/orderbook/${req["chainId"]}/collections/${req["collection"]}?${fm.renderURLSearchParams(req, ["chainId", "collection"])}`,
      { ...initReq, method: "GET" },
    );
  }
  static QueryCollections(
    req: QueryCollectionsRequest,
    initReq?: fm.InitReq,
  ): Promise<QueryCollectionsResponse> {
    return fm.fetchReq<QueryCollectionsRequest, QueryCollectionsResponse>(
      `/v1/orderbook/${req["chainId"]}/collections:query`,
      { ...initReq, method: "POST", body: JSON.stringify(req, fm.replacer) },
    );
  }
  static QueryCollectionActivity(
    req: QueryCollectionActivityRequest,
    initReq?: fm.InitReq,
  ): Promise<QueryActivityResponse> {
    return fm.fetchReq<QueryCollectionActivityRequest, QueryActivityResponse>(
      `/v1/orderbook/${req["chainId"]}/collections/${req["collection"]}/activity:query`,
      { ...initReq, method: "POST", body: JSON.stringify(req, fm.replacer) },
    );
  }
  static GetCollectionToken(
    req: GetCollectionTokenRequest,
    initReq?: fm.InitReq,
  ): Promise<Orderbook_apiV1Types.Token> {
    return fm.fetchReq<GetCollectionTokenRequest, Orderbook_apiV1Types.Token>(
      `/v1/orderbook/${req["chainId"]}/collections/${req["collection"]}/tokens/${req["tokenId"]}?${fm.renderURLSearchParams(req, ["chainId", "collection", "tokenId"])}`,
      { ...initReq, method: "GET" },
    );
  }
  static GetErc1155Meta(
    req: GetErc1155MetaRequest,
    initReq?: fm.InitReq,
  ): Promise<Orderbook_apiV1Types.Erc1155Meta> {
    return fm.fetchReq<GetErc1155MetaRequest, Orderbook_apiV1Types.Erc1155Meta>(
      `/v1/orderbook/${req["chainId"]}/collections/${req["collection"]}/tokens/${req["tokenId"]}:erc1155?${fm.renderURLSearchParams(req, ["chainId", "collection", "tokenId"])}`,
      { ...initReq, method: "GET" },
    );
  }
  static GetTokensByRef(
    req: GetTokensByRefRequest,
    initReq?: fm.InitReq,
  ): Promise<GetTokensByRefResponse> {
    return fm.fetchReq<GetTokensByRefRequest, GetTokensByRefResponse>(
      `/v1/orderbook/tokens:ref?${fm.renderURLSearchParams(req, [])}`,
      { ...initReq, method: "GET" },
    );
  }
  static QueryCollectionTokens(
    req: QueryCollectionTokensRequest,
    initReq?: fm.InitReq,
  ): Promise<QueryTokensResponse> {
    return fm.fetchReq<QueryCollectionTokensRequest, QueryTokensResponse>(
      `/v1/orderbook/${req["chainId"]}/collections/${req["collection"]}/tokens:query`,
      { ...initReq, method: "POST", body: JSON.stringify(req, fm.replacer) },
    );
  }
  static QueryCollectionTransfers(
    req: QueryCollectionTransfersRequest,
    initReq?: fm.InitReq,
  ): Promise<QueryTransfersResponse> {
    return fm.fetchReq<QueryCollectionTransfersRequest, QueryTransfersResponse>(
      `/v1/orderbook/${req["chainId"]}/collections/${req["collection"]}/transfers:query`,
      { ...initReq, method: "POST", body: JSON.stringify(req, fm.replacer) },
    );
  }
  static GetBidMerkleRoot(
    req: GetBidMerkleRootRequest,
    initReq?: fm.InitReq,
  ): Promise<Orderbook_apiV1Types.MerkleRoot> {
    return fm.fetchReq<
      GetBidMerkleRootRequest,
      Orderbook_apiV1Types.MerkleRoot
    >(
      `/v1/orderbook/${req["chainId"]}/collections/${req["collection"]}/bids:merkle_root`,
      { ...initReq, method: "POST", body: JSON.stringify(req, fm.replacer) },
    );
  }
  static GetCollectionTokenAttributes(
    req: GetCollectionTokenAttributesRequest,
    initReq?: fm.InitReq,
  ): Promise<GetCollectionTokenAttributesResponse> {
    return fm.fetchReq<
      GetCollectionTokenAttributesRequest,
      GetCollectionTokenAttributesResponse
    >(
      `/v1/orderbook/${req["chainId"]}/collections/${req["collection"]}/tokens:attributes?${fm.renderURLSearchParams(req, ["chainId", "collection"])}`,
      { ...initReq, method: "GET" },
    );
  }
  static GetTokenAttributes(
    req: GetTokenAttributesRequest,
    initReq?: fm.InitReq,
  ): Promise<GetTokenAttributesResponse> {
    return fm.fetchReq<GetTokenAttributesRequest, GetTokenAttributesResponse>(
      `/v1/orderbook/${req["chainId"]}/collections/${req["collection"]}/tokens/${req["tokenId"]}:attributes?${fm.renderURLSearchParams(req, ["chainId", "collection", "tokenId"])}`,
      { ...initReq, method: "GET" },
    );
  }
  static QueryWalletTokens(
    req: QueryWalletTokensRequest,
    initReq?: fm.InitReq,
  ): Promise<QueryTokensResponse> {
    return fm.fetchReq<QueryWalletTokensRequest, QueryTokensResponse>(
      `/v1/wallets/${req["walletAddress"]}/tokens:query`,
      { ...initReq, method: "POST", body: JSON.stringify(req, fm.replacer) },
    );
  }
  static QueryWalletActivity(
    req: QueryWalletActivityRequest,
    initReq?: fm.InitReq,
  ): Promise<QueryActivityResponse> {
    return fm.fetchReq<QueryWalletActivityRequest, QueryActivityResponse>(
      `/v1/wallets/${req["walletAddress"]}/activity:query`,
      { ...initReq, method: "POST", body: JSON.stringify(req, fm.replacer) },
    );
  }
  static QueryWalletOrders(
    req: QueryWalletOrdersRequest,
    initReq?: fm.InitReq,
  ): Promise<QueryOrdersResponse> {
    return fm.fetchReq<QueryWalletOrdersRequest, QueryOrdersResponse>(
      `/v1/wallets/${req["walletAddress"]}/orders:query`,
      { ...initReq, method: "POST", body: JSON.stringify(req, fm.replacer) },
    );
  }
  static GetWalletNonce(
    req: GetWalletNonceRequest,
    initReq?: fm.InitReq,
  ): Promise<GetWalletNonceResponse> {
    return fm.fetchReq<GetWalletNonceRequest, GetWalletNonceResponse>(
      `/v1/wallets:nonce?${fm.renderURLSearchParams(req, [])}`,
      { ...initReq, method: "GET" },
    );
  }
  static GetWalletMasterNonce(
    req: GetWalletMasterNonceRequest,
    initReq?: fm.InitReq,
  ): Promise<GetWalletMasterNonceResponse> {
    return fm.fetchReq<
      GetWalletMasterNonceRequest,
      GetWalletMasterNonceResponse
    >(`/v1/wallets:master_nonce?${fm.renderURLSearchParams(req, [])}`, {
      ...initReq,
      method: "GET",
    });
  }
  static StreamActivity(
    req: StreamActivityRequest,
    entityNotifier?: fm.NotifyStreamEntityArrival<Orderbook_apiV1Types.Activity>,
    initReq?: fm.InitReq,
  ): Promise<void> {
    return fm.fetchStreamingRequest<
      StreamActivityRequest,
      Orderbook_apiV1Types.Activity
    >(`/v1/stream/activity`, entityNotifier, {
      ...initReq,
      method: "POST",
      body: JSON.stringify(req, fm.replacer),
    });
  }
  static QueryCrossChainCollections(
    req: QueryCrossChainCollectionsRequest,
    initReq?: fm.InitReq,
  ): Promise<QueryCollectionsResponse> {
    return fm.fetchReq<
      QueryCrossChainCollectionsRequest,
      QueryCollectionsResponse
    >(`/v1/orderbook/cross/collections:query`, {
      ...initReq,
      method: "POST",
      body: JSON.stringify(req, fm.replacer),
    });
  }
  static QueryCrossChainTransfers(
    req: QueryCrossChainTransfersRequest,
    initReq?: fm.InitReq,
  ): Promise<QueryTransfersResponse> {
    return fm.fetchReq<QueryCrossChainTransfersRequest, QueryTransfersResponse>(
      `/v1/orderbook/cross/transfers:query`,
      { ...initReq, method: "POST", body: JSON.stringify(req, fm.replacer) },
    );
  }
  static QueryCrossChainTokens(
    req: QueryCrossChainTokensRequest,
    initReq?: fm.InitReq,
  ): Promise<QueryTokensResponse> {
    return fm.fetchReq<QueryCrossChainTokensRequest, QueryTokensResponse>(
      `/v1/orderbook/cross/tokens:query`,
      { ...initReq, method: "POST", body: JSON.stringify(req, fm.replacer) },
    );
  }
  static QueryCrossChainOrders(
    req: QueryCrossChainOrdersRequest,
    initReq?: fm.InitReq,
  ): Promise<QueryOrdersResponse> {
    return fm.fetchReq<QueryCrossChainOrdersRequest, QueryOrdersResponse>(
      `/v1/orderbook/cross/orders:query`,
      { ...initReq, method: "POST", body: JSON.stringify(req, fm.replacer) },
    );
  }
  static QueryCrossChainActivity(
    req: QueryCrossChainActivityRequest,
    initReq?: fm.InitReq,
  ): Promise<QueryActivityResponse> {
    return fm.fetchReq<QueryCrossChainActivityRequest, QueryActivityResponse>(
      `/v1/orderbook/cross/activity:query`,
      { ...initReq, method: "POST", body: JSON.stringify(req, fm.replacer) },
    );
  }
  static RefreshToken(
    req: RefreshTokenRequest,
    initReq?: fm.InitReq,
  ): Promise<CommonV1Common.Empty> {
    return fm.fetchReq<RefreshTokenRequest, CommonV1Common.Empty>(
      `/v1/orderbook/${req["chainId"]}/collections/${req["collection"]}/tokens/${req["tokenId"]}:refresh`,
      { ...initReq, method: "POST", body: JSON.stringify(req, fm.replacer) },
    );
  }
  static BuyCrossChainListings(
    req: BuyCrossChainListingsRequest,
    initReq?: fm.InitReq,
  ): Promise<Orderbook_apiV1Types.Steps> {
    return fm.fetchReq<
      BuyCrossChainListingsRequest,
      Orderbook_apiV1Types.Steps
    >(`/v1/orderbook/cross/listings:bulk-buy`, {
      ...initReq,
      method: "POST",
      body: JSON.stringify(req, fm.replacer),
    });
  }
  static BuyListings(
    req: BuyListingsRequest,
    initReq?: fm.InitReq,
  ): Promise<Orderbook_apiV1Types.Steps> {
    return fm.fetchReq<BuyListingsRequest, Orderbook_apiV1Types.Steps>(
      `/v1/orderbook/${req["chainId"]}/collections/${req["collection"]}/listings:bulk-buy`,
      { ...initReq, method: "POST", body: JSON.stringify(req, fm.replacer) },
    );
  }
  static BuyListing(
    req: BuyListingRequest,
    initReq?: fm.InitReq,
  ): Promise<Orderbook_apiV1Types.Steps> {
    return fm.fetchReq<BuyListingRequest, Orderbook_apiV1Types.Steps>(
      `/v1/orderbook/${req["chainId"]}/collections/${req["collection"]}/listings:buy`,
      { ...initReq, method: "POST", body: JSON.stringify(req, fm.replacer) },
    );
  }
  static AcceptOffer(
    req: AcceptOfferRequest,
    initReq?: fm.InitReq,
  ): Promise<Orderbook_apiV1Types.Steps> {
    return fm.fetchReq<AcceptOfferRequest, Orderbook_apiV1Types.Steps>(
      `/v1/orderbook/${req["chainId"]}/collections/${req["collection"]}/offers:accept-single`,
      { ...initReq, method: "POST", body: JSON.stringify(req, fm.replacer) },
    );
  }
  static AcceptOffers(
    req: AcceptOffersRequest,
    initReq?: fm.InitReq,
  ): Promise<Orderbook_apiV1Types.Steps> {
    return fm.fetchReq<AcceptOffersRequest, Orderbook_apiV1Types.Steps>(
      `/v1/orderbook/${req["chainId"]}/collections/${req["collection"]}/offers:accept`,
      { ...initReq, method: "POST", body: JSON.stringify(req, fm.replacer) },
    );
  }
  static SweepCollection(
    req: SweepCollectionRequest,
    initReq?: fm.InitReq,
  ): Promise<Orderbook_apiV1Types.Steps> {
    return fm.fetchReq<SweepCollectionRequest, Orderbook_apiV1Types.Steps>(
      `/v1/orderbook/${req["chainId"]}/collections/${req["collection"]}:sweep`,
      { ...initReq, method: "POST", body: JSON.stringify(req, fm.replacer) },
    );
  }
  static ConvertCurrency(
    req: ConvertCurrencyRequest,
    initReq?: fm.InitReq,
  ): Promise<ConvertCurrencyResponse> {
    return fm.fetchReq<ConvertCurrencyRequest, ConvertCurrencyResponse>(
      `/v1/orderbook/currency/convert`,
      { ...initReq, method: "POST", body: JSON.stringify(req, fm.replacer) },
    );
  }
  static GetExchangeRate(
    req: GetExchangeRateRequest,
    initReq?: fm.InitReq,
  ): Promise<GetExchangeRateResponse> {
    return fm.fetchReq<GetExchangeRateRequest, GetExchangeRateResponse>(
      `/v1/orderbook/currency/exchange_rate?${fm.renderURLSearchParams(req, [])}`,
      { ...initReq, method: "GET" },
    );
  }
  static GetUsdExchangeRate(
    req: GetUsdExchangeRateRequest,
    initReq?: fm.InitReq,
  ): Promise<GetUsdExchangeRateResponse> {
    return fm.fetchReq<GetUsdExchangeRateRequest, GetUsdExchangeRateResponse>(
      `/v1/orderbook/currency/usd_exchange_rate?${fm.renderURLSearchParams(req, [])}`,
      { ...initReq, method: "GET" },
    );
  }
  static GetCurrencies(
    req: CommonV1Common.Empty,
    initReq?: fm.InitReq,
  ): Promise<GetCurrenciesResponse> {
    return fm.fetchReq<CommonV1Common.Empty, GetCurrenciesResponse>(
      `/v1/orderbook/currency?${fm.renderURLSearchParams(req, [])}`,
      { ...initReq, method: "GET" },
    );
  }
  static GetJob(
    req: GetJobRequest,
    initReq?: fm.InitReq,
  ): Promise<AsyncV1Async.Job> {
    return fm.fetchReq<GetJobRequest, AsyncV1Async.Job>(
      `/v1/orderbook/jobs/${req["jobId"]}?${fm.renderURLSearchParams(req, ["jobId"])}`,
      { ...initReq, method: "GET" },
    );
  }
  static GetMarketplaceConfig(
    req: GetMarketplaceConfigRequest,
    initReq?: fm.InitReq,
  ): Promise<Orderbook_apiV1Types.MarketplaceConfig> {
    return fm.fetchReq<
      GetMarketplaceConfigRequest,
      Orderbook_apiV1Types.MarketplaceConfig
    >(`/v1/orderbook/marketplace/config?${fm.renderURLSearchParams(req, [])}`, {
      ...initReq,
      method: "GET",
    });
  }
  static QueryDailyCollectionPrice(
    req: QueryDailyCollectionPriceRequest,
    initReq?: fm.InitReq,
  ): Promise<Orderbook_apiV1Types.Timeseries> {
    return fm.fetchReq<
      QueryDailyCollectionPriceRequest,
      Orderbook_apiV1Types.Timeseries
    >(
      `/v1/orderbook/${req["chainId"]}/metrics/collections/${req["collection"]}/price?${fm.renderURLSearchParams(req, ["chainId", "collection"])}`,
      { ...initReq, method: "GET" },
    );
  }
  static QueryDailyTokenPrice(
    req: QueryDailyTokenPriceRequest,
    initReq?: fm.InitReq,
  ): Promise<Orderbook_apiV1Types.Timeseries> {
    return fm.fetchReq<
      QueryDailyTokenPriceRequest,
      Orderbook_apiV1Types.Timeseries
    >(
      `/v2/orderbook/${req["chainId"]}/collections/${req["collection"]}/tokens/${req["tokenId"]}/price?${fm.renderURLSearchParams(req, ["chainId", "collection", "tokenId"])}`,
      { ...initReq, method: "GET" },
    );
  }
  static QueryDailyCollectionSalesVolume(
    req: QueryDailyCollectionSalesVolumeRequest,
    initReq?: fm.InitReq,
  ): Promise<Orderbook_apiV1Types.Timeseries> {
    return fm.fetchReq<
      QueryDailyCollectionSalesVolumeRequest,
      Orderbook_apiV1Types.Timeseries
    >(
      `/v1/orderbook/${req["chainId"]}/metrics/collections/${req["collection"]}/volume?${fm.renderURLSearchParams(req, ["chainId", "collection"])}`,
      { ...initReq, method: "GET" },
    );
  }
  static QueryDailyTokenSalesVolume(
    req: QueryDailyTokenSalesVolumeRequest,
    initReq?: fm.InitReq,
  ): Promise<Orderbook_apiV1Types.Timeseries> {
    return fm.fetchReq<
      QueryDailyTokenSalesVolumeRequest,
      Orderbook_apiV1Types.Timeseries
    >(
      `/v2/orderbook/${req["chainId"]}/collections/${req["collection"]}/tokens/${req["tokenId"]}/volume?${fm.renderURLSearchParams(req, ["chainId", "collection", "tokenId"])}`,
      { ...initReq, method: "GET" },
    );
  }
}
