import { Flex, Text } from '../primitives';
import React, { FC } from 'react';
import { Tx, TxSummary } from '@v2/components/TxSummary/TxSummary';

type Props = {
  title: string;
  subtitle?: React.ReactNode;
  txList?: Tx[];
};

const Progress: FC<Props> = ({ title, subtitle, txList }) => {
  return (
    <Flex
      direction='column'
      css={{
        alignItems: 'start',
        gap: '$4',
        p: '$modalContentPadding',
      }}
    >
      <Flex direction='column' css={{ gap: '$1' }}>
        <Text style='subtitle1'>{title}</Text>
        <Flex direction='column'>
          {subtitle && (
            <Text style='body2' color='subtle'>
              {subtitle}
            </Text>
          )}
          <TxSummary txList={txList} />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Progress;
