import { styled } from '@stitches.config';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import React, {
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  ReactNode,
  useState,
  useEffect,
} from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { ModalSize } from '../modal/Modal';
import useMediaQuery from '@hooks/useMediaQuery';

const Overlay = styled(DialogPrimitive.Overlay, {
  backgroundColor: '$overlayBackground',
  position: 'fixed',
  inset: 0,
  zIndex: 1000,
});

// eslint-disable-next-line react/display-name
const AnimatedOverlay = forwardRef<
  ElementRef<typeof Overlay>,
  ComponentPropsWithoutRef<typeof Overlay>
>(({ ...props }, forwardedRef) => (
  <Overlay {...props} forceMount asChild>
    <motion.div
      ref={forwardedRef}
      transition={{ duration: 0.5 }}
      initial={{
        opacity: 0,
      }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    />
  </Overlay>
));

const Content = styled(DialogPrimitive.Content, {
  backgroundColor: '$contentBackground',
  borderRadius: '25px',
  overflow: 'hidden',
  $$shadowColor: '$colors$gray7',
  boxShadow: 'box-shadow: 0px 2px 16px $$shadowColor',
  border: '1px solid $borderColor',
  position: 'fixed',
  left: '50%',
  maxWidth: 516,
  top: '100%',
  width: '100%',
  zIndex: 1000,
  '&:focus': { outline: 'none' },
  '@media(max-width: 520px)': {
    borderRadius: '$radiusNone',
    border: 0,
    height: '100dvh',
    top: 0,
    left: 0,
    width: '100%',
  },
});

// eslint-disable-next-line react/display-name
const AnimatedContent = forwardRef<
  ElementRef<typeof DialogPrimitive.DialogContent>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.DialogContent>
>(({ children, ...props }, forwardedRef) => {
  const isMobile = useMediaQuery('(max-width: 520px)');

  const animation = isMobile
    ? {
        initial: {
          opacity: 0,
          bottom: '-100%',
          top: 'auto',
          left: 0,
        },
        animate: {
          opacity: 1,
          bottom: 0,
          top: 'auto',
          left: 0,
        },

        exit: {
          opacity: 0,
          bottom: '-100%',
          top: 'auto',
          left: 0,
        },
      }
    : {
        initial: {
          opacity: 0,
          top: '14%',
          transform: 'translateX(-50%)',
        },
        animate: {
          opacity: 1,
          top: '9%',
          transform: 'translateX(-50%)',
        },

        exit: {
          opacity: 0,
          top: '14%',
          transform: 'translateX(-50%)',
        },
      };

  return (
    <Content forceMount asChild {...props}>
      <motion.div
        key={isMobile + 'modal'}
        ref={forwardedRef}
        transition={{ type: isMobile ? 'tween' : 'spring', duration: 0.2 }}
        {...animation}
      >
        {children}
      </motion.div>
    </Content>
  );
});

const StyledAnimatedContent = styled(AnimatedContent, {
  display: 'flex',
  flexDirection: 'column',
});

type Props = {
  trigger: ReactNode;
  portalProps?: DialogPrimitive.PortalProps;
  onOpenChange?: (open: boolean) => void;
  open?: boolean;
  size?: ModalSize;
};

// eslint-disable-next-line react/display-name
const Dialog = forwardRef<
  ElementRef<typeof DialogPrimitive.Content>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & Props
>(
  (
    { children, trigger, portalProps, onOpenChange, open, size, ...props },
    forwardedRef,
  ) => {
    const [dialogOpen, setDialogOpen] = useState(false);

    useEffect(() => {
      if (open !== undefined && open !== dialogOpen) {
        setDialogOpen(open);
        if (onOpenChange) {
          onOpenChange(open);
        }
      }
    }, [dialogOpen, onOpenChange, open]);

    return (
      <DialogPrimitive.Root
        onOpenChange={(open) => {
          setDialogOpen(open);
          if (onOpenChange) {
            onOpenChange(open);
          }
        }}
        open={dialogOpen}
      >
        <DialogPrimitive.DialogTrigger asChild>
          {trigger}
        </DialogPrimitive.DialogTrigger>
        <AnimatePresence>
          {dialogOpen && (
            <DialogPrimitive.DialogPortal forceMount {...portalProps}>
              <AnimatedOverlay data-radix-dismissable />
              <StyledAnimatedContent
                ref={forwardedRef}
                {...props}
                forceMount
                css={{
                  maxWidth: size === ModalSize.MD ? 516 : 750,
                }}
              >
                {children}
              </StyledAnimatedContent>
            </DialogPrimitive.DialogPortal>
          )}
        </AnimatePresence>
      </DialogPrimitive.Root>
    );
  },
);

export {
  Dialog,
  Content,
  AnimatedContent,
  Overlay,
  AnimatedOverlay,
  StyledAnimatedContent,
};
