import { formatBN } from '../../utils/numbers';
import React, { FC } from 'react';
import { Flex, Text } from './index';

type Props = {
  amount: string | number | bigint | null | undefined;
  maximumFractionDigits?: number;
  decimals?: number;
  css?: Parameters<typeof Text>['0']['css'];
  textStyle?: Parameters<typeof Text>['0']['style'];
  children?: React.ReactNode;
  wrap?: boolean;
};

const FormatCrypto: FC<Props> = ({
  amount,
  maximumFractionDigits = 4,
  decimals = 18,
  css,
  textStyle = 'subtitle3',
  children,
  wrap = true,
}) => {
  const value = formatBN(amount, maximumFractionDigits, decimals);
  return (
    <Flex
      align='center'
      css={{
        gap: '$1',
        alignItems: 'start',
        flexWrap: wrap ? 'wrap' : 'nowrap',
      }}
    >
      <Text
        style={textStyle}
        css={{ ...css, lineHeight: 1, textWrap: 'nowrap' }}
      >
        {value}
      </Text>
      {value !== '-' ? children : null}
    </Flex>
  );
};

export default FormatCrypto;
