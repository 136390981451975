import { useCart } from '../../hooks';
import React, { FC, ReactNode, useEffect, useMemo } from 'react';
import {
  Cart,
  CheckoutStatus,
  CheckoutTransactionError,
} from '../../context/CartProvider';
import { useCoinConversion, useMarketplaceChain } from '@hooks';
import { useWatchQueryKey } from '@hooks/useWatchQueryKey';
import { toFixed } from '@reservoir-kit-ui-overrides/lib/numbers';
import { zeroAddress, erc20Abi, parseUnits, Address } from 'viem';
import { useAccount, useBalance, useReadContracts } from 'wagmi';

type ChildrenProps = {
  loading: boolean;
  currency?: NonNullable<Cart['items'][0]['price']>['currency'];
  cartCurrencyConverted?: Boolean;
  totalPrice: number;
  feeOnTop?: number;
  usdPrice: number | null;
  balance?: bigint;
  hasEnoughCurrency: boolean;
  items: Cart['items'];
  unavailableItems: Cart['items'];
  transaction?: Cart['transaction'];
  cartChain: Cart['chain'];
  checkout: ReturnType<typeof useCart>['checkout'];
  clear: ReturnType<typeof useCart>['clear'];
  remove: ReturnType<typeof useCart>['remove'];
  add: ReturnType<typeof useCart>['add'];
  validate: ReturnType<typeof useCart>['validate'];
  openCheckout: ReturnType<typeof useCart>['openCheckout'];
};

type Props = {
  children: (props: ChildrenProps) => ReactNode;
};

export const CartPopoverRenderer: FC<Props> = ({ children }) => {
  const {
    data,
    clear,
    clearTransaction,
    validate,
    remove,
    add,
    checkout,
    openCheckout,
  } = useCart((cart) => cart);

  const {
    isValidating,
    totalPrice,
    items,
    currency,
    transaction,
    feeOnTop,
    chain: cartChain,
  } = data;

  const usdConversion = useCoinConversion(
    'USD',
    currency?.symbol || currency?.name,
  );

  const usdPrice = usdConversion.length > 0 ? usdConversion[0].price : null;
  const cartCurrencyConverted = items.some(
    (item) => item.price && item.price?.currency?.address !== currency?.address,
  );

  useEffect(() => {
    if (transaction?.status === CheckoutStatus.Complete || transaction?.error) {
      clearTransaction();
    } else {
      validate();
    }
  }, [transaction]);

  const unavailableItems = useMemo(
    () => items.filter((item) => !item.price),
    [items],
  );

  const chain = useMarketplaceChain();
  const { address } = useAccount();

  const shouldFetchBalance = !!address && !!currency?.address;

  const { data: dataBalance, queryKey: queryKeyBalance } = useBalance({
    chainId: cartChain?.id || chain?.id,
    address,
    query: {
      enabled: shouldFetchBalance && currency?.address === zeroAddress,
    },
  });

  const { data: dataWrappedBalance, queryKey: queryKeyWrappedBalance } =
    useReadContracts({
      allowFailure: false,
      contracts: [
        {
          address: currency?.address as Address,
          abi: erc20Abi,
          functionName: 'balanceOf',
          chainId: cartChain?.id || chain.id,
          args: [address as Address],
        },
      ],
      query: {
        enabled: shouldFetchBalance && currency?.address !== zeroAddress,
      },
    });

  useWatchQueryKey({
    queryKey: queryKeyBalance,
    isEnabled: shouldFetchBalance,
  });
  useWatchQueryKey({
    queryKey: queryKeyWrappedBalance,
    isEnabled: shouldFetchBalance,
  });

  const nativeBalance = dataBalance?.value || 0n;
  const wrappedBalance = dataWrappedBalance?.[0] || 0n;
  const balance = nativeBalance || wrappedBalance;

  const decimals = currency?.decimals || 18;
  const hasEnoughCurrency =
    data && balance && totalPrice
      ? balance >=
        parseUnits(`${toFixed(totalPrice, decimals) as number}`, decimals)
      : false;

  return (
    <>
      {children({
        loading: isValidating,
        items,
        unavailableItems,
        currency,
        cartCurrencyConverted,
        totalPrice,
        feeOnTop,
        usdPrice,
        hasEnoughCurrency:
          transaction?.errorType ===
          CheckoutTransactionError.InsufficientBalance
            ? false
            : hasEnoughCurrency,
        transaction,
        cartChain,
        checkout,
        clear,
        remove,
        add,
        validate,
        openCheckout,
        balance,
      })}
    </>
  );
};

export default CartPopoverRenderer;
