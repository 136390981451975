import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime.js';

dayjs.extend(relativeTime);

export const getTimeSince = (timestamp?: number) => {
  if (timestamp) {
    return dayjs.unix(timestamp).fromNow();
  } else {
    return '';
  }
};
