/* eslint-disable react-hooks/rules-of-hooks */
import React, {
  ComponentPropsWithoutRef,
  ReactElement,
  useEffect,
} from 'react';
import {
  Flex,
  Text,
  Button,
  FormatCurrency,
  FormatCryptoCurrency,
  Box,
  ErrorWell,
} from '../../primitives';

import { Modal } from '../Modal';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AcceptBidStep,
  AcceptBidModalRenderer,
  AcceptBidStepData,
  AcceptBidTokenData,
  EnhancedAcceptBidTokenData,
} from './AcceptBidModalRenderer';
import AcceptBidLineItem from './AcceptBidLineItem';
import { Collapsible } from '../../primitives/Collapsible';
import { ApproveBidCollapsible } from './ApproveBidCollapsible';
import SigninStep from '../SigninStep';
import AcceptBidSummaryLineItem from './AcceptBidSummaryLineItem';
import { truncateAddress } from '../../lib/truncate';
import getChainBlockExplorerUrl from '../../lib/getChainBlockExplorerUrl';
import { Dialog } from '../../primitives/Dialog';
import { ViewTx } from '@v2/components/ViewTx/ViewTx';
import { TokenOpSuccessPreview } from '@v2/components/TokenOpSuccessPreview/TokenOpSuccessPreview';
import { useMarketplaceChain } from '@hooks';
import { useSignals } from '@preact/signals-react/runtime';
import { getCollection, sSelectedCollection } from '@signals/collection';
import { Order } from '@api/orderbook_api/v1/types.pb';
import { int } from '@api/utils';
import { useOpenState } from '@hooks/useOpenState';
import { setModalState } from '@signals/modalState';
import { trackEvent } from '@utils/analytics/events';
import { TxSummary } from '@v2/components/TxSummary/TxSummary';

type BidData = {
  tokens?: EnhancedAcceptBidTokenData[];
  bids?: NonNullable<Order>[];
  txHash?: string;
  maker?: string;
};

const ModalCopy = {
  title: 'Accept Offer',
  ctaAccept: 'Accept',
  ctaAwaitingApproval: 'Waiting for Approval',
  ctaClose: 'Close',
  ctaDone: 'Done',
};

type Props = Pick<Parameters<typeof Modal>['0'], 'trigger'> & {
  tokens: AcceptBidTokenData[];
  copyOverrides?: Partial<typeof ModalCopy>;
  onBidAccepted?: (data: BidData) => void;
  onClose?: (
    data: BidData,
    stepData: AcceptBidStepData | null,
    currentStep: AcceptBidStep,
  ) => void;
  onBidAcceptError?: (error: Error, data: BidData) => void;
  onCurrentStepUpdate?: (data: AcceptBidStepData) => void;
  onPointerDownOutside?: ComponentPropsWithoutRef<
    typeof Dialog
  >['onPointerDownOutside'];
};

export function AcceptBidModal({
  trigger,
  tokens,
  copyOverrides,
  onBidAccepted,
  onClose,
  onBidAcceptError,
  onCurrentStepUpdate,
  onPointerDownOutside,
}: Props): ReactElement {
  const { isOpen, open, setIsOpen, createCloseHandler } = useOpenState({
    onChange: setModalState,
  });
  useSignals();
  const copy: typeof ModalCopy = { ...ModalCopy, ...copyOverrides };
  const collection = sSelectedCollection.value;
  const modalChain = useMarketplaceChain();

  useEffect(() => {
    if (isOpen) {
      trackEvent('accept_offer_modal_opened');
    }
  }, [isOpen]);

  if (!isOpen) {
    return <div onClick={open}>{trigger}</div>;
  }

  return (
    <AcceptBidModalRenderer tokens={tokens}>
      {({
        loading,
        acceptBidStep,
        transactionError,
        txHash,
        usdPrices,
        prices,
        tokensData,
        address,
        stepData,
        acceptBid,
      }) => {
        useEffect(() => {
          if (acceptBidStep === AcceptBidStep.Complete && onBidAccepted) {
            const data: BidData = {
              tokens: tokensData,
              maker: address,
            };
            if (txHash) {
              data.txHash = txHash;
            }
            onBidAccepted(data);
          }
        }, [acceptBidStep, address, tokensData, txHash]);

        useEffect(() => {
          if (transactionError && onBidAcceptError) {
            const data: BidData = {
              tokens: tokensData,
              maker: address,
            };
            onBidAcceptError(transactionError, data);
          }
        }, [address, tokensData, transactionError]);

        useEffect(() => {
          if (stepData && onCurrentStepUpdate) {
            onCurrentStepUpdate(stepData);
          }
        }, [stepData]);

        const close = createCloseHandler(() => {
          trackEvent('accept_offer_modal_closed');
          onClose?.(
            {
              tokens: tokensData,
              maker: address,
            },
            stepData,
            acceptBidStep,
          );
        });

        const transfersTxHashes =
          stepData?.output?.reduce((txHashes, item) => {
            item.txHashes?.forEach((txHash) => {
              if (txHash.txHash) {
                txHashes.add(txHash.txHash);
              }
            });
            return txHashes;
          }, new Set<string>()) || [];
        const totalSales = Array.from(transfersTxHashes).length;
        const failedSales =
          totalSales - (stepData?.currentStep?.items?.length || 0);
        return (
          <Modal
            trigger={trigger}
            title={copy.title}
            open={true}
            onOpenChange={setIsOpen}
            loading={loading}
            onPointerDownOutside={(e) => {
              if (onPointerDownOutside) {
                onPointerDownOutside(e);
              }
            }}
          >
            {acceptBidStep === AcceptBidStep.Unavailable && !loading && (
              <Flex direction='column' css={{ height: '100%' }}>
                <Box css={{ p: '$modalContentPadding' }}>
                  <Text
                    style='subtitle1'
                    css={{ mb: '$3', textAlign: 'center' }}
                  >
                    {tokens.length > 1 ? 'Offers are ' : 'Offer is '} no longer
                    available
                  </Text>
                </Box>
                <Box css={{ flexGrow: 1 }} />
                <Box
                  css={{
                    p: '$modalContentPadding',
                    borderTop: '1px solid $borderColor',
                  }}
                >
                  <Button
                    color='onlyBorder'
                    onClick={close}
                    css={{
                      width: '100%',
                    }}
                  >
                    {copy.ctaClose}
                  </Button>
                </Box>
              </Flex>
            )}

            {acceptBidStep === AcceptBidStep.Checkout && !loading && (
              <Flex direction='column' css={{ height: '100%' }}>
                <Box
                  css={{
                    p: '$modalContentPadding',
                    borderBottom: '1px solid $borderColor',
                  }}
                >
                  {transactionError && <ErrorWell error={transactionError} />}
                  {tokensData.map(({ tokenData, bidOrders }, i) => {
                    const tokenCollection = getCollection(
                      tokenData?.collection?.contractAddress,
                    );
                    const props = {
                      key: i,
                      chainId: modalChain?.id,
                      token: {
                        name: tokenData?.name || '',
                        id: tokenData?.tokenId || '',
                      },
                      collection: {
                        id: tokenData?.collection?.contractAddress || '',
                        name: tokenData?.collection?.name || '',
                      },
                      img:
                        tokenData?.imageUrl ||
                        tokenCollection?.config?.imageUrl ||
                        '',
                    };

                    if (!bidOrders || !bidOrders.length) {
                      return <AcceptBidLineItem {...props} key={props.key} />;
                    } else {
                      return bidOrders.map((bidOrder) => {
                        const fees = bidOrder.additionalFees?.map((fee) => ({
                          amount: int(fee.amount),
                          recipient: fee.recipient,
                        }));
                        return (
                          <AcceptBidLineItem
                            {...props}
                            key={bidOrder.id}
                            netAmount={bidOrder.price?.netAmount?.decimal}
                            price={bidOrder.price?.amount?.decimal}
                            fees={fees}
                            currency={bidOrder.price?.currency?.address}
                            decimals={bidOrder.price?.currency?.decimals}
                            sourceImg={bidOrder.source?.logoUrl || ''}
                          />
                        );
                      });
                    }
                  })}
                </Box>
                <Box css={{ flexGrow: 1 }} />
                <Box
                  css={{
                    p: '$modalContentPadding',
                    borderTop: '1px solid $borderColor',
                  }}
                >
                  {prices.map((price, i) => (
                    <Collapsible
                      key={i}
                      trigger={
                        <Flex justify='between'>
                          <Text style='h6'>
                            You Get{' '}
                            <Text
                              css={{ color: '$neutralSolidHover', ml: '$2' }}
                            >
                              <FontAwesomeIcon
                                icon={faChevronDown}
                                width={16}
                                height={16}
                              />
                            </Text>
                          </Text>
                          <Flex direction='column' css={{ gap: '$1' }}>
                            <FormatCryptoCurrency
                              chainId={modalChain?.id}
                              amount={price.netAmount}
                              decimals={price.currency?.decimals}
                              address={price.currency?.address}
                              symbol={price.currency?.symbol}
                              textStyle='subtitle1'
                            />
                            {price.currency?.symbol &&
                            usdPrices[price.currency.symbol] ? (
                              <FormatCurrency
                                color='subtle'
                                style='body1'
                                amount={
                                  usdPrices[price.currency.symbol].price *
                                  price.netAmount
                                }
                                css={{ textAlign: 'end' }}
                              />
                            ) : null}
                          </Flex>
                        </Flex>
                      }
                    >
                      <Flex
                        css={{
                          gap: '$2',
                          paddingTop: '$2',
                        }}
                        direction='column'
                      >
                        <Flex justify='between'>
                          <Text style='subtitle3' color='subtle'>
                            Total {price.currency?.symbol} Offer Value
                          </Text>
                          <FormatCryptoCurrency
                            chainId={modalChain?.id}
                            amount={price.amount}
                            decimals={price.currency?.decimals}
                            address={price.currency?.address}
                            symbol={price.currency?.symbol}
                            textStyle='subtitle3'
                          />
                        </Flex>
                        {price.royalty > 0 ? (
                          <Flex justify='between'>
                            <Text style='subtitle3' color='subtle'>
                              Creator Royalties
                            </Text>
                            <Text
                              css={{ ml: 'auto' }}
                              style='subtitle3'
                              color='subtle'
                            >
                              -
                            </Text>
                            <FormatCryptoCurrency
                              chainId={modalChain?.id}
                              amount={price.royalty}
                              decimals={price.currency?.decimals}
                              address={price.currency?.address}
                              symbol={price.currency?.symbol}
                              textStyle='subtitle3'
                            />
                          </Flex>
                        ) : null}
                        {price.marketplaceFee > 0 ? (
                          <Flex justify='between'>
                            <Text style='subtitle3' color='subtle'>
                              Marketplace Fee
                            </Text>
                            <Text
                              css={{ ml: 'auto' }}
                              style='subtitle3'
                              color='subtle'
                            >
                              -
                            </Text>
                            <FormatCryptoCurrency
                              chainId={modalChain?.id}
                              amount={price.marketplaceFee}
                              decimals={price.currency?.decimals}
                              address={price.currency?.address}
                              symbol={price.currency?.symbol}
                              textStyle='subtitle3'
                            />
                          </Flex>
                        ) : null}
                        {price.feesOnTop > 0 ? (
                          <Flex justify='between'>
                            <Text style='subtitle3' color='subtle'>
                              Referral Fee
                            </Text>
                            <Text
                              css={{ ml: 'auto' }}
                              style='subtitle3'
                              color='subtle'
                            >
                              -
                            </Text>
                            <FormatCryptoCurrency
                              chainId={modalChain?.id}
                              amount={price.feesOnTop}
                              decimals={price.currency?.decimals}
                              address={price.currency?.address}
                              symbol={price.currency?.symbol}
                              textStyle='subtitle3'
                            />
                          </Flex>
                        ) : null}
                      </Flex>
                    </Collapsible>
                  ))}
                </Box>
                <Box
                  css={{
                    p: '$modalContentPadding',
                    pt: 0,
                  }}
                >
                  <Button
                    css={{
                      width: '100%',
                    }}
                    color='primary'
                    onClick={() => acceptBid()}
                  >
                    {copy.ctaAccept}
                  </Button>
                </Box>
              </Flex>
            )}
            {acceptBidStep === AcceptBidStep.Auth && !loading && (
              <Flex direction='column' css={{ height: '100%' }}>
                <Box
                  css={{
                    p: '$modalContentPadding',
                    borderBottom: '1px solid $borderColor',
                  }}
                >
                  <AcceptBidSummaryLineItem
                    tokensData={tokensData}
                    usdPrices={usdPrices}
                    prices={prices}
                    chain={modalChain}
                  />
                </Box>
                <Box
                  css={{
                    p: '$modalContentPadding',
                  }}
                >
                  <SigninStep css={{ gap: '$1' }} />
                </Box>
                <Box css={{ flexGrow: 1 }} />
                <Box
                  css={{
                    p: '$modalContentPadding',
                    borderTop: '1px solid $borderColor',
                  }}
                >
                  <Button disabled={true} css={{ width: '100%' }}>
                    {copy.ctaAwaitingApproval}
                  </Button>
                </Box>
              </Flex>
            )}
            {acceptBidStep === AcceptBidStep.ApproveMarketplace && !loading && (
              <Flex direction='column' css={{ height: '100%' }}>
                <Box
                  css={{
                    p: '$modalContentPadding',
                    borderBottom: '1px solid $borderColor',
                  }}
                >
                  <AcceptBidSummaryLineItem
                    tokensData={tokensData}
                    usdPrices={usdPrices}
                    prices={prices}
                    chain={modalChain}
                  />
                </Box>
                <Flex
                  direction='column'
                  css={{
                    width: '100%',
                    p: '$modalContentPadding',
                    gap: '$3',
                  }}
                >
                  {stepData?.currentItem.data?.acceptOffers?.items?.map(
                    (item) => (
                      <ApproveBidCollapsible
                        key={item.id}
                        orders={[item]}
                        tokensData={tokensData}
                        isCurrentStep={true}
                        open={true}
                      />
                    ),
                  )}
                </Flex>

                <Box css={{ flexGrow: 1 }} />
                <Box
                  css={{
                    p: '$modalContentPadding',
                    borderTop: '1px solid $borderColor',
                  }}
                >
                  <Button disabled={true} css={{ width: '100%' }}>
                    {copy.ctaAwaitingApproval}
                  </Button>
                </Box>
              </Flex>
            )}

            {acceptBidStep === AcceptBidStep.Finalizing && !loading && (
              <Flex direction='column' css={{ height: '100%' }}>
                <Box
                  css={{
                    p: '$modalContentPadding',
                    borderBottom: '1px solid $borderColor',
                  }}
                >
                  <AcceptBidSummaryLineItem
                    tokensData={tokensData}
                    usdPrices={usdPrices}
                    prices={prices}
                    chain={modalChain}
                  />
                </Box>
                <Flex
                  direction='column'
                  css={{
                    p: '$modalContentPadding',
                    borderBottom: '1px solid $borderColor',
                    gap: '$2',
                  }}
                >
                  <Text style='subtitle1'>Finalizing on blockchain</Text>
                  <Text style='body2' color='subtle'>
                    You can close this modal while it finalizes on the
                    blockchain. The transaction will continue in the background.
                  </Text>
                  <Flex direction='column' css={{ gap: '$1', width: '100%' }}>
                    {stepData?.output?.map((item, itemIndex) => {
                      return (
                        <TxSummary key={itemIndex} txList={item?.txHashes} />
                      );
                    })}
                  </Flex>
                </Flex>
                <Box css={{ flexGrow: 1 }} />
                <Box
                  css={{
                    p: '$modalContentPadding',
                    borderTop: '1px solid $borderColor',
                  }}
                >
                  <Button
                    color='onlyBorder'
                    onClick={close}
                    css={{
                      width: '100%',
                    }}
                  >
                    {copy.ctaClose}
                  </Button>
                </Box>
              </Flex>
            )}

            {acceptBidStep === AcceptBidStep.Complete && !loading && (
              <Flex direction='column' css={{ height: '100%' }}>
                <Flex direction='column'>
                  <Box
                    css={{
                      p: '$modalContentPadding',
                    }}
                  >
                    <TokenOpSuccessPreview
                      token={tokensData?.[0]?.tokenData}
                      collection={collection}
                    />
                  </Box>
                </Flex>

                {stepData?.currentStep && (
                  <Flex
                    direction='column'
                    css={{
                      p: '$modalContentPadding',
                      flexDirection: 'column',
                      borderTop: '1px solid $borderColor',
                      gap: '$2',
                    }}
                  >
                    <Flex direction='column' css={{ gap: '$1' }}>
                      {stepData?.output?.map((item, itemIndex) => {
                        return (
                          <TxSummary key={itemIndex} txList={item?.txHashes} />
                        );
                      })}
                    </Flex>
                  </Flex>
                )}
                <Box css={{ flexGrow: 1 }} />
                <Box
                  css={{
                    p: '$modalContentPadding',
                    borderTop: '1px solid $borderColor',
                  }}
                >
                  <Button
                    color='onlyBorder'
                    onClick={close}
                    css={{
                      width: '100%',
                    }}
                  >
                    {copy.ctaClose}
                  </Button>
                </Box>
              </Flex>
            )}
          </Modal>
        );
      }}
    </AcceptBidModalRenderer>
  );
}

AcceptBidModal.Custom = AcceptBidModalRenderer;
