import { computed, effect, signal } from '@preact/signals-react';
import { OrderbookService } from '@api/orderbook_api/v1/orderbook.pb';
import { sMarketplace } from '@signals/marketplace';
import { Collection, CollectionConfig } from '@api/orderbook_api/v1/types.pb';
import { getRequestOpts } from '@utils/wallet';
import { logError } from '@utils/logger';
import { uniq } from 'lodash';

const __selectedCollectionContractAddress = signal<string | undefined>(
  undefined,
);

/**
 * The currently selected collection
 */
export const sSelectedCollection = computed(() => {
  if (
    sCollections.value === undefined ||
    sCollections.value?.length === 0 ||
    sMarketplace.value === undefined
  ) {
    return undefined;
  }

  const collection =
    sCollections.value?.find((c) =>
      collectionIdsAreEqual(
        c.contractAddress,
        __selectedCollectionContractAddress.value,
      ),
    ) ?? sCollections.value?.at(0);
  return collection as Collection;
});

const __collections = signal<undefined | null | Collection[]>([]);

export const sCollections = computed(() => {
  return __collections.value;
});

export const sCollectionAddresses = computed(() => {
  const addresses =
    __collections.value?.map(
      (collection) => collection.contractAddress ?? '',
    ) ?? [];
  return uniq(addresses);
});

export const updateSelectedCollection = (contractId?: string) => {
  if (!contractId || global.window === undefined) {
    return;
  }

  __selectedCollectionContractAddress.value = contractId;
};

const collectionIdsAreEqual = (a?: string, b?: string) => {
  return a?.toLowerCase() === b?.toLowerCase();
};

const fetchCollections = async (collections?: CollectionConfig[]) => {
  let promises: Promise<Collection>[] | undefined = [];
  promises = collections?.map((collection) => {
    return OrderbookService.GetCollection(
      {
        chainId: collection.chainId,
        collection: collection.collection,
      },
      getRequestOpts(),
    );
  });
  Promise.allSettled(promises ?? []).then((results) => {
    __collections.value = results
      .filter((result) => {
        if (result.status === 'rejected') {
          logError('failed to load collection', result.reason);
        }
        return result.status === 'fulfilled';
      })
      .map((result) => {
        if (result.status === 'fulfilled') {
          return result.value;
        }
      }) as Collection[];
  });
};

effect(async () => {
  fetchCollections(sMarketplace.value?.collections);
});

export const getCollection = (
  collectionAddress?: string,
  chainId?: number,
): Collection | undefined => {
  const contractAddress =
    collectionAddress ?? __selectedCollectionContractAddress.value;
  const chain =
    chainId && chainId > 0 ? chainId : getChainForCollection(contractAddress);

  return __collections.value?.find(
    (collection) =>
      collection.chainId === String(chain) &&
      collection.contractAddress === contractAddress,
  );
};

export const getChainForCollection = (address?: string) => {
  return Number(
    sMarketplace.value?.collections?.find(
      (collection) =>
        collection.collection?.toUpperCase() === address?.toUpperCase(),
    )?.chainId,
  );
};
