import { styled } from '@stitches.config';
import React, {
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  ReactNode,
  useState,
} from 'react';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';

const DropdownMenuContent = styled(DropdownMenuPrimitive.DropdownMenuContent, {
  p: '$1',
  borderRadius: '$radiusMedium',
  zIndex: 5,
  boxShadow: '0px 12px 52px 0px #00000026',
  backgroundColor: '$dropdownBg',
});

const DropdownMenuItem = styled(DropdownMenuPrimitive.DropdownMenuItem, {
  fontSize: 16,
  fontFamily: '$body',
  color: '$gray12',
  px: '$3',
  py: '$3',
  borderRadius: '$radiusMedium',
  outline: 'none',
  cursor: 'pointer',
  $$focusColor: '$colors$focusColor',
  '&:hover': {
    backgroundColor: '$neutralBgActive',
  },
  '&[data-highlighted]': {
    boxShadow: '0 0 0 2px $$focusColor',
  },
});

type Props = {
  trigger: ReactNode;
  contentProps?: DropdownMenuPrimitive.DropdownMenuContentProps;
};

// eslint-disable-next-line react/display-name
const Dropdown = forwardRef<
  ElementRef<typeof DropdownMenuPrimitive.Root>,
  ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Root> & Props
>(({ children, trigger, contentProps, ...props }, forwardedRef) => {
  const [open, setOpen] = useState(false);

  return (
    <DropdownMenuPrimitive.Root {...props} open={open} onOpenChange={setOpen}>
      <DropdownMenuPrimitive.Trigger asChild>
        {trigger}
      </DropdownMenuPrimitive.Trigger>
      {open && (
        <DropdownMenuContent ref={forwardedRef} {...contentProps}>
          {children}
        </DropdownMenuContent>
      )}
    </DropdownMenuPrimitive.Root>
  );
});

export { Dropdown, DropdownMenuContent, DropdownMenuItem };
