import {
  OrderbookService,
  QueryCrossChainOrdersRequest,
  QueryOrdersRequest,
  QueryOrdersResponse,
} from '@api/orderbook_api/v1/orderbook.pb';
import { getRequestOpts } from '@utils/wallet';
import { SWRInfiniteConfiguration } from 'swr/infinite';
import useInfiniteApi from './useInfiniteApi';
import { useMemo } from 'react';

export default function useOrderQuery(
  request: QueryCrossChainOrdersRequest,
  config?: Omit<SWRInfiniteConfiguration, 'fetcher'>,
) {
  const pageSize = request.pageSize ? request.pageSize : 25;
  const getKey = (pageIndex: number, previousPageData: QueryOrdersResponse) => {
    if (
      previousPageData &&
      previousPageData.total &&
      previousPageData.total < pageSize
    ) {
      return null;
    }
    return [request, pageIndex];
  };
  const fetcher = async (key: any[]) => {
    const response = await OrderbookService.QueryCrossChainOrders(
      {
        ...key[0],
        pageSize,
        page: key[1],
      },
      getRequestOpts(),
    );
    return response;
  };
  const response = useInfiniteApi<QueryOrdersResponse>(
    getKey,
    { fetcher, ...config },
    pageSize,
  );

  const flatResponse = useMemo(() => {
    return response.data?.flatMap((page) => page?.orders || []) ?? [];
  }, [response.data]);

  return {
    ...response,
    data: flatResponse,
  };
}
