import { Inter } from '@next/font/google';
import {
  crimsonDark,
  violetDark,
  slateDark,
  greenDark,
  irisDark,
  violetDarkA,
  whiteA,
  redDark,
  blackA,
  blue,
} from '@radix-ui/colors';
import { createTheme } from '@stitches.config';

const inter = Inter({
  subsets: ['latin'],
});

export const dark = createTheme({
  fonts: {
    body: inter.style.fontFamily,
  },
  radii: {
    radiusNone: '0px',
    radiusTiny: '4px',
    radiusSmall: '8px',
    radiusMedium: '12px',
    radiusLarge: '16px',
  },
  colors: {
    ...crimsonDark,
    ...violetDark,
    ...violetDarkA,
    ...slateDark,
    ...greenDark,
    ...irisDark,
    ...whiteA,
    ...redDark,
    ...blackA,
    ...blue,

    //Aliases

    //Primary
    primary1: '$iris1',
    primary2: '$iris2',
    primary3: '$iris3',
    primary4: '$iris4',
    primary5: '$iris5',
    primary6: '$iris6',
    primary7: '$iris7',
    primary8: '$iris8',
    primary9: '$iris9',
    primary10: '$iris10',
    primary11: '$iris11',
    primary12: '$iris12',

    //Secondary
    secondary1: '$violetA1',
    secondary2: '$violetA2',
    secondary3: '$violetA3',
    secondary4: '$violetA4',
    secondary5: '$violetA5',
    secondary6: '$violetA6',
    secondary7: '$violetA7',
    secondary8: '$violetA8',
    secondary9: '$violetA9',
    secondary10: '$violetA10',
    secondary11: '$violetA11',
    secondary12: '$violetA12',

    //Gray
    gray1: '$slate1',
    gray2: '$slate2',
    gray3: '$slate3',
    gray4: '$slate4',
    gray5: '$slate5',
    gray6: '$slate6',
    gray7: '$slate7',
    gray8: '$slate8',
    gray9: '$slate9',
    gray10: '$slate10',
    gray11: '$slate11',
    gray12: '$slate12',

    neutralBgSubtle: '$gray3',
    neutralBg: '#151515',
    neutralBgTransparent20: 'rgba(21, 21, 21, 0.2)',
    neutralBgTransparent40: 'rgba(21, 21, 21, 0.4)',
    fancyBg: '#3d3d3d90',
    fancyColor: 'white',
    panelBg: '$gray2',
    panelShadow: 'transparent',
    dropdownBg: '$gray2',
    sidebarOverlay: 'black',
    btnPrimaryBg: 'white',
    btnPrimaryColor: 'black',
    borderColor: '$slate7',
    btnSecondaryBg: 'black',
    btnSecondaryColor: 'white',
    btnSubtleColor: '#6b7280',
    textColor: 'white',
    dropdownActiveBg: '$gray5',
    dropdownCountColor: '$gray11',
    btnDisabledBg: '$gray5',
    btnDisabledText: '$gray11',
    btnSubtleText: '$gray11',
    linkColor: '#70CBFF',
    accentColor: '#70CBFF',
    tabSelectedBg: '$gray6',
    checkboxBg: '$gray5',
    textError: '#c34d5f',

    contentBackground: '$neutralBgSubtle',
    neutralBgHover: '$slate4',
    neutralLine: '$slate6',
    neutralBorder: '$slate7',
    neutralBorderHover: '$slate11',
    neutralSolid: '$slate9',
    neutralSolidHover: '$slate10',
    neutralText: '$slate11',
    neutralTextContrast: '$slate12',
    focusColor: '$neutralTextContrast',
    errorAccent: '$red9',
    buttonTextColor: 'white',
    inputBackground: '$neutralBgSubtle',
    overlayBackground: '$blackA10',
    wellBackground: '$neutralBg',
    popoverBackground: '$slate5',
    invertedTextColor: 'black',
    subtleTextContrast: '$slate11',
    selectedBg: '$slate6',

    brandColor: '$textColor',
    ghostBg: 'transparent',
    ghostText: '$btnSubtleText',
    dialogBg: '$neutralBg',
    ghostBrandBorder: '$borderColor',
    ghostBrandText: '$btnSubtleText',
  },
});
