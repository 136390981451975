import Text from './Text';

type Props = {
  value: string;
};

export default function DateDisplay(props: Props) {
  return (
    <Text
      style='body1'
      color='subtle'
      css={{ whiteSpace: 'nowrap', alignContent: 'center' }}
    >
      Expires on {props.value}
    </Text>
  );
}
