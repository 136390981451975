const wrappedContracts: Record<number, string> = {
  1: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2', //mainnet
  5: '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6', //goerli
  10: '0x4200000000000000000000000000000000000006', //optimism
  56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c', //bnb
  204: '0x4200000000000000000000000000000000000006', //opbnb
  137: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270', //polygon
  324: '0x5aea5775959fbc2557cc8789bc1bf90a239d9a91', //zksync
  1101: '0x4f9a0e7fd2bf6067db6994cf12e4495df938e6e9', // zkEVM
  42161: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1', //arbitrum
  42170: '0x722e8bdd2ce80a4422e880164f2079488e115365', //arbitrum nova
  43114: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7', //avalance
  59144: '0xe5d7c2a44ffddf6b295a15c148167daaaf5cf34f',
  999: '0x8a5027ea12f45a13deb6CB96A07913c6e192BE84', //zoratestnet
  80001: '0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889', //mumbai
  8453: '0x4200000000000000000000000000000000000006', //base
  84531: '0x4200000000000000000000000000000000000006', //base goerli
  84532: '0x4200000000000000000000000000000000000006', //base sepolia
  534353: '0xa1EA0B2354F5A344110af2b6AD68e75545009a03', //scrolltestnet
  534352: '0x5300000000000000000000000000000000000004', //scroll
  7777777: '0x4200000000000000000000000000000000000006', //zora
  11155111: '0x7b79995e5f793a07bc00c21412e50ecae098e7f9', //sepolia
  888888888: '0x4200000000000000000000000000000000000006', //ancient8
  28122024: '0x4200000000000000000000000000000000000006', //ancient8testnet
  68840142: '0x822b4c4713433c6b88547845850a39343bf0957e', //frametestnet
}

export default wrappedContracts
