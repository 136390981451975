import React, {
  FC,
  useEffect,
  useState,
  useCallback,
  ReactNode,
  useMemo,
} from 'react';
import { ExpirationOption } from '../../types/ExpirationOption';
import expirationOptions from '../../lib/defaultExpirationOptions';
import dayjs from 'dayjs';
import { formatUnits, parseUnits, zeroAddress } from 'viem';
import { useAccount, useSwitchChain } from 'wagmi';
import {
  Collection,
  Currency,
  Order,
  StepItemStatus,
  Token,
} from '@api/orderbook_api/v1/types.pb';
import useCollectionToken from '@hooks/useCollectionToken';
import { ContractType } from '@api/common/v1/common.pb';
import { getCollection } from '@signals/collection';
import { EnhancedStep, EnhancedStepItem, EnhancedSteps } from '@types';
import { useChainCurrency, useCoinConversion } from '@hooks';
import useCollectionOrders from '@hooks/useCollectionOrders';
import paymentProcessorExchange from '@utils/paymentProcessorExchange';
import useWalletTokens from '@hooks/useWalletTokens';
import useOnChainRoyalties from '@hooks/useOnChainRoyalties';
import { CreateListingRequest } from '@api/orderbook_api/v1/orderbook.pb';
import { useWallet } from '@hooks/useWallet';
import { getChainById } from '@signals/chains';
import { OrderProtocol } from '@api/payment_processor/v2/payment_processor.pb';

export enum EditListingStep {
  Edit,
  Approving,
  Complete,
}

export type EditListingStepData = {
  totalSteps: number;
  stepProgress: number;
  currentStep: EnhancedStep;
  currentStepItem: EnhancedStepItem;
};

type ChildrenProps = {
  loading: boolean;
  listing?: Order;
  tokenId?: string;
  contract?: string;
  isOracleOrder: boolean;
  price: number | undefined;
  token?: Token;
  currency?: Currency;
  quantityAvailable: number;
  collection?: Collection;
  quantity: number;
  editListingStep: EditListingStep;
  transactionError?: Error;
  totalUsd: number;
  royaltyBps?: number;
  expirationOptions: ExpirationOption[];
  expirationOption: ExpirationOption;
  usdPrice: number;
  steps?: EnhancedSteps;
  stepData?: EditListingStepData;
  setPrice: React.Dispatch<React.SetStateAction<number | undefined>>;
  setQuantity: React.Dispatch<React.SetStateAction<number>>;
  setExpirationOption: React.Dispatch<React.SetStateAction<ExpirationOption>>;
  setEditListingStep: React.Dispatch<React.SetStateAction<EditListingStep>>;
  editListing: () => void;
};

type Props = {
  listingId?: string;
  tokenId?: string;
  collectionId?: string;
  chainId?: number;
  normalizeRoyalties?: boolean;
  enableOnChainRoyalties: boolean;
  children: (props: ChildrenProps) => ReactNode;
};

export const EditListingModalRenderer: FC<Props> = ({
  listingId,
  tokenId,
  collectionId,
  chainId,
  normalizeRoyalties,
  enableOnChainRoyalties = false,
  children,
}) => {
  const { wallet } = useWallet();

  const { switchChainAsync } = useSwitchChain();
  let { chain: activeWalletChain } = useAccount();

  const [editListingStep, setEditListingStep] = useState<EditListingStep>(
    EditListingStep.Edit,
  );
  const [transactionError, setTransactionError] = useState<Error | undefined>();
  const [stepData, setStepData] = useState<EditListingStepData | undefined>();
  const [steps, setSteps] = useState<EnhancedSteps | undefined>();
  const [price, setPrice] = useState<number | undefined>(0);
  const [quantity, setQuantity] = useState(1);

  const { data: listings } = useCollectionOrders(
    {
      orderIds: [listingId as string],
    },
    {
      revalidateFirstPage: true,
    },
  );

  const listing = listings && listings[0] ? listings[0] : undefined;
  const contract = listing?.collection;
  const currency = listing?.price?.currency;

  const exchange = paymentProcessorExchange;

  const isOracleOrder = listing?.useOffChainCancellation || false;

  useEffect(() => {
    if (listing?.price?.amount?.decimal) {
      setPrice(listing?.price?.amount?.decimal);
    }
  }, [listing?.price]);

  const coinConversion = useCoinConversion(
    listing ? 'USD' : undefined,
    currency?.symbol,
  );
  const usdPrice = coinConversion.length > 0 ? coinConversion[0].price : 0;
  const totalUsd = usdPrice * (listing?.price?.amount?.decimal || 0);

  const [expirationOption, setExpirationOption] = useState<ExpirationOption>(
    expirationOptions[5],
  );

  const collection = getCollection(listing?.collection || collectionId);
  const rendererChain = getChainById(Number(collection?.chainId));
  let royaltyBps = collection?.royalties?.[0]?.bps;

  const { data: token } = useCollectionToken({
    collection: collectionId,
    tokenId,
    chainId: rendererChain?.id.toString(),
  });

  const is1155 = collection?.type === ContractType.CONTRACT_TYPE_ERC1155;

  const { data: userTokens } = useWalletTokens(
    is1155
      ? {
          walletAddress: wallet?.address,
          collections: [contract as string],
        }
      : {},
  );

  const quantityAvailable =
    is1155 && userTokens[0] ? Number(listing?.amount || 1) : 1;

  const chainCurrency = useChainCurrency(Number(rendererChain?.id));

  const { data: onChainRoyalties } = useOnChainRoyalties({
    contract,
    tokenId,
    chainId: chainCurrency.chainId,
    enabled: enableOnChainRoyalties,
  });

  const onChainRoyaltyBps = useMemo(() => {
    const totalRoyalty = onChainRoyalties?.[1].reduce((total, royalty) => {
      total += parseFloat(formatUnits(royalty, currency?.decimals || 18));
      return total;
    }, 0);
    if (totalRoyalty) {
      return (totalRoyalty / 1) * 10000;
    }
    return 0;
  }, [onChainRoyalties, currency?.decimals]);

  if (enableOnChainRoyalties && onChainRoyaltyBps) {
    royaltyBps = onChainRoyaltyBps;
  }

  const editListing = useCallback(async () => {
    if (!wallet) {
      const error = new Error('Missing a wallet/signer');
      setTransactionError(error);
      throw error;
    }

    if (activeWalletChain && rendererChain?.id !== activeWalletChain?.id) {
      activeWalletChain = await switchChainAsync({
        chainId: rendererChain?.id as number,
      });
    }

    if (rendererChain?.id !== activeWalletChain?.id) {
      const error = new Error(`Mismatching chainIds`);
      setTransactionError(error);
      throw error;
    }

    if (!listingId) {
      const error = new Error('Missing list id to edit');
      setTransactionError(error);
      throw error;
    }

    if (!isOracleOrder) {
      const error = new Error('Not an oracle order');
      setTransactionError(error);
      throw error;
    }

    if (!exchange) {
      const error = new Error('Missing Exchange');
      setTransactionError(error);
      throw error;
    }

    setTransactionError(undefined);

    let expirationTime: string | null = null;

    if (expirationOption.relativeTime && expirationOption.relativeTimeUnit) {
      expirationTime = dayjs()
        .add(expirationOption.relativeTime, expirationOption.relativeTimeUnit)
        .unix()
        .toString();
    }

    const listingRequest: CreateListingRequest = {
      tokenId: tokenId,
      itemPrice: (
        parseUnits(`${price as number}`, currency?.decimals || 18) *
        BigInt(quantity)
      ).toString(),
      orderbook: listing?.orderbook,
      kind: listing?.kind,
      protocol: token?.isErc1155
        ? OrderProtocol.ERC1155_FILL_OR_KILL
        : OrderProtocol.ERC721_FILL_OR_KILL,
    };

    if (quantity > 1) {
      listingRequest.amount = quantity.toString();
    }

    if (expirationTime) {
      listingRequest.expiration = expirationTime;
    }

    if (currency?.address != zeroAddress) {
      listingRequest.paymentMethod = currency?.address;
    }

    setEditListingStep(EditListingStep.Approving);

    wallet
      .createListing({
        request: listingRequest,
        beforeStep: async (steps, step, item) => {
          if (!steps) {
            return;
          }
          setSteps(steps.steps as EnhancedSteps);

          const executableSteps = steps.steps?.filter(
            (step) => step.items && step.items.length > 0,
          );

          const stepCount = executableSteps?.length || 0;
          const currentStepItem = item as EnhancedStepItem;
          const currentStep = step as EnhancedStep;

          const currentStepIndex = executableSteps?.findIndex((step) => {
            return step.items?.find(
              (item) => item.status === StepItemStatus.INCOMPLETE,
            );
          });

          if (currentStepItem) {
            setStepData({
              totalSteps: stepCount,
              stepProgress: currentStepIndex || 0,
              currentStep,
              currentStepItem,
            });
          }
        },
      })
      .catch((error: Error) => {
        setTransactionError(error);
        setEditListingStep(EditListingStep.Edit);
        setStepData(undefined);
        setSteps(undefined);
      })
      .finally(() => {
        setEditListingStep(EditListingStep.Complete);
      });
  }, [
    wallet,
    rendererChain?.id,
    listingId,
    isOracleOrder,
    exchange,
    expirationOption.relativeTime,
    expirationOption.relativeTimeUnit,
    tokenId,
    price,
    currency?.decimals,
    currency?.address,
    quantity,
    listing?.orderbook,
    listing?.kind,
  ]);

  return (
    <>
      {children({
        loading: !listing || !token || !collection || !exchange,
        listing,
        tokenId,
        contract,
        isOracleOrder,
        currency,
        token: token as Token,
        price,
        quantityAvailable,
        collection,
        quantity,
        expirationOption,
        expirationOptions,
        editListingStep,
        transactionError,
        usdPrice,
        totalUsd,
        royaltyBps,
        steps,
        stepData,
        setPrice,
        setQuantity,
        setExpirationOption,
        setEditListingStep,
        editListing,
      })}
    </>
  );
};
