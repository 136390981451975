import FormatCrypto from './FormatCrypto';
import React, { FC, ComponentProps } from 'react';
import Text from './Text';
import { sSelectedChain } from '@signals/chains';

type FormatCryptoCurrencyProps = {
  logoHeight?: number;
  borderRadius?: string;
  address?: string;
  symbol?: string;
  small: boolean;
  smallCss?: ComponentProps<typeof Text>['css'];
  cryptoColor?: ComponentProps<typeof Text>['color'];
  wrap?: boolean;
};

type Props = ComponentProps<typeof FormatCrypto> & FormatCryptoCurrencyProps;

const FormatCryptoCurrency: FC<Props> = ({
  amount,
  symbol,
  maximumFractionDigits,
  textStyle,
  css,
  decimals,
  small,
  smallCss,
  cryptoColor,
  wrap = true,
}) => {
  const defaultSymbol = sSelectedChain.value?.nativeCurrency.symbol;

  return (
    <FormatCrypto
      css={css}
      textStyle={textStyle}
      amount={amount}
      maximumFractionDigits={maximumFractionDigits}
      decimals={decimals}
      wrap={wrap}
    >
      {small && (
        <Text css={smallCss} style='cryptoSmall'>
          {symbol || defaultSymbol}
        </Text>
      )}
      {!small && (
        <Text css={{ ...css, lineHeight: 1 }} color={cryptoColor}>
          {symbol || defaultSymbol}
        </Text>
      )}
    </FormatCrypto>
  );
};

export default FormatCryptoCurrency;
