/* eslint-disable react-hooks/rules-of-hooks */
import React, {
  ReactElement,
  useEffect,
  ComponentPropsWithoutRef,
} from 'react';
import { Flex, Text, Box, Button, Loader, ErrorWell } from '../../primitives';
import { CancelBidModalRenderer, CancelStep } from './CancelBidModalRenderer';
import { Modal } from '../Modal';
import TokenPrimitive from '../../modal/TokenPrimitive';
import Progress from '../Progress';
import { truncateAddress } from '../../lib/truncate';
import getChainBlockExplorerUrl from '../../lib/getChainBlockExplorerUrl';
import { Dialog } from '../../primitives/Dialog';
import { getTimeSince } from '@reservoir-kit-ui-overrides/lib/getTimeSince';
import { ViewTx } from '@v2/components/ViewTx/ViewTx';
import { int } from '@api/utils';
import { getCollection } from '@signals/collection';
import { useSignals } from '@preact/signals-react/runtime';
import { OrderStatus } from '@api/orderbook_api/v1/types.pb';
import { useMediaQuery } from 'react-responsive';
import { TokenIconWithPriceListing } from '@reservoir-kit-ui-overrides/components/TokenIconWithPriceListing/TokenIconWithPriceListing';
import { useOpenState } from '@hooks/useOpenState';
import { setModalState } from '@signals/modalState';
import { trackEvent } from '@utils/analytics/events';
import { TxSummary } from '@v2/components/TxSummary/TxSummary';

const ModalCopy = {
  title: 'Cancel Offer',
  ctaCancel: 'Continue to cancel',
  ctaAwaitingApproval: 'Waiting for approval',
  ctaAwaitingValidation: 'Waiting for transaction to be validated',
  ctaClose: 'Close',
};

type Props = Pick<Parameters<typeof Modal>['0'], 'trigger'> & {
  bidId: string;
  chainId?: number;
  normalizeRoyalties?: boolean;
  copyOverrides?: Partial<typeof ModalCopy>;
  onClose?: (data: any, currentStep: CancelStep) => void;
  onCancelComplete?: (data: any) => void;
  onCancelError?: (error: Error, data: any) => void;
  onPointerDownOutside?: ComponentPropsWithoutRef<
    typeof Dialog
  >['onPointerDownOutside'];
};

export function CancelBidModal({
  bidId,
  trigger,
  copyOverrides,
  onClose,
  onCancelComplete,
  onCancelError,
  onPointerDownOutside,
}: Props): ReactElement {
  const copy: typeof ModalCopy = { ...ModalCopy, ...copyOverrides };
  const { isOpen, open, setIsOpen, createCloseHandler } = useOpenState({
    onChange: setModalState,
  });
  useSignals();

  useEffect(() => {
    setModalState(isOpen);
  }, [isOpen]);

  const isSmallDevice = useMediaQuery({ maxWidth: 600 });

  useEffect(() => {
    if (isOpen) {
      trackEvent('cancel_offer_modal_opened');
    }
  }, [isOpen]);

  if (!isOpen) {
    return <div onClick={open}>{trigger}</div>;
  }

  return (
    <CancelBidModalRenderer bidId={bidId}>
      {({
        loading,
        bid,
        token,
        cancelStep,
        transactionError,
        stepData,
        totalUsd,
        cancelOrder,
      }) => {
        const bidCollection = getCollection(bid?.collection);
        const expires = getTimeSince(int(bid?.expiration));
        const bidImg = token?.imageUrl || bidCollection?.config?.imageUrl;
        // TODO: double check if attribute offers are supported
        // const isAttributeOffer = (bid?.criteria?.kind as any) === 'attribute';
        const isAttributeOffer = false;

        const close = createCloseHandler(() => {
          trackEvent('cancel_offer_modal_closed');
          onClose?.(
            {
              bid,
              stepData: stepData,
            },
            cancelStep,
          );
        });

        useEffect(() => {
          if (cancelStep === CancelStep.Complete && onCancelComplete) {
            const data = {
              bid,
              stepData: stepData,
            };
            onCancelComplete(data);
          }
        }, [bid, cancelStep, stepData]);

        useEffect(() => {
          if (transactionError && onCancelError) {
            const data = {
              bid,
              stepData: stepData,
            };
            onCancelError(transactionError, data);
          }
        }, [bid, stepData, transactionError]);

        const isBidAvailable =
          bid &&
          (bid.status === OrderStatus.ACTIVE ||
            bid.status === OrderStatus.PENDING_APPROVAL) &&
          !loading;

        return (
          <Modal
            trigger={trigger}
            title={copy.title}
            open={true}
            onOpenChange={setIsOpen}
            loading={loading}
            onPointerDownOutside={(e) => {
              if (onPointerDownOutside) {
                onPointerDownOutside(e);
              }
            }}
          >
            {!isBidAvailable && !loading && (
              <Flex
                direction='column'
                justify='center'
                css={{ px: '$4', py: '$6' }}
              >
                <Text style='h6' css={{ textAlign: 'center' }}>
                  Selected bid is no longer available
                </Text>
              </Flex>
            )}
            {isBidAvailable && cancelStep === CancelStep.Cancel && (
              <Flex direction='column' css={{ height: '100%' }}>
                {transactionError && <ErrorWell error={transactionError} />}

                <Box
                  css={{
                    p: '$modalContentPadding',
                    borderBottom: '1px solid $borderColor',
                  }}
                >
                  {isSmallDevice ? (
                    <TokenIconWithPriceListing
                      chainId={Number(bidCollection?.chainId)}
                      img={bidImg}
                      name={bid?.token?.name}
                      price={bid?.price}
                      totalUsd={totalUsd}
                    />
                  ) : (
                    <TokenPrimitive
                      img={bidImg}
                      name={bid?.token?.name}
                      price={bid?.price?.amount?.decimal}
                      usdPrice={totalUsd}
                      collection={bidCollection?.name || ''}
                      currencyContract={bid?.price?.currency?.address}
                      currencyDecimals={bid?.price?.currency?.decimals}
                      currencySymbol={bid?.price?.currency?.symbol}
                      expires={expires}
                      source={bid?.source?.iconUrl || ''}
                      priceSubtitle='Offer'
                    />
                  )}
                </Box>

                <Flex direction='column' css={{ p: '$modalContentPadding' }}>
                  <Text style='subtitle1'>
                    Confirm cancellation in your wallet
                  </Text>
                  <Text style='body2' color='subtle'>
                    You need to sign a message to approve the cancellation.{' '}
                  </Text>
                </Flex>
                <Box css={{ flexGrow: 1 }} />
                <Box
                  css={{
                    p: '$modalContentPadding',
                    borderTop: '1px solid $borderColor',
                  }}
                >
                  <Button
                    color='error'
                    onClick={cancelOrder}
                    css={{ width: '100%' }}
                  >
                    {copy.ctaCancel}
                  </Button>
                </Box>
              </Flex>
            )}
            {cancelStep === CancelStep.Approving && (
              <Flex direction='column' css={{ height: '100%' }}>
                <Box
                  css={{
                    p: '$modalContentPadding',
                    borderBottom: '1px solid $borderColor',
                  }}
                >
                  {isSmallDevice ? (
                    <TokenIconWithPriceListing
                      chainId={Number(bidCollection?.chainId)}
                      img={bidImg}
                      name={bid?.token?.name}
                      price={bid?.price}
                      totalUsd={totalUsd}
                    />
                  ) : (
                    <TokenPrimitive
                      img={bidImg}
                      name={bid?.token?.name}
                      price={bid?.price?.amount?.decimal}
                      usdPrice={totalUsd}
                      collection={bidCollection?.name || ''}
                      currencyContract={bid?.price?.currency?.address}
                      currencyDecimals={bid?.price?.currency?.decimals}
                      currencySymbol={bid?.price?.currency?.symbol}
                      expires={expires}
                      source={bid?.source?.iconUrl || ''}
                      priceSubtitle='Offer'
                    />
                  )}
                </Box>
                {!stepData && <Loader css={{ height: 206 }} />}
                {stepData && (
                  <>
                    <Progress
                      title={
                        stepData?.currentStepItem.txHashes
                          ? 'Finalizing on blockchain'
                          : 'Confirm cancelation in your wallet'
                      }
                      txList={stepData?.currentStepItem.txHashes}
                    />
                    {isAttributeOffer &&
                      !stepData?.currentStepItem.txHashes && (
                        <Flex justify='center'>
                          <Text
                            style='body2'
                            color='subtle'
                            css={{
                              maxWidth: 400,
                              textAlign: 'center',
                              mx: '$3',
                            }}
                          >
                            This will cancel your offer on all items that were
                            included in this attribute offer.
                          </Text>
                        </Flex>
                      )}
                  </>
                )}
                <Box css={{ flexGrow: 1 }}></Box>
                <Box
                  css={{
                    p: '$modalContentPadding',
                    borderTop: '1px solid $borderColor',
                  }}
                >
                  <Button disabled={true} css={{ width: '100%' }}>
                    {stepData?.currentStepItem.txHashes
                      ? copy.ctaAwaitingValidation
                      : copy.ctaAwaitingApproval}
                  </Button>
                </Box>
              </Flex>
            )}
            {cancelStep === CancelStep.Complete && (
              <Flex direction='column' css={{ height: '100%' }}>
                <Box
                  css={{
                    p: '$modalContentPadding',
                    borderBottom: '1px solid $borderColor',
                  }}
                >
                  {isSmallDevice ? (
                    <TokenIconWithPriceListing
                      chainId={Number(bidCollection?.chainId)}
                      img={bidImg}
                      name={bid?.token?.name}
                      price={bid?.price}
                      totalUsd={totalUsd}
                    />
                  ) : (
                    <TokenPrimitive
                      img={bidImg}
                      name={bid?.token?.name}
                      price={bid?.price?.amount?.decimal}
                      usdPrice={totalUsd}
                      collection={bidCollection?.name || ''}
                      currencyContract={bid?.price?.currency?.address}
                      currencyDecimals={bid?.price?.currency?.decimals}
                      currencySymbol={bid?.price?.currency?.symbol}
                      expires={expires}
                      source={bid?.source?.iconUrl || ''}
                      priceSubtitle='Offer'
                    />
                  )}
                </Box>
                <Flex
                  direction='column'
                  css={{
                    p: '$modalContentPadding',
                    gap: '$2',
                  }}
                >
                  <Text style='subtitle1'>Offer Canceled</Text>
                  <Text style='body2' color='subtle'>
                    <>
                      Your {bid?.source?.name as string} offer has been
                      canceled.
                    </>
                  </Text>

                  <Flex direction='column' css={{ gap: '$1' }}>
                    <TxSummary txList={stepData?.currentStepItem?.txHashes} />
                  </Flex>
                </Flex>
                <Box css={{ flexGrow: 1 }}></Box>
                <Box
                  css={{
                    p: '$modalContentPadding',
                    borderTop: '1px solid $borderColor',
                  }}
                >
                  <Button
                    color='onlyBorder'
                    onClick={close}
                    css={{
                      width: '100%',
                    }}
                  >
                    {copy.ctaClose}
                  </Button>
                </Box>
              </Flex>
            )}
          </Modal>
        );
      }}
    </CancelBidModalRenderer>
  );
}

CancelBidModal.Custom = CancelBidModalRenderer;
