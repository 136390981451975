import { useBatchUpdate, useStateUrl } from '@utils/useStateUrl';
import { trackEvent } from '@utils/analytics/events';

const DEFAULT_TAB = 'info';

export const useOpenToken = () => {
  const [selectedToken, setSelectedToken, mutateSelectedToken] = useStateUrl<
    string | undefined
  >({
    name: 'token',
    type: 'string',
    defaultValue: undefined,
  });
  const [, , mutateTokenTab] = useStateUrl<string>({
    name: 'tokenTab',
    type: 'string',
    defaultValue: DEFAULT_TAB,
  });
  const { batchUpdate } = useBatchUpdate();

  const openToken = (
    collectionAddress: string | undefined,
    tokenId: string | undefined,
  ) => {
    if (collectionAddress === undefined || tokenId === undefined) {
      return;
    }
    trackEvent('token_modal_opened');
    batchUpdate([
      mutateSelectedToken(`${collectionAddress}:${tokenId}`),
      mutateTokenTab(DEFAULT_TAB),
    ]);
  };

  const closeToken = () => {
    setSelectedToken(undefined);
    trackEvent('token_modal_closed');
  };

  return {
    selectedToken,
    openToken,
    closeToken,
  };
};
