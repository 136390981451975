import React, {
  createContext,
  ComponentPropsWithoutRef,
  PropsWithChildren,
  useEffect,
} from 'react';
import { SWRConfig } from 'swr';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import calendarCss from './styles/calendar';
import { swrDefaultOptions } from './lib/swr';

type ReservoirKitProviderOptions = {
  disablePoweredByReservoir?: boolean;
  disableJumperLink?: boolean;
  alwaysIncludeListingCurrency?: boolean;
  preferDisplayFiatTotal?: boolean;
};

interface Props {
  swrOptions?: ComponentPropsWithoutRef<typeof SWRConfig>['value'];
}

export const ProviderOptionsContext =
  createContext<ReservoirKitProviderOptions>({});

export const ReservoirKitProvider = function ({
  children,
  swrOptions = {},
}: PropsWithChildren<Props>) {
  useEffect(() => {
    calendarCss();
  }, []);

  return (
    <TooltipPrimitive.Provider>
      <SWRConfig value={{ ...swrDefaultOptions, ...swrOptions }}>
        {children}
      </SWRConfig>
    </TooltipPrimitive.Provider>
  );
};
