import { computed, signal } from '@preact/signals-react';

const __modalState = signal<{ open: boolean }>({
  open: false,
});

export const sModalState = computed(() => {
  return __modalState.value;
});

export const setModalState = (open: boolean) => {
  __modalState.value = { open };
};
