/* eslint-disable react-hooks/rules-of-hooks */
import React, {
  ComponentPropsWithoutRef,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {
  Flex,
  Box,
  Text,
  Button,
  Loader,
  Select,
  ErrorWell,
  Img,
  Input,
  FormatCryptoCurrency,
  DateDisplay,
} from '../../primitives';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flatpickr from 'react-flatpickr';
import { Modal } from '../Modal';
import {
  ListModalRenderer,
  ListStep,
  ListModalStepData,
} from './ListModalRenderer';
import { faTag } from '@fortawesome/free-solid-svg-icons';
import SigninStep from '../SigninStep';
import { zeroAddress } from 'viem';
import ListCheckout from './ListCheckout';
import QuantitySelector from '../QuantitySelector';
import dayjs from 'dayjs';
import { CurrencySelector } from '../CurrencySelector';
import PriceBreakdown from './PriceBreakdown';
import FloorDropdown from './FloorDropdown';
import { formatNumber } from '../../lib/numbers';
import { Dialog } from '../../primitives/Dialog';
import { TokenOpSuccessPreview } from '@v2/components/TokenOpSuccessPreview/TokenOpSuccessPreview';
import {
  Currency,
  Order,
  StepAction,
  StepKind,
} from '@api/orderbook_api/v1/types.pb';
import { getChainForCollection } from '@signals/collection';
import { getChainById } from '@signals/chains';
import { useMediaQuery } from 'react-responsive';
import { TokenIconWithPrice } from '@reservoir-kit-ui-overrides/components/TokenIconWithPrice/TokenIconWithPrice';
import { getTopOffer } from '@utils/getTopOffer';
import { useWallet } from '@hooks/useWallet';
import { useOpenState } from '@hooks/useOpenState';
import { setModalState } from '@signals/modalState';
import { trackEvent } from '@utils/analytics/events';
import { useAccount } from 'wagmi';

type ListingCallbackData = {
  listing?: Order;
  tokenId?: string;
  collectionId?: string;
};

const ModalCopy = {
  title: 'List item for sale',
  titleListed: 'Your item has been listed',
  ctaClose: 'Close',
  ctaSetPrice: 'Set your price',
  ctaList: 'List for Sale',
  ctaAwaitingApproval: 'Waiting for Approval',
  ctaGoToToken: 'Go to Token',
};

type Props = Pick<Parameters<typeof Modal>['0'], 'trigger'> & {
  tokenId?: string;
  collectionId?: string;
  currencies?: Currency[];
  oracleEnabled?: boolean;
  copyOverrides?: Partial<typeof ModalCopy>;
  feesBps?: string[];
  onGoToToken?: () => any;
  onListingComplete?: (data: ListingCallbackData) => void;
  onListingError?: (error: Error, data: ListingCallbackData) => void;
  onClose?: (
    data: ListingCallbackData,
    stepData: ListModalStepData | null,
    currentStep: ListStep,
  ) => void;
  onPointerDownOutside?: ComponentPropsWithoutRef<
    typeof Dialog
  >['onPointerDownOutside'];
};

const MINIMUM_AMOUNT = 0.000001;
const MAXIMUM_AMOUNT = Infinity;

export function ListModal({
  trigger,
  tokenId,
  collectionId,
  currencies,
  copyOverrides,
  onGoToToken,
  onListingComplete,
  onListingError,
  onClose,
  onPointerDownOutside,
}: Props): ReactElement {
  const copy: typeof ModalCopy = { ...ModalCopy, ...copyOverrides };
  const { isOpen, open, setIsOpen, createCloseHandler } = useOpenState({
    onChange: setModalState,
  });
  const isSmallDevice = useMediaQuery({ maxWidth: 600 });
  const datetimeElement = useRef<Flatpickr | null>(null);
  const modalChain = getChainById(getChainForCollection(collectionId));

  useEffect(() => {
    if (isOpen) {
      trackEvent('listing_modal_opened');
    }
  }, [isOpen]);

  if (!isOpen) {
    return <div onClick={open}>{trigger}</div>;
  }

  return (
    <ListModalRenderer
      tokenId={tokenId}
      collectionId={collectionId}
      currencies={currencies}
    >
      {({
        loading,
        token,
        quantityAvailable,
        collection,
        usdPrice,
        listStep,
        expirationOption,
        expirationOptions,
        listingData,
        transactionError,
        stepData,
        price,
        currencies,
        currency,
        quantity,
        setPrice,
        listToken,
        setCurrency,
        setExpirationOption,
        setQuantity,
      }) => {
        const { address } = useAccount();
        const close = createCloseHandler(() => {
          trackEvent('listing_modal_closed');
          onClose?.(
            {
              tokenId: tokenId,
              collectionId: collectionId,
              listing: listingData,
            },
            stepData,
            listStep,
          );
        });

        const expirationDate = useMemo(() => {
          if (expirationOption && expirationOption.relativeTime) {
            const newExpirationTime = expirationOption.relativeTimeUnit
              ? dayjs().add(
                  expirationOption.relativeTime,
                  expirationOption.relativeTimeUnit,
                )
              : dayjs.unix(expirationOption.relativeTime);
            return newExpirationTime.format('MM/DD/YYYY h:mm A');
          }
          return '';
        }, [expirationOption]);

        useEffect(() => {
          if (listStep === ListStep.Complete && onListingComplete) {
            const data: ListingCallbackData = {
              tokenId: tokenId,
              collectionId: collectionId,
              listing: listingData,
            };
            onListingComplete(data);
          }
        }, [listStep]);

        useEffect(() => {
          if (transactionError && onListingError) {
            const data: ListingCallbackData = {
              tokenId: tokenId,
              collectionId: collectionId,
              listing: listingData,
            };
            onListingError(transactionError, data);
          }
        }, [transactionError]);

        const floorAskPrice = collection?.floorPrice;
        // const floorAskPrice = testFloorPrice; // to simulate floor Price

        const decimalFloorPrice = floorAskPrice?.amount?.decimal;
        // TODO: see if we can get native currency added to the API
        // const nativeFloorPrice = floorAskPrice?.amount?.native;
        const usdFloorPrice = floorAskPrice?.amount?.usd;
        const defaultCurrency = currencies?.find(
          (currency) => currency?.address === zeroAddress,
        );

        const topBidPrice = useMemo(() => {
          return getTopOffer({
            token,
            collection,
            address,
          })?.price;
        }, [token, collection, address]);

        const floorButtonEnabled = Boolean(
          (currency.address === floorAskPrice?.currency?.address &&
            decimalFloorPrice) ||
            (currency.symbol === 'USDC' && usdFloorPrice), //||
          // (nativeFloorPrice && currency.address === zeroAddress) ||
          // (nativeFloorPrice && defaultCurrency),
        );

        const minimumAmount = MINIMUM_AMOUNT;
        const maximumAmount = MAXIMUM_AMOUNT;

        const withinPricingBounds =
          price &&
          Number(price) !== 0 &&
          Number(price) <= maximumAmount &&
          Number(price) >= minimumAmount;

        const canPurchase = price !== '' && withinPricingBounds;

        const handleSetFloor = () => {
          // If currency matches floor ask currency, use decimal floor price
          if (
            currency.address === floorAskPrice?.currency?.address &&
            decimalFloorPrice
          ) {
            setPrice(decimalFloorPrice.toString());
          }

          // If currency is USDC, use usd floor price
          else if (currency.symbol === 'USDC' && usdFloorPrice) {
            setPrice(usdFloorPrice?.toString());
            // } else if (nativeFloorPrice) {
            // // If currency is native currency, use native floor price
            // if (currency.address === zeroAddress) {
            //   setPrice(nativeFloorPrice.toString());
            // }
            // // Fallback to default currency if it exists
            // else {
            //   if (defaultCurrency) {
            //     setCurrency(defaultCurrency);
            //     setPrice(nativeFloorPrice.toString());
            //   }
            // }
          }
        };

        // listStep = ListStep.Complete; // Simulate Complete state

        return (
          <Modal
            trigger={trigger}
            title={
              listStep === ListStep.Complete ? copy.titleListed : copy.title
            }
            open={true}
            onOpenChange={setIsOpen}
            loading={loading}
            onPointerDownOutside={(e) => {
              if (
                e.target instanceof Element &&
                datetimeElement.current?.flatpickr?.calendarContainer &&
                datetimeElement.current.flatpickr.calendarContainer.contains(
                  e.target,
                )
              ) {
                e.preventDefault();
              }
              if (onPointerDownOutside) {
                onPointerDownOutside(e);
              }
            }}
            onFocusCapture={(e) => {
              e.stopPropagation();
            }}
          >
            {!loading && listStep == ListStep.Unavailable && (
              <Flex
                direction='column'
                align='center'
                css={{ p: '$4', gap: '$5' }}
              >
                <Box css={{ color: '$neutralSolid', mt: 48 }}>
                  <FontAwesomeIcon
                    icon={faTag}
                    style={{ width: '32px', height: '32px' }}
                  />
                </Box>

                <Text style='h6' css={{ mb: '$3', textAlign: 'center' }}>
                  Listing is not available for this collection.
                </Text>
                <Button css={{ width: '100%' }} onClick={close}>
                  {copy.ctaClose}
                </Button>
              </Flex>
            )}
            {!loading && listStep == ListStep.SetPrice && (
              <Flex direction='column' css={{ height: '100%' }}>
                {transactionError && <ErrorWell error={transactionError} />}
                {!isSmallDevice && (
                  <ListCheckout
                    collection={collection}
                    token={token}
                    chain={modalChain}
                  />
                )}
                <Flex
                  direction='column'
                  align='center'
                  css={{ p: '$modalContentPadding', gap: '$5' }}
                >
                  {quantityAvailable > 1 && (
                    <Flex
                      align='center'
                      justify='between'
                      css={{ width: '100%', gap: '$3', '@bp1': { gap: '$6' } }}
                    >
                      <Flex
                        align='start'
                        direction='column'
                        css={{ gap: '$1', flexShrink: 0 }}
                      >
                        <Text style='subtitle2'>Quantity</Text>
                        <Text style='body3' color='subtle'>
                          {quantityAvailable} items available
                        </Text>
                      </Flex>
                      <QuantitySelector
                        quantity={quantity}
                        setQuantity={setQuantity}
                        min={1}
                        max={quantityAvailable}
                        css={{ width: '100%', justifyContent: 'space-between' }}
                      />
                    </Flex>
                  )}

                  <Flex direction='column' css={{ gap: '$3', width: '100%' }}>
                    <Flex direction='column' css={{ gap: '$1', width: '100%' }}>
                      <Text style='subtitle1'>Enter a price</Text>
                      {floorButtonEnabled && (
                        <Flex css={{ gap: '$1' }} align='center'>
                          <Text
                            style='body1'
                            color='subtle'
                            css={{ whiteSpace: 'nowrap' }}
                          >
                            Floor price:
                          </Text>
                          <FormatCryptoCurrency
                            amount={floorAskPrice?.amount?.raw}
                            chainId={modalChain.id}
                            symbol={floorAskPrice?.currency?.symbol}
                            textStyle='body1'
                            textColor='subtle'
                          />
                          <Text style='body1' color='subtle'>
                            ·
                          </Text>
                          <Text
                            style='body1'
                            color='subtle'
                            css={{ whiteSpace: 'nowrap' }}
                          >
                            Best offer:
                          </Text>
                          <FormatCryptoCurrency
                            amount={topBidPrice?.amount?.raw}
                            chainId={modalChain.id}
                            symbol={topBidPrice?.currency?.symbol}
                            textStyle='body1'
                            textColor='subtle'
                          />
                        </Flex>
                      )}
                    </Flex>
                    <Flex
                      align='center'
                      justify='between'
                      css={{ gap: '$3', width: '100%' }}
                    >
                      <Input
                        type='number'
                        step='any'
                        value={price}
                        onChange={(e) => {
                          setPrice(e.target.value);
                        }}
                        placeholder='Amount'
                      />
                      {currencies.length > 1 ? (
                        <div>
                          <CurrencySelector
                            currency={currency}
                            currencies={currencies}
                            setCurrency={setCurrency}
                          />
                        </div>
                      ) : (
                        <Flex align='center' css={{ flexShrink: 0, px: '$2' }}>
                          <Text style='body1' color='subtle' as='p'>
                            {currency.symbol}
                          </Text>
                        </Flex>
                      )}
                      {floorButtonEnabled ? (
                        <Button
                          color='secondary'
                          size='none'
                          css={{
                            px: '$4',
                            fontWeight: 500,
                          }}
                          onClick={() => handleSetFloor()}
                        >
                          Floor
                        </Button>
                      ) : null}
                      <FloorDropdown
                        token={token}
                        currency={currency}
                        defaultCurrency={defaultCurrency}
                        setPrice={setPrice}
                        setCurrency={setCurrency}
                      />
                    </Flex>
                    {Number(price) !== 0 && !withinPricingBounds && (
                      <Box>
                        <Text style='body2' color='error'>
                          {maximumAmount !== Infinity
                            ? `Amount must be between ${formatNumber(
                                minimumAmount,
                              )} - ${formatNumber(maximumAmount)}`
                            : `Amount must be higher than ${formatNumber(
                                minimumAmount,
                              )}`}
                        </Text>
                      </Box>
                    )}
                    {/* {collection &&
                      collection?.floorPrice?.amount?.native !== undefined &&
                      Number(price) !== 0 &&
                      Number(price) >= minimumAmount &&
                      currency.address === zeroAddress &&
                      Number(price) < collection?.floorPrice.amount.native && (
                        <Box>
                          <Text style='body2' color='error'>
                            Price is{' '}
                            {Math.round(
                              ((collection.floorPrice.amount.native - +price) /
                                ((collection.floorPrice.amount.native +
                                  +price) /
                                  2)) *
                                100 *
                                1000,
                            ) / 1000}
                            % below the floor
                          </Text>
                        </Box>
                      )} */}
                  </Flex>
                  <Flex direction='column' css={{ width: '100%', gap: '$3' }}>
                    <Text style='subtitle1'>Expiration Date</Text>
                    <Flex
                      css={{
                        gap: '$3',
                        width: '100%',
                        flexDirection: 'column',
                        '@sm': {
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        },
                      }}
                    >
                      <Select
                        value={expirationOption?.text || ''}
                        onValueChange={(value: string) => {
                          const option = expirationOptions.find(
                            (option) => option.value == value,
                          );
                          if (option) {
                            setExpirationOption(option);
                            trackEvent('listing_modal_expiration_set');
                          }
                        }}
                        css={{ '@sm': { maxWidth: 160 } }}
                      >
                        {expirationOptions
                          .filter(({ value }) => value !== 'custom')
                          .map((option) => (
                            <Select.Item key={option.text} value={option.value}>
                              <Select.ItemText>{option.text}</Select.ItemText>
                            </Select.Item>
                          ))}
                      </Select>
                      <DateDisplay value={expirationDate} />
                    </Flex>
                  </Flex>
                  {!isSmallDevice ? (
                    <PriceBreakdown
                      price={price}
                      usdPrice={usdPrice}
                      currency={currency}
                      quantity={quantity}
                      collection={collection}
                    />
                  ) : (
                    <TokenIconWithPrice
                      token={token}
                      collection={collection}
                      price={price}
                      usdPrice={usdPrice}
                      currency={currency}
                      fees={listingData?.additionalFees}
                      quantity={quantity}
                    />
                  )}
                </Flex>
                <Box css={{ flexGrow: 1 }}></Box>
                <Box
                  css={{
                    p: '$modalContentPadding',
                    width: '100%',
                    borderTop: '1px solid $borderColor',
                    boxSizing: 'border-box',
                  }}
                >
                  <Button
                    disabled={canPurchase ? false : true}
                    onClick={listToken as any}
                    css={{ width: '100%' }}
                  >
                    {copy.ctaList}
                  </Button>
                </Box>
              </Flex>
            )}
            {!loading && listStep == ListStep.Listing && (
              <Flex direction='column' css={{ height: '100%' }}>
                <ListCheckout
                  collection={collection}
                  token={token}
                  price={price}
                  currency={currency}
                  quantity={quantity}
                  expirationOption={expirationOption}
                  containerCss={{
                    borderBottom: '1px solid',
                    borderBottomColor: '$borderColor',
                    borderColor: '$borderColor',
                  }}
                />
                <Flex
                  align='center'
                  css={{ gap: '$3', p: '$modalContentPadding' }}
                >
                  <Flex css={{ color: '$neutralSolid', width: 'auto' }}>
                    <Img
                      width={64}
                      height={64}
                      css={{
                        aspectRatio: '1/1',
                        objectFit: 'cover',
                        borderRadius: '50%',
                      }}
                      src={collection?.config?.imageUrl || '/images/img.png'}
                      alt={`collection ${collection?.name}`}
                    />
                  </Flex>
                  <Flex
                    direction='column'
                    align='start'
                    css={{ width: '100%', gap: '$1' }}
                  >
                    {stepData &&
                    stepData.currentStep.action ===
                      StepAction.AUTHORIZE_LISTING ? (
                      <SigninStep css={{}} />
                    ) : null}
                    {stepData &&
                    stepData.currentStep.action !==
                      StepAction.AUTHORIZE_LISTING ? (
                      <>
                        <Text style='subtitle1'>
                          {stepData.currentStep.kind === StepKind.TRANSACTION
                            ? 'Approve Collection'
                            : 'Confirm listing in your wallet'}
                        </Text>
                        <Text
                          css={{
                            maxWidth: 295,
                          }}
                          style='body2'
                          color='subtle'
                        >
                          {stepData?.currentStep.description}
                        </Text>
                      </>
                    ) : null}
                    {!stepData && (
                      <Flex
                        css={{ height: '100%', py: '$5' }}
                        justify='center'
                        align='center'
                      >
                        <Loader />
                      </Flex>
                    )}
                  </Flex>
                </Flex>
                <Box css={{ flexGrow: 1 }}></Box>
                <Flex
                  css={{
                    width: '100%',
                    p: '$modalContentPadding',
                    borderTop: '1px solid $borderColor',
                  }}
                >
                  <Button css={{ width: '100%', mt: 'auto' }} disabled={true}>
                    {copy.ctaAwaitingApproval}
                  </Button>
                </Flex>
              </Flex>
            )}
            {!loading && listStep == ListStep.Complete && (
              <Flex direction='column' align='center' css={{ height: '100%' }}>
                <Flex
                  direction='column'
                  align='center'
                  css={{
                    width: '100%',
                    p: '$modalContentPadding',
                  }}
                >
                  <TokenOpSuccessPreview
                    token={token}
                    collection={collection}
                  />

                  {/* {source ? (
                    <Flex direction='column' align='center' css={{ gap: '$2' }}>
                      <Text style='subtitle3' color='subtle' as='p'>
                        View Listing on
                      </Text>
                      <a
                        target='_blank'
                        href={`${modalChain?.baseApiUrl}/redirect/sources/${source}/tokens/${token?.token?.contract}:${token?.token?.tokenId}/link/v2`}
                      >
                        <Image
                          css={{ width: 24, borderRadius: '$radiusTiny' }}
                          src={marketplace?.imageUrl}
                        />
                      </a>
                    </Flex>
                  ) : null} */}
                </Flex>
                <Box css={{ flexGrow: 1 }}></Box>
                <Flex
                  css={{
                    p: '$modalContentPadding',
                    width: '100%',
                    flexDirection: 'column',
                    borderTop: '1px solid $borderColor',
                    gap: '$3',
                    '@bp1': {
                      flexDirection: 'row',
                    },
                  }}
                >
                  {!!onGoToToken ? (
                    <>
                      <Button
                        onClick={close}
                        css={{ flex: 1 }}
                        color='onlyBorder'
                      >
                        {copy.ctaClose}
                      </Button>
                      <Button
                        style={{ flex: 1 }}
                        color='primary'
                        onClick={() => {
                          onGoToToken();
                        }}
                      >
                        {copy.ctaGoToToken}
                      </Button>
                    </>
                  ) : (
                    <Button
                      onClick={close}
                      style={{ flex: 1 }}
                      color='onlyBorder'
                    >
                      {copy.ctaClose}
                    </Button>
                  )}
                </Flex>
              </Flex>
            )}
          </Modal>
        );
      }}
    </ListModalRenderer>
  );
}

ListModal.Custom = ListModalRenderer;
