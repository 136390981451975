import React, { FC, ReactNode, useCallback, useState } from 'react';
import { useChainCurrency, useCoinConversion } from '@hooks';
import dayjs from 'dayjs';
import { ExpirationOption } from '../../types/ExpirationOption';
import defaultExpirationOptions from '../../lib/defaultExpirationOptions';
import { Address, parseUnits, zeroAddress } from 'viem';
import { useAccount, useSwitchChain } from 'wagmi';
import {
  Collection,
  Currency,
  Order,
  Orderbook,
  OrderKind,
  StepItemStatus,
  Token,
} from '@api/orderbook_api/v1/types.pb';
import useCollectionToken from '@hooks/useCollectionToken';
import { getCollection } from '@signals/collection';
import { CreateListingRequest } from '@api/orderbook_api/v1/orderbook.pb';
import { EnhancedStep } from '@types';
import { useWallet } from '@hooks/useWallet';
import { getChainById } from '@signals/chains';
import { trackEvent } from '@utils/analytics/events';
import { OrderProtocol } from '@api/payment_processor/v2/payment_processor.pb';

export enum ListStep {
  Unavailable,
  SetPrice,
  Listing,
  Complete,
}

export type ListModalStepData = {
  totalSteps: number;
  stepProgress: number;
  currentStep: EnhancedStep;
  listingData?: Order;
};

type ChildrenProps = {
  loading: boolean;
  token?: Token;
  quantityAvailable: number;
  collection?: Collection;
  listStep: ListStep;
  usdPrice: number;
  expirationOptions: ExpirationOption[];
  expirationOption: ExpirationOption;
  listingData?: Order;
  transactionError?: Error | null;
  stepData: ListModalStepData | null;
  price: string;
  currencies: Currency[];
  currency: Currency;
  quantity: number;
  setListStep: React.Dispatch<React.SetStateAction<ListStep>>;
  setExpirationOption: React.Dispatch<React.SetStateAction<ExpirationOption>>;
  setPrice: React.Dispatch<React.SetStateAction<string>>;
  setCurrency: (currency: Currency) => void;
  setQuantity: React.Dispatch<React.SetStateAction<number>>;
  listToken: (options: { royaltyBps: number }) => void;
};

type Props = {
  tokenId?: string;
  collectionId?: string;
  currencies?: Currency[];
  children: (props: ChildrenProps) => ReactNode;
};

const expirationOptions = [
  ...defaultExpirationOptions,
  {
    text: 'Custom',
    value: 'custom',
    relativeTime: null,
    relativeTimeUnit: null,
  },
];

export const ListModalRenderer: FC<Props> = ({
  tokenId,
  collectionId,
  currencies: preferredCurrencies,
  children,
}) => {
  const { wallet } = useWallet();

  const { switchChainAsync } = useSwitchChain();
  let { chain: activeWalletChain } = useAccount();

  const [listStep, setListStep] = useState<ListStep>(ListStep.SetPrice);
  const [listingData, setListingData] = useState<Order>();

  const [transactionError, setTransactionError] = useState<Error | null>();
  const [stepData, setStepData] = useState<ListModalStepData | null>(null);
  const [price, setPrice] = useState('');

  const [currencies, setCurrencies] = useState<Currency[] | undefined>(
    preferredCurrencies,
  );
  const [quantity, setQuantity] = useState(1);

  const collection = getCollection(collectionId as Address);
  const rendererChain = getChainById(Number(collection?.chainId));
  const defaultCurrency = useChainCurrency(rendererChain?.id);

  const [currency, __setCurrency] = useState<Currency>(defaultCurrency);
  const [expirationOption, setExpirationOption] = useState<ExpirationOption>(
    expirationOptions[5],
  );

  const { data: token } = useCollectionToken({
    tokenId: tokenId?.toString() as string,
    collection: collectionId as Address,
    chainId: rendererChain?.id.toString(),
  });

  const is1155 = token?.isErc1155;
  const setCurrency = (currency: Currency) => {
    trackEvent('listing_modal_payment_currency_set');
    __setCurrency(currency);
  };

  const quantityAvailable = is1155
    ? Number(token?.tokenOwnerErc1155?.balance)
    : 1;

  const coinConversion = useCoinConversion(
    currency ? 'USD' : undefined,
    currency.symbol,
    currency.coinGeckoId,
  );
  const usdPrice = coinConversion.length > 0 ? coinConversion[0].price : 0;

  const listToken = useCallback(async () => {
    trackEvent('listing_modal_list_button_clicked');
    if (!wallet) {
      const error = new Error('Missing a wallet/signer');
      setTransactionError(error);
      throw error;
    }

    if (activeWalletChain && rendererChain?.id !== activeWalletChain?.id) {
      activeWalletChain = await switchChainAsync({
        chainId: rendererChain?.id as number,
      });
    }

    if (rendererChain?.id !== activeWalletChain?.id) {
      const error = new Error(`Mismatching chainIds`);
      setTransactionError(error);
      throw error;
    }

    setTransactionError(null);

    let expirationTime: string | null = null;

    if (expirationOption.relativeTime) {
      if (expirationOption.relativeTimeUnit) {
        expirationTime = dayjs()
          .add(expirationOption.relativeTime, expirationOption.relativeTimeUnit)
          .unix()
          .toString();
      } else {
        expirationTime = `${expirationOption.relativeTime}`;
      }
    }

    const listingRequest: CreateListingRequest = {
      tokenId: tokenId,
      chainId: rendererChain?.id.toString(),
      collection: collectionId,
      itemPrice: (
        parseUnits(`${+price}`, currency.decimals || 18) * BigInt(quantity)
      ).toString(),
      paymentMethod: currency.address,
      orderbook: Orderbook.LIMIT_BREAK,
      kind: OrderKind.PAYMENT_PROCESSOR_V2,
      protocol: token?.isErc1155
        ? OrderProtocol.ERC1155_FILL_PARTIAL
        : OrderProtocol.ERC721_FILL_OR_KILL,
    };

    // TODO: confirm that marketplace fees are not a thing
    // const fees = feesBps; //|| client.marketplaceFees;
    // if (fees) {
    //   listingRequest.marketplaceFees = fees;
    // }

    if (quantity > 1) {
      listingRequest.amount = quantity.toString();
    }

    if (expirationTime) {
      listingRequest.expiration = expirationTime;
    }

    if (currency && currency.address != zeroAddress) {
      listingRequest.paymentMethod = currency.address;
    }

    // TODO: confirm that oracle is not enabled
    // if (oracleEnabled) {
    //   listingRequest.options = {
    //     [`${listingRequest.orderKind}`]: {
    //       useOffChainCancellation: false,
    //     },
    //   };
    // }

    setListingData(listingRequest);
    setListStep(ListStep.Listing);

    wallet
      ?.createListing({
        request: listingRequest,
        beforeStep: async (steps, step, item) => {
          const executableSteps = steps.steps?.filter(
            (step) => step.items && step.items.length > 0,
          );

          let stepCount = executableSteps?.length;
          let incompleteStepItemIndex: number | null = null;
          let incompleteStepIndex: number | null = null;

          executableSteps?.find((step, i) => {
            if (!step.items) {
              return false;
            }

            incompleteStepItemIndex = step.items.findIndex(
              (item) => item.status == StepItemStatus.INCOMPLETE,
            );
            if (incompleteStepItemIndex !== -1) {
              incompleteStepIndex = i;
              return true;
            }
          });

          const currentStep = step as EnhancedStep;

          if (
            incompleteStepIndex === null ||
            incompleteStepItemIndex === null
          ) {
            setListStep(ListStep.Complete);

            setStepData({
              totalSteps: stepCount as number,
              stepProgress: stepCount as number,
              currentStep,
              listingData,
            });
          } else {
            setStepData({
              totalSteps: stepCount as number,
              stepProgress: incompleteStepIndex,
              currentStep: executableSteps?.[
                incompleteStepIndex
              ] as EnhancedStep,
              listingData,
            });
          }
        },
      })
      .then(() => {
        trackEvent('listing_modal_transaction_succeeded');
        setListStep(ListStep.Complete);
      })
      .catch((error: Error) => {
        trackEvent('listing_modal_transaction_failed');
        setListStep(ListStep.SetPrice);
        setTransactionError(error);
      });
  }, [
    wallet,
    rendererChain,
    expirationOption.relativeTime,
    expirationOption.relativeTimeUnit,
    tokenId,
    collectionId,
    price,
    currency,
    quantity,
    listingData,
  ]);

  return (
    <>
      {children({
        loading: !token || !collection,
        token: token as Token,
        quantityAvailable,
        collection,
        listStep,
        usdPrice,
        expirationOption,
        expirationOptions,
        listingData,
        transactionError,
        stepData,
        price,
        currencies: currencies || [defaultCurrency],
        currency,
        quantity,
        setListStep,
        setPrice,
        setCurrency,
        setExpirationOption,
        setQuantity,
        listToken,
      })}
    </>
  );
};

ListModalRenderer.displayName = 'ListModalRenderer';
