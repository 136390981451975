import { int } from '@api/utils';
import { useCallback, useState } from 'react';
import { useSWRConfig } from 'swr';
import useSWRInfinite from 'swr/infinite';
import { SWRInfiniteConfiguration } from 'swr/infinite';

type SWRInfiniteParams = Parameters<typeof useSWRInfinite>;

export default function useInfiniteApi<ResponseType>(
  getKey: SWRInfiniteParams['0'],
  options: SWRInfiniteConfiguration,
  limit: number = 25,
) {
  const { mutate: globalMutate } = useSWRConfig();
  const [keys, setKeys] = useState<string[]>([]);
  const response = useSWRInfinite<ResponseType>(
    useCallback(
      (pageIndex, previousPageData) => {
        const params = getKey(pageIndex, previousPageData);
        const key = params && params[0] ? JSON.stringify(params) : null;
        if (key && !keys.includes(key)) {
          setKeys([...keys, key]);
        }
        return params;
      },
      [getKey, keys],
    ),
    null,
    options,
  );

  const { size, error, setSize, mutate } = response;
  const data = response.data as any;
  let hasNextPage: boolean;
  const itemCount = int(data?.[size - 1]?.total);
  hasNextPage = itemCount === limit;
  const isFetchingInitialData = !data && !error && size > 0;
  const isFetchingPage =
    size > 0 &&
    (isFetchingInitialData || (data && typeof data[size - 1] === 'undefined'));

  const fetchNextPage = useCallback(() => {
    if (!isFetchingPage && hasNextPage) {
      setSize((size) => size + 1);
    }
  }, [isFetchingPage, hasNextPage]);

  const resetCache = useCallback(() => {
    setSize(0);
    return mutate(undefined, {
      revalidate: false,
    }).then(() => {
      globalMutate(
        (key) => {
          const url = key && key[0] ? key[0] : null;
          if (url) {
            return keys.includes(url);
          }
          return false;
        },
        undefined,
        false,
      ).then(() => {
        setKeys([]);
      });
    });
  }, [keys]);

  return {
    ...response,
    hasNextPage,
    isFetchingInitialData,
    isFetchingPage,
    resetCache,
    fetchNextPage,
  };
}
