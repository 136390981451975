import { zeroAddress } from 'viem';

const paymentProcessorExchange = {
  orderKind: 'payment-processor-v2',
  enabled: true,
  customFeesSupported: true,
  numFeesSupported: 1,
  collectionBidSupported: true,
  supportedBidCurrencies: [
    '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
    '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
    '0x3c499c542cef5e3811e1192ce70d8cc03d5c3359',
    '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
    '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
  ],
  partialOrderSupported: false,
  traitBidSupported: false,
  oracleEnabled: true,
  paymentTokens: [
    {
      address: '0x0000000000000000000000000000000000000000',
      symbol: 'MATIC',
      name: 'Matic Token',
      decimals: 18,
    },
    {
      address: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
      symbol: 'WMATIC',
      name: 'Wrapped Matic',
      decimals: 18,
    },
    {
      address: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
      symbol: 'WETH',
      name: 'Wrapped Ether',
      decimals: 18,
    },
    {
      address: '0x3c499c542cef5e3811e1192ce70d8cc03d5c3359',
      symbol: 'USDC',
      name: 'USD Coin',
      decimals: 6,
    },
    {
      address: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
      symbol: 'USDC.e',
      name: 'USD Coin (PoS)',
      decimals: 6,
    },
    {
      address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
      symbol: 'WETH',
      name: 'Wrapped Ether',
      decimals: 18,
    },
  ],
};

export default paymentProcessorExchange;
