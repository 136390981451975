//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ERC20
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const erc20Abi = [
  {
    type: "function",
    inputs: [],
    name: "DOMAIN_SEPARATOR",
    outputs: [{ name: "", internalType: "bytes32", type: "bytes32" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [
      { name: "", internalType: "address", type: "address" },
      { name: "", internalType: "address", type: "address" },
    ],
    name: "allowance",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [
      { name: "spender", internalType: "address", type: "address" },
      { name: "amount", internalType: "uint256", type: "uint256" },
    ],
    name: "approve",
    outputs: [{ name: "", internalType: "bool", type: "bool" }],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    inputs: [{ name: "", internalType: "address", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [],
    name: "decimals",
    outputs: [{ name: "", internalType: "uint8", type: "uint8" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [],
    name: "name",
    outputs: [{ name: "", internalType: "string", type: "string" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [{ name: "", internalType: "address", type: "address" }],
    name: "nonces",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [
      { name: "owner", internalType: "address", type: "address" },
      { name: "spender", internalType: "address", type: "address" },
      { name: "value", internalType: "uint256", type: "uint256" },
      { name: "deadline", internalType: "uint256", type: "uint256" },
      { name: "v", internalType: "uint8", type: "uint8" },
      { name: "r", internalType: "bytes32", type: "bytes32" },
      { name: "s", internalType: "bytes32", type: "bytes32" },
    ],
    name: "permit",
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    inputs: [],
    name: "symbol",
    outputs: [{ name: "", internalType: "string", type: "string" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [],
    name: "totalSupply",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [
      { name: "to", internalType: "address", type: "address" },
      { name: "amount", internalType: "uint256", type: "uint256" },
    ],
    name: "transfer",
    outputs: [{ name: "", internalType: "bool", type: "bool" }],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    inputs: [
      { name: "from", internalType: "address", type: "address" },
      { name: "to", internalType: "address", type: "address" },
      { name: "amount", internalType: "uint256", type: "uint256" },
    ],
    name: "transferFrom",
    outputs: [{ name: "", internalType: "bool", type: "bool" }],
    stateMutability: "nonpayable",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "owner",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "spender",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "amount",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "Approval",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "from", internalType: "address", type: "address", indexed: true },
      { name: "to", internalType: "address", type: "address", indexed: true },
      {
        name: "amount",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "Transfer",
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// PaymentProcessor
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const paymentProcessorAbi = [
  {
    type: "constructor",
    inputs: [
      {
        name: "configurationContract",
        internalType: "address",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    inputs: [{ name: "data", internalType: "bytes", type: "bytes" }],
    name: "acceptOffer",
    outputs: [],
    stateMutability: "payable",
  },
  {
    type: "function",
    inputs: [{ name: "data", internalType: "bytes", type: "bytes" }],
    name: "addBannedAccountForCollection",
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    inputs: [{ name: "data", internalType: "bytes", type: "bytes" }],
    name: "addTrustedChannelForCollection",
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    inputs: [{ name: "data", internalType: "bytes", type: "bytes" }],
    name: "bulkAcceptOffers",
    outputs: [],
    stateMutability: "payable",
  },
  {
    type: "function",
    inputs: [{ name: "data", internalType: "bytes", type: "bytes" }],
    name: "bulkBuyListings",
    outputs: [],
    stateMutability: "payable",
  },
  {
    type: "function",
    inputs: [{ name: "data", internalType: "bytes", type: "bytes" }],
    name: "buyListing",
    outputs: [],
    stateMutability: "payable",
  },
  {
    type: "function",
    inputs: [
      { name: "tokenAddress", internalType: "address", type: "address" },
    ],
    name: "collectionBountySettings",
    outputs: [
      {
        name: "royaltyBountyNumerator",
        internalType: "uint16",
        type: "uint16",
      },
      {
        name: "exclusiveBountyReceiver",
        internalType: "address",
        type: "address",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [
      { name: "tokenAddress", internalType: "address", type: "address" },
    ],
    name: "collectionPaymentSettings",
    outputs: [
      {
        name: "",
        internalType: "struct CollectionPaymentSettings",
        type: "tuple",
        components: [
          {
            name: "paymentSettings",
            internalType: "enum PaymentSettings",
            type: "uint8",
          },
          {
            name: "paymentMethodWhitelistId",
            internalType: "uint32",
            type: "uint32",
          },
          {
            name: "constrainedPricingPaymentMethod",
            internalType: "address",
            type: "address",
          },
          {
            name: "royaltyBackfillNumerator",
            internalType: "uint16",
            type: "uint16",
          },
          {
            name: "royaltyBountyNumerator",
            internalType: "uint16",
            type: "uint16",
          },
          {
            name: "isRoyaltyBountyExclusive",
            internalType: "bool",
            type: "bool",
          },
          {
            name: "blockTradesFromUntrustedChannels",
            internalType: "bool",
            type: "bool",
          },
          { name: "blockBannedAccounts", internalType: "bool", type: "bool" },
        ],
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [
      { name: "tokenAddress", internalType: "address", type: "address" },
    ],
    name: "collectionRoyaltyBackfillSettings",
    outputs: [
      {
        name: "royaltyBackfillNumerator",
        internalType: "uint16",
        type: "uint16",
      },
      {
        name: "royaltyBackfillReceiver",
        internalType: "address",
        type: "address",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [{ name: "data", internalType: "bytes", type: "bytes" }],
    name: "createPaymentMethodWhitelist",
    outputs: [
      {
        name: "paymentMethodWhitelistId",
        internalType: "uint32",
        type: "uint32",
      },
    ],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    inputs: [{ name: "data", internalType: "bytes", type: "bytes" }],
    name: "destroyCosigner",
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    inputs: [],
    name: "eip712Domain",
    outputs: [
      { name: "fields", internalType: "bytes1", type: "bytes1" },
      { name: "name", internalType: "string", type: "string" },
      { name: "version", internalType: "string", type: "string" },
      { name: "chainId", internalType: "uint256", type: "uint256" },
      { name: "verifyingContract", internalType: "address", type: "address" },
      { name: "salt", internalType: "bytes32", type: "bytes32" },
      { name: "extensions", internalType: "uint256[]", type: "uint256[]" },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [
      { name: "tokenAddress", internalType: "address", type: "address" },
    ],
    name: "getBannedAccounts",
    outputs: [{ name: "", internalType: "address[]", type: "address[]" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [
      { name: "tokenAddress", internalType: "address", type: "address" },
      { name: "tokenId", internalType: "uint256", type: "uint256" },
    ],
    name: "getCeilingPrice",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [],
    name: "getDefaultPaymentMethods",
    outputs: [{ name: "", internalType: "address[]", type: "address[]" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [],
    name: "getDomainSeparator",
    outputs: [{ name: "", internalType: "bytes32", type: "bytes32" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [
      { name: "tokenAddress", internalType: "address", type: "address" },
      { name: "tokenId", internalType: "uint256", type: "uint256" },
    ],
    name: "getFloorPrice",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [
      { name: "tokenAddress", internalType: "address", type: "address" },
    ],
    name: "getTrustedChannels",
    outputs: [{ name: "", internalType: "address[]", type: "address[]" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [
      {
        name: "paymentMethodWhitelistId",
        internalType: "uint32",
        type: "uint32",
      },
    ],
    name: "getWhitelistedPaymentMethods",
    outputs: [{ name: "", internalType: "address[]", type: "address[]" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [
      { name: "paymentMethod", internalType: "address", type: "address" },
    ],
    name: "isDefaultPaymentMethod",
    outputs: [{ name: "", internalType: "bool", type: "bool" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [
      { name: "account", internalType: "address", type: "address" },
      { name: "nonce", internalType: "uint256", type: "uint256" },
    ],
    name: "isNonceUsed",
    outputs: [{ name: "isUsed", internalType: "bool", type: "bool" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [
      {
        name: "paymentMethodWhitelistId",
        internalType: "uint32",
        type: "uint32",
      },
      { name: "paymentMethod", internalType: "address", type: "address" },
    ],
    name: "isPaymentMethodWhitelisted",
    outputs: [{ name: "", internalType: "bool", type: "bool" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [],
    name: "lastPaymentMethodWhitelistId",
    outputs: [{ name: "", internalType: "uint32", type: "uint32" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [{ name: "account", internalType: "address", type: "address" }],
    name: "masterNonces",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [
      {
        name: "paymentMethodWhitelistId",
        internalType: "uint32",
        type: "uint32",
      },
    ],
    name: "paymentMethodWhitelistOwners",
    outputs: [{ name: "", internalType: "address", type: "address" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [{ name: "data", internalType: "bytes", type: "bytes" }],
    name: "reassignOwnershipOfPaymentMethodWhitelist",
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    inputs: [
      { name: "account", internalType: "address", type: "address" },
      { name: "orderDigest", internalType: "bytes32", type: "bytes32" },
    ],
    name: "remainingFillableQuantity",
    outputs: [
      {
        name: "",
        internalType: "struct PartiallyFillableOrderStatus",
        type: "tuple",
        components: [
          {
            name: "state",
            internalType: "enum PartiallyFillableOrderState",
            type: "uint8",
          },
          {
            name: "remainingFillableQuantity",
            internalType: "uint248",
            type: "uint248",
          },
        ],
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [{ name: "data", internalType: "bytes", type: "bytes" }],
    name: "removeBannedAccountForCollection",
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    inputs: [{ name: "data", internalType: "bytes", type: "bytes" }],
    name: "removeTrustedChannelForCollection",
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    inputs: [{ name: "data", internalType: "bytes", type: "bytes" }],
    name: "renounceOwnershipOfPaymentMethodWhitelist",
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    inputs: [],
    name: "revokeMasterNonce",
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    inputs: [{ name: "data", internalType: "bytes", type: "bytes" }],
    name: "revokeOrderDigest",
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    inputs: [{ name: "data", internalType: "bytes", type: "bytes" }],
    name: "revokeSingleNonce",
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    inputs: [{ name: "data", internalType: "bytes", type: "bytes" }],
    name: "setCollectionPaymentSettings",
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    inputs: [{ name: "data", internalType: "bytes", type: "bytes" }],
    name: "setCollectionPricingBounds",
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    inputs: [{ name: "data", internalType: "bytes", type: "bytes" }],
    name: "setTokenPricingBounds",
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    inputs: [{ name: "data", internalType: "bytes", type: "bytes" }],
    name: "sweepCollection",
    outputs: [],
    stateMutability: "payable",
  },
  {
    type: "function",
    inputs: [{ name: "data", internalType: "bytes", type: "bytes" }],
    name: "unwhitelistPaymentMethod",
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    inputs: [{ name: "data", internalType: "bytes", type: "bytes" }],
    name: "whitelistPaymentMethod",
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "seller",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "buyer",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "tokenAddress",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "beneficiary",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "paymentCoin",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "tokenId",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
      {
        name: "amount",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
      {
        name: "salePrice",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "AcceptOfferERC1155",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "seller",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "buyer",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "tokenAddress",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "beneficiary",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "paymentCoin",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "tokenId",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
      {
        name: "salePrice",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "AcceptOfferERC721",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "tokenAddress",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "account",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "BannedAccountAddedForCollection",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "tokenAddress",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "account",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "BannedAccountRemovedForCollection",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "buyer",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "seller",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "tokenAddress",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "beneficiary",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "paymentCoin",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "tokenId",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
      {
        name: "amount",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
      {
        name: "salePrice",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "BuyListingERC1155",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "buyer",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "seller",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "tokenAddress",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "beneficiary",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "paymentCoin",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "tokenId",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
      {
        name: "salePrice",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "BuyListingERC721",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "paymentMethodWhitelistId",
        internalType: "uint32",
        type: "uint32",
        indexed: true,
      },
      {
        name: "whitelistOwner",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "whitelistName",
        internalType: "string",
        type: "string",
        indexed: false,
      },
    ],
    name: "CreatedPaymentMethodWhitelist",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "cosigner",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "DestroyedCosigner",
  },
  { type: "event", anonymous: false, inputs: [], name: "EIP712DomainChanged" },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "account",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "nonce",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "MasterNonceInvalidated",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "nonce",
        internalType: "uint256",
        type: "uint256",
        indexed: true,
      },
      {
        name: "account",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "wasCancellation",
        internalType: "bool",
        type: "bool",
        indexed: false,
      },
    ],
    name: "NonceInvalidated",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "nonce",
        internalType: "uint256",
        type: "uint256",
        indexed: true,
      },
      {
        name: "account",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "NonceRestored",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "orderDigest",
        internalType: "bytes32",
        type: "bytes32",
        indexed: true,
      },
      {
        name: "account",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "wasCancellation",
        internalType: "bool",
        type: "bool",
        indexed: false,
      },
    ],
    name: "OrderDigestInvalidated",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "orderDigest",
        internalType: "bytes32",
        type: "bytes32",
        indexed: true,
      },
      {
        name: "account",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "amountFilled",
        internalType: "uint248",
        type: "uint248",
        indexed: false,
      },
    ],
    name: "OrderDigestItemsFilled",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "orderDigest",
        internalType: "bytes32",
        type: "bytes32",
        indexed: true,
      },
      {
        name: "account",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "amountRestoredToOrder",
        internalType: "uint248",
        type: "uint248",
        indexed: false,
      },
    ],
    name: "OrderDigestItemsRestored",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "orderDigest",
        internalType: "bytes32",
        type: "bytes32",
        indexed: true,
      },
      {
        name: "account",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "orderStartAmount",
        internalType: "uint248",
        type: "uint248",
        indexed: false,
      },
    ],
    name: "OrderDigestOpened",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "paymentMethodWhitelistId",
        internalType: "uint32",
        type: "uint32",
        indexed: true,
      },
      {
        name: "paymentMethod",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "PaymentMethodAddedToWhitelist",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "paymentMethodWhitelistId",
        internalType: "uint32",
        type: "uint32",
        indexed: true,
      },
      {
        name: "paymentMethod",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "PaymentMethodRemovedFromWhitelist",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "id", internalType: "uint32", type: "uint32", indexed: true },
      {
        name: "newOwner",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "ReassignedPaymentMethodWhitelistOwnership",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "tokenAddress",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "channel",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "TrustedChannelAddedForCollection",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "tokenAddress",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "channel",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "TrustedChannelRemovedForCollection",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "tokenAddress",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "floorPrice",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
      {
        name: "ceilingPrice",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "UpdatedCollectionLevelPricingBoundaries",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "tokenAddress",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "paymentSettings",
        internalType: "enum PaymentSettings",
        type: "uint8",
        indexed: false,
      },
      {
        name: "paymentMethodWhitelistId",
        internalType: "uint32",
        type: "uint32",
        indexed: true,
      },
      {
        name: "constrainedPricingPaymentMethod",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "royaltyBackfillNumerator",
        internalType: "uint16",
        type: "uint16",
        indexed: false,
      },
      {
        name: "royaltyBackfillReceiver",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "royaltyBountyNumerator",
        internalType: "uint16",
        type: "uint16",
        indexed: false,
      },
      {
        name: "exclusiveBountyReceiver",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "blockTradesFromUntrustedChannels",
        internalType: "bool",
        type: "bool",
        indexed: false,
      },
      {
        name: "blockBannedAccounts",
        internalType: "bool",
        type: "bool",
        indexed: false,
      },
    ],
    name: "UpdatedCollectionPaymentSettings",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "tokenAddress",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "tokenId",
        internalType: "uint256",
        type: "uint256",
        indexed: true,
      },
      {
        name: "floorPrice",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
      {
        name: "ceilingPrice",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "UpdatedTokenLevelPricingBoundaries",
  },
  { type: "error", inputs: [], name: "InvalidShortString" },
  {
    type: "error",
    inputs: [],
    name: "PaymentProcessor__InvalidConstructorArguments",
  },
  {
    type: "error",
    inputs: [{ name: "str", internalType: "string", type: "string" }],
    name: "StringTooLong",
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// PaymentProcessorEncoder
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const paymentProcessorEncoderAbi = [
  {
    type: "function",
    inputs: [
      {
        name: "paymentProcessorAddress",
        internalType: "address",
        type: "address",
      },
      { name: "isCollectionLevelOffer", internalType: "bool", type: "bool" },
      {
        name: "saleDetails",
        internalType: "struct Order",
        type: "tuple",
        components: [
          {
            name: "protocol",
            internalType: "enum OrderProtocols",
            type: "uint8",
          },
          { name: "maker", internalType: "address", type: "address" },
          { name: "beneficiary", internalType: "address", type: "address" },
          { name: "marketplace", internalType: "address", type: "address" },
          {
            name: "fallbackRoyaltyRecipient",
            internalType: "address",
            type: "address",
          },
          { name: "paymentMethod", internalType: "address", type: "address" },
          { name: "tokenAddress", internalType: "address", type: "address" },
          { name: "tokenId", internalType: "uint256", type: "uint256" },
          { name: "amount", internalType: "uint248", type: "uint248" },
          { name: "itemPrice", internalType: "uint256", type: "uint256" },
          { name: "nonce", internalType: "uint256", type: "uint256" },
          { name: "expiration", internalType: "uint256", type: "uint256" },
          {
            name: "marketplaceFeeNumerator",
            internalType: "uint256",
            type: "uint256",
          },
          {
            name: "maxRoyaltyFeeNumerator",
            internalType: "uint256",
            type: "uint256",
          },
          {
            name: "requestedFillAmount",
            internalType: "uint248",
            type: "uint248",
          },
          {
            name: "minimumFillAmount",
            internalType: "uint248",
            type: "uint248",
          },
        ],
      },
      {
        name: "signature",
        internalType: "struct SignatureECDSA",
        type: "tuple",
        components: [
          { name: "v", internalType: "uint8", type: "uint8" },
          { name: "r", internalType: "bytes32", type: "bytes32" },
          { name: "s", internalType: "bytes32", type: "bytes32" },
        ],
      },
      {
        name: "tokenSetProof",
        internalType: "struct TokenSetProof",
        type: "tuple",
        components: [
          { name: "rootHash", internalType: "bytes32", type: "bytes32" },
          { name: "proof", internalType: "bytes32[]", type: "bytes32[]" },
        ],
      },
      {
        name: "cosignature",
        internalType: "struct Cosignature",
        type: "tuple",
        components: [
          { name: "signer", internalType: "address", type: "address" },
          { name: "taker", internalType: "address", type: "address" },
          { name: "expiration", internalType: "uint256", type: "uint256" },
          { name: "v", internalType: "uint8", type: "uint8" },
          { name: "r", internalType: "bytes32", type: "bytes32" },
          { name: "s", internalType: "bytes32", type: "bytes32" },
        ],
      },
      {
        name: "feeOnTop",
        internalType: "struct FeeOnTop",
        type: "tuple",
        components: [
          { name: "recipient", internalType: "address", type: "address" },
          { name: "amount", internalType: "uint256", type: "uint256" },
        ],
      },
    ],
    name: "encodeAcceptOfferCalldata",
    outputs: [{ name: "", internalType: "bytes", type: "bytes" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [
      { name: "", internalType: "address", type: "address" },
      { name: "tokenAddress", internalType: "address", type: "address" },
      { name: "account", internalType: "address", type: "address" },
    ],
    name: "encodeAddBannedAccountForCollectionCalldata",
    outputs: [{ name: "", internalType: "bytes", type: "bytes" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [
      { name: "", internalType: "address", type: "address" },
      { name: "tokenAddress", internalType: "address", type: "address" },
      { name: "channel", internalType: "address", type: "address" },
    ],
    name: "encodeAddTrustedChannelForCollectionCalldata",
    outputs: [{ name: "", internalType: "bytes", type: "bytes" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [
      {
        name: "paymentProcessorAddress",
        internalType: "address",
        type: "address",
      },
      {
        name: "isCollectionLevelOfferArray",
        internalType: "bool[]",
        type: "bool[]",
      },
      {
        name: "saleDetailsArray",
        internalType: "struct Order[]",
        type: "tuple[]",
        components: [
          {
            name: "protocol",
            internalType: "enum OrderProtocols",
            type: "uint8",
          },
          { name: "maker", internalType: "address", type: "address" },
          { name: "beneficiary", internalType: "address", type: "address" },
          { name: "marketplace", internalType: "address", type: "address" },
          {
            name: "fallbackRoyaltyRecipient",
            internalType: "address",
            type: "address",
          },
          { name: "paymentMethod", internalType: "address", type: "address" },
          { name: "tokenAddress", internalType: "address", type: "address" },
          { name: "tokenId", internalType: "uint256", type: "uint256" },
          { name: "amount", internalType: "uint248", type: "uint248" },
          { name: "itemPrice", internalType: "uint256", type: "uint256" },
          { name: "nonce", internalType: "uint256", type: "uint256" },
          { name: "expiration", internalType: "uint256", type: "uint256" },
          {
            name: "marketplaceFeeNumerator",
            internalType: "uint256",
            type: "uint256",
          },
          {
            name: "maxRoyaltyFeeNumerator",
            internalType: "uint256",
            type: "uint256",
          },
          {
            name: "requestedFillAmount",
            internalType: "uint248",
            type: "uint248",
          },
          {
            name: "minimumFillAmount",
            internalType: "uint248",
            type: "uint248",
          },
        ],
      },
      {
        name: "signatures",
        internalType: "struct SignatureECDSA[]",
        type: "tuple[]",
        components: [
          { name: "v", internalType: "uint8", type: "uint8" },
          { name: "r", internalType: "bytes32", type: "bytes32" },
          { name: "s", internalType: "bytes32", type: "bytes32" },
        ],
      },
      {
        name: "tokenSetProofsArray",
        internalType: "struct TokenSetProof[]",
        type: "tuple[]",
        components: [
          { name: "rootHash", internalType: "bytes32", type: "bytes32" },
          { name: "proof", internalType: "bytes32[]", type: "bytes32[]" },
        ],
      },
      {
        name: "cosignaturesArray",
        internalType: "struct Cosignature[]",
        type: "tuple[]",
        components: [
          { name: "signer", internalType: "address", type: "address" },
          { name: "taker", internalType: "address", type: "address" },
          { name: "expiration", internalType: "uint256", type: "uint256" },
          { name: "v", internalType: "uint8", type: "uint8" },
          { name: "r", internalType: "bytes32", type: "bytes32" },
          { name: "s", internalType: "bytes32", type: "bytes32" },
        ],
      },
      {
        name: "feesOnTopArray",
        internalType: "struct FeeOnTop[]",
        type: "tuple[]",
        components: [
          { name: "recipient", internalType: "address", type: "address" },
          { name: "amount", internalType: "uint256", type: "uint256" },
        ],
      },
    ],
    name: "encodeBulkAcceptOffersCalldata",
    outputs: [{ name: "", internalType: "bytes", type: "bytes" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [
      {
        name: "paymentProcessorAddress",
        internalType: "address",
        type: "address",
      },
      {
        name: "saleDetailsArray",
        internalType: "struct Order[]",
        type: "tuple[]",
        components: [
          {
            name: "protocol",
            internalType: "enum OrderProtocols",
            type: "uint8",
          },
          { name: "maker", internalType: "address", type: "address" },
          { name: "beneficiary", internalType: "address", type: "address" },
          { name: "marketplace", internalType: "address", type: "address" },
          {
            name: "fallbackRoyaltyRecipient",
            internalType: "address",
            type: "address",
          },
          { name: "paymentMethod", internalType: "address", type: "address" },
          { name: "tokenAddress", internalType: "address", type: "address" },
          { name: "tokenId", internalType: "uint256", type: "uint256" },
          { name: "amount", internalType: "uint248", type: "uint248" },
          { name: "itemPrice", internalType: "uint256", type: "uint256" },
          { name: "nonce", internalType: "uint256", type: "uint256" },
          { name: "expiration", internalType: "uint256", type: "uint256" },
          {
            name: "marketplaceFeeNumerator",
            internalType: "uint256",
            type: "uint256",
          },
          {
            name: "maxRoyaltyFeeNumerator",
            internalType: "uint256",
            type: "uint256",
          },
          {
            name: "requestedFillAmount",
            internalType: "uint248",
            type: "uint248",
          },
          {
            name: "minimumFillAmount",
            internalType: "uint248",
            type: "uint248",
          },
        ],
      },
      {
        name: "signatures",
        internalType: "struct SignatureECDSA[]",
        type: "tuple[]",
        components: [
          { name: "v", internalType: "uint8", type: "uint8" },
          { name: "r", internalType: "bytes32", type: "bytes32" },
          { name: "s", internalType: "bytes32", type: "bytes32" },
        ],
      },
      {
        name: "cosignatures",
        internalType: "struct Cosignature[]",
        type: "tuple[]",
        components: [
          { name: "signer", internalType: "address", type: "address" },
          { name: "taker", internalType: "address", type: "address" },
          { name: "expiration", internalType: "uint256", type: "uint256" },
          { name: "v", internalType: "uint8", type: "uint8" },
          { name: "r", internalType: "bytes32", type: "bytes32" },
          { name: "s", internalType: "bytes32", type: "bytes32" },
        ],
      },
      {
        name: "feesOnTop",
        internalType: "struct FeeOnTop[]",
        type: "tuple[]",
        components: [
          { name: "recipient", internalType: "address", type: "address" },
          { name: "amount", internalType: "uint256", type: "uint256" },
        ],
      },
    ],
    name: "encodeBulkBuyListingsCalldata",
    outputs: [{ name: "", internalType: "bytes", type: "bytes" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [
      {
        name: "paymentProcessorAddress",
        internalType: "address",
        type: "address",
      },
      {
        name: "saleDetails",
        internalType: "struct Order",
        type: "tuple",
        components: [
          {
            name: "protocol",
            internalType: "enum OrderProtocols",
            type: "uint8",
          },
          { name: "maker", internalType: "address", type: "address" },
          { name: "beneficiary", internalType: "address", type: "address" },
          { name: "marketplace", internalType: "address", type: "address" },
          {
            name: "fallbackRoyaltyRecipient",
            internalType: "address",
            type: "address",
          },
          { name: "paymentMethod", internalType: "address", type: "address" },
          { name: "tokenAddress", internalType: "address", type: "address" },
          { name: "tokenId", internalType: "uint256", type: "uint256" },
          { name: "amount", internalType: "uint248", type: "uint248" },
          { name: "itemPrice", internalType: "uint256", type: "uint256" },
          { name: "nonce", internalType: "uint256", type: "uint256" },
          { name: "expiration", internalType: "uint256", type: "uint256" },
          {
            name: "marketplaceFeeNumerator",
            internalType: "uint256",
            type: "uint256",
          },
          {
            name: "maxRoyaltyFeeNumerator",
            internalType: "uint256",
            type: "uint256",
          },
          {
            name: "requestedFillAmount",
            internalType: "uint248",
            type: "uint248",
          },
          {
            name: "minimumFillAmount",
            internalType: "uint248",
            type: "uint248",
          },
        ],
      },
      {
        name: "signature",
        internalType: "struct SignatureECDSA",
        type: "tuple",
        components: [
          { name: "v", internalType: "uint8", type: "uint8" },
          { name: "r", internalType: "bytes32", type: "bytes32" },
          { name: "s", internalType: "bytes32", type: "bytes32" },
        ],
      },
      {
        name: "cosignature",
        internalType: "struct Cosignature",
        type: "tuple",
        components: [
          { name: "signer", internalType: "address", type: "address" },
          { name: "taker", internalType: "address", type: "address" },
          { name: "expiration", internalType: "uint256", type: "uint256" },
          { name: "v", internalType: "uint8", type: "uint8" },
          { name: "r", internalType: "bytes32", type: "bytes32" },
          { name: "s", internalType: "bytes32", type: "bytes32" },
        ],
      },
      {
        name: "feeOnTop",
        internalType: "struct FeeOnTop",
        type: "tuple",
        components: [
          { name: "recipient", internalType: "address", type: "address" },
          { name: "amount", internalType: "uint256", type: "uint256" },
        ],
      },
    ],
    name: "encodeBuyListingCalldata",
    outputs: [{ name: "", internalType: "bytes", type: "bytes" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [
      { name: "", internalType: "address", type: "address" },
      { name: "whitelistName", internalType: "string", type: "string" },
    ],
    name: "encodeCreatePaymentMethodWhitelistCalldata",
    outputs: [{ name: "", internalType: "bytes", type: "bytes" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [
      { name: "", internalType: "address", type: "address" },
      { name: "cosigner", internalType: "address", type: "address" },
      {
        name: "signature",
        internalType: "struct SignatureECDSA",
        type: "tuple",
        components: [
          { name: "v", internalType: "uint8", type: "uint8" },
          { name: "r", internalType: "bytes32", type: "bytes32" },
          { name: "s", internalType: "bytes32", type: "bytes32" },
        ],
      },
    ],
    name: "encodeDestroyCosignerCalldata",
    outputs: [{ name: "", internalType: "bytes", type: "bytes" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [
      { name: "", internalType: "address", type: "address" },
      { name: "id", internalType: "uint32", type: "uint32" },
      { name: "newOwner", internalType: "address", type: "address" },
    ],
    name: "encodeReassignOwnershipOfPaymentMethodWhitelistCalldata",
    outputs: [{ name: "", internalType: "bytes", type: "bytes" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [
      { name: "", internalType: "address", type: "address" },
      { name: "tokenAddress", internalType: "address", type: "address" },
      { name: "account", internalType: "address", type: "address" },
    ],
    name: "encodeRemoveBannedAccountForCollectionCalldata",
    outputs: [{ name: "", internalType: "bytes", type: "bytes" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [
      { name: "", internalType: "address", type: "address" },
      { name: "tokenAddress", internalType: "address", type: "address" },
      { name: "channel", internalType: "address", type: "address" },
    ],
    name: "encodeRemoveTrustedChannelForCollectionCalldata",
    outputs: [{ name: "", internalType: "bytes", type: "bytes" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [
      { name: "", internalType: "address", type: "address" },
      { name: "id", internalType: "uint32", type: "uint32" },
    ],
    name: "encodeRenounceOwnershipOfPaymentMethodWhitelistCalldata",
    outputs: [{ name: "", internalType: "bytes", type: "bytes" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [
      { name: "", internalType: "address", type: "address" },
      { name: "digest", internalType: "bytes32", type: "bytes32" },
    ],
    name: "encodeRevokeOrderDigestCalldata",
    outputs: [{ name: "", internalType: "bytes", type: "bytes" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [
      { name: "", internalType: "address", type: "address" },
      { name: "nonce", internalType: "uint256", type: "uint256" },
    ],
    name: "encodeRevokeSingleNonceCalldata",
    outputs: [{ name: "", internalType: "bytes", type: "bytes" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [
      { name: "", internalType: "address", type: "address" },
      { name: "tokenAddress", internalType: "address", type: "address" },
      {
        name: "paymentSettings",
        internalType: "enum PaymentSettings",
        type: "uint8",
      },
      {
        name: "paymentMethodWhitelistId",
        internalType: "uint32",
        type: "uint32",
      },
      {
        name: "constrainedPricingPaymentMethod",
        internalType: "address",
        type: "address",
      },
      {
        name: "royaltyBackfillNumerator",
        internalType: "uint16",
        type: "uint16",
      },
      {
        name: "royaltyBackfillReceiver",
        internalType: "address",
        type: "address",
      },
      {
        name: "royaltyBountyNumerator",
        internalType: "uint16",
        type: "uint16",
      },
      {
        name: "exclusiveBountyReceiver",
        internalType: "address",
        type: "address",
      },
      {
        name: "blockTradesFromUntrustedChannels",
        internalType: "bool",
        type: "bool",
      },
      { name: "blockBannedAccounts", internalType: "bool", type: "bool" },
    ],
    name: "encodeSetCollectionPaymentSettingsCalldata",
    outputs: [{ name: "", internalType: "bytes", type: "bytes" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [
      { name: "", internalType: "address", type: "address" },
      { name: "tokenAddress", internalType: "address", type: "address" },
      {
        name: "pricingBounds",
        internalType: "struct PricingBounds",
        type: "tuple",
        components: [
          { name: "isSet", internalType: "bool", type: "bool" },
          { name: "floorPrice", internalType: "uint120", type: "uint120" },
          { name: "ceilingPrice", internalType: "uint120", type: "uint120" },
        ],
      },
    ],
    name: "encodeSetCollectionPricingBoundsCalldata",
    outputs: [{ name: "", internalType: "bytes", type: "bytes" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [
      { name: "", internalType: "address", type: "address" },
      { name: "tokenAddress", internalType: "address", type: "address" },
      { name: "tokenIds", internalType: "uint256[]", type: "uint256[]" },
      {
        name: "pricingBounds",
        internalType: "struct PricingBounds[]",
        type: "tuple[]",
        components: [
          { name: "isSet", internalType: "bool", type: "bool" },
          { name: "floorPrice", internalType: "uint120", type: "uint120" },
          { name: "ceilingPrice", internalType: "uint120", type: "uint120" },
        ],
      },
    ],
    name: "encodeSetTokenPricingBoundsCalldata",
    outputs: [{ name: "", internalType: "bytes", type: "bytes" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [
      {
        name: "paymentProcessorAddress",
        internalType: "address",
        type: "address",
      },
      {
        name: "feeOnTop",
        internalType: "struct FeeOnTop",
        type: "tuple",
        components: [
          { name: "recipient", internalType: "address", type: "address" },
          { name: "amount", internalType: "uint256", type: "uint256" },
        ],
      },
      {
        name: "sweepOrder",
        internalType: "struct SweepOrder",
        type: "tuple",
        components: [
          {
            name: "protocol",
            internalType: "enum OrderProtocols",
            type: "uint8",
          },
          { name: "tokenAddress", internalType: "address", type: "address" },
          { name: "paymentMethod", internalType: "address", type: "address" },
          { name: "beneficiary", internalType: "address", type: "address" },
        ],
      },
      {
        name: "items",
        internalType: "struct SweepItem[]",
        type: "tuple[]",
        components: [
          { name: "maker", internalType: "address", type: "address" },
          { name: "marketplace", internalType: "address", type: "address" },
          {
            name: "fallbackRoyaltyRecipient",
            internalType: "address",
            type: "address",
          },
          { name: "tokenId", internalType: "uint256", type: "uint256" },
          { name: "amount", internalType: "uint248", type: "uint248" },
          { name: "itemPrice", internalType: "uint256", type: "uint256" },
          { name: "nonce", internalType: "uint256", type: "uint256" },
          { name: "expiration", internalType: "uint256", type: "uint256" },
          {
            name: "marketplaceFeeNumerator",
            internalType: "uint256",
            type: "uint256",
          },
          {
            name: "maxRoyaltyFeeNumerator",
            internalType: "uint256",
            type: "uint256",
          },
        ],
      },
      {
        name: "signatures",
        internalType: "struct SignatureECDSA[]",
        type: "tuple[]",
        components: [
          { name: "v", internalType: "uint8", type: "uint8" },
          { name: "r", internalType: "bytes32", type: "bytes32" },
          { name: "s", internalType: "bytes32", type: "bytes32" },
        ],
      },
      {
        name: "cosignatures",
        internalType: "struct Cosignature[]",
        type: "tuple[]",
        components: [
          { name: "signer", internalType: "address", type: "address" },
          { name: "taker", internalType: "address", type: "address" },
          { name: "expiration", internalType: "uint256", type: "uint256" },
          { name: "v", internalType: "uint8", type: "uint8" },
          { name: "r", internalType: "bytes32", type: "bytes32" },
          { name: "s", internalType: "bytes32", type: "bytes32" },
        ],
      },
    ],
    name: "encodeSweepCollectionCalldata",
    outputs: [{ name: "", internalType: "bytes", type: "bytes" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [
      { name: "", internalType: "address", type: "address" },
      {
        name: "paymentMethodWhitelistId",
        internalType: "uint32",
        type: "uint32",
      },
      { name: "paymentMethod", internalType: "address", type: "address" },
    ],
    name: "encodeUnwhitelistPaymentMethodCalldata",
    outputs: [{ name: "", internalType: "bytes", type: "bytes" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [
      { name: "", internalType: "address", type: "address" },
      {
        name: "paymentMethodWhitelistId",
        internalType: "uint32",
        type: "uint32",
      },
      { name: "paymentMethod", internalType: "address", type: "address" },
    ],
    name: "encodeWhitelistPaymentMethodCalldata",
    outputs: [{ name: "", internalType: "bytes", type: "bytes" }],
    stateMutability: "view",
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// TrustedForwarder
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const trustedForwarderAbi = [
  { type: "constructor", inputs: [], stateMutability: "nonpayable" },
  {
    type: "function",
    inputs: [],
    name: "APP_SIGNER_TYPEHASH",
    outputs: [{ name: "", internalType: "bytes32", type: "bytes32" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [
      { name: "owner", internalType: "address", type: "address" },
      { name: "appSigner", internalType: "address", type: "address" },
    ],
    name: "__TrustedForwarder_init",
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    inputs: [],
    name: "deactivateSigner",
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    inputs: [],
    name: "domainSeparatorV4",
    outputs: [{ name: "", internalType: "bytes32", type: "bytes32" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [],
    name: "eip712Domain",
    outputs: [
      { name: "fields", internalType: "bytes1", type: "bytes1" },
      { name: "name", internalType: "string", type: "string" },
      { name: "version", internalType: "string", type: "string" },
      { name: "chainId", internalType: "uint256", type: "uint256" },
      { name: "verifyingContract", internalType: "address", type: "address" },
      { name: "salt", internalType: "bytes32", type: "bytes32" },
      { name: "extensions", internalType: "uint256[]", type: "uint256[]" },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [
      { name: "target", internalType: "address", type: "address" },
      { name: "message", internalType: "bytes", type: "bytes" },
    ],
    name: "forwardCall",
    outputs: [{ name: "returnData", internalType: "bytes", type: "bytes" }],
    stateMutability: "payable",
  },
  {
    type: "function",
    inputs: [
      { name: "target", internalType: "address", type: "address" },
      { name: "message", internalType: "bytes", type: "bytes" },
      {
        name: "signature",
        internalType: "struct TrustedForwarder.SignatureECDSA",
        type: "tuple",
        components: [
          { name: "v", internalType: "uint8", type: "uint8" },
          { name: "r", internalType: "bytes32", type: "bytes32" },
          { name: "s", internalType: "bytes32", type: "bytes32" },
        ],
      },
    ],
    name: "forwardCall",
    outputs: [{ name: "returnData", internalType: "bytes", type: "bytes" }],
    stateMutability: "payable",
  },
  {
    type: "function",
    inputs: [],
    name: "owner",
    outputs: [{ name: "", internalType: "address", type: "address" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    inputs: [],
    name: "signer",
    outputs: [{ name: "", internalType: "address", type: "address" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [{ name: "newOwner", internalType: "address", type: "address" }],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    inputs: [{ name: "signer_", internalType: "address", type: "address" }],
    name: "updateSigner",
    outputs: [],
    stateMutability: "nonpayable",
  },
  { type: "event", anonymous: false, inputs: [], name: "EIP712DomainChanged" },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "version", internalType: "uint8", type: "uint8", indexed: false },
    ],
    name: "Initialized",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "previousOwner",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "newOwner",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "OwnershipTransferred",
  },
  { type: "error", inputs: [], name: "InvalidShortString" },
  {
    type: "error",
    inputs: [{ name: "str", internalType: "string", type: "string" }],
    name: "StringTooLong",
  },
  {
    type: "error",
    inputs: [],
    name: "TrustedForwarder__CannotSetAppSignerToZeroAddress",
  },
  {
    type: "error",
    inputs: [],
    name: "TrustedForwarder__CannotSetOwnerToZeroAddress",
  },
  {
    type: "error",
    inputs: [],
    name: "TrustedForwarder__CannotUseWithoutSignature",
  },
  { type: "error", inputs: [], name: "TrustedForwarder__InvalidSignature" },
  { type: "error", inputs: [], name: "TrustedForwarder__SignerNotAuthorized" },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// UniswapV3Router
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const uniswapV3RouterAbi = [
  {
    type: "constructor",
    inputs: [
      { name: "_factory", internalType: "address", type: "address" },
      { name: "_WETH9", internalType: "address", type: "address" },
    ],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    inputs: [],
    name: "WETH9",
    outputs: [{ name: "", internalType: "address", type: "address" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [
      {
        name: "params",
        internalType: "struct ISwapRouter.ExactInputParams",
        type: "tuple",
        components: [
          { name: "path", internalType: "bytes", type: "bytes" },
          { name: "recipient", internalType: "address", type: "address" },
          { name: "deadline", internalType: "uint256", type: "uint256" },
          { name: "amountIn", internalType: "uint256", type: "uint256" },
          {
            name: "amountOutMinimum",
            internalType: "uint256",
            type: "uint256",
          },
        ],
      },
    ],
    name: "exactInput",
    outputs: [{ name: "amountOut", internalType: "uint256", type: "uint256" }],
    stateMutability: "payable",
  },
  {
    type: "function",
    inputs: [
      {
        name: "params",
        internalType: "struct ISwapRouter.ExactInputSingleParams",
        type: "tuple",
        components: [
          { name: "tokenIn", internalType: "address", type: "address" },
          { name: "tokenOut", internalType: "address", type: "address" },
          { name: "fee", internalType: "uint24", type: "uint24" },
          { name: "recipient", internalType: "address", type: "address" },
          { name: "deadline", internalType: "uint256", type: "uint256" },
          { name: "amountIn", internalType: "uint256", type: "uint256" },
          {
            name: "amountOutMinimum",
            internalType: "uint256",
            type: "uint256",
          },
          {
            name: "sqrtPriceLimitX96",
            internalType: "uint160",
            type: "uint160",
          },
        ],
      },
    ],
    name: "exactInputSingle",
    outputs: [{ name: "amountOut", internalType: "uint256", type: "uint256" }],
    stateMutability: "payable",
  },
  {
    type: "function",
    inputs: [
      {
        name: "params",
        internalType: "struct ISwapRouter.ExactOutputParams",
        type: "tuple",
        components: [
          { name: "path", internalType: "bytes", type: "bytes" },
          { name: "recipient", internalType: "address", type: "address" },
          { name: "deadline", internalType: "uint256", type: "uint256" },
          { name: "amountOut", internalType: "uint256", type: "uint256" },
          { name: "amountInMaximum", internalType: "uint256", type: "uint256" },
        ],
      },
    ],
    name: "exactOutput",
    outputs: [{ name: "amountIn", internalType: "uint256", type: "uint256" }],
    stateMutability: "payable",
  },
  {
    type: "function",
    inputs: [
      {
        name: "params",
        internalType: "struct ISwapRouter.ExactOutputSingleParams",
        type: "tuple",
        components: [
          { name: "tokenIn", internalType: "address", type: "address" },
          { name: "tokenOut", internalType: "address", type: "address" },
          { name: "fee", internalType: "uint24", type: "uint24" },
          { name: "recipient", internalType: "address", type: "address" },
          { name: "deadline", internalType: "uint256", type: "uint256" },
          { name: "amountOut", internalType: "uint256", type: "uint256" },
          { name: "amountInMaximum", internalType: "uint256", type: "uint256" },
          {
            name: "sqrtPriceLimitX96",
            internalType: "uint160",
            type: "uint160",
          },
        ],
      },
    ],
    name: "exactOutputSingle",
    outputs: [{ name: "amountIn", internalType: "uint256", type: "uint256" }],
    stateMutability: "payable",
  },
  {
    type: "function",
    inputs: [],
    name: "factory",
    outputs: [{ name: "", internalType: "address", type: "address" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [{ name: "data", internalType: "bytes[]", type: "bytes[]" }],
    name: "multicall",
    outputs: [{ name: "results", internalType: "bytes[]", type: "bytes[]" }],
    stateMutability: "payable",
  },
  {
    type: "function",
    inputs: [],
    name: "refundETH",
    outputs: [],
    stateMutability: "payable",
  },
  {
    type: "function",
    inputs: [
      { name: "token", internalType: "address", type: "address" },
      { name: "value", internalType: "uint256", type: "uint256" },
      { name: "deadline", internalType: "uint256", type: "uint256" },
      { name: "v", internalType: "uint8", type: "uint8" },
      { name: "r", internalType: "bytes32", type: "bytes32" },
      { name: "s", internalType: "bytes32", type: "bytes32" },
    ],
    name: "selfPermit",
    outputs: [],
    stateMutability: "payable",
  },
  {
    type: "function",
    inputs: [
      { name: "token", internalType: "address", type: "address" },
      { name: "nonce", internalType: "uint256", type: "uint256" },
      { name: "expiry", internalType: "uint256", type: "uint256" },
      { name: "v", internalType: "uint8", type: "uint8" },
      { name: "r", internalType: "bytes32", type: "bytes32" },
      { name: "s", internalType: "bytes32", type: "bytes32" },
    ],
    name: "selfPermitAllowed",
    outputs: [],
    stateMutability: "payable",
  },
  {
    type: "function",
    inputs: [
      { name: "token", internalType: "address", type: "address" },
      { name: "nonce", internalType: "uint256", type: "uint256" },
      { name: "expiry", internalType: "uint256", type: "uint256" },
      { name: "v", internalType: "uint8", type: "uint8" },
      { name: "r", internalType: "bytes32", type: "bytes32" },
      { name: "s", internalType: "bytes32", type: "bytes32" },
    ],
    name: "selfPermitAllowedIfNecessary",
    outputs: [],
    stateMutability: "payable",
  },
  {
    type: "function",
    inputs: [
      { name: "token", internalType: "address", type: "address" },
      { name: "value", internalType: "uint256", type: "uint256" },
      { name: "deadline", internalType: "uint256", type: "uint256" },
      { name: "v", internalType: "uint8", type: "uint8" },
      { name: "r", internalType: "bytes32", type: "bytes32" },
      { name: "s", internalType: "bytes32", type: "bytes32" },
    ],
    name: "selfPermitIfNecessary",
    outputs: [],
    stateMutability: "payable",
  },
  {
    type: "function",
    inputs: [
      { name: "token", internalType: "address", type: "address" },
      { name: "amountMinimum", internalType: "uint256", type: "uint256" },
      { name: "recipient", internalType: "address", type: "address" },
    ],
    name: "sweepToken",
    outputs: [],
    stateMutability: "payable",
  },
  {
    type: "function",
    inputs: [
      { name: "token", internalType: "address", type: "address" },
      { name: "amountMinimum", internalType: "uint256", type: "uint256" },
      { name: "recipient", internalType: "address", type: "address" },
      { name: "feeBips", internalType: "uint256", type: "uint256" },
      { name: "feeRecipient", internalType: "address", type: "address" },
    ],
    name: "sweepTokenWithFee",
    outputs: [],
    stateMutability: "payable",
  },
  {
    type: "function",
    inputs: [
      { name: "amount0Delta", internalType: "int256", type: "int256" },
      { name: "amount1Delta", internalType: "int256", type: "int256" },
      { name: "_data", internalType: "bytes", type: "bytes" },
    ],
    name: "uniswapV3SwapCallback",
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    inputs: [
      { name: "amountMinimum", internalType: "uint256", type: "uint256" },
      { name: "recipient", internalType: "address", type: "address" },
    ],
    name: "unwrapWETH9",
    outputs: [],
    stateMutability: "payable",
  },
  {
    type: "function",
    inputs: [
      { name: "amountMinimum", internalType: "uint256", type: "uint256" },
      { name: "recipient", internalType: "address", type: "address" },
      { name: "feeBips", internalType: "uint256", type: "uint256" },
      { name: "feeRecipient", internalType: "address", type: "address" },
    ],
    name: "unwrapWETH9WithFee",
    outputs: [],
    stateMutability: "payable",
  },
  { type: "receive", stateMutability: "payable" },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// WERC20
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const werc20Abi = [
  {
    constant: true,
    payable: false,
    type: "function",
    inputs: [],
    name: "name",
    outputs: [{ name: "", type: "string" }],
    stateMutability: "view",
  },
  {
    constant: false,
    payable: false,
    type: "function",
    inputs: [
      { name: "guy", type: "address" },
      { name: "wad", type: "uint256" },
    ],
    name: "approve",
    outputs: [{ name: "", type: "bool" }],
    stateMutability: "nonpayable",
  },
  {
    constant: true,
    payable: false,
    type: "function",
    inputs: [],
    name: "totalSupply",
    outputs: [{ name: "", type: "uint256" }],
    stateMutability: "view",
  },
  {
    constant: false,
    payable: false,
    type: "function",
    inputs: [
      { name: "src", type: "address" },
      { name: "dst", type: "address" },
      { name: "wad", type: "uint256" },
    ],
    name: "transferFrom",
    outputs: [{ name: "", type: "bool" }],
    stateMutability: "nonpayable",
  },
  {
    constant: false,
    payable: false,
    type: "function",
    inputs: [{ name: "wad", type: "uint256" }],
    name: "withdraw",
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    constant: true,
    payable: false,
    type: "function",
    inputs: [],
    name: "decimals",
    outputs: [{ name: "", type: "uint8" }],
    stateMutability: "view",
  },
  {
    constant: true,
    payable: false,
    type: "function",
    inputs: [{ name: "", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "", type: "uint256" }],
    stateMutability: "view",
  },
  {
    constant: true,
    payable: false,
    type: "function",
    inputs: [],
    name: "symbol",
    outputs: [{ name: "", type: "string" }],
    stateMutability: "view",
  },
  {
    constant: false,
    payable: false,
    type: "function",
    inputs: [
      { name: "dst", type: "address" },
      { name: "wad", type: "uint256" },
    ],
    name: "transfer",
    outputs: [{ name: "", type: "bool" }],
    stateMutability: "nonpayable",
  },
  {
    constant: false,
    payable: true,
    type: "function",
    inputs: [],
    name: "deposit",
    outputs: [],
    stateMutability: "payable",
  },
  {
    constant: true,
    payable: false,
    type: "function",
    inputs: [
      { name: "", type: "address" },
      { name: "", type: "address" },
    ],
    name: "allowance",
    outputs: [{ name: "", type: "uint256" }],
    stateMutability: "view",
  },
  { payable: true, type: "fallback", stateMutability: "payable" },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "src", type: "address", indexed: true },
      { name: "guy", type: "address", indexed: true },
      { name: "wad", type: "uint256", indexed: false },
    ],
    name: "Approval",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "src", type: "address", indexed: true },
      { name: "dst", type: "address", indexed: true },
      { name: "wad", type: "uint256", indexed: false },
    ],
    name: "Transfer",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "dst", type: "address", indexed: true },
      { name: "wad", type: "uint256", indexed: false },
    ],
    name: "Deposit",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "src", type: "address", indexed: true },
      { name: "wad", type: "uint256", indexed: false },
    ],
    name: "Withdrawal",
  },
] as const;
