import { Address, erc721Abi, Hex, PublicClient } from 'viem';
import { addr, bigInt, int, zeroAddress } from '../api/utils';
import {
  AcceptOfferRequest,
  AcceptOffersRequest,
  BuyCrossChainListingsRequest,
  BuyListingRequest,
  BuyListingsRequest,
  CancelOrderRequest,
  ConvertCurrencyRequest,
  CreateAttributeBidRequest,
  CreateBidRequest,
  CreateListingRequest,
  OrderbookService,
  QueryCollectionActivityRequest,
  QueryCollectionTokensRequest,
  QueryCollectionTransfersRequest,
  QueryCrossChainCollectionsRequest,
  QueryCrossChainTokensRequest,
  QueryOrdersRequest,
  QueryWalletActivityRequest,
  QueryWalletOrdersRequest,
  QueryWalletTokensRequest,
  StreamActivityRequest,
  SweepCollectionRequest,
  UpdateBidRequest,
  UpdateListingRequest,
} from '../api/orderbook_api/v1/orderbook.pb';
import {
  erc20Abi,
  paymentProcessorAbi,
  uniswapV3RouterAbi,
  werc20Abi,
} from '../api/contracts/generated';
import {
  Activity,
  EncodedPaymentProcessorWrite,
  SignatureKind,
  Step,
  StepItem,
  StepItemStatus,
  Steps,
  StepSwapCurrency,
  StepUnwrapCurrency,
  StepWrapCurrency,
} from '../api/orderbook_api/v1/types.pb';
import { logDebug, logError, logInfo } from './logger';
import { trackEvent } from './analytics/events';
import { GetWalletClientReturnType } from 'wagmi/actions';
import { InitReq } from '@api';
import { AnalyticsEventName } from '@utils/analytics/types';
import { signal, Signal } from '@preact/signals-react';
import { sMarketplace } from '@signals/marketplace';

const authTokenKey = 'orderbook-authToken';

export class Wallet {
  chainId?: number;
  client?: GetWalletClientReturnType;
  address?: Address;
  publicClient?: PublicClient;
  sToken: Signal<string | undefined>;

  constructor(client?: GetWalletClientReturnType, publicClient?: PublicClient) {
    this.client = client;
    this.chainId = client?.chain.id as number;
    this.address = client?.account?.address;
    this.publicClient = publicClient;
    this.sToken = signal<string | undefined>(undefined);
  }

  public requestOpts() {
    return getRequestOpts();
  }

  public async switchChain(chainId: number) {
    if (this.client) {
      await this.client.switchChain({ id: chainId });
    }
    this.chainId = this.client?.chain.id as number;
  }

  public signedIn() {
    return this.isSignedIn();
  }

  public isSignedIn() {
    if (typeof window === 'undefined') {
      return false;
    }
    // check if authToken exists in local storage
    const authToken = localStorage.getItem(authTokenKey);
    if (!authToken) {
      return false;
    }
    // check if authToken is expired
    const decodedToken = jwtDecode(authToken);
    if (!decodedToken) {
      return false;
    }

    if (decodedToken.address !== this.address) {
      localStorage.removeItem(authTokenKey);
      this.sToken.value = undefined;
      return false;
    }

    const currentTime = Date.now() / 1000;
    if (decodedToken.exp <= currentTime) {
      localStorage.removeItem(authTokenKey);
      this.sToken.value = undefined;
      return false;
    } else {
      return true;
    }
  }

  public async signIn() {
    if (this.isSignedIn()) {
      logInfo('Already signed in');
      return;
    }
    if (!this.client) {
      logInfo('No client found');
      return;
    }

    if (typeof window === 'undefined') {
      logInfo('No window found');
      return;
    }

    trackEvent('step_message_signing', { walletAddress: this.address });
    return await OrderbookService.GetAuthNonce(
      {
        publicAddress: this.address,
      },
      this.requestOpts(),
    )
      .then((response) => {
        const nonce = response.nonce;
        return this.client?.signMessage({
          account: this.address as Address,
          message: nonce as Hex,
        });
      })
      .then((signature) => {
        return OrderbookService.Auth(
          {
            publicAddress: this.address,
            signature: signature,
          },
          this.requestOpts(),
        );
      })
      .then((response) => {
        logDebug('Auth successful');
        trackEvent('step_message_signed', { walletAddress: this.address });
        localStorage.setItem(authTokenKey, response.token as string);
        this.sToken.value = response.token as string;
      })
      .catch((error) => {
        logError('Error signing message', error);
        trackEvent('step_message_rejected', { walletAddress: this.address });
      });
  }

  public signOut() {
    if (typeof window === 'undefined') return;
    localStorage.removeItem(authTokenKey);
    this.sToken.value = undefined;
  }

  public async getCollection(chainId: number, collection: string) {
    return await OrderbookService.GetCollection(
      {
        chainId: String(chainId),
        collection: collection,
      },
      this.requestOpts(),
    );
  }

  public async getToken(chainId: number, collection: string, tokenId: string) {
    return await OrderbookService.GetCollectionToken(
      {
        chainId: String(chainId),
        collection: collection,
        tokenId: tokenId,
      },
      this.requestOpts(),
    );
  }

  public async getTokens(refs: string[]) {
    return await OrderbookService.GetTokensByRef(
      {
        refs,
      },
      this.requestOpts(),
    );
  }

  public async getOrder(chainId: number, collection: string, orderId: string) {
    return await OrderbookService.GetOrder(
      {
        chainId: String(chainId),
        collection: collection,
        orderId: orderId,
      },
      this.requestOpts(),
    );
  }

  public async refreshToken(
    chainId: number,
    collection: string,
    tokenId: string,
  ) {
    return await OrderbookService.RefreshToken(
      {
        chainId: String(chainId),
        collection: collection,
        tokenId: tokenId,
      },
      this.requestOpts(),
    );
  }

  public async streamActivities(
    request: StreamActivityRequest,
    onActivity: (activity: Activity) => void,
  ) {
    return await OrderbookService.StreamActivity(
      request,
      onActivity,
      this.requestOpts(),
    );
  }

  public async queryTokens(request: QueryCrossChainTokensRequest) {
    return await OrderbookService.QueryCrossChainTokens(
      request,
      this.requestOpts(),
    );
  }

  public async queryOrders(request: QueryOrdersRequest) {
    return await OrderbookService.QueryOrders(request, this.requestOpts());
  }

  public async queryCollections(request: QueryCrossChainCollectionsRequest) {
    return await OrderbookService.QueryCrossChainCollections(
      request,
      this.requestOpts(),
    );
  }

  public async queryCollectionActivity(
    request: QueryCollectionActivityRequest,
  ) {
    return await OrderbookService.QueryCollectionActivity(
      request,
      this.requestOpts(),
    );
  }

  public async queryCollectionTokens(request: QueryCollectionTokensRequest) {
    return await OrderbookService.QueryCollectionTokens(
      request,
      this.requestOpts(),
    );
  }

  public async queryCollectionOrders(request: QueryOrdersRequest) {
    return await OrderbookService.QueryOrders(request, this.requestOpts());
  }

  public async queryCollectionTransfers(
    request: QueryCollectionTransfersRequest,
  ) {
    return await OrderbookService.QueryCollectionTransfers(
      request,
      this.requestOpts(),
    );
  }

  public async queryWalletActivity(request: QueryWalletActivityRequest) {
    return await OrderbookService.QueryWalletActivity(
      request,
      this.requestOpts(),
    );
  }

  public async queryWalletTokens(request: QueryWalletTokensRequest) {
    return await OrderbookService.QueryWalletTokens(
      request,
      this.requestOpts(),
    );
  }

  public async queryWalletOrders(request: QueryWalletOrdersRequest) {
    return await OrderbookService.QueryWalletOrders(
      request,
      this.requestOpts(),
    );
  }

  public async convertCurrency(request: ConvertCurrencyRequest) {
    return await OrderbookService.ConvertCurrency(request, this.requestOpts());
  }

  public async cancelOrder(
    request: CancelOrderRequest,
    beforeStep?: StepHook,
    afterStep?: StepHook,
    execute: Boolean = true,
  ) {
    const response = await OrderbookService.CancelOrder(
      request,
      this.requestOpts(),
    );
    if (!execute) {
      return { steps: response, txHashes: undefined };
    }
    const txHashes = await this.executeSteps(response, {
      beforeStep,
      afterStep,
    });

    return { steps: response, txHashes };
  }

  public async createListing(props: {
    request: CreateListingRequest;
    beforeStep?: StepHook;
    afterStep?: StepHook;
    execute?: Boolean;
  }) {
    const { request, beforeStep, afterStep, execute = true } = props;
    console.log('[Dev]', 'createListing', request);
    const response = await OrderbookService.CreateListing(
      request,
      this.requestOpts(),
    );

    if (!execute) {
      return { steps: response, txHashes: undefined };
    }

    const txHashes = await this.executeSteps(response, {
      beforeStep,
      afterStep,
    });
    return { steps: response, txHashes };
  }

  public async updateListing(
    request: UpdateListingRequest,
    onProgress?: StepHook,
    execute: Boolean = true,
  ) {
    const response = await OrderbookService.UpdateListing(
      request,
      this.requestOpts(),
    );
    if (!execute) {
      return response;
    }
    await this.executeSteps(response);
    return response;
  }

  public async buyCrossChainListings(props: {
    request: BuyCrossChainListingsRequest;
    onBefore?: StepHook;
    onAfter?: StepHook;
    execute?: Boolean;
  }) {
    const { request, onBefore, onAfter, execute = true } = props;
    const response = await OrderbookService.BuyCrossChainListings(
      request,
      this.requestOpts(),
    );
    if (!execute) {
      return { steps: response, txHashes: undefined };
    }
    const txHashes = await this.executeSteps(response, {
      beforeStep: onBefore,
      afterStep: onAfter,
    });
    return { steps: response, txHashes };
  }

  public async buyListings(props: {
    request: BuyListingsRequest;
    onBefore?: StepHook;
    onAfter?: StepHook;
    execute?: Boolean;
  }) {
    const { request, onBefore, onAfter, execute = true } = props;
    const response = await OrderbookService.BuyListings(
      request,
      this.requestOpts(),
    );
    if (!execute) {
      return { steps: response, txHashes: undefined };
    }
    const txHashes = await this.executeSteps(response, {
      beforeStep: onBefore,
      afterStep: onAfter,
    });
    return { steps: response, txHashes };
  }

  public async buyListing(props: {
    request: BuyListingRequest;
    onBefore?: StepHook;
    onAfter?: StepHook;
    execute?: Boolean;
  }) {
    const { request, onBefore, onAfter, execute = true } = props;
    const response = await OrderbookService.BuyListing(
      request,
      this.requestOpts(),
    );
    if (!execute) {
      return { steps: response, txHashes: undefined };
    }
    let txHashes: string[] | undefined;
    await this.executeSteps(response, {
      beforeStep: onBefore,
      afterStep: onAfter,
    });
    return { steps: response, txHashes };
  }

  public async createBid(props: {
    request: CreateBidRequest;
    beforeStep?: StepHook;
    afterStep?: StepHook;
    execute?: Boolean;
  }) {
    const { request, beforeStep, afterStep, execute = true } = props;
    const response = await OrderbookService.CreateBid(
      request,
      this.requestOpts(),
    );
    if (!execute) {
      return { steps: response, txHashes: undefined };
    }
    const txHashes = await this.executeSteps(response, {
      beforeStep,
      afterStep,
    });
    return { steps: response, txHashes };
  }

  public async createAttributeBid(
    request: CreateAttributeBidRequest,
    beforeStep?: StepHook,
    afterStep?: StepHook,
    execute: Boolean = true,
  ) {
    const response = await OrderbookService.CreateAttributeBid(
      request,
      this.requestOpts(),
    );
    if (!execute) {
      return { steps: response, txHashes: undefined };
    }
    const txHashes = await this.executeSteps(response, {
      beforeStep,
      afterStep,
    });
    return { steps: response, txHashes };
  }

  public async updateBid(request: UpdateBidRequest, execute: Boolean = true) {
    const response = await OrderbookService.UpdateBid(
      request,
      this.requestOpts(),
    );
    if (!execute) {
      return response;
    }
    await this.executeSteps(response);
    return response;
  }

  public async acceptOffer(props: {
    request: AcceptOfferRequest;
    onBefore?: StepHook;
    onAfter?: StepHook;
    execute?: Boolean;
  }) {
    const { request, onBefore, onAfter, execute = true } = props;
    const response = await OrderbookService.AcceptOffer(
      request,
      this.requestOpts(),
    );
    if (!execute) {
      return { steps: response, txHashes: undefined };
    }
    const txHashes = await this.executeSteps(response, {
      beforeStep: onBefore,
      afterStep: onAfter,
    });
    return { steps: response, txHashes };
  }

  public async acceptOffers(props: {
    request: AcceptOffersRequest;
    onBefore?: StepHook;
    onAfter?: StepHook;
    execute?: Boolean;
  }) {
    const { request, onBefore, onAfter, execute = true } = props;
    const response = await OrderbookService.AcceptOffers(
      request,
      this.requestOpts(),
    );
    if (!execute) {
      return { steps: response, txHashes: undefined };
    }
    const txHashes = await this.executeSteps(response, {
      beforeStep: onBefore,
      afterStep: onAfter,
    });
    return { steps: response, txHashes };
  }

  public async sweepCollection(props: {
    request: SweepCollectionRequest;
    onBefore?: StepHook;
    onAfter?: StepHook;
    execute?: Boolean;
  }) {
    const { request, onBefore, onAfter, execute = true } = props;
    const response = await OrderbookService.SweepCollection(
      request,
      this.requestOpts(),
    );
    if (!execute) {
      return response;
    }

    await this.executeSteps(response, {
      beforeStep: onBefore,
      afterStep: onAfter,
    });
    return response;
  }

  public async executeSteps(steps: Steps, hooks?: StepHooks) {
    console.log('[Dev]', 'executeSteps', steps, hooks);
    const promises: (() => Promise<any | void>)[] = [];
    if (!steps.steps) {
      return;
    }
    let txHash: TxHash | undefined;
    steps.steps.forEach((step) => {
      return step.items
        ?.filter((item) => item.status !== StepItemStatus.COMPLETE)
        .forEach((item) => {
          const setOutput = (output?: TxHash | string) => {
            if (!output) {
              return;
            }
            if (output.length === 66) {
              logInfo('Setting tx hash', output);
              txHash = output as TxHash;
            }
          };
          const input = {
            item,
            setOutput,
            wait: true,
          };
          promises.push(() =>
            hooks?.beforeStep
              ? hooks.beforeStep(steps, step, item)
              : Promise.resolve(),
          );
          if (item.data?.wrapCurrency) {
            promises.push(() =>
              wrapPromise(
                'step_wrap_currency',
                executeStepWrapCurrency(this, input),
              ),
            );
          }
          if (item.data?.unwrapCurrency) {
            promises.push(() =>
              wrapPromise(
                'step_unwrap_currency',
                executeStepUnwrapCurrency(this, input),
              ),
            );
          }
          if (item.data?.addAllowance) {
            promises.push(() =>
              wrapPromise(
                'step_add_allowance',
                executeStepAddAllowance(this, input),
              ),
            );
          }
          if (item.data?.approveNftContract) {
            promises.push(() =>
              wrapPromise(
                'step_approve_nft_contract',
                executeStepItemApprove(this, input),
              ),
            );
          }
          if (item.data?.sign) {
            promises.push(() =>
              wrapPromise('step_sign', executeStepItemSign(this, input)),
            );
          }
          if (item.data?.swapCurrency) {
            promises.push(() =>
              wrapPromise(
                'step_swap_currency',
                executeStepSwapCurrency(this, input),
              ),
            );
          }
          if (item.data?.acceptOffers) {
            promises.push(() =>
              wrapPromise(
                'step_accept_offers',
                executeStepAcceptOffers(this, input),
              ),
            );
          }
          if (item.data?.buyListing) {
            promises.push(() =>
              wrapPromise(
                'step_buy_listing',
                executeStepBuyListing(this, input),
              ),
            );
          }
          if (item.data?.buyListings) {
            promises.push(() =>
              wrapPromise(
                'step_buy_listings',
                executeStepBuyListings(this, input),
              ),
            );
          }
          if (item.data?.revokeNonce) {
            promises.push(() =>
              wrapPromise(
                'step_revoke_nonce',
                executeStepRevokeNonce(this, input),
              ),
            );
          }
          if (item.data?.sweepCollection) {
            promises.push(() =>
              wrapPromise(
                'step_sweep_collection',
                sweepCollectionStep(this, input),
              ),
            );
          }
          if (hooks?.afterStep) {
            promises.push(() =>
              hooks?.afterStep
                ? hooks.afterStep(steps, step, item, txHash)
                : Promise.resolve(),
            );
          }
        });
    });
    return executePromisesSequentially(promises);
  }
}

const awaitTx = async (publicClient: PublicClient, txHash?: TxHash) => {
  if (!txHash || txHash.length !== 66) {
    logInfo('No tx hash to await');
    return;
  }
  try {
    return await publicClient.waitForTransactionReceipt({
      hash: txHash as Address,
    });
  } catch (e) {
    logError('Error waiting for transaction receipt', e);
  }
};

export type TxHash = `0x${string}`;

type StepHook = (
  steps: Steps,
  step: Step,
  item: StepItem,
  output?: TxHash,
) => Promise<void>;

type StepHooks = {
  beforeStep?: StepHook;
  afterStep?: StepHook;
};

function executePromisesSequentially(promises: (() => Promise<any>)[]) {
  let result = Promise.resolve();
  promises.forEach((promise) => {
    logInfo('Executing promise', promise);
    result = result.then((res) => {
      logInfo('Promise result', res);
      return promise();
    });
  });
  return result.then(() =>
    console.log('[Dev]', 'all promises have been done successtown'),
  );
}

type ItemInputArgs = {
  item: StepItem;
  setOutput: (output?: TxHash | string) => void;
  wait?: boolean;
};

const sweepCollectionStep = async (wallet: Wallet, args: ItemInputArgs) => {
  const { item, setOutput, wait } = args;
  if (item.status === StepItemStatus.COMPLETE) {
    return;
  }
  if (!item?.data?.sweepCollection) {
    return;
  }
  await wallet.switchChain(int(item.data.chainId));
  if (
    !item.data.sweepCollection ||
    !item.data.sweepCollection.items ||
    !item.data.sweepCollection.call
  ) {
    return;
  }

  setOutput(
    await sweepCollectionOnChain(wallet, item.data.sweepCollection.call, wait),
  );
};

const sweepCollectionOnChain = async (
  wallet: Wallet,
  call: EncodedPaymentProcessorWrite,
  wait?: boolean,
) => {
  if (!wallet.publicClient) {
    throw new Error('No public client found');
  }
  const { request } = await wallet.publicClient.simulateContract({
    account: wallet.address,
    abi: paymentProcessorAbi,
    address: call.contractAddress as Address,
    functionName: 'sweepCollection',
    args: [call.encodedCalldata as `0x${string}`],
  });

  const output = await wallet.client?.writeContract(request);
  if (wait) {
    await awaitTx(wallet.publicClient, output as TxHash);
  }
  return output;
};

const executeStepUnwrapCurrency = async (
  wallet: Wallet,
  args: ItemInputArgs,
) => {
  const { item, setOutput, wait } = args;
  if (item.status === StepItemStatus.COMPLETE) {
    return;
  }
  if (!item?.data?.unwrapCurrency) {
    return;
  }
  try {
    await wallet.switchChain(int(item.data.chainId));
    const result = await unwrapCurrencyOnChain(
      wallet,
      item.data.unwrapCurrency,
      wait,
    );
    setOutput(result);
  } catch (e) {
    console.error('Error executing unwrap currency', e);
    throw e;
  }
};

const executeStepSwapCurrency = async (wallet: Wallet, args: ItemInputArgs) => {
  const { item, setOutput, wait } = args;
  if (item.status === StepItemStatus.COMPLETE) {
    return;
  }
  if (!item?.data?.swapCurrency) {
    return;
  }
  try {
    console.log('[Dev]', 'swapCurrencyOnChain', item.data.swapCurrency);
    await wallet.switchChain(int(item.data.chainId));
    const result = await swapCurrencyOnChain(
      wallet,
      item.data.swapCurrency,
      wait,
    );
    console.log('[Dev]', 'swapCurrencyOnChain result', result);
    setOutput(result);
  } catch (e) {
    console.error('Error executing swap currency', e);
    throw e;
  }
};

const executeStepRevokeNonce = async (wallet: Wallet, args: ItemInputArgs) => {
  const { item, setOutput, wait } = args;
  if (item.status === StepItemStatus.COMPLETE) {
    return;
  }
  if (!item?.data?.revokeNonce) {
    return;
  }
  if (!item.data.revokeNonce || !item.data.revokeNonce.call) {
    return;
  }
  await wallet.switchChain(int(item.data.chainId));

  setOutput(
    await revokeSingleNonceOnChain(wallet, item.data.revokeNonce.call, wait),
  );
};

const executeStepWrapCurrency = async (wallet: Wallet, args: ItemInputArgs) => {
  const { item, setOutput, wait } = args;
  if (item.status === StepItemStatus.COMPLETE) {
    return;
  }
  if (!item?.data?.wrapCurrency) {
    return;
  }
  await wallet.switchChain(int(item.data.chainId));
  setOutput(await wrapCurrencyOnChain(wallet, item.data.wrapCurrency, wait));
};

const executeStepBuyListings = async (wallet: Wallet, args: ItemInputArgs) => {
  const { item, setOutput, wait } = args;
  if (item.status === StepItemStatus.COMPLETE) {
    return;
  }
  if (!item?.data?.buyListings) {
    return;
  }
  if (
    !item.data.buyListings ||
    !item.data.buyListings.items ||
    !item.data.buyListings.call
  ) {
    return;
  }
  await wallet.switchChain(int(item.data.chainId));
  if (item.data.buyListings.call.functionName == 'buyListing') {
    setOutput(
      await buyListingOnChain(wallet, item.data.buyListings.call, wait),
    );
    return;
  }
  setOutput(
    await bulkBuyListingsOnChain(wallet, item.data.buyListings.call, wait),
  );
};

const executeStepAddAllowance = async (wallet: Wallet, args: ItemInputArgs) => {
  const { item, setOutput, wait } = args;
  if (item.status === StepItemStatus.COMPLETE) {
    return;
  }
  if (!item?.data?.addAllowance) {
    return;
  }
  if (
    !item.data.addAllowance ||
    !item.data.addAllowance.operator ||
    !item.data.addAllowance.amount ||
    !item.data.addAllowance.contractAddress
  ) {
    return;
  }
  await wallet.switchChain(int(item.data.chainId));

  setOutput(
    await approveAllowanceOnChain(
      wallet,
      item.data.addAllowance.contractAddress,
      item.data.addAllowance.operator,
      item.data.addAllowance.amount,
      wait,
    ),
  );
};

const executeStepBuyListing = async (wallet: Wallet, args: ItemInputArgs) => {
  const { item, setOutput, wait } = args;
  logInfo('Executing buy listing status', item?.status);
  if (item.status === StepItemStatus.COMPLETE) {
    return;
  }
  logInfo('Executing buy listing buyListing', item?.data?.buyListing);
  if (!item?.data?.buyListing) {
    return;
  }
  if (
    !item.data.buyListing ||
    !item.data.buyListing.order ||
    !item.data.buyListing.call
  ) {
    return;
  }
  logInfo('Executing buy listing switching chain');
  await wallet.switchChain(int(item.data.chainId));
  setOutput(await buyListingOnChain(wallet, item.data.buyListing.call, wait));
};

const executeStepAcceptOffers = async (wallet: Wallet, args: ItemInputArgs) => {
  const { item, setOutput, wait } = args;
  if (item.status === StepItemStatus.COMPLETE) {
    return;
  }
  if (!item?.data?.acceptOffers) {
    return;
  }
  if (
    !item.data.acceptOffers ||
    !item.data.acceptOffers.items ||
    !item.data.acceptOffers.call
  ) {
    return;
  }
  await wallet.switchChain(int(item.data.chainId));
  if (item.data.acceptOffers.call.functionName == 'acceptOffer') {
    setOutput(
      await acceptOfferOnChain(wallet, item.data.acceptOffers.call, wait),
    );
    return;
  }
  setOutput(
    await bulkAcceptBidsOnChain(wallet, item.data.acceptOffers.call, wait),
  );
};

const executeStepItemApprove = async (wallet: Wallet, args: ItemInputArgs) => {
  const { item, setOutput, wait } = args;
  if (item.status === StepItemStatus.COMPLETE) {
    return;
  }
  if (!item?.data?.approveNftContract) {
    return;
  }
  await wallet.switchChain(int(item.data.chainId));
  const collection = await OrderbookService.GetCollection(
    {
      chainId: item.data.chainId,
      collection: item.data.approveNftContract.contractAddress,
    },
    getRequestOpts(),
  );
  const address = wallet.address;
  const client = wallet.client;
  const publicClient = wallet.publicClient;
  if (!publicClient) {
    throw new Error('No public client found');
  }
  if (item.data.approveNftContract.tokenId) {
    const { request } = await publicClient.simulateContract({
      account: address,
      abi: erc721Abi,
      address: item.data.approveNftContract.contractAddress as Address,
      functionName: 'approve',
      args: [
        collection.paymentProcessorAddress as Address,
        bigInt(item.data.approveNftContract.tokenId as string),
      ],
    });
    const output = await wallet.client?.writeContract(request);
    setOutput(output);
    if (wait) {
      await awaitTx(publicClient, output);
    }
    return;
  } else {
    const { request } = await publicClient.simulateContract({
      account: address,
      abi: erc721Abi,
      address: item.data.approveNftContract.contractAddress as Address,
      functionName: 'setApprovalForAll',
      args: [collection.paymentProcessorAddress as Address, true],
    });
    const output = await wallet.client?.writeContract(request);
    setOutput(output);
    if (wait) {
      await awaitTx(publicClient, output);
    }
    return;
  }
};

const executeStepItemSign = async (wallet: Wallet, args: ItemInputArgs) => {
  const { item, setOutput, wait } = args;
  if (item.status === StepItemStatus.COMPLETE) {
    return;
  }
  if (!item?.data?.sign) {
    return;
  }
  await wallet.switchChain(int(item.data.chainId));

  if (item?.data?.sign) {
    const signature = await executeSignature(wallet, args);
    await OrderbookService.SubmitOrderSignature(
      {
        chainId: String(item.data.chainId),
        collection: item?.data?.sign.collection,
        orderId: item?.data?.sign.orderId,
        signature: signature,
      },
      wallet.requestOpts(),
    );
  }
  return;
};

const executeSignature = async (wallet: Wallet, args: ItemInputArgs) => {
  const { item } = args;
  let message: Record<string, unknown> = {};
  const sign = item.data?.sign;
  console.log('[Dev]', 'sign', sign);
  if (!sign) {
    throw new Error('No sign found');
  }
  if (sign.saleApproval) {
    message = sign.saleApproval as Record<string, unknown>;
  }
  if (sign.itemOfferApproval) {
    message = sign.itemOfferApproval as Record<string, unknown>;
  }
  if (sign.collectionOfferApproval) {
    message = sign.collectionOfferApproval as Record<string, unknown>;
  }
  if (sign.tokenSetOfferApproval) {
    message = sign.tokenSetOfferApproval as Record<string, unknown>;
  }
  if (!sign.types) {
    throw new Error('No types found');
  }
  if (sign.signatureKind !== SignatureKind.EIP712) {
    throw new Error('Invalid signature kind');
  }
  return await wallet?.client?.signTypedData({
    account: wallet.address ?? zeroAddress,
    domain: {
      chainId: int(item.data?.chainId),
      name: sign?.domain?.name ?? 'PaymentProcessor',
      version: sign?.domain?.version ?? '2',
      verifyingContract: sign?.domain?.verifyingContract as `0x${string}`,
    },
    types: sign.types,
    primaryType: sign.primaryType as string,
    message: message,
  });
};

const unwrapCurrencyOnChain = async (
  wallet: Wallet,
  call: StepUnwrapCurrency,
  wait?: boolean,
) => {
  const publicClient = wallet?.publicClient;
  if (!publicClient) {
    throw new Error('No public client found');
  }
  const { request } = await publicClient.simulateContract({
    account: wallet.address,
    abi: werc20Abi,
    address: call.contractAddress as Address,
    functionName: 'withdraw',
    args: [BigInt(call.amount as string)],
  });
  const output = await wallet.client?.writeContract(request);
  if (wait) {
    await awaitTx(publicClient, output);
  }
  return output;
};

const swapCurrencyOnChain = async (
  wallet: Wallet,
  call: StepSwapCurrency,
  wait?: boolean,
) => {
  const publicClient = wallet?.publicClient;
  if (!publicClient) {
    throw new Error('No public client found');
  }
  const { request } = await publicClient.simulateContract({
    account: wallet.address,
    abi: uniswapV3RouterAbi,
    address: call.contractAddress as Address,
    functionName: 'exactOutputSingle',
    args: [
      {
        tokenIn: addr(call.tokenIn),
        tokenOut: addr(call.tokenOut),
        fee: !call.fee || call.fee === 0 ? 3000 : call.fee,
        recipient: addr(call.recipient),
        deadline: BigInt(int(call.deadline)),
        amountOut: BigInt(call.amountOut as string),
        amountInMaximum: BigInt(call.amountInMaximum as string),
        sqrtPriceLimitX96: BigInt(call.sqrtPriceLimitX96 as string),
      },
    ],
    value: BigInt(call.value as string),
  });
  const output = await wallet.client?.writeContract(request);
  if (wait) {
    await awaitTx(publicClient, output);
  }
  return output;
};

const revokeSingleNonceOnChain = async (
  wallet: Wallet,
  call: EncodedPaymentProcessorWrite,
  wait?: boolean,
) => {
  const publicClient = wallet.publicClient;
  if (!publicClient) {
    throw new Error('No public client found');
  }
  const { request } = await publicClient.simulateContract({
    account: wallet.address,
    abi: paymentProcessorAbi,
    address: call.contractAddress as Address,
    functionName: 'revokeSingleNonce',
    args: [call.encodedCalldata as `0x${string}`],
  });
  const output = await wallet.client?.writeContract(request);
  if (wait) {
    await awaitTx(publicClient, output);
  }
  return output;
};

export const buyListingOnChain = async (
  wallet: Wallet,
  call: EncodedPaymentProcessorWrite,
  wait?: boolean,
) => {
  const processor = paymentProcessorAbi;
  const publicClient = wallet?.publicClient;
  if (!publicClient) {
    throw new Error('No public client found');
  }
  logInfo('buyListingOnChain call', call);
  let value = BigInt(0);
  if (call.value) {
    value = BigInt(call.value);
  }
  logInfo('buyListingOnChain value', value);
  const { request } = await publicClient.simulateContract({
    account: wallet.address,
    abi: processor,
    address: call.contractAddress as Address,
    functionName: 'buyListing',
    args: [call.encodedCalldata as `0x${string}`],
    value: value,
  });
  logInfo('buyListingOnChain request', request);
  const output = await wallet.client?.writeContract(request);
  if (wait) {
    await awaitTx(publicClient, output);
  }
  return output;
};

export const wrapCurrencyOnChain = async (
  wallet: Wallet,
  call: StepWrapCurrency,
  wait?: boolean,
) => {
  const publicClient = wallet?.publicClient;
  if (!publicClient) {
    throw new Error('No public client found');
  }
  let value = BigInt(call.amount as string);
  const { request } = await publicClient.simulateContract({
    account: wallet.address,
    abi: werc20Abi,
    address: call.paymentMethod as Address,
    functionName: 'deposit',
    value: value,
  });
  const output = await wallet.client?.writeContract(request);
  if (wait) {
    await awaitTx(publicClient, output);
  }
  return output;
};

export const acceptOfferOnChain = async (
  wallet: Wallet,
  call: EncodedPaymentProcessorWrite,
  wait?: boolean,
) => {
  const publicClient = wallet?.publicClient;
  if (!publicClient) {
    throw new Error('No public client found');
  }
  let value = BigInt(0);
  if (call.value) {
    value = BigInt(call.value);
  }
  const { request } = await publicClient.simulateContract({
    account: wallet.address,
    abi: paymentProcessorAbi,
    address: call.contractAddress as Address,
    functionName: 'acceptOffer',
    args: [call.encodedCalldata as `0x${string}`],
    value,
  });
  const output = await wallet.client?.writeContract(request);
  if (wait) {
    await awaitTx(publicClient, output);
  }
  return output;
};

export const approveAllowanceOnChain = async (
  wallet: Wallet,
  paymentMethodAddress: string,
  contractAddress: string,
  amount: string,
  wait?: boolean,
) => {
  const publicClient = wallet?.publicClient;
  if (!publicClient) {
    throw new Error('No public client found');
  }
  const { request } = await publicClient.simulateContract({
    account: wallet.address,
    abi: erc20Abi,
    address: paymentMethodAddress as Address,
    functionName: 'approve',
    args: [contractAddress as Address, BigInt(amount)],
  });
  const output = await wallet.client?.writeContract(request);
  if (wait) {
    await awaitTx(publicClient, output);
  }
  return output;
};

export const bulkBuyListingsOnChain = async (
  wallet: Wallet,
  call: EncodedPaymentProcessorWrite,
  wait?: boolean,
) => {
  const publicClient = wallet?.publicClient;
  if (!publicClient) {
    throw new Error('No public client found');
  }
  let value = BigInt(0);
  if (call.value) {
    value = BigInt(call.value);
  }
  const { request } = await publicClient.simulateContract({
    account: wallet.address,
    abi: paymentProcessorAbi,
    address: call.contractAddress as Address,
    functionName: 'bulkBuyListings',
    args: [call.encodedCalldata as `0x${string}`],
    value: value,
  });
  const hash = await wallet.client?.writeContract(request);
  if (wait) {
    await awaitTx(publicClient, hash);
  }
  return hash;
};

export const bulkAcceptBidsOnChain = async (
  wallet: Wallet,
  call: EncodedPaymentProcessorWrite,
  wait?: boolean,
) => {
  const publicClient = wallet?.publicClient;
  if (!publicClient) {
    throw new Error('No public client found');
  }
  let value = BigInt(0);
  if (call.value) {
    value = BigInt(call.value);
  }
  const { request } = await publicClient.simulateContract({
    account: wallet.address,
    abi: paymentProcessorAbi,
    address: call.contractAddress as Address,
    functionName: 'bulkAcceptOffers',
    args: [call.encodedCalldata as `0x${string}`],
    value,
  });
  const hash = await wallet.client?.writeContract(request);
  if (wait) {
    await awaitTx(publicClient, hash);
  }
  return hash;
};

const jwtDecode = (token: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

// defaultMarketplaceId is the default marketplace id to use if none is provided
const defaultMarketplaceId = '00000000-0000-0000-0000-000000000000';

export const getRequestOpts = () => {
  const marketplaceId = sMarketplace?.value?.id || defaultMarketplaceId;
  if (typeof window === 'undefined') {
    return {
      pathPrefix: process.env.NEXT_PUBLIC_ORDERBOOK_URL,
      headers: {
        'x-marketplace-id': marketplaceId,
      },
    };
  }

  const token = localStorage.getItem(authTokenKey);
  if (!token) {
    return {
      pathPrefix: process.env.NEXT_PUBLIC_ORDERBOOK_URL,
      headers: {
        'x-marketplace-id': marketplaceId,
      },
    };
  }
  return {
    pathPrefix: process.env.NEXT_PUBLIC_ORDERBOOK_URL,
    headers: {
      authorization: `Bearer ${token}`,
      'x-marketplace-id': marketplaceId,
    },
    //TODO: exclude in prod
    credentials: 'same-origin',
  } as InitReq;
};

// wrapPromise is a utility function that wraps a promise and tracks and logs an event if the promise rejects
const wrapPromise = (eventName: AnalyticsEventName, promise: Promise<any>) => {
  return new Promise((resolve, reject) => {
    promise.then(resolve).catch((e) => {
      trackEvent(eventName, { error: e });
      logError(`Error in ${eventName}`, e);
      reject(e);
    });
  });
};
