import supportedChains from '@api/utils/chains';
import { computed } from '@preact/signals-react';
import { sSelectedCollection } from './collection';

const supportedChainsMap = supportedChains.reduce((map, chain) => {
  map[chain.id] = chain;
  return map;
}, {} as Record<number, (typeof supportedChains)[0]>);

export const getChainById = (id: number) => {
  return supportedChainsMap[id];
};

export const sSelectedChain = computed(() => {
  const chainId = sSelectedCollection.value?.chainId;
  if (chainId === undefined) {
    return undefined;
  }

  return getChainById(Number(chainId));
});
