import {
  OrderbookService,
  QueryCollectionTokensRequest,
  QueryTokensResponse,
} from '@api/orderbook_api/v1/orderbook.pb';
import { int } from '@api/utils';
import { getRequestOpts } from '@utils/wallet';
import useInfiniteApi from '@hooks/useInfiniteApi';
import { SWRInfiniteConfiguration } from 'swr/infinite';
import { useMemo } from 'react';

const fetcher = async (
  key: [QueryCollectionTokensRequest | undefined, number],
) => {
  if (!key || !key?.[0]?.chainId || !key?.[0]?.collection) return {};
  const response = await OrderbookService.QueryCollectionTokens(
    {
      ...key[0],
      page: key[1].toString(),
      orderBy: key[0].orderBy,
    },
    getRequestOpts(),
  );
  return response;
};

export default function useCollectionTokens(
  request?: QueryCollectionTokensRequest,
  config?: SWRInfiniteConfiguration,
) {
  const getKey = (pageIndex: number, previousPageData: QueryTokensResponse) => {
    if (
      previousPageData &&
      previousPageData.total &&
      int(previousPageData.total) < pageSize
    ) {
      return null;
    }
    return [request, pageIndex, request?.orderBy];
  };
  const pageSize = int(request?.pageSize) || 25;
  const response = useInfiniteApi<QueryTokensResponse>(
    getKey,
    { fetcher, revalidateFirstPage: true, ...config },
    pageSize,
  );
  const flatResponse = useMemo(() => {
    return response.data?.flatMap((page) => page.tokens || []) ?? [];
  }, [response.data]);

  return {
    ...response,
    data: flatResponse,
  };
}
