import {
  useState,
  useEffect,
  useCallback,
  useRef,
  MutableRefObject,
} from 'react';
import { Deserializers, Serializers, StateParam } from './serialize-params';

import { useRouter } from 'next/router';
import { isEqual } from 'lodash';

type SearchParams = Map<string, string>;

export const getUrlParam = <T>(
  param: string | StateParam<T>,
  location: any,
  defaultValue: MutableRefObject<any>,
) => {
  const searchParams = parseQueryString(location);
  const urlParam: StateParam<T> =
    typeof param === 'string'
      ? {
          name: param,
          type: 'string',
          defaultValue: defaultValue.current,
        }
      : param;
  let urlValue = searchParams.get(urlParam.name);
  if (urlValue === undefined || urlValue === null) {
    urlParam.currentValue = defaultValue.current;
  } else {
    urlParam.currentValue = Deserializers[urlParam.type](urlValue) as T;
  }
  return urlParam;
};

function createQueryString(searchParams: SearchParams): string {
  const keyValuePairs = [];
  for (const [key, value] of searchParams) {
    keyValuePairs.push(`${key}=${value}`);
  }
  return keyValuePairs.join('&');
}

function parseQueryString(location: any): SearchParams {
  const query = new Map(Object.entries(location.query));
  return query as any;
  const queryString = location.search.substring(1);
  const keyValuePairs = queryString
    .split('&')
    .filter((pair: string) => pair !== '');
  const searchParams = new Map();
  keyValuePairs.forEach((pair: string) => {
    const [key, value] = pair.split('=');
    searchParams.set(key, value);
  });
  return searchParams;
}

export const useStateUrl = <T>(
  param: string | StateParam<T>,
): [
  T,
  (newValue: T) => void,
  (newValue: T) => (searchParams: SearchParams) => SearchParams,
] => {
  const router = useRouter();
  const location = router;
  const locationRef = useRef(location);
  const navigate = router.replace;

  const defaultValue = useRef(
    typeof param === 'string' ? undefined : param.defaultValue,
  );

  const urlParam = getUrlParam<T>(param, location, defaultValue);
  const [value, setValue] = useState(urlParam.currentValue);

  useEffect(() => {
    const newUrlParam = getUrlParam<T>(param, location, defaultValue);
    if (!isEqual(newUrlParam.currentValue, value)) {
      setValue(newUrlParam.currentValue);
    }
    locationRef.current = location;
  }, [location]);

  const mutateValue = useCallback(
    (newValue: T | undefined) => (searchParams: SearchParams) => {
      setValue(newValue);
      if (newValue === undefined) {
        searchParams.delete(urlParam.name);
      } else {
        searchParams.set(
          urlParam.name,
          (Serializers[urlParam.type] as any)(newValue),
        );
      }
      return searchParams;
    },
    [],
  );

  const update = useCallback((newValue: T) => {
    const searchParams = parseQueryString(locationRef.current);
    const newSearchParams = mutateValue(newValue)(searchParams);
    const newQueryString = createQueryString(newSearchParams);
    navigate(`${locationRef.current.pathname}?${newQueryString}`, undefined, {
      shallow: true,
    });
  }, []);

  return [value!, update, mutateValue];
};

export const useBatchUpdate = () => {
  const router = useRouter();
  const location = router;
  const locationRef = useRef(location);
  const navigate = router.replace;

  useEffect(() => {
    locationRef.current = location;
  }, [location.query]);

  const batchUpdate = useCallback((updates: Array<any>) => {
    const searchParams = parseQueryString(locationRef.current);

    const newSearchParams = updates.reduce((acc, update) => {
      return update(acc);
    }, searchParams);

    const newQueryString = createQueryString(newSearchParams);
    navigate(`?${newQueryString}`, undefined, {
      shallow: true,
    });
  }, []);

  return { batchUpdate };
};
