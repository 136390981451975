import { Activity } from '@api/orderbook_api/v1/types.pb';
import { computed, signal } from '@preact/signals-react';
import { Address } from 'viem';

export type OrderEventName =
  | 'purchase_error'
  | 'purchase_complete'
  | 'accept_offer_error'
  | 'accept_offer_complete'
  | 'offer_error'
  | 'offer_complete'
  | 'listing_error'
  | 'listing_complete'
  | 'cancel_error'
  | 'cancel_complete'
  | 'unknown';

export type OrderEventData = {
  requestId?: string;
  errors?: { message?: string; orderId?: string }[];
  path: Activity;
  fees?: any;
  error?: any; // Manually added client error
};

export type OrderEvent = {
  name: OrderEventName;
  data: OrderEventData;
};

const __event = signal<undefined | OrderEvent>(undefined);

export const sEvent = computed(() => {
  return __event.value;
});

export const generateEvent = (name: OrderEventName, data: OrderEventData) => {
  __event.value = {
    name,
    data,
  };
};
