import React, { ReactElement, useContext, useEffect, useState } from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { AnimatePresence } from 'framer-motion';
import {
  AnimatedOverlay,
  StyledAnimatedContent,
} from '../../primitives/Dialog';
import {
  Anchor,
  Button,
  Flex,
  Text,
  Box,
  Loader,
  ErrorWell,
  FormatCryptoCurrency,
} from '../../primitives';
import { styled } from '@stitches/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faClose,
  faCube,
} from '@fortawesome/free-solid-svg-icons';
import { TokenCheckout } from '../../modal/TokenCheckout';
import { Cart, CartItem, CheckoutStatus } from '../../context/CartProvider';
import { truncateAddress } from '../../lib/truncate';
import getChainBlockExplorerUrl from '../../lib/getChainBlockExplorerUrl';
import { getCollectionToken } from '@hooks/useCollectionToken';
import { Order } from '@api/orderbook_api/v1/types.pb';
import { TxSummary } from '@v2/components/TxSummary/TxSummary';

const Title = styled(DialogPrimitive.Title, {
  margin: 0,
});

type Props = {
  items: CartItem[];
  totalPrice: number;
  usdPrice: number;
  currency: NonNullable<Cart['items'][0]['price']>['currency'];
  cartChain: Cart['chain'];
  transaction?: Cart['transaction'];
  open?: boolean;
  setCartPopoverOpen: (isOpen: boolean) => void;
  hasEnoughCurrency: boolean;
  balance?: bigint;
  onClose?: () => void;
};

export function CartCheckoutModal({
  items,
  totalPrice,
  usdPrice,
  currency,
  cartChain,
  transaction,
  open,
  hasEnoughCurrency,
  balance,
  setCartPopoverOpen,
  onClose,
}: Props): ReactElement | null {
  const [dialogOpen, setDialogOpen] = useState(false);

  const [images, setImages] = useState<string[]>([]);
  useEffect(() => {
    const loadImages = async () => {
      const tokenPromises: Promise<string>[] = items
        .slice(0, 2)
        .map((order) => {
          const { token, collection } = order;
          const contract = collection;
          return getCollectionToken({
            collection: contract,
            tokenId: token?.tokenId,
            chainId: cartChain?.id.toString(),
          }).then((fullToken) => fullToken?.imageUrl || '');
        });
      Promise.all(tokenPromises).then((images) => {
        setImages(images);
      });
    };
    loadImages();
  }, [items]);

  useEffect(() => {
    if (open !== undefined && open !== dialogOpen) {
      setDialogOpen(open);
    }
  }, [open]);

  return (
    <DialogPrimitive.Root
      onOpenChange={(open) => {
        setDialogOpen(open);
        if (!open) {
          onClose?.();
          setCartPopoverOpen(false);
        }
      }}
      open={dialogOpen}
    >
      <AnimatePresence>
        {dialogOpen && (
          <DialogPrimitive.DialogPortal forceMount>
            <AnimatedOverlay style={{ zIndex: 1002 }} />
            <StyledAnimatedContent
              forceMount
              css={{
                zIndex: 1003,
              }}
            >
              <Flex
                css={{
                  p: '$modalContentPadding',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  borderBottom: '1px solid $borderColor',
                }}
              >
                <Title
                  css={{
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <Text css={{ fontWeight: 600, fontSize: 18 }}>
                    Complete checkout
                  </Text>
                </Title>
                <DialogPrimitive.Close asChild>
                  <Button
                    color='ghost'
                    size='none'
                    css={{ color: '$neutralText', height: 'auto' }}
                  >
                    <FontAwesomeIcon icon={faClose} width={16} height={16} />
                  </Button>
                </DialogPrimitive.Close>
              </Flex>
              <Box css={{ maxHeight: '85vh', overflowY: 'auto' }}>
                {transaction?.status === CheckoutStatus.Approving && (
                  <Flex direction='column'>
                    <Box
                      css={{
                        p: '$modalContentPadding',
                        borderBottom: '1px solid $borderColor',
                      }}
                    >
                      <TokenCheckout
                        itemCount={items.length}
                        images={images}
                        totalPrice={totalPrice}
                        usdPrice={usdPrice}
                        currency={currency}
                        chain={cartChain}
                      />
                    </Box>
                    <Flex
                      direction='column'
                      css={{ p: '$modalContentPadding', overflowY: 'auto' }}
                    >
                      <Flex
                        direction='column'
                        align='center'
                        justify='center'
                        css={{
                          color: '$neutralBorderHover',
                          flex: 1,
                          gap: '$5',
                        }}
                      >
                        <Flex css={{ py: '$4' }}>
                          <Loader />
                        </Flex>
                      </Flex>
                    </Flex>
                    <Flex
                      css={{
                        p: '$modalContentPadding',
                        width: '100%',
                        borderTop: '1px solid $borderColor',
                      }}
                    >
                      <Button css={{ flex: 1 }} disabled={true}>
                        Waiting for Approval...
                      </Button>
                    </Flex>
                  </Flex>
                )}
                {transaction?.status === CheckoutStatus.Finalizing && (
                  <Flex direction='column'>
                    <Flex
                      direction='column'
                      css={{
                        p: '$modalContentPadding',
                        borderTop: '1px solid $borderColor',
                      }}
                    >
                      <Flex
                        direction='column'
                        align='center'
                        justify='center'
                        css={{
                          gap: '$4',
                        }}
                      >
                        <Text style='h6'>Finalizing on blockchain</Text>
                        <Text
                          style='subtitle3'
                          color='subtle'
                          css={{ textAlign: 'center' }}
                        >
                          You can close this modal while it finalizes on the
                          blockchain. The transaction will continue in the
                          background.
                        </Text>

                        <FontAwesomeIcon
                          icon={faCube}
                          style={{ height: 24, width: 24 }}
                        />
                      </Flex>
                    </Flex>
                    <Flex
                      css={{
                        p: '$modalContentPadding',
                        width: '100%',
                        borderTop: '1px solid $borderColor',
                      }}
                    >
                      <Button disabled={true} css={{ flex: 1 }}>
                        Waiting to be Validated...
                      </Button>
                    </Flex>
                  </Flex>
                )}
                {transaction?.status === CheckoutStatus.Complete && (
                  <Flex direction='column' align='center'>
                    <Flex
                      direction='column'
                      css={{
                        width: '100%',
                        p: '$modalContentPadding',
                        borderTop: '1px solid $borderColor',
                      }}
                    >
                      <TxSummary txList={transaction?.txHashes} />
                    </Flex>
                    <Flex
                      css={{
                        width: '100%',
                        p: '$modalContentPadding',
                        borderTop: '1px solid $borderColor',
                      }}
                    >
                      <Button
                        css={{ flex: 1 }}
                        onClick={() => {
                          setDialogOpen(false);
                          onClose?.();
                        }}
                      >
                        Close
                      </Button>
                    </Flex>
                  </Flex>
                )}
                {transaction?.status === CheckoutStatus.Idle && (
                  <Flex direction='column'>
                    {!hasEnoughCurrency && (
                      <Flex direction='column' align='start'>
                        <Box
                          css={{
                            width: '100%',
                            p: '$modalContentPadding',
                            borderBottom: '1px solid $borderColor',
                          }}
                        >
                          <TokenCheckout
                            itemCount={items.length}
                            images={images}
                            totalPrice={totalPrice}
                            usdPrice={usdPrice}
                            currency={currency}
                            chain={cartChain}
                          />
                        </Box>
                        <Box
                          css={{
                            p: '$modalContentPadding',
                            borderBottom: '1px solid $borderColor',
                          }}
                        >
                          <Flex
                            align='center'
                            justify='start'
                            css={{ mb: '$2', mt: '$3', gap: '$2' }}
                          >
                            <Text style='body1' color='error'>
                              Insufficient balance:
                            </Text>
                            <FormatCryptoCurrency
                              textStyle='body1'
                              textColor='error'
                              chainId={cartChain?.id}
                              amount={balance}
                              address={currency?.address}
                              decimals={currency?.decimals}
                              symbol={currency?.symbol}
                              logoWidth={10}
                            />
                          </Flex>
                        </Box>
                      </Flex>
                    )}
                    {hasEnoughCurrency && (
                      <Flex
                        direction='column'
                        align='center'
                        css={{
                          width: '100%',
                          p: '$modalContentPadding',
                          borderTop: '1px solid $borderColor',
                        }}
                      >
                        <Flex
                          direction='column'
                          align='center'
                          css={{ width: '100%', gap: 24 }}
                        >
                          {transaction?.error && (
                            <ErrorWell
                              css={{ width: '100%' }}
                              error={transaction?.error}
                            />
                          )}
                        </Flex>
                      </Flex>
                    )}
                    <Flex
                      css={{
                        width: '100%',
                        p: '$modalContentPadding',
                        borderTop: '1px solid $borderColor',
                      }}
                    >
                      <Button
                        css={{ flex: 1 }}
                        onClick={() => {
                          setDialogOpen(false);
                          onClose?.();
                        }}
                      >
                        Close
                      </Button>
                    </Flex>
                  </Flex>
                )}
              </Box>
            </StyledAnimatedContent>
          </DialogPrimitive.DialogPortal>
        )}
      </AnimatePresence>
    </DialogPrimitive.Root>
  );
}
