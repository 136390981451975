import { Button, Text } from '@components/primitives';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MouseEventHandler } from 'react';

interface Props {
  name: string;
  value: string;
  onRemove?: (e: Parameters<MouseEventHandler<HTMLButtonElement>>[0]) => void;
}

export const Tag = ({ name, value, onRemove }: Props) => {
  return (
    <Button
      onClick={(e) => {
        onRemove?.(e);
      }}
      color='gray3'
    >
      <Text style='body1'>{name}:</Text>
      <Text
        style='subtitle1'
        css={{
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          maxWidth: 150,
          '@md': {
            maxWidth: 'initial',
          },
        }}
      >
        {value}
      </Text>
      {onRemove && (
        <Text css={{ color: '$gray9' }}>
          <FontAwesomeIcon icon={faClose} width='16' height='16' />
        </Text>
      )}
    </Button>
  );
};
