import { createContext, PropsWithChildren, useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import { installLogger } from '@utils/logger';
import { initMixPanel } from '@utils/mixpanel';
import { AnalyticsEventName } from './types';
import { installGA, installSentry, trackEvent, trackPageView } from './events';

initMixPanel();

interface AnalyticsContextType {
  trackMarketplaceEvent: (eventName: AnalyticsEventName) => void;
}

const AnalyticsContext = createContext<AnalyticsContextType>({
  trackMarketplaceEvent: (eventName) => {},
});

const sentryDsn = process.env.NEXT_PUBLIC_SENTRY_DSN;
const googleAnalyticsMeasurementId =
  process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID;

const isProd = process.env.NEXT_PUBLIC_APP_STAGE === 'prod';

if (sentryDsn) {
  installSentry({
    sentryDsn,
    sentryEnvironment: isProd ? 'production' : 'development',
    tracesSampleRate: isProd ? 0.1 : 1,
  });
}

if (googleAnalyticsMeasurementId) {
  installGA({
    googleAnalyticsMeasurementId,
  });
}

installLogger({
  loggingLevel:
    process.env.NEXT_PUBLIC_APP_STAGE === 'prod' ? 'CRITICAL' : 'INFO',
  reportingLevel: 'ERROR',
});

export const AnalyticsProvider = ({ children }: PropsWithChildren) => {
  const { events } = useRouter();
  const routeChangeEmitter = events;

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      trackPageView();
    };

    routeChangeEmitter.on('routeChangeComplete', handleRouteChange);

    return () => {
      routeChangeEmitter.off('routeChangeComplete', handleRouteChange);
    };
  }, [routeChangeEmitter]);

  const trackMarketplaceEvent = (eventName: AnalyticsEventName) => {
    const meta = {};

    trackEvent(eventName, meta);
  };

  return (
    <AnalyticsContext.Provider value={{ trackMarketplaceEvent }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = () => {
  return useContext(AnalyticsContext);
};
