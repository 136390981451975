import { Marketplace } from '@components/Marketplace';
import type { AppProps } from 'next/app';
import '../styles/globals.css';
import { memo, useMemo } from 'react';

const App = (props: AppProps) => {
  const memoizedMarketplace = useMemo(() => {
    return <Marketplace {...props} />;
  }, [props.Component, props.pageProps]);

  return memoizedMarketplace;
};

export default memo(App);
