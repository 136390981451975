import React, { FC } from 'react';
import { styled } from '@stitches.config';
import { Flex, FormatCryptoCurrency, Text } from '../../primitives';
import { useMediaQuery } from 'react-responsive';

type Props = {
  token: {
    name?: string;
    id: string;
  };
  collection?: {
    name: string;
    id: string;
  };
  fees?: any;
  img: string;
  sourceImg?: string;
  price?: number;
  netAmount?: number;
  currency?: string;
  decimals?: number;
  chainId?: number;
};

const Img = styled('img', {
  borderRadius: '$radiusMedium',
  aspectRatio: '1/1',
});

const largeScreenImgSize = 86;
const smallScreenImgSize = 62;

const AcceptBidLineItem: FC<Props> = ({
  img,
  token,
  collection,
  chainId,
  sourceImg,
  price,
  netAmount,
  fees,
  currency,
  decimals,
}) => {
  const isSmallDevice = useMediaQuery({ maxWidth: 600 });
  const imageSize = isSmallDevice ? smallScreenImgSize : largeScreenImgSize;
  const royaltiesBps = fees?.reduce((total: number, fee: any) => {
    if (fee?.kind === 'royalty') {
      total += fee?.bps || 0;
    }
    return total;
  }, 0);

  const isUnavailable = !price;

  const amountSlot = (
    <FormatCryptoCurrency
      chainId={chainId}
      amount={price}
      address={currency}
      decimals={decimals}
      textStyle='subtitle1'
    />
  );

  const offerNotAvailableSlot = (
    <Text color='subtle' style='body1'>
      Offer no longer available
    </Text>
  );

  return (
    <Flex css={{ gap: '$5', flexDirection: 'column' }}>
      <Flex css={{ gap: '$4' }}>
        <Img
          src={img}
          alt={'Token Image'}
          css={{
            overflow: 'hidden',
            visibility: !img || img.length === 0 ? 'hidden' : 'visible',
            flexShrink: 0,
            objectFit: 'cover',
            filter: isUnavailable ? 'grayscale(1)' : 'unset',
            width: imageSize,
            height: imageSize,
          }}
        />
        <Flex
          direction='column'
          align='start'
          justify='center'
          css={{ gap: 2, mr: 'auto', overflow: 'hidden' }}
        >
          <Text
            style='subtitle1'
            ellipsify
            color={isUnavailable ? 'subtle' : 'base'}
          >
            {token.name || `#${token.id}`}
          </Text>
          <Text ellipsify style='h6' color='subtle'>
            {collection?.name}
          </Text>
          {royaltiesBps && (
            <Text style='h6' color='subtle'>
              Creator Royalties: {royaltiesBps / 100}%
            </Text>
          )}
        </Flex>
        {!isSmallDevice && (isUnavailable ? offerNotAvailableSlot : amountSlot)}
      </Flex>
      {isSmallDevice && (
        <Flex direction='column' css={{ gap: '$3' }}>
          {isUnavailable ? (
            offerNotAvailableSlot
          ) : (
            <Flex justify='between'>
              <Text style='subtitle1' color='subtle' css={{ fontWeight: 400 }}>
                Amount
              </Text>
              <div> {amountSlot}</div>
            </Flex>
          )}
          {royaltiesBps && (
            <Flex justify='between'>
              <Text style='subtitle1' color='subtle' css={{ fontWeight: 400 }}>
                Creator royalties
              </Text>
              <Text style='subtitle1' color='subtle'>
                {royaltiesBps / 100}%
              </Text>
            </Flex>
          )}
        </Flex>
      )}
    </Flex>
  );
};

export default AcceptBidLineItem;
