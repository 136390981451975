import { ConvertCurrencyResponse } from '@api/orderbook_api/v1/orderbook.pb';
import useSWR from 'swr/immutable';
import { useWallet } from './useWallet';

export const useCurrencyConversions = (
  to = 'usd',
  chainId: number,
  currencies?: string[],
) => {
  const { wallet } = useWallet();

  const fetcher = async (currencies: string[]) => {
    const promises = currencies.map(
      (from) =>
        wallet
          ?.convertCurrency({
            toCurrency: to,
            fromCurrency: from,
            chainId: chainId?.toString(),
            amount: 1,
          })
          .catch(() => undefined) as Promise<any>,
    );
    const results = await Promise.allSettled(promises);
    return results.map((result) =>
      result.status === 'fulfilled' ? result.value : undefined,
    );
  };

  const { data, error } = useSWR<ConvertCurrencyResponse[]>(
    currencies,
    fetcher,
    {
      refreshInterval: 300000, //5m
    },
  );

  return {
    data,
    isError: !!error,
    isLoading: !data && !error,
  };
};
