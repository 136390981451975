/* eslint-disable react-hooks/rules-of-hooks */
import React, { FC } from 'react';
import { Flex, FormatCryptoCurrency, Img, Text } from '../../primitives';
import { ExpirationOption } from '../../types/ExpirationOption';
import { CSS } from '@stitches/react';
import { getTimeSince } from '@reservoir-kit-ui-overrides/lib/getTimeSince';
import {
  Collection,
  Currency,
  Token,
  TokenAttributeValue,
} from '@api/orderbook_api/v1/types.pb';

type Props = {
  token?: NonNullable<Token>;
  collection: NonNullable<Collection>;
  price?: number;
  currency?: Currency;
  quantity?: number;
  trait?: TokenAttributeValue;
  expirationOption?: ExpirationOption;
  containerCss?: CSS;
};

const TokenInfo: FC<Props> = ({
  token,
  collection,
  price,
  currency,
  quantity,
  trait,
  expirationOption,
  containerCss,
}) => {
  const expirationDisplay =
    expirationOption?.value === 'custom' && expirationOption.relativeTime
      ? getTimeSince(expirationOption.relativeTime)
      : `in ${expirationOption?.text.toLowerCase()}`;

  const tokenName = token?.tokenId ? `#${token?.tokenId}` : token?.name;

  return (
    <Flex
      align='center'
      justify='between'
      css={{ width: '100%', py: '$4', gap: '$4', ...containerCss }}
    >
      <Flex align='center' css={{ gap: '$3', overflow: 'hidden' }}>
        <Img
          src={token?.imageUrl || collection?.config?.imageUrl}
          alt={token?.name || collection?.name}
          css={{
            borderRadius: '$radiusMedium',
            objectFit: 'cover',
            height: 86,
            width: 86,
            aspectRatio: '1/1',
          }}
        />
        <Flex direction='column' css={{ gap: '$1', overflow: 'hidden' }}>
          <Text style='h6' ellipsify>
            {token ? tokenName : collection?.name}
          </Text>
          <Flex align='center' css={{ gap: '$1' }}>
            <Text style='subtitle2' color='subtle' ellipsify>
              {token ? collection?.name : null}
            </Text>
            {/* {chain && !expirationOption ? (
              <>
                {token ? <Divider direction='vertical' /> : null}
                <ChainIcon chainId={chain.id} height={12} />
                <Text style='subtitle2' color='subtle' ellipsify>
                  {chain.name}
                </Text>
              </>
            ) : null} */}
          </Flex>
          <Flex css={{ gap: '$1' }}>
            {trait ? (
              <Flex
                css={{
                  width: 'max-content',
                  backgroundColor: '$neutralBg',
                  borderRadius: '$radiusTiny',
                  py: '$1',
                  px: '$2',
                }}
              >
                <Text style='body3' color='subtle' ellipsify>
                  {trait?.key}: {trait?.value}
                </Text>
              </Flex>
            ) : null}
            {quantity && quantity > 1 ? (
              <Flex
                css={{
                  width: 'max-content',
                  backgroundColor: '$neutralBg',
                  borderRadius: '$radiusTiny',
                  py: '$1',
                  px: '$2',
                }}
              >
                <Text style='body3' color='subtle' ellipsify>
                  {quantity} items
                </Text>
              </Flex>
            ) : null}
          </Flex>
        </Flex>
      </Flex>

      {!price ? null : (
        <Flex direction='column' align='end' css={{ gap: '$1', flexShrink: 0 }}>
          {price && currency ? (
            <FormatCryptoCurrency
              chainId={Number(collection.chainId)}
              amount={Number(price)}
              address={currency?.address}
              symbol={currency?.symbol}
              textStyle='h6'
            />
          ) : null}
          {expirationOption ? (
            <Text style='body2' color='subtle'>
              Expires {expirationDisplay}
            </Text>
          ) : null}
        </Flex>
      )}
    </Flex>
  );
};

export default TokenInfo;
