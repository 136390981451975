import React, { FC } from 'react';
import { zeroAddress } from 'viem';
import { StyledComponent } from '@stitches/react/types/styled-component';
import Text from './Text';
import { useChainCurrency } from '@hooks';

type Props = {
  address: string;
  chainId?: number;
  isSmall?: boolean;
  textColor?: any;
  textStyle?: any;
  symbol?: string;
} & Parameters<StyledComponent>['0'];

const CryptoCurrencyIcon: FC<Props> = ({
  address = zeroAddress,
  chainId,
  css,
  isSmall,
  textColor,
  textStyle,
  symbol,
}) => {
  const chainCurrency = useChainCurrency(chainId);

  if (isSmall) {
    return (
      <Text css={css} style='tiny'>
        {symbol || chainCurrency.symbol}
      </Text>
    );
  }

  return (
    <Text color={textColor} style={textStyle} css={css}>
      {symbol || chainCurrency.symbol}
    </Text>
  );
};

export default CryptoCurrencyIcon;
