import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useBlockNumber, useBalance } from 'wagmi';

interface Props {
  queryKey: ReturnType<typeof useBalance>['queryKey'];
  isEnabled?: boolean;
}

export const useWatchQueryKey = ({ queryKey, isEnabled = true }: Props) => {
  const queryClient = useQueryClient();
  const { data: blockNumber } = useBlockNumber({ watch: isEnabled });

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey });
  }, [blockNumber, queryClient]);
};
