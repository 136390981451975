import {
  GetCollectionTokenRequest,
  OrderbookService,
} from '@api/orderbook_api/v1/orderbook.pb';
import useSWR from 'swr/immutable';
import { SWRConfiguration } from 'swr';
import { getRequestOpts } from '@utils/wallet';

const fetcher = async (request?: GetCollectionTokenRequest) => {
  if (!request) {
    return null;
  }
  const { chainId, collection, tokenId } = request;
  if (!chainId || !collection || !tokenId) {
    return null;
  }
  const result = await OrderbookService.GetCollectionToken(
    {
      chainId: String(chainId),
      collection,
      tokenId,
    },
    getRequestOpts(),
  );
  return result;
};

export default function useCollectionToken(
  request?: GetCollectionTokenRequest,
  config?: SWRConfiguration,
) {
  return useSWR(request, fetcher, config);
}

export async function getCollectionToken(request: GetCollectionTokenRequest) {
  return await fetcher(request);
}
