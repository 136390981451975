import React, { FC } from 'react';
import { Flex, Select } from '../primitives';
import { Currency } from '@api/orderbook_api/v1/types.pb';

type Props = {
  currencies: Currency[];
  currency: Currency;
  setCurrency: (currency: Currency) => void;
};

export const CurrencySelector: FC<Props> = ({
  currencies,
  currency,
  setCurrency,
}) => {
  return (
    <Select
      value={currency.symbol}
      onValueChange={(value: string) => {
        const option = currencies.find((option) => option.address == value);
        if (option) {
          setCurrency(option);
        }
      }}
    >
      {currencies.map((option) => (
        <Select.Item key={option.address} value={option.address as string}>
          <Select.ItemText>
            <Flex align='center' css={{ gap: '$1' }}>
              {option.symbol}
            </Flex>
          </Select.ItemText>
        </Select.Item>
      ))}
    </Select>
  );
};
