import React from 'react'

const ReservoirText = (props: any) => {
  return (
    <svg
      width="50"
      height="10"
      viewBox="0 0 50 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0.104492 8.99985V2.75985C0.608492 2.72385 1.11249 2.72385 1.62849 2.75985L1.73649 3.77985H1.80849C2.00049 2.97585 2.78049 2.63985 3.87249 2.63985C3.92049 3.04785 3.92049 3.71985 3.87249 4.12785C2.51649 4.12785 2.14449 4.40385 1.82049 5.26785V8.99985C1.40049 9.05985 0.512492 9.05985 0.104492 8.99985Z" />
      <path d="M10.2115 5.02785V6.40785C9.40749 6.46785 7.55949 6.47985 6.52749 6.41985V6.71985C6.52749 7.57185 7.01949 7.72785 8.05149 7.72785C8.75949 7.72785 9.26349 7.66785 9.93549 7.53585C9.98349 7.94385 9.98349 8.51985 9.93549 8.92785C9.09549 9.07185 8.42349 9.14385 7.63149 9.14385C5.90349 9.14385 4.81149 8.62785 4.81149 7.10385V4.70385C4.81149 3.10785 5.96349 2.63985 7.55949 2.63985C9.14349 2.63985 10.2115 3.31185 10.2115 5.02785ZM8.53149 5.36385V4.95585C8.53149 4.13985 8.06349 4.04385 7.53549 4.04385C7.00749 4.04385 6.52749 4.13985 6.52749 4.95585V5.42385L7.45149 5.36385H8.53149Z" />
      <path d="M15.9181 6.94785V7.53585C15.9181 8.69985 15.0061 9.14385 13.5181 9.14385C12.8821 9.14385 12.1501 9.09585 11.4661 8.97585C11.4061 8.54385 11.4061 8.05185 11.4661 7.63185C12.1141 7.73985 12.7621 7.77585 13.2661 7.77585C13.7461 7.77585 14.2021 7.73985 14.2021 7.33185V7.10385C14.2021 6.75585 13.9621 6.69585 13.5541 6.58785L12.4741 6.27585C11.7901 6.08385 11.4181 5.65185 11.4181 4.85985V4.19985C11.4181 3.08385 12.3781 2.63985 13.9021 2.63985C14.5021 2.63985 15.1261 2.68785 15.8221 2.83185C15.8821 3.26385 15.8821 3.74385 15.8221 4.16385C15.0901 4.03185 14.6221 3.99585 14.0941 3.99585C13.5781 3.99585 13.1341 4.03185 13.1341 4.43985V4.66785C13.1341 4.96785 13.4101 5.07585 13.7821 5.18385L14.7901 5.45985C15.5341 5.66385 15.9181 6.03585 15.9181 6.94785Z" />
      <path d="M22.5865 5.02785V6.40785C21.7825 6.46785 19.9345 6.47985 18.9025 6.41985V6.71985C18.9025 7.57185 19.3945 7.72785 20.4265 7.72785C21.1345 7.72785 21.6385 7.66785 22.3105 7.53585C22.3585 7.94385 22.3585 8.51985 22.3105 8.92785C21.4705 9.07185 20.7985 9.14385 20.0065 9.14385C18.2785 9.14385 17.1865 8.62785 17.1865 7.10385V4.70385C17.1865 3.10785 18.3385 2.63985 19.9345 2.63985C21.5185 2.63985 22.5865 3.31185 22.5865 5.02785ZM20.9065 5.36385V4.95585C20.9065 4.13985 20.4385 4.04385 19.9105 4.04385C19.3825 4.04385 18.9025 4.13985 18.9025 4.95585V5.42385L19.8265 5.36385H20.9065Z" />
      <path d="M24.0811 8.99985V2.75985C24.5851 2.72385 25.0891 2.72385 25.6051 2.75985L25.7131 3.77985H25.7851C25.9771 2.97585 26.7571 2.63985 27.8491 2.63985C27.8971 3.04785 27.8971 3.71985 27.8491 4.12785C26.4931 4.12785 26.1211 4.40385 25.7971 5.26785V8.99985C25.3771 9.05985 24.4891 9.05985 24.0811 8.99985Z" />
      <path d="M30.1801 8.99985L28.2601 2.80785C28.6801 2.74785 29.5681 2.74785 29.9761 2.80785L31.2841 7.42785L32.5801 2.80785C32.9881 2.74785 33.8401 2.74785 34.2481 2.80785L32.3401 8.99985C31.8121 9.05985 30.7081 9.05985 30.1801 8.99985Z" />
      <path d="M40.7087 4.66785V7.13985C40.7087 8.62785 39.5807 9.14385 37.9007 9.14385C36.2207 9.14385 35.0927 8.62785 35.0927 7.13985V4.66785C35.0927 3.11985 36.2207 2.63985 37.9007 2.63985C39.5807 2.63985 40.7087 3.11985 40.7087 4.66785ZM38.9927 6.76785V5.01585C38.9927 4.18785 38.4887 4.09185 37.9007 4.09185C37.3007 4.09185 36.8087 4.18785 36.8087 5.01585V6.76785C36.8087 7.58385 37.3007 7.67985 37.9007 7.67985C38.4887 7.67985 38.9927 7.58385 38.9927 6.76785Z" />
      <path d="M42.3037 8.99985V2.80785C42.7117 2.74785 43.5997 2.74785 44.0197 2.80785V8.99985C43.5997 9.05985 42.7117 9.05985 42.3037 8.99985ZM44.0917 0.467852L44.0557 1.83585C43.6357 1.89585 42.6877 1.89585 42.2557 1.83585L42.2317 0.467852C42.6637 0.407852 43.6597 0.407852 44.0917 0.467852Z" />
      <path d="M45.7725 8.99985V2.75985C46.2765 2.72385 46.7805 2.72385 47.2965 2.75985L47.4045 3.77985H47.4765C47.6685 2.97585 48.4485 2.63985 49.5405 2.63985C49.5885 3.04785 49.5885 3.71985 49.5405 4.12785C48.1845 4.12785 47.8125 4.40385 47.4885 5.26785V8.99985C47.0685 9.05985 46.1805 9.05985 45.7725 8.99985Z" />
    </svg>
  )
}

export default ReservoirText
