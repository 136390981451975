import { formatBN } from '../lib/numbers';
import React, { FC } from 'react';
import { Flex, Text } from './index';

type Props = {
  amount: string | number | bigint | null | undefined;
  maximumFractionDigits?: number;
  decimals?: number;
  css?: Parameters<typeof Text>['0']['css'];
  textStyle?: Parameters<typeof Text>['0']['style'];
  textColor?: Parameters<typeof Text>['0']['color'];
  children?: React.ReactNode;
  isSmall?: boolean;
};

const FormatCrypto: FC<Props> = ({
  amount,
  maximumFractionDigits = 4,
  decimals = 18,
  css,
  textStyle = 'subtitle1',
  textColor = 'base',
  children,
  isSmall,
}) => {
  const value = formatBN(amount, maximumFractionDigits, decimals);
  return (
    <Flex align={isSmall ? 'start' : 'center'} css={{ gap: '$1' }}>
      <Text
        style={textStyle}
        color={textColor}
        css={{ whiteSpace: 'nowrap', ...css }}
        as='p'
      >
        {value}
      </Text>
      {value !== '-' ? (
        <Text style={textStyle} color={textColor} css={css} as='p'>
          {children}
        </Text>
      ) : null}
    </Flex>
  );
};

export default FormatCrypto;
