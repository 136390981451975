import * as allChains from 'viem/chains';

const getChainBlockExplorerUrl = (chainId: number) => {
  const wagmiChain: allChains.Chain | undefined = Object.values({
    ...allChains,
  }).find(({ id }) => id === chainId);

  return wagmiChain?.blockExplorers?.default?.url || 'https://etherscan.io';
};

export default getChainBlockExplorerUrl;
