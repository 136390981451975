import { Price } from '@api/orderbook_api/v1/types.pb';
import {
  Flex,
  FormatCryptoCurrency,
  FormatCurrency,
  Img,
  Text,
} from '@reservoir-kit-ui-overrides/primitives';

interface Props {
  chainId?: number;
  img?: string;
  name?: string;
  price?: Price;
  totalUsd: number;
  expirationDisplay?: string;
}

export const TokenIconWithPriceListing = ({
  chainId,
  img,
  name,
  price,
  totalUsd,
  expirationDisplay,
}: Props) => {
  return (
    <Flex align='center' css={{ gap: '$3', w: '100%' }}>
      <Img
        src={img}
        alt={name}
        css={{
          borderRadius: '$radiusMedium',
          objectFit: 'cover',
          height: 62,
          width: 62,
          aspectRatio: '1/1',
        }}
      />
      <Flex direction='column' css={{ overflow: 'hidden' }}>
        <Text style='subtitle1' ellipsify>
          {name}
        </Text>
        <Flex css={{ gap: '$2' }}>
          <FormatCryptoCurrency
            chainId={chainId}
            amount={price?.amount?.decimal || undefined}
            address={price?.currency?.address}
            symbol={price?.currency?.symbol}
            decimals={price?.currency?.decimals}
            textStyle='body1'
          />
          <FormatCurrency amount={totalUsd} style='body1' color='subtle' />
          {expirationDisplay && (
            <Text style='body1' color='subtle'>
              Expires in {expirationDisplay}
            </Text>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
