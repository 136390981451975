interface Props {
  width?: number;
  height?: number;
}

export const IconDot = ({ width = 13, height = 13 }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 13 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='6.89502' cy='6.13184' r='5.17534' fill='currentColor' />
    </svg>
  );
};
