import { Address, formatUnits, parseUnits, zeroAddress, erc20Abi } from 'viem';
import { Config, getBalance } from '@wagmi/core';
import { useMemo } from 'react';
import useSWR from 'swr';
import { Currency, Order } from '@api/orderbook_api/v1/types.pb';
import { useSignals } from '@preact/signals-react/runtime';
import { useCurrencyConversions } from './useCurrencyConversions';
import { float, int } from '@api/utils';
import { useReadContracts } from 'wagmi';
import { useConfig } from 'wagmi';
import { getChainById } from '@signals/chains';
import { getCollection } from '@signals/collection';
import { floatToBigIntAndScale } from '@utils/numbers';

export type EnhancedCurrency = Currency & {
  usdPrice?: number;
  usdPriceRaw?: bigint;
  usdTotalPriceRaw?: bigint;
  usdTotalFormatted?: string;
  usdBalanceRaw?: bigint;
  balance?: string | number | bigint;
  currencyTotalRaw?: bigint;
  currencyTotalFormatted?: string;
};

const fetchNativeBalances = async (
  address: Address,
  config: Config,
  tokens?: Currency[],
) => {
  const balancePromises = tokens?.map((currency) =>
    getBalance(config, {
      address: address,
      chainId: currency?.chainId as any,
    }),
  );

  const settledResults = balancePromises
    ? await Promise.allSettled(balancePromises)
    : [];
  return settledResults.map((result) => {
    return result.status === 'fulfilled' ? result.value : null;
  });
};

export function usePaymentTokensV2(options: {
  open: boolean;
  address: Address;
  quantityToken: Record<string, number>;
  orders?: Order[];
  chainId: number;
}) {
  useSignals();

  const config = useConfig();
  const { open, address, quantityToken, orders, chainId } = options;

  const selectedCollection = getCollection(orders?.[0]?.collection);
  const selectedChain = getChainById(chainId);
  const allPaymentTokens = useMemo(() => {
    let paymentTokens = selectedChain?.listingCurrencies?.filter((currency) =>
      selectedCollection?.supportedPaymentMethods
        ?.map((v) => v.toLowerCase())
        .includes(currency.address?.toLowerCase() as string),
    );
    return paymentTokens;
  }, [
    selectedChain?.listingCurrencies,
    selectedCollection?.supportedPaymentMethods,
  ]);

  const nativeCurrencies = useMemo(() => {
    return selectedChain?.listingCurrencies?.filter(
      (currency) => currency.address === zeroAddress,
    );
  }, [selectedChain?.listingCurrencies]);

  const nonNativeCurrencies = useMemo(() => {
    return (
      selectedChain?.listingCurrencies?.filter(
        (currency) => currency.address !== zeroAddress,
      ) ?? []
    );
  }, [selectedChain?.listingCurrencies]);

  const { data: nonNativeBalances, queryKey } = useReadContracts({
    contracts: nonNativeCurrencies.map((currency) => ({
      abi: erc20Abi,
      address: currency.address as `0x${string}`,
      chainId: int(currency.chainId),
      functionName: 'balanceOf',
      args: [address as any],
    })),
    query: {
      enabled: open && address ? true : false,
    },
    allowFailure: false,
  });

  const key = open
    ? { address, chainId: selectedChain?.id, tokens: nativeCurrencies }
    : undefined;

  const { data: nativeBalances } = useSWR(
    key,
    () => fetchNativeBalances(address, config, nativeCurrencies),
    {
      revalidateOnFocus: false,
    },
  );

  const preferredCurrencyConversions = useCurrencyConversions(
    orders?.[0] ? orders?.[0].price?.currency?.symbol : undefined,
    selectedChain?.id as number,
    open ? (allPaymentTokens?.map((t) => t.address) as string[]) : undefined,
  );

  return useMemo(() => {
    if (!open) {
      return [];
    }
    const paymentTokens = allPaymentTokens?.reduce(
      (tokens, token, i) => {
        const conversionData = preferredCurrencyConversions?.data?.[i];
        tokens[`${token.address?.toLowerCase()}:${selectedChain?.id}`] = {
          total: 0n,
          usdTotal: 0,
          currency: {
            ...token,
            address: token.address?.toLowerCase(),
          },
          chainId: selectedChain?.id as number,
          conversionData: {
            ...conversionData,
            usd: conversionData?.usd?.toString(),
          },
        };
        return tokens;
      },
      {} as Record<
        string,
        {
          total: bigint;
          usdTotal: number;
          currency: Currency;
          chainId: number;
          conversionData?: { conversion?: string; usd?: string };
        }
      >,
    );

    if (!paymentTokens || !orders || !orders.length || !orders?.[0]) {
      return [selectedChain?.listingCurrencies?.[0] as EnhancedCurrency];
    }

    let totalQuantities: Record<string, number> = {};
    let orderMap: Record<string, number> = {};
    const normalizedQuantities: Record<string, number> = {};
    for (const key in quantityToken) {
      normalizedQuantities[key.toLowerCase()] = quantityToken[key];
    }

    orders?.forEach((order, i) => {
      const tokenKey = `${order?.collection?.toLowerCase()}:${
        order?.token?.tokenId
      }`;
      const contractKey = `${order?.collection?.toLowerCase()}`; //todo: test with sweeping
      let assetKey = tokenKey;
      let totalQuantity = 0;
      let requiredQuantity = 0;
      //Determine correct key to use
      if (normalizedQuantities[tokenKey] !== undefined) {
        assetKey = tokenKey;
      } else if (normalizedQuantities[contractKey] !== undefined) {
        assetKey = contractKey;
      }

      totalQuantity = totalQuantities[assetKey] || 0;
      requiredQuantity = normalizedQuantities[assetKey] || 0;

      //quantity check
      const pathQuantity = int(order?.amount) || 0;
      const quantityLeft = requiredQuantity - totalQuantity;
      if (totalQuantity === requiredQuantity) {
        return;
      }

      let quantityToTake = 0;

      if (quantityLeft >= pathQuantity) {
        quantityToTake = pathQuantity;
      } else {
        quantityToTake = quantityLeft;
      }

      orderMap[order?.id as string] = quantityToTake;
      totalQuantities[assetKey] = totalQuantity + quantityToTake;

      //Total for BuyIn or listing currency
      const currency =
        order?.takerPrice?.currency?.address ?? order?.price?.currency?.address;
      const orderAmount = float(
        order?.takerPrice?.amount?.raw ?? order.price?.amount?.raw ?? '1',
      );

      const currencyChainId = int(order?.chainId) || chainId;
      const currencyKey = `${currency?.toLowerCase()}:${currencyChainId}`;

      const totalRawNumber = orderAmount * (quantityToTake / int(order.amount));

      // Occasionally, the totalRawNumber will be a float due to currency conversion,
      // so we need to convert it to a bigInt and adjust the decimal scale accordingly
      if (totalRawNumber % 1 !== 0) {
        const { bigInt, scale } = floatToBigIntAndScale(totalRawNumber);
        if (
          paymentTokens[currencyKey] &&
          paymentTokens[currencyKey].currency?.decimals
        ) {
          paymentTokens[currencyKey].total += bigInt;
          paymentTokens[currencyKey].currency.decimals += scale;
        }
      } else {
        if (paymentTokens[currencyKey]) {
          paymentTokens[currencyKey].total += BigInt(totalRawNumber);
        }
      }
    });

    const preferredToken = Object.values(paymentTokens).find(
      (token) => token.total > 0n,
    );

    return Object.values(paymentTokens)
      .map((token) => {
        const currency = token.currency;

        let balance: string | number | bigint = 0n;
        if (currency.address === zeroAddress) {
          const index =
            nativeCurrencies?.findIndex(
              (nativeCurrency) => nativeCurrency.symbol === currency.symbol,
            ) || 0;

          balance = nativeBalances?.[index]?.value ?? 0n;
        } else {
          const index =
            nonNativeCurrencies?.findIndex(
              (nonNativeCurrency) =>
                nonNativeCurrency.symbol === currency.symbol &&
                nonNativeCurrency?.address?.toLowerCase() ===
                  currency?.address?.toLowerCase(),
            ) || 0;
          balance =
            nonNativeBalances &&
            nonNativeBalances[index] &&
            (typeof nonNativeBalances[index] === 'string' ||
              typeof nonNativeBalances[index] === 'number' ||
              typeof nonNativeBalances[index] === 'bigint')
              ? (nonNativeBalances[index] as string | number | bigint)
              : 0n;
        }

        const conversionData = token.conversionData;
        let currencyTotalRaw = token.total;
        if (
          !currencyTotalRaw &&
          (token.currency.address !== preferredToken?.currency.address ||
            token.chainId !== preferredToken?.chainId)
        ) {
          currencyTotalRaw =
            conversionData?.conversion &&
            conversionData?.conversion !== '0' &&
            preferredToken?.total
              ? (preferredToken.total *
                  parseUnits('1', currency.decimals ?? 18)) /
                parseUnits(
                  conversionData?.conversion?.toString(),
                  preferredToken.currency.decimals ?? 18,
                )
              : 0n;
        }

        const currencyTotalFormatted =
          currencyTotalRaw > 0n
            ? formatUnits(currencyTotalRaw, currency?.decimals || 18)
            : undefined;

        const usdPrice = Number(conversionData?.usd ?? 0);
        const usdPriceRaw = parseUnits(usdPrice.toString(), 6);
        const usdTotalPriceRaw = conversionData?.usd
          ? ((preferredToken?.total || 0n) * usdPriceRaw) /
            parseUnits('1', preferredToken?.currency?.decimals ?? 18)
          : undefined;

        const usdTotalFormatted = usdTotalPriceRaw
          ? formatUnits(usdTotalPriceRaw, 6)
          : undefined;
        const usdBalanceRaw =
          conversionData?.usd && typeof balance === 'bigint'
            ? ((balance || 0n) * usdPriceRaw) /
              parseUnits('1', preferredToken?.currency?.decimals ?? 18)
            : undefined;

        const returnToken = {
          ...currency,
          address: token?.currency?.address?.toLowerCase(),
          usdPrice: token.usdTotal,
          usdPriceRaw,
          usdTotalPriceRaw,
          balance,
          currencyTotalRaw,
          currencyTotalFormatted,
          usdTotalFormatted: usdTotalFormatted,
          usdBalanceRaw: usdBalanceRaw,
          chainId: token.chainId.toString(),
        };

        return returnToken;
      })
      .sort((a, b) => {
        return Number(b.usdBalanceRaw) - Number(a.usdBalanceRaw);
      });
  }, [
    open,
    allPaymentTokens,
    orders,
    preferredCurrencyConversions?.data,
    selectedChain?.id,
    quantityToken,
    chainId,
    nativeCurrencies,
    nativeBalances,
    nonNativeCurrencies,
    nonNativeBalances,
  ]) as EnhancedCurrency[];
}
