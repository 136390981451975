import React, { FC } from 'react';
import {
  Flex,
  FormatCryptoCurrency,
  FormatCurrency,
  Img,
  Text,
} from '../primitives';
import { Collection, Token } from '@api/orderbook_api/v1/types.pb';
import { EnhancedCurrency } from '@hooks/usePaymentTokensv2';

enum Size {
  SM,
  LG,
}

type Props = {
  collection?: Collection;
  token?: Token;
  itemCount: number;
  totalPrice: bigint;
  usdTotalFormatted?: number | string;
  currency?: EnhancedCurrency;
  size?: Size;
  chainId?: number;
};

export const PurchaseCheckout: FC<Props> = ({
  collection,
  token,
  itemCount,
  totalPrice,
  chainId,
  usdTotalFormatted,
  currency,
}) => {
  const itemSubject = itemCount === 1 ? 'item' : 'items';

  return (
    <Flex direction='column'>
      <Flex justify='between' align='center' css={{ width: '100%' }}>
        <Flex direction='column'>
          <Flex align='center' css={{ gap: 22 }}>
            <Img
              src={token ? token?.imageUrl : collection?.config?.imageUrl}
              css={{
                borderRadius: '$radiusMedium',
                objectFit: 'cover',
                height: 86,
                width: 86,
              }}
            />
            <Flex direction='column'>
              <Text style='subtitle1'>
                {token ? token?.name || `#${token?.tokenId}` : collection?.name}
              </Text>
              <Text style='body1' color='subtle'>
                {itemCount} {itemSubject}
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex direction='column' align='end'>
          <FormatCryptoCurrency
            chainId={chainId}
            textStyle='subtitle1'
            amount={totalPrice}
            address={currency?.address}
            decimals={currency?.decimals}
            symbol={currency?.symbol}
            logoWidth={18}
          />
          {usdTotalFormatted ? (
            <FormatCurrency
              amount={usdTotalFormatted}
              style='body1'
              color='subtle'
              css={{ textAlign: 'end' }}
            />
          ) : null}
        </Flex>
      </Flex>
    </Flex>
  );
};
