import React, { FC } from 'react';
import { Flex, Img, Text } from '../primitives';
import { useMediaQuery } from 'react-responsive';
import { Chain, Collection } from '@api/orderbook_api/v1/types.pb';

type Props = {
  collection?: Collection;
  chain?: Chain | null;
  subtextSlot?: React.ReactNode;
};

const largeScreenImgSize = 86;
const smallScreenImgSize = 62;

export const CollectionInfo: FC<Props> = ({
  collection,
  chain,
  subtextSlot,
}) => {
  // TODO: get mint data added to collections
  // const mintData = collection?.mintStages?.find(
  //   (stage) => stage.kind === 'public',
  // );

  const isSmallDevice = useMediaQuery({ maxWidth: 600 });
  const imageSize = isSmallDevice ? smallScreenImgSize : largeScreenImgSize;
  // const mintEndTime = mintData ? getTimeSince(mintData?.endTime) : undefined;

  return (
    <Flex
      justify='between'
      align='center'
      css={{ gap: '$4', maxWidth: '100%' }}
    >
      <Flex align='center' css={{ gap: 22, overflow: 'hidden' }}>
        <Img
          src={collection?.config?.imageUrl}
          css={{
            borderRadius: '$radiusMedium',
            objectFit: 'cover',
            height: imageSize,
            width: imageSize,
          }}
        />
        <Flex direction='column' css={{ gap: '$1', overflow: 'hidden' }}>
          <Text style='subtitle1' ellipsify>
            {collection?.name}
          </Text>
          {subtextSlot}
          {/* <Flex align='center' css={{ gap: '$1' }}>
            {mintEndTime ? (
              <Text style='subtitle2' color='subtle'>
                Ends {mintEndTime}
              </Text>
            ) : null}
            {chain ? (
              <>
                {mintEndTime ? (
                  <Divider direction='vertical' css={{ maxHeight: 12 }} />
                ) : null}
                <ChainIcon chainId={chain.id} height={12} />
                <Text style='subtitle2' color='subtle' ellipsify>
                  {chain.name}
                </Text>
              </>
            ) : null}
          </Flex> */}
        </Flex>
      </Flex>
    </Flex>
  );
};
