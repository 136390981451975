import React, { FC, ComponentPropsWithoutRef, CSSProperties } from 'react';
import { Button, Flex, Text } from '../../primitives';
import TokenMedia from './index';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSignals } from '@preact/signals-react/runtime';

type TokenFallbackProps = {
  mode?: 'default' | 'simple';
  style?: CSSProperties;
  className?: string;
  token: ComponentPropsWithoutRef<typeof TokenMedia>['token'];
  chainId?: number;
  onRefreshClicked: () => void;
};

const TokenFallback: FC<TokenFallbackProps> = ({
  mode,
  style,
  className,
  token,
  chainId,
  onRefreshClicked,
}) => {
  useSignals();

  return (
    <Flex
      justify='center'
      align='center'
      direction='column'
      css={{ gap: '$2', aspectRatio: '1/1', p: '$2', ...style }}
      className={className}
    >
      {mode === 'simple' || !token?.collection ? (
        <FontAwesomeIcon icon={faImage} style={{ height: '50%' }} />
      ) : (
        <>
          <Text style='body2' css={{ textAlign: 'center' }}>
            No Content Available
          </Text>
          <Button color='secondary' onClick={onRefreshClicked}>
            Refresh
          </Button>
        </>
      )}
    </Flex>
  );
};

export default TokenFallback;
