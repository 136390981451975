import React, {
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  ReactNode,
  useContext,
} from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClose,
  faChevronLeft,
  faLock,
} from '@fortawesome/free-solid-svg-icons';
import { Anchor, Button, Flex, Text, Loader, Box } from '../primitives';
import { styled } from '@stitches.config';
import { Dialog } from '../primitives/Dialog';
import { ProviderOptionsContext } from '../ReservoirKitProvider';
import ReservoirText from '../img/ReservoirText';
import { IconClose } from '@v2/icons/IconClose';

const Title = styled(DialogPrimitive.Title, {
  margin: 0,
});

export enum ModalSize {
  MD,
  LG,
}

type Props = {
  title: string;
  children: ReactNode;
  size?: ModalSize;
  onBack?: (() => void) | null;
  loading?: boolean;
} & Pick<
  ComponentPropsWithoutRef<typeof Dialog>,
  | 'onPointerDownOutside'
  | 'onOpenChange'
  | 'open'
  | 'trigger'
  | 'onFocusCapture'
>;

export const Logo = styled(ReservoirText, {
  fill: '$neutralText',
  '&:hover': {
    fill: '$neutralSolid',
  },
});

// eslint-disable-next-line react/display-name
export const Modal = forwardRef<ElementRef<typeof Dialog>, Props>(
  (
    {
      title,
      children,
      trigger,
      onBack,
      open,
      size = ModalSize.MD,
      onOpenChange,
      loading,
      onPointerDownOutside,
      onFocusCapture,
    },
    forwardedRef,
  ) => {
    const providerOptionsContext = useContext(ProviderOptionsContext);

    return (
      <Dialog
        ref={forwardedRef}
        trigger={<div>{trigger}</div>}
        open={open}
        onOpenChange={onOpenChange}
        size={size}
        onPointerDownOutside={onPointerDownOutside}
        onFocusCapture={onFocusCapture}
      >
        <Flex
          css={{
            p: '$modalContentPadding',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: '1px solid $borderColor',
          }}
        >
          <Title
            css={{ alignItems: 'center', display: 'flex', fontWeight: 400 }}
          >
            {onBack && (
              <Button
                color='ghost'
                size='none'
                css={{ mr: '$2', color: '$neutralText' }}
                onClick={onBack}
              >
                <FontAwesomeIcon icon={faChevronLeft} width={16} height={16} />
              </Button>
            )}
            <Text css={{ fontWeight: 600, fontSize: 18 }}>{title}</Text>
          </Title>
          <DialogPrimitive.Close asChild>
            <Button
              color='ghost'
              size='none'
              css={{ color: '$neutralText', height: 'auto' }}
            >
              <IconClose />
            </Button>
          </DialogPrimitive.Close>
        </Flex>
        {loading && (
          <Loader
            css={{
              minHeight: 242,
              backgroundColor: '$contentBackground',
            }}
          />
        )}
        <Box css={{ overflowY: 'auto', height: '100%' }}>{children}</Box>
      </Dialog>
    );
  },
);
