import { Flex, Text } from '../primitives';
import React, { FC } from 'react';
import { CSS } from '@stitches/react';

type Props = {
  css: CSS;
};

const SigninStep: FC<Props> = ({ css }) => {
  return (
    <Flex
      css={{ color: '$neutralText', ...css }}
      direction='column'
      justify='center'
    >
      <Text style='subtitle1' color='base'>
        Confirm listing in your wallet
      </Text>
    </Flex>
  );
};

export default SigninStep;
