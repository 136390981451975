import FormatCrypto from './FormatCrypto';
import React, { FC, ComponentProps } from 'react';
import CryptoCurrencyIcon from './CryptoCurrencyIcon';
import Tooltip from './Tooltip';
import { zeroAddress } from 'viem';

type FormatCryptoCurrencyProps = {
  logoWidth?: number;
  address?: string;
  chainId?: number;
  symbol?: string;
  isSmall?: boolean;
};

type Props = ComponentProps<typeof FormatCrypto> & FormatCryptoCurrencyProps;

const FormatCryptoCurrency: FC<Props> = ({
  amount,
  address = zeroAddress,
  maximumFractionDigits,
  logoWidth = 14,
  textStyle,
  css,
  textColor,
  decimals,
  chainId,
  symbol,
  isSmall,
}) => {
  return (
    <FormatCrypto
      css={css}
      textColor={textColor}
      textStyle={textStyle}
      amount={amount}
      maximumFractionDigits={maximumFractionDigits}
      decimals={decimals}
      isSmall={isSmall}
    >
      <CryptoCurrencyIcon
        css={css}
        address={address}
        chainId={chainId}
        isSmall={isSmall}
        textColor={textColor}
        textStyle={textStyle}
        symbol={symbol}
      />
    </FormatCrypto>
  );
};

export default FormatCryptoCurrency;
