import useSWR from 'swr';
import { OrderbookService } from '@api/orderbook_api/v1/orderbook.pb';
import { getRequestOpts } from '@utils/wallet';
import { sSelectedChain } from '@signals/chains';

const useChainStats = (chainId?: number) => {
  const chain = chainId ? chainId : sSelectedChain.value;
  const fetcher = async () => {
    return await OrderbookService.GetChain(
      {
        chainId: String(chain),
      },
      getRequestOpts(),
    );
  };
  return useSWR(`useChainStats/${chain}`, fetcher);
};

export default useChainStats;
