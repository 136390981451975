import React, { FC, useEffect, useMemo, useState } from 'react';
import { EnhancedAcceptBidTokenData } from './AcceptBidModalRenderer';
import { Flex, Img, Loader, Text } from '../../primitives';
import { styled } from '@stitches/react';
import { Order } from '@api/orderbook_api/v1/types.pb';
import { useSignals } from '@preact/signals-react/runtime';
import { sSelectedCollection } from '@signals/collection';

type Props = {
  orders: Order[];
  tokensData: EnhancedAcceptBidTokenData[];
  open?: boolean;
  isCurrentStep?: boolean;
};

const StyledImg = styled(Img, {
  width: 24,
  height: 24,
  borderRadius: '$radiusTiny',
});

export const ApproveBidCollapsible: FC<Props> = ({
  orders,
  tokensData,
  open,
  isCurrentStep,
}) => {
  useSignals();
  const [collapsibleOpen, setCollapsibleOpen] = useState(false);
  const collection = sSelectedCollection.value;

  useEffect(() => {
    if (open !== undefined && open !== collapsibleOpen) {
      setCollapsibleOpen(open);
    }
  }, [collapsibleOpen, open]);

  const orderMap = useMemo(
    () =>
      tokensData.reduce((orders, tokenData) => {
        tokenData.bidOrders.forEach((order) => {
          if (order.id) {
            orders[order.id] = order;
          }
        });
        return orders;
      }, {} as Record<string, EnhancedAcceptBidTokenData['bidOrders'][0]>),
    [tokensData],
  );

  if (!orders || orders.length === 0) {
    return null;
  }

  const title = 'Confirm Sale';

  return (
    <Flex direction='column' css={{ gap: '$1' }}>
      <Text style='subtitle1'>{title}</Text>
      <Flex direction='column'>
        <Flex justify='start' align='center' css={{ gap: '$2' }}>
          <Text
            style='body2'
            color='subtle'
            css={{ display: 'flex', alignItems: 'center' }}
          >
            Confirm sale of {orders.length}{' '}
            {orders.length > 1 ? 'items' : 'item'}
          </Text>
          {isCurrentStep ? <Loader /> : null}
        </Flex>
      </Flex>
    </Flex>
  );
};
