import React, {
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Address, formatUnits } from 'viem';
import { useAccount, useSwitchChain } from 'wagmi';
import * as allChains from 'viem/chains';
import { ProviderOptionsContext } from '../../ReservoirKitProvider';
import {
  Collection,
  Currency,
  Order,
  OrderStatus,
  OrderType,
  StepKind,
} from '@api/orderbook_api/v1/types.pb';
import { getCollection } from '@signals/collection';
import { ContractType } from '@api/common/v1/common.pb';
import {
  usePaymentTokensV2,
  EnhancedCurrency,
} from '@hooks/usePaymentTokensv2';
import { int } from '@api/utils';
import { logDebug, logError } from '@utils/logger';
import { LimitBreakChain } from '@api/utils/chains';
import { EnhancedStep, EnhancedStepItem } from '@types';
import { TxHash } from '@utils/wallet';
import { useChainCurrency } from '@hooks';
import { useInterval } from 'usehooks-ts';
import { useWallet } from '@hooks/useWallet';
import { getChainById } from '@signals/chains';
import { trackEvent } from '@utils/analytics/events';

export enum SweepStep {
  Idle,
  SelectPayment,
  Approving,
  Finalizing,
  Complete,
}

export type SweepModalStepData = {
  totalSteps: number;
  stepProgress: number;
  currentStep: EnhancedStep;
  currentStepItem: EnhancedStepItem;
  orders: Order[];
};

export type ChildrenProps = {
  collection?: Collection;
  loading: boolean;
  isFetchingPath: boolean;
  orders: NonNullable<Order[]>;
  selectedTokens: NonNullable<Order[]>;
  setSelectedTokens: React.Dispatch<React.SetStateAction<NonNullable<Order[]>>>;
  itemAmount: number;
  setItemAmount: React.Dispatch<React.SetStateAction<number>>;
  maxItemAmount: number;
  setMaxItemAmount: React.Dispatch<React.SetStateAction<number>>;
  paymentCurrency?: EnhancedCurrency;
  setPaymentCurrency: React.Dispatch<
    React.SetStateAction<EnhancedCurrency | undefined>
  >;
  averageUnitPrice: bigint;
  chainCurrency: Currency;
  paymentTokens: EnhancedCurrency[];
  totalIncludingFees: bigint;
  buyResponseFees?: any;
  feeOnTop: bigint;
  feeUsd: string;
  usdPrice: number;
  usdPriceRaw: bigint;
  currentChain: LimitBreakChain | null | undefined;
  address?: string;
  balance?: bigint;
  isConnected: boolean;
  disableJumperLink?: boolean;
  hasEnoughCurrency: boolean;
  addFundsLink: string;
  blockExplorerBaseUrl: string;
  transactionError: Error | null | undefined;
  stepData: SweepModalStepData | null;
  setStepData: React.Dispatch<React.SetStateAction<SweepModalStepData | null>>;
  sweepStep: SweepStep;
  setSweepStep: React.Dispatch<React.SetStateAction<SweepStep>>;
  sweepTokens: () => void;
};

type Props = {
  collectionId?: string;
  onConnectWallet: () => void;
  chainId?: number;
  defaultQuantity?: number;
  feesOnTopBps?: string[] | null;
  feesOnTopUsd?: string[] | null;
  normalizeRoyalties?: boolean;
  children: (props: ChildrenProps) => ReactNode;
  usePermit?: boolean;
};

export const SweepModalRenderer: FC<Props> = ({
  collectionId,
  feesOnTopBps,
  feesOnTopUsd,
  defaultQuantity,
  onConnectWallet,
  normalizeRoyalties,
  children,
  usePermit,
}) => {
  const [selectedTokens, setSelectedTokens] = useState<NonNullable<Order[]>>(
    [],
  );
  const [isFetchingPath, setIsFetchingPath] = useState(false);
  const [fetchedInitialOrders, setFetchedInitialOrders] = useState(false);
  const [orders, setOrders] = useState<NonNullable<Order[]>>([]);
  const [itemAmount, setItemAmount] = useState<number>(1);
  const [maxItemAmount, setMaxItemAmount] = useState<number>(1);
  const [sweepStep, setSweepStep] = useState<SweepStep>(SweepStep.Idle);
  const [stepData, setStepData] = useState<SweepModalStepData | null>(null);
  const [transactionError, setTransactionError] = useState<Error | null>();
  const [totalIncludingFees, setTotalIncludingFees] = useState(0n);
  const [averageUnitPrice, setAverageUnitPrice] = useState(0n);

  const { switchChainAsync } = useSwitchChain();
  let { chain: activeWalletChain } = useAccount();

  const [hasEnoughCurrency, setHasEnoughCurrency] = useState(true);
  const [feeOnTop, setFeeOnTop] = useState(0n);

  const [buyResponseFees, setBuyResponseFees] = useState<any | undefined>(
    undefined,
  );

  const { address } = useAccount();
  const { wallet } = useWallet();

  const collection = getCollection(collectionId);
  const rendererChain = getChainById(Number(collection?.chainId));
  const chainCurrency = useChainCurrency(rendererChain?.id);

  const wagmiChain: allChains.Chain | undefined = Object.values({
    ...allChains,
  }).find(({ id }) => rendererChain?.id === id);

  const providerOptions = useContext(ProviderOptionsContext);
  const disableJumperLink = providerOptions?.disableJumperLink;

  const blockExplorerBaseUrl =
    wagmiChain?.blockExplorers?.default?.url || 'https://etherscan.io';

  const is1155 = collection?.type === ContractType.CONTRACT_TYPE_ERC1155; // 'erc1155';
  const isSingleToken1155 = is1155 && collection?.totalSupply === '1';

  const [_paymentCurrency, _setPaymentCurrency] = useState<
    EnhancedCurrency | undefined
  >(undefined);

  const paymentKey = collectionId;
  const paymentTokens = usePaymentTokensV2({
    open: true,
    address: address as Address,
    quantityToken: {
      [`${paymentKey}`]: itemAmount,
    },
    orders: orders,
    chainId: rendererChain?.id,
  });

  const paymentCurrency = useMemo(() => {
    return paymentTokens?.find((paymentToken: EnhancedCurrency) => {
      return paymentToken?.address === _paymentCurrency?.address;
    });
  }, [paymentTokens, _paymentCurrency]);

  const usdPrice = paymentCurrency?.usdPrice || 0;
  const usdPriceRaw = paymentCurrency?.usdPriceRaw || 0n;

  const feeUsd = formatUnits(
    feeOnTop * usdPriceRaw,
    (paymentCurrency?.decimals || 18) + 6,
  );

  const fetchBuyPath = useCallback(
    (
      paymentCurrency: EnhancedCurrency | undefined,
      paymentTokens: EnhancedCurrency[],
    ) => {
      if (paymentTokens.length === 0) {
        return;
      }

      setIsFetchingPath(true);

      if (!paymentCurrency) {
        _setPaymentCurrency(paymentTokens[0]);
      }

      return wallet
        ?.queryOrders({
          chainId: collection?.chainId as string,
          collection: collection?.contractAddress as string,
          types: [OrderType.ASK],
          status: [OrderStatus.ACTIVE],
          takerOptions: {
            paymentMethod: paymentCurrency?.address as Address,
            beneficiary: address as Address,
          },
          orderBy: [
            {
              field: 'item_price',
              desc: false,
            },
          ],
          pageSize: 30,
        })
        .then((response) => {
          if ((response.orders?.length || 0) > 0) {
            // Filter out own orders
            const orders = response.orders?.filter(
              (order) => order.maker !== address,
            ) as Order[];
            setOrders(orders);

            const pathOrderQuantity =
              orders?.reduce(
                (quantity, order) => quantity + (int(order?.amount) || 1),
                0,
              ) || 0;
            let totalMaxQuantity = pathOrderQuantity;

            setMaxItemAmount(
              pathOrderQuantity > totalMaxQuantity
                ? totalMaxQuantity
                : pathOrderQuantity,
            );

            if (!paymentCurrency && orders?.[0]) {
              const listingToken = {
                address: orders[0].price?.currency?.address as Address,
                decimals: orders[0].price?.currency?.decimals || 18,
                symbol: orders[0].price?.currency?.symbol || '',
                name: orders[0].price?.currency?.symbol || '',
                chainId: rendererChain?.id.toString() || '1',
              };

              _setPaymentCurrency(listingToken);
            }
          }
        })
        .catch((err) => {
          logError('Error fetching orders', err.message, err);
          setOrders([]);
          throw err;
        })
        .finally(() => {
          setFetchedInitialOrders(true);
          setIsFetchingPath(false);
        });
    },
    [wallet, rendererChain?.id, collection?.contractAddress],
  );

  const setPaymentCurrency: typeof _setPaymentCurrency = useCallback(
    (
      value:
        | EnhancedCurrency
        | ((
            prevState: EnhancedCurrency | undefined,
          ) => EnhancedCurrency | undefined)
        | undefined,
    ) => {
      trackEvent('sweep_modal_payment_currency_set');
      if (typeof value === 'function') {
        _setPaymentCurrency((prevState) => {
          const newValue = value(prevState);
          if (newValue?.address !== paymentCurrency?.address) {
            fetchBuyPath(newValue, paymentTokens)?.catch((err) => {
              if (
                err?.statusCode === 400 &&
                err?.message?.includes('Price too high')
              ) {
                return prevState;
                // _setPaymentCurrency(prevState);
              }
            });
          }
          return newValue;
        });
      } else {
        if (value?.address !== paymentCurrency?.address) {
          _setPaymentCurrency(value);
          fetchBuyPath(value, paymentTokens)?.catch((err) => {
            if (
              err?.statusCode === 400 &&
              err?.message?.includes('Price too high')
            ) {
              _setPaymentCurrency(paymentCurrency);
            }
          });
        }
      }
    },
    [fetchBuyPath, _setPaymentCurrency, paymentCurrency],
  );

  const fetchBuyPathIfIdle = useCallback(() => {
    if (collection && sweepStep === SweepStep.Idle) {
      fetchBuyPath(paymentCurrency, paymentTokens);
    }
  }, [collection, sweepStep, fetchBuyPath, paymentCurrency, paymentTokens]);

  useEffect(() => {
    fetchBuyPathIfIdle();
  }, []);

  useInterval(() => {
    fetchBuyPathIfIdle();
  }, 60000);

  useEffect(() => {
    let totalFees = 0n;
    if (
      paymentCurrency?.currencyTotalRaw &&
      paymentCurrency.currencyTotalRaw > 0n
    ) {
      let currencyTotalRawMinusRelayerFees = paymentCurrency?.currencyTotalRaw;
      // if cross-chain, subtract relayer fees from currencyTotalRaw
      if (buyResponseFees?.relayer?.amount?.raw) {
        const relayerFees = BigInt(buyResponseFees?.relayer?.amount?.raw ?? 0);

        currencyTotalRawMinusRelayerFees -= relayerFees;
      }

      if (feesOnTopBps && feesOnTopBps.length > 0) {
        const fees = feesOnTopBps.reduce((totalFees, feeOnTop) => {
          const [_, fee] = feeOnTop.split(':');
          return (
            totalFees +
            (BigInt(fee) * currencyTotalRawMinusRelayerFees) / 10000n
          );
        }, 0n);
        totalFees += fees;
        setFeeOnTop(fees);
      } else if (feesOnTopUsd && feesOnTopUsd.length > 0 && usdPriceRaw) {
        const fees = feesOnTopUsd.reduce((totalFees, feeOnTop) => {
          const [_, fee] = feeOnTop.split(':');
          const atomicFee = BigInt(fee);
          const convertedAtomicFee =
            atomicFee * BigInt(10 ** paymentCurrency?.decimals!);
          const currencyFee = convertedAtomicFee / usdPriceRaw;
          return totalFees + currencyFee;
        }, 0n);
        totalFees += fees;
        setFeeOnTop(fees);
      } else {
        setFeeOnTop(0n);
      }

      setTotalIncludingFees(paymentCurrency.currencyTotalRaw + totalFees);
      setAverageUnitPrice(
        paymentCurrency.currencyTotalRaw / BigInt(itemAmount),
      );
    } else {
      setTotalIncludingFees(0n);
      setAverageUnitPrice(0n);
    }
  }, [
    paymentCurrency,
    feesOnTopBps,
    feesOnTopUsd,
    usdPriceRaw,
    itemAmount,
    buyResponseFees,
  ]);

  const addFundsLink = paymentCurrency?.address
    ? `https://jumper.exchange/?toChain=${rendererChain?.id}&toToken=${paymentCurrency?.address}`
    : `https://jumper.exchange/?toChain=${rendererChain?.id}`;

  // Determine if user has enough funds in paymentToken
  useEffect(() => {
    if (
      paymentCurrency?.balance != undefined &&
      totalIncludingFees != undefined &&
      BigInt(paymentCurrency?.balance) < totalIncludingFees
    ) {
      setHasEnoughCurrency(false);
    } else {
      setHasEnoughCurrency(true);
    }
  }, [totalIncludingFees, paymentCurrency?.balance]);

  useEffect(() => {
    let updatedTokens = [];
    let quantity = 0;
    for (var i = 0; i < orders.length; i++) {
      const order = orders[i];
      const amount = int(order.amount);
      if (amount && amount > 1) {
        quantity += amount;
      } else {
        quantity++;
      }
      updatedTokens.push(order);
      if (quantity >= itemAmount) {
        break;
      }
    }
    setSelectedTokens(updatedTokens);
  }, [itemAmount, maxItemAmount, orders]);

  // Reset state on close
  useEffect(() => {
    setItemAmount(defaultQuantity || 1);
  }, []);

  useEffect(() => {
    if (maxItemAmount > 0 && itemAmount > maxItemAmount) {
      setItemAmount(maxItemAmount);
    }
  }, [maxItemAmount, itemAmount]);

  const sweepTokens = useCallback(async () => {
    trackEvent('sweep_modal_sweep_button_clicked');
    if (!wallet) {
      onConnectWallet();
      if (document.body.style) {
        document.body.style.pointerEvents = 'auto';
      }
      logDebug(['Missing wallet, prompting connection']);
      return;
    }

    if (activeWalletChain && rendererChain?.id !== activeWalletChain?.id) {
      activeWalletChain = await switchChainAsync({
        chainId: rendererChain?.id as number,
      });
    }
    if (rendererChain?.id !== activeWalletChain?.id) {
      const error = new Error(`Mismatching chainIds`);
      setTransactionError(error);
      throw error;
    }

    setTransactionError(null);

    setSweepStep(SweepStep.Approving);
    const orderIds = orders
      .slice(0, itemAmount)
      .map((order) => order.id) as string[];

    wallet
      ?.buyListings({
        request: {
          chainId: collection?.chainId,
          collection: collection?.contractAddress,
          paymentMethod: paymentCurrency?.address,
          orderIds,
          beneficiary: address as Address,
        },
        onBefore: async (steps, step, item) => {
          const executableSteps = steps?.steps?.filter(
            (step) => step.items && step.items.length > 0,
          );
          let stepCount = executableSteps?.length;
          const currentStepItem = item;
          const currentStepIndex = steps?.steps?.indexOf(step) || 0;
          const currentStep = step;
          if (currentStepItem) {
            setStepData({
              totalSteps: stepCount as number,
              stepProgress: currentStepIndex,
              currentStep: {
                ...currentStep,
                description: 'Confirm transaction in your wallet',
              } as EnhancedStep,
              currentStepItem,
              orders: currentStepItem.data?.sweepCollection?.items || [],
            });
          }
        },
        onAfter: async (steps, step, item, output) => {
          const executableSteps = steps?.steps?.filter(
            (step) => step.items && step.items.length > 0,
          );
          let stepCount = executableSteps?.length;
          const currentStepItem = item;
          const currentStepIndex = steps?.steps?.indexOf(step) || 0;
          const currentStep = step;
          if (currentStepItem) {
            setStepData({
              totalSteps: stepCount as number,
              stepProgress: currentStepIndex as number,
              currentStep: currentStep as EnhancedStep,
              currentStepItem: {
                ...currentStepItem,
                txHashes: [
                  {
                    txHash: output as TxHash,
                    chainId: rendererChain?.id as number,
                  },
                ],
              },
              orders: currentStepItem.data?.sweepCollection?.items || [],
            });
          }
          if (step.kind === StepKind.CALL_PAYMENT_PROCESSOR) {
            setSweepStep(SweepStep.Finalizing);
          }
        },
      })
      .then((response) => {
        trackEvent('sweep_modal_transaction_success');
        setSweepStep(SweepStep.Complete);
      })
      .catch((error: Error) => {
        trackEvent('sweep_modal_transaction_failed');
        setTransactionError(error);
        setSweepStep(SweepStep.Idle);
        logError('Error sweeping tokens', error.message, error);
        fetchBuyPath(paymentCurrency, paymentTokens);
      });
  }, [
    wallet,
    rendererChain?.id,
    orders,
    itemAmount,
    collection?.chainId,
    collection?.contractAddress,
    paymentCurrency,
    address,
    onConnectWallet,
    fetchBuyPath,
    paymentTokens,
  ]);

  return (
    <>
      {children({
        collection,
        loading:
          (collection && !fetchedInitialOrders) || !(paymentTokens.length > 0),
        isFetchingPath,
        address,
        selectedTokens,
        setSelectedTokens,
        itemAmount,
        setItemAmount,
        maxItemAmount,
        setMaxItemAmount,
        paymentCurrency,
        setPaymentCurrency,
        chainCurrency,
        paymentTokens,
        totalIncludingFees,
        buyResponseFees,
        averageUnitPrice,
        feeOnTop,
        feeUsd,
        usdPrice,
        disableJumperLink,
        usdPriceRaw,
        isConnected: wallet !== undefined,
        currentChain: rendererChain,
        orders,
        balance: paymentCurrency?.balance
          ? BigInt(paymentCurrency.balance)
          : undefined,
        hasEnoughCurrency,
        addFundsLink,
        blockExplorerBaseUrl,
        transactionError,
        stepData,
        setStepData,
        sweepStep,
        setSweepStep,
        sweepTokens,
      })}
    </>
  );
};
