import {
  OrderbookService,
  QueryTokensResponse,
  QueryWalletTokensRequest,
} from '@api/orderbook_api/v1/orderbook.pb';
import { SWRInfiniteConfiguration } from 'swr/infinite';
import { int } from '@api/utils';
import useInfiniteApi from '@hooks/useInfiniteApi';
import { getRequestOpts } from '@utils/wallet';
import { useMemo } from 'react';
import { sCollectionAddresses } from '@signals/collection';

export default function useWalletTokens(
  request: QueryWalletTokensRequest,
  config?: Omit<SWRInfiniteConfiguration, 'fetcher'>,
) {
  const pageSize = request.pageSize ? int(request.pageSize) : 25;
  request.collections = sCollectionAddresses.value;
  const getKey = (pageIndex: number, previousPageData: QueryTokensResponse) => {
    if (
      previousPageData &&
      previousPageData.total &&
      int(previousPageData.total) < pageSize
    ) {
      return null;
    }
    return [request, pageIndex, request?.orderBy];
  };
  const fetcher = async (key: [QueryWalletTokensRequest, string]) => {
    if (!request.walletAddress) return {};
    const response = await OrderbookService.QueryWalletTokens(
      { ...key[0], page: key[1] },
      getRequestOpts(),
    );
    return response;
  };
  const response = useInfiniteApi<QueryTokensResponse>(
    getKey,
    {
      fetcher,
      revalidateOnMount: false,
      revalidateFirstPage: false,
      ...config,
    },
    pageSize,
  );

  const flatResponse = useMemo(() => {
    return response.data?.flatMap((page) => page.tokens || []) ?? [];
  }, [response.data]);

  return {
    ...response,
    data: flatResponse,
  };
}
