import { Collection, Token } from '@api/orderbook_api/v1/types.pb';
import { areAddressesEqual, getTopOffer } from '@utils/getTopOffer';
import { Address } from 'viem';

type Props = {
  token: Token;
  collection?: Collection;
  walletAddress?: Address;
};

export function getTokenActions({ token, collection, walletAddress }: Props) {
  const is1155 = token?.isErc1155;
  const floorAsk = token?.stats?.floorAsk;
  const floorAskMaker = floorAsk?.maker;
  const floorAskPrice = floorAsk?.price;
  const countOwned = is1155
    ? Number(token?.tokenOwnerErc1155?.balance || 0)
    : areAddressesEqual(token?.ownerAddress, walletAddress)
    ? 1
    : 0;
  const isListedByAccount =
    !!floorAskPrice && areAddressesEqual(floorAskMaker, walletAddress);
  const topOffer = getTopOffer({
    collection,
    token,
    address: walletAddress,
  });
  const isTopBidder = areAddressesEqual(topOffer?.maker, walletAddress);
  const topOfferPrice = topOffer?.price;
  const hasTopPrice = !!topOfferPrice && !isTopBidder;

  const isOwner = countOwned > 0;

  const remainingQuantity =
    Number(token?.tokenOwnerErc1155?.balance) -
    Number(token?.tokenOwnerErc1155?.listedBalance);

  const canList = is1155 ? remainingQuantity > 0 : isOwner && !floorAskPrice;
  const canCancelListing = isOwner && isListedByAccount && !is1155;
  const canManageListings = isOwner && isListedByAccount && is1155;
  const canAcceptOffer = isOwner && hasTopPrice;

  const canMakeOffer = is1155 ? !isTopBidder : !isOwner && !isTopBidder;
  const canCancelOffer = isTopBidder;
  const canBuy = is1155
    ? floorAskPrice && !areAddressesEqual(floorAsk.maker, walletAddress)
    : !isOwner && floorAskPrice;
  const canManage = isOwner && isListedByAccount;

  return {
    canList,
    canCancelListing,
    canManageListings,
    canAcceptOffer,
    canMakeOffer,
    canCancelOffer,
    canBuy,
    canManage,
  };
}
