/* eslint-disable react-hooks/rules-of-hooks */
import React, {
  ComponentPropsWithoutRef,
  ReactElement,
  useCallback,
  useEffect,
} from 'react';
import { WalletClient, formatUnits } from 'viem';
import { Modal } from '../Modal';
import {
  SweepModalRenderer,
  SweepModalStepData,
  SweepStep,
} from './SweepModalRenderer';
import {
  Box,
  Button,
  ErrorWell,
  Flex,
  FormatCryptoCurrency,
  Loader,
  Text,
} from '../../primitives';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons';
import { formatNumber } from '../../lib/numbers';
import QuantitySelector from '../QuantitySelector';
import { CurrentStepTxHashes } from '../CurrentStepTxHashes';
import SigninStep from '../SigninStep';
import { ApprovePurchasingCollapsible } from '../ApprovePurchasingCollapsible';
import { truncateAddress } from '../../lib/truncate';
import getChainBlockExplorerUrl from '../../lib/getChainBlockExplorerUrl';
import { CollectionInfo } from '../CollectionInfo';
import { PurchaseCheckout } from '../PurchaseCheckout';
import { PaymentDetails } from '../../common/PaymentDetails';
import { Dialog } from '../../primitives/Dialog';
import { SelectPaymentTokenv2 } from '../SelectPaymentTokenv2';
import { Order, StepAction } from '@api/orderbook_api/v1/types.pb';
import { useSignals } from '@preact/signals-react/runtime';
import { ViewTx } from '@v2/components/ViewTx/ViewTx';
import { CollectionOpSuccessPreview } from '@v2/components/CollectionOpSuccessPreview/CollectionOpSuccessPreview';
import { getChainById } from '@signals/chains';
import { getCollection } from '@signals/collection';
import { useOpenState } from '@hooks/useOpenState';
import { setModalState } from '@signals/modalState';
import { trackEvent } from '@utils/analytics/events';
import { EnhancedCurrency } from '@hooks/usePaymentTokensv2';
import { TxSummary } from '@v2/components/TxSummary/TxSummary';

export type SweepCallbackData = {
  collectionId?: string;
  maker?: string;
  stepData: SweepModalStepData | null;
};

export const SweepModalCopy = {
  ctaConnect: 'Connect',
  sweepTitle: 'Sweep',
  sweepCtaClose: 'Close',
  sweepCtaBuy: 'Sweep',
  sweepCtaBuyDisabled: 'Sweep',
  sweepCtaInsufficientFunds: 'Add Funds to Purchase',
  sweepCtaAwaitingApproval: 'Waiting for approval',
  sweepCtaAwaitingValidation: 'Waiting to be validated',
  sweepCtaGoToToken: '',
};

type Props = Pick<Parameters<typeof Modal>['0'], 'trigger'> & {
  collectionId?: string;
  defaultQuantity?: number;
  onConnectWallet: () => void;
  feesOnTopBps?: string[] | null;
  feesOnTopUsd?: string[] | null;
  chainId?: number;
  copyOverrides?: Partial<typeof SweepModalCopy>;
  walletClient?: WalletClient;
  normalizeRoyalties?: boolean;
  usePermit?: boolean;
  onSweepComplete?: (data: SweepCallbackData) => void;
  onSweepError?: (error: Error, data: SweepCallbackData) => void;
  onClose?: (data: SweepCallbackData, currentStep: SweepStep) => void;
  onGoToToken?: (data: SweepCallbackData) => any;
  onPointerDownOutside?: ComponentPropsWithoutRef<
    typeof Dialog
  >['onPointerDownOutside'];
};

export function SweepModal({
  trigger,
  collectionId,
  chainId,
  feesOnTopBps,
  feesOnTopUsd,
  copyOverrides,
  walletClient,
  normalizeRoyalties,
  usePermit,
  onSweepComplete,
  onSweepError,
  onClose,
  onConnectWallet,
  onGoToToken,
  onPointerDownOutside,
  defaultQuantity,
}: Props): ReactElement {
  useSignals();
  const copy: typeof SweepModalCopy = {
    ...SweepModalCopy,
    ...copyOverrides,
  };
  const { isOpen, open, setIsOpen, createCloseHandler } = useOpenState({
    onChange: setModalState,
  });

  const collection = getCollection(collectionId);
  const modalChain = getChainById(Number(collection?.chainId));

  useEffect(() => {
    if (isOpen) {
      trackEvent('sweep_modal_opened');
    }
  }, [isOpen]);

  if (!isOpen) {
    return <div onClick={open}>{trigger}</div>;
  }

  return (
    <SweepModalRenderer
      onConnectWallet={onConnectWallet}
      chainId={modalChain?.id}
      defaultQuantity={defaultQuantity}
      collectionId={collectionId}
      feesOnTopBps={feesOnTopBps}
      feesOnTopUsd={feesOnTopUsd}
      normalizeRoyalties={normalizeRoyalties}
      usePermit={usePermit}
    >
      {({
        loading,
        isFetchingPath,
        collection,
        orders,
        totalIncludingFees,
        buyResponseFees,
        averageUnitPrice,
        selectedTokens,
        feeOnTop,
        feeUsd,
        paymentTokens,
        paymentCurrency,
        setPaymentCurrency,
        addFundsLink,
        chainCurrency,
        itemAmount,
        setItemAmount,
        maxItemAmount,
        setMaxItemAmount,
        usdPrice,
        usdPriceRaw,
        currentChain,
        address,
        isConnected,
        disableJumperLink,
        balance,
        hasEnoughCurrency,
        transactionError,
        stepData,
        sweepStep,
        setStepData,
        setSweepStep,
        sweepTokens,
      }) => {
        useEffect(() => {
          if (sweepStep === SweepStep.Complete && onSweepComplete) {
            const data: SweepCallbackData = {
              collectionId: collection?.contractAddress,
              maker: address,
              stepData,
            };

            onSweepComplete(data);
          }
        }, [sweepStep]);

        const setQuantity = (amount: React.SetStateAction<number>) => {
          trackEvent('sweep_modal_quantity_changed');
          setItemAmount(amount);
        };

        const close = createCloseHandler(() => {
          trackEvent('sweep_modal_closed');
          onClose?.(
            {
              collectionId: collection?.contractAddress,
              maker: address,
              stepData,
            },
            sweepStep,
          );
        });

        useEffect(() => {
          if (transactionError && onSweepError) {
            const data: SweepCallbackData = {
              collectionId: collection?.contractAddress,
              maker: address,
              stepData,
            };
            onSweepError(transactionError, data);
          }
        }, [transactionError]);

        const hasTokens = orders && orders.length > 0;

        const maxQuantity = maxItemAmount;

        const orderMap = stepData?.orders
          ? (stepData.orders as Order[]).reduce(
              (orders: Record<string, Order>, order: Order) => {
                if (order.id) {
                  orders[order.id] = order;
                }

                return orders;
              },
              {} as Record<string, Order>,
            )
          : {};

        const totalSales =
          stepData?.currentStep?.items?.reduce((total, item) => {
            item.data?.sweepCollection?.items?.forEach((orderData) => {
              total += Number(orderData.amount || 1);
            });
            return total;
          }, 0) || 0;

        const failedSales = itemAmount - totalSales;
        const successfulSales = itemAmount - failedSales;

        return (
          <Modal
            trigger={trigger}
            title={copy.sweepTitle}
            open={true}
            loading={loading}
            onPointerDownOutside={(e) => {
              const dismissableLayers = Array.from(
                document.querySelectorAll('div[data-radix-dismissable]'),
              );
              const clickedDismissableLayer = dismissableLayers.some((el) =>
                e.target ? el.contains(e.target as Node) : false,
              );

              if (!clickedDismissableLayer && dismissableLayers.length > 0) {
                e.preventDefault();
              }

              if (onPointerDownOutside) {
                onPointerDownOutside(e);
              }
            }}
            onOpenChange={setIsOpen}
          >
            {(!loading && !hasTokens) ||
            (maxItemAmount === 0 && sweepStep === SweepStep.Idle) ? (
              <Flex
                direction='column'
                align='center'
                css={{ width: '100%', height: '100%' }}
              >
                <Flex
                  direction='column'
                  align='center'
                  css={{ p: '$modalContentPadding' }}
                >
                  <Box css={{ color: '$neutralSolid' }}>
                    <FontAwesomeIcon
                      icon={faMagnifyingGlass}
                      style={{
                        width: '36px',
                        height: '32px',
                      }}
                    />
                  </Box>
                  <Text style='subtitle1' css={{ textAlign: 'center' }}>
                    No available items were found for this collection.
                  </Text>
                </Flex>
                <Box css={{ flexGrow: 1 }} />
                <Box
                  css={{
                    p: '$modalContentPadding',
                    borderTop: '1px solid $borderColor',
                    width: '100%',
                  }}
                >
                  <Button
                    color='onlyBorder'
                    css={{ width: '100%' }}
                    onClick={close}
                  >
                    {copy.sweepCtaClose}
                  </Button>
                </Box>
              </Flex>
            ) : null}

            {!loading &&
              hasTokens &&
              maxItemAmount !== 0 &&
              sweepStep === SweepStep.Idle && (
                <Flex direction='column' css={{ height: '100%' }}>
                  <Flex direction='column'>
                    {transactionError ? (
                      <ErrorWell error={transactionError} />
                    ) : null}
                    <Flex direction='column'>
                      <Box
                        css={{
                          p: '$modalContentPadding',
                          borderBottom: '1px solid $borderColor',
                        }}
                      >
                        <CollectionInfo collection={collection} />
                      </Box>
                      <Flex
                        align='end'
                        justify='between'
                        direction='column'
                        css={{
                          width: '100%',
                          p: '$modalContentPadding',
                          gap: '$3',
                          borderBottom: '1px solid $borderColor',
                        }}
                      >
                        <Flex css={{ gap: '$3', width: '100%' }}>
                          <Flex
                            direction='column'
                            align='start'
                            css={{
                              gap: '$1',
                              overflow: 'hidden',
                              flexGrow: 1,
                              maxWidth: 300,
                            }}
                          >
                            <Text style='subtitle1'>Quantity</Text>
                            <Text
                              style='body1'
                              color='subtle'
                              ellipsify
                              css={{ width: '100%' }}
                            >
                              {formatNumber(maxQuantity)}{' '}
                              {maxQuantity === 1 ? 'item' : 'items'} available
                            </Text>
                          </Flex>
                          <QuantitySelector
                            quantity={itemAmount}
                            setQuantity={setQuantity}
                            min={1}
                            max={maxQuantity}
                            css={{
                              justifyContent: 'space-between',
                              maxWidth: 200,
                            }}
                          />
                        </Flex>
                        {itemAmount > 1 && averageUnitPrice ? (
                          <Flex justify='end' css={{ gap: '$3' }}>
                            <Flex align='center' css={{ gap: '$2' }}>
                              <Text style='body2' color='subtle'>
                                Avg Item Price
                              </Text>
                              <FormatCryptoCurrency
                                chainId={modalChain.id}
                                amount={averageUnitPrice}
                                address={paymentCurrency?.address}
                                decimals={paymentCurrency?.decimals}
                                symbol={paymentCurrency?.name}
                                maximumFractionDigits={2}
                                textStyle='body1'
                              />
                            </Flex>
                          </Flex>
                        ) : null}
                      </Flex>
                    </Flex>
                  </Flex>
                  <Box css={{ flexGrow: 1 }} />
                  <Flex direction='column'>
                    {paymentTokens.length > 1 ? (
                      <Flex
                        direction='column'
                        css={{
                          gap: '$2',
                          p: '$modalContentPadding',
                          py: '$4',
                          borderRadius: '$radiusMedium',
                          borderBottom: '1px solid $borderColor',
                          '&:hover': {
                            backgroundColor: '$neutralBgHover',
                          },
                        }}
                        onClick={() => setSweepStep(SweepStep.SelectPayment)}
                      >
                        <Flex
                          justify='between'
                          align='center'
                          css={{
                            gap: '$1',
                          }}
                        >
                          <Text style='body1'>Payment Method</Text>
                          <Flex
                            align='center'
                            css={{ gap: '$3', cursor: 'pointer' }}
                          >
                            <Flex align='center'>
                              {/* <CryptoCurrencyIcon
                                address={paymentCurrency?.address as string}
                                css={{ width: 16, height: 16, mr: '$1' }}
                              /> */}
                              <Text style='subtitle1'>
                                {paymentCurrency?.symbol}
                              </Text>
                            </Flex>
                            <Box css={{ color: '$neutralSolidHover' }}>
                              <FontAwesomeIcon
                                icon={faChevronRight}
                                width={10}
                              />
                            </Box>
                          </Flex>
                        </Flex>
                      </Flex>
                    ) : null}
                    <Box css={{ p: '$modalContentPadding', pb: '$2' }}>
                      <PaymentDetails
                        chainId={modalChain.id}
                        crosschainFees={buyResponseFees}
                        paymentCurrency={paymentCurrency}
                        feeOnTop={feeOnTop}
                        feeUsd={feeUsd}
                        loading={isFetchingPath}
                      />
                    </Box>
                  </Flex>
                  <Box css={{ p: '$modalContentPadding', width: '100%' }}>
                    {hasEnoughCurrency || !isConnected ? (
                      <Button
                        disabled={
                          !(selectedTokens.length > 0) ||
                          (!hasEnoughCurrency && isConnected)
                        }
                        onClick={sweepTokens}
                        css={{ width: '100%' }}
                      >
                        {!isConnected
                          ? copy.ctaConnect
                          : selectedTokens.length > 0
                          ? copy.sweepCtaBuy
                          : copy.sweepCtaBuyDisabled}
                      </Button>
                    ) : (
                      <Flex direction='column' align='center'>
                        <Flex align='center' css={{ mb: '$3' }}>
                          <Text css={{ mr: '$3' }} color='error' style='body3'>
                            Insufficient Balance
                            {paymentTokens.length > 1
                              ? ', select another token or add funds'
                              : null}
                          </Text>

                          <FormatCryptoCurrency
                            chainId={modalChain.id}
                            amount={paymentCurrency?.balance}
                            address={paymentCurrency?.address}
                            decimals={paymentCurrency?.decimals}
                            symbol={paymentCurrency?.name}
                            textStyle='body3'
                          />
                        </Flex>
                        <Button
                          color='onlyBorder'
                          onClick={close}
                          css={{ width: '100%' }}
                        >
                          {copy.sweepCtaClose}
                        </Button>
                      </Flex>
                    )}
                  </Box>
                </Flex>
              )}

            {!loading && sweepStep === SweepStep.SelectPayment && (
              <Flex direction='column' css={{ height: '100%' }}>
                <Flex
                  direction='column'
                  css={{ p: '$modalContentPadding', gap: '$4' }}
                >
                  <Text style='subtitle1'>Select Payment Method</Text>
                  <SelectPaymentTokenv2
                    paymentTokens={paymentTokens}
                    currency={paymentCurrency}
                    setCurrency={setPaymentCurrency}
                    goBack={() => setSweepStep(SweepStep.Idle)}
                    itemAmount={itemAmount}
                    chainId={modalChain?.id || 1}
                  />
                </Flex>
                <Box css={{ flexGrow: 1 }} />
                <Box
                  css={{
                    p: '$modalContentPadding',
                    borderTop: '1px solid $borderColor',
                  }}
                >
                  <Button
                    color='onlyBorder'
                    onClick={() => setSweepStep(SweepStep.Idle)}
                    css={{
                      width: '100%',
                    }}
                  >
                    {copy.sweepCtaClose}
                  </Button>
                </Box>
              </Flex>
            )}

            {sweepStep === SweepStep.Approving && (
              <Flex direction='column' css={{ height: '100%' }}>
                <Box
                  css={{
                    p: '$modalContentPadding',
                    borderBottom: '1px solid $borderColor',
                  }}
                >
                  <PurchaseCheckout
                    chainId={chainId}
                    collection={collection}
                    itemCount={itemAmount}
                    totalPrice={
                      (paymentCurrency?.currencyTotalRaw || 0n) + feeOnTop
                    }
                    currency={paymentCurrency}
                    usdTotalFormatted={formatUnits(
                      ((paymentCurrency?.currencyTotalRaw || 0n) + feeOnTop) *
                        (paymentCurrency?.usdPriceRaw || 0n),
                      (paymentCurrency?.decimals || 18) + 6,
                    )}
                  />
                </Box>

                {stepData?.currentStep == undefined ? (
                  <Flex
                    justify='center'
                    css={{ width: '100%', p: '$modalContentPadding' }}
                  >
                    <Loader />
                  </Flex>
                ) : null}

                {stepData?.currentStep &&
                stepData.currentStep.action !== StepAction.AUTHORIZE_LISTING &&
                stepData.currentStep.action !== StepAction.SWEEP_COLLECTION ? (
                  <>
                    <Flex
                      css={{
                        color: '$neutralText',
                        p: '$modalContentPadding',
                        gap: '$2',
                      }}
                      direction='column'
                      justify='center'
                    >
                      <Text style='subtitle1'>
                        {stepData.currentStep.description}
                      </Text>
                    </Flex>
                    <CurrentStepTxHashes
                      currentStepItem={stepData?.currentStepItem}
                    />
                    <Box css={{ flexGrow: 1 }} />
                    <Box
                      css={{
                        p: '$modalContentPadding',
                        borderTop: '1px solid $borderColor',
                      }}
                    >
                      <Button disabled={true} css={{ width: '100%' }}>
                        {copy.sweepCtaAwaitingApproval}
                      </Button>
                    </Box>
                  </>
                ) : null}

                {stepData?.currentStep &&
                  stepData.currentStep.action ===
                    StepAction.AUTHORIZE_LISTING && (
                    <>
                      <Box css={{ flexGrow: 1 }} />
                      <Box
                        css={{
                          p: '$modalContentPadding',
                          borderTop: '1px solid $borderColor',
                        }}
                      >
                        <SigninStep css={{}} />
                        <Button disabled={true} css={{ width: '100%' }}>
                          {copy.sweepCtaAwaitingApproval}
                        </Button>
                      </Box>
                    </>
                  )}

                {stepData?.currentStep &&
                stepData?.currentStep?.action ===
                  StepAction.SWEEP_COLLECTION ? (
                  <>
                    {stepData?.currentStep?.items &&
                    stepData?.currentStep?.items.length > 1 ? (
                      <Flex
                        direction='column'
                        css={{
                          gap: '$4',
                          width: '100%',
                          p: '$modalContentPadding',
                        }}
                      >
                        <Text style='subtitle1'>Approve Purchases</Text>
                        <Text style='body1' color='subtle'>
                          The purchase of these items needs to be split into{' '}
                          {stepData?.currentStep?.items.length} separate
                          transactions.
                        </Text>
                        {stepData?.currentStep?.items.map((item, idx) => (
                          <ApprovePurchasingCollapsible
                            key={idx}
                            item={item}
                            orderMap={orderMap}
                            usdPrice={+usdPrice}
                            chain={currentChain}
                            open={true}
                          />
                        ))}
                      </Flex>
                    ) : (
                      <>
                        <Flex
                          direction='column'
                          css={{
                            p: '$modalContentPadding',
                            gap: '$4',
                            width: '100%',
                          }}
                        >
                          <Text style='subtitle1'>
                            Confirm transaction in your wallet
                          </Text>
                          <CurrentStepTxHashes
                            currentStepItem={stepData?.currentStepItem}
                          />
                        </Flex>
                        <Box css={{ flexGrow: 1 }} />
                        <Box
                          css={{
                            width: '100%',
                            p: '$modalContentPadding',
                            borderTop: '1px solid $borderColor',
                          }}
                        >
                          <Button disabled={true} css={{ width: '100%' }}>
                            {copy.sweepCtaAwaitingApproval}
                          </Button>
                        </Box>
                      </>
                    )}
                  </>
                ) : null}
              </Flex>
            )}

            {sweepStep === SweepStep.Finalizing && (
              <Flex direction='column' css={{ height: '100%' }}>
                <Box
                  css={{
                    p: '$modalContentPadding',
                    borderBottom: '1px solid $borderColor',
                  }}
                >
                  <PurchaseCheckout
                    chainId={chainId}
                    collection={collection}
                    itemCount={itemAmount}
                    totalPrice={
                      (paymentCurrency?.currencyTotalRaw || 0n) + feeOnTop
                    }
                    currency={paymentCurrency}
                    usdTotalFormatted={formatUnits(
                      ((paymentCurrency?.currencyTotalRaw || 0n) + feeOnTop) *
                        (paymentCurrency?.usdPriceRaw || 0n),
                      (paymentCurrency?.decimals || 18) + 6,
                    )}
                  />
                </Box>
                <Flex
                  direction='column'
                  css={{
                    p: '$modalContentPadding',
                    gap: '$2',
                  }}
                >
                  <Text style='subtitle1'>Finalizing on blockchain</Text>
                  <Text style='body1' color='subtle'>
                    You can close this modal while it finalizes on the
                    blockchain. The transaction will continue in the background.
                  </Text>
                </Flex>
                <CurrentStepTxHashes
                  currentStepItem={stepData?.currentStepItem}
                />
                <Box css={{ flexGrow: 1 }} />
                <Box
                  css={{
                    p: '$modalContentPadding',
                    borderTop: '1px solid $borderColor',
                  }}
                >
                  <Button disabled={true} css={{ width: '100%' }}>
                    {copy.sweepCtaAwaitingValidation}
                  </Button>
                </Box>
              </Flex>
            )}

            {sweepStep === SweepStep.Complete && (
              <Flex direction='column' css={{ width: '100%', height: '100%' }}>
                <Flex
                  direction='column'
                  css={{
                    p: '$modalContentPadding',
                  }}
                >
                  <CollectionOpSuccessPreview collection={collection} />
                  {/* <Text style='h5' css={{ textAlign: 'center' }}>
                    {failedSales
                      ? `${successfulSales} ${
                          successfulSales > 1 ? 'items' : 'item'
                        } purchased, ${failedSales} ${
                          failedSales > 1 ? 'items' : 'item'
                        } failed`
                      : 'Congrats! Purchase was successful.'}
                  </Text> */}
                </Flex>
                <Flex
                  direction='column'
                  css={{
                    p: '$modalContentPadding',
                    borderTop: '1px solid $borderColor',
                  }}
                >
                  <TxSummary txList={stepData?.currentStepItem?.txHashes} />
                </Flex>
                <Box css={{ flexGrow: 1 }} />
                <Flex css={{ width: '100%' }}>
                  {!!onGoToToken ? (
                    <Flex
                      css={{
                        p: '$modalContentPadding',
                        borderTop: '1px solid $borderColor',
                        gap: '$2',
                      }}
                    >
                      <Button onClick={close} css={{ flex: 1 }} color='ghost'>
                        {copy.sweepCtaClose}
                      </Button>
                      <Button
                        style={{ flex: 1 }}
                        color='primary'
                        onClick={() => {
                          onGoToToken({
                            collectionId: collection?.contractAddress,
                            maker: address,
                            stepData,
                          });
                        }}
                      >
                        {copy.sweepCtaGoToToken.length > 0
                          ? copy.sweepCtaGoToToken
                          : `View ${successfulSales > 1 ? 'Tokens' : 'Token'}`}
                      </Button>
                    </Flex>
                  ) : (
                    <Box
                      css={{
                        p: '$modalContentPadding',
                        borderTop: '1px solid $borderColor',
                        width: '100%',
                      }}
                    >
                      <Button
                        color='onlyBorder'
                        css={{ width: '100%' }}
                        onClick={close}
                      >
                        {copy.sweepCtaClose}
                      </Button>
                    </Box>
                  )}
                </Flex>
              </Flex>
            )}
          </Modal>
        );
      }}
    </SweepModalRenderer>
  );
}

SweepModal.Custom = SweepModalRenderer;
