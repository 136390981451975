import React, {
  FC,
  useEffect,
  useState,
  useCallback,
  ReactNode,
  useMemo,
  memo,
} from 'react';
import { Address, formatUnits } from 'viem';
import * as allChains from 'viem/chains';
import { useSwitchChain } from 'wagmi';
import {
  Collection,
  Currency,
  Order,
  StepAction,
  Token,
} from '@api/orderbook_api/v1/types.pb';
import useCollectionToken from '@hooks/useCollectionToken';
import {
  usePaymentTokensV2,
  EnhancedCurrency,
} from '@hooks/usePaymentTokensv2';
import { useSignals } from '@preact/signals-react/runtime';
import { TxHash } from '@utils/wallet';
import { int } from '@api/utils';
import { EnhancedStep, EnhancedStepItem, EnhancedSteps } from '@types';
import { useWallet } from '@hooks/useWallet';
import { useAccount } from 'wagmi';
import { logError, logInfo, logDebug } from '@utils/logger';
import { getCollection } from '@signals/collection';
import { getChainById } from '@signals/chains';
import { trackEvent } from '@utils/analytics/events';

export enum BuyStep {
  Checkout,
  Approving,
  Complete,
  Unavailable,
  SelectPayment,
}

export type BuyModalStepData = {
  totalSteps: number;
  stepProgress: number;
  currentStep: EnhancedStep;
  currentStepItem: EnhancedStepItem;
};

type ChildrenProps = {
  loading: boolean;
  isFetchingPath: boolean;
  tokenData?: Token;
  collection?: Collection;
  listing?: Order;
  quantityAvailable: number;
  averageUnitPrice: bigint;
  paymentCurrency?: EnhancedCurrency;
  paymentTokens: EnhancedCurrency[];
  totalIncludingFees: bigint;
  feeOnTop: bigint;
  buyResponseFees?: any;
  buyStep: BuyStep;
  transactionError?: Error | null;
  hasEnoughCurrency: boolean;
  addFundsLink: string;
  feeUsd: string;
  totalUsd: bigint;
  usdPrice: number;
  balance?: bigint;
  address?: string;
  blockExplorerBaseUrl: string;
  blockExplorerBaseName: string;
  steps: EnhancedSteps | null;
  stepData: BuyModalStepData | null;
  quantity: number;
  isConnected: boolean;
  isOwner: boolean;
  setPaymentCurrency: React.Dispatch<
    React.SetStateAction<Currency | undefined>
  >;
  setBuyStep: React.Dispatch<React.SetStateAction<BuyStep>>;
  setQuantity: React.Dispatch<React.SetStateAction<number>>;
  buyToken: () => void;
};

type Props = {
  token?: string;
  collectionAddress?: string;
  orderId?: string;
  chainId?: number;
  defaultQuantity?: number;
  feesOnTopBps?: string[] | null;
  feesOnTopUsd?: string[] | null;
  normalizeRoyalties?: boolean;
  children: (props: ChildrenProps) => ReactNode;
  usePermit?: boolean;
};

export const BuyModalRenderer: FC<Props> = memo(function BuyModalRenderer({
  token,
  collectionAddress,
  orderId,
  feesOnTopBps,
  defaultQuantity,
  feesOnTopUsd,
  normalizeRoyalties,
  children,
  usePermit,
}) {
  useSignals();
  const [totalIncludingFees, setTotalIncludingFees] = useState(0n);
  const [averageUnitPrice, setAverageUnitPrice] = useState(0n);
  const [order, setOrder] = useState<Order | undefined>(undefined);
  const [isFetchingPath, setIsFetchingPath] = useState(false);
  const [feeOnTop, setFeeOnTop] = useState(0n);
  const [buyStep, setBuyStep] = useState<BuyStep>(BuyStep.Checkout);
  const [transactionError, setTransactionError] = useState<Error | null>();
  const [hasEnoughCurrency, setHasEnoughCurrency] = useState(true);
  const [stepData, setStepData] = useState<BuyModalStepData | null>(null);
  const [steps, setSteps] = useState<EnhancedSteps | null>(null);
  const [quantity, setQuantity] = useState(defaultQuantity || 1);
  const { switchChainAsync } = useSwitchChain();
  const { wallet } = useWallet();
  const { address, isConnected } = useAccount();
  const quantityRemaining = useMemo(() => {
    return int(order?.amountRemaining) || 0;
  }, [order]);
  const [buyResponseFees, setBuyResponseFees] = useState<any | undefined>(
    undefined,
  );
  const [_paymentCurrency, _setPaymentCurrency] = useState<
    EnhancedCurrency | undefined
  >(undefined);
  const collection = getCollection(collectionAddress);
  const chainId = Number(collection?.chainId);
  const rendererChain = getChainById(chainId);

  const { data: tokenData } = useCollectionToken({
    tokenId: token,
    collection: collection?.contractAddress,
    chainId: chainId.toString(),
  });

  const paymentTokens = usePaymentTokensV2({
    open: true,
    address: wallet?.address as Address,
    quantityToken: useMemo(
      () => ({
        [`${collection?.contractAddress}:${token}`]: quantity,
      }),
      [collection?.contractAddress, token, quantity],
    ),
    orders: useMemo(() => [order as Order], [order]),
    chainId: rendererChain?.id,
  });

  const paymentCurrency = useMemo(() => {
    return paymentTokens?.find(
      (paymentToken) => paymentToken?.address === _paymentCurrency?.address,
    );
  }, [paymentTokens, _paymentCurrency]);

  const wagmiChain: allChains.Chain | undefined = Object.values({
    ...allChains,
  }).find(({ id }) => rendererChain?.id === id);

  const blockExplorerBaseUrl =
    wagmiChain?.blockExplorers?.default?.url || 'https://etherscan.io';
  const blockExplorerBaseName =
    wagmiChain?.blockExplorers?.default?.name || 'Etherscan';

  const isOwner =
    tokenData?.ownerAddress?.toLowerCase() === address?.toLowerCase();

  const usdPrice = paymentCurrency?.usdPrice || 0;
  const usdPriceRaw = paymentCurrency?.usdPriceRaw || 0n;
  const feeUsd = formatUnits(
    feeOnTop * usdPriceRaw,
    (paymentCurrency?.decimals || 18) + 6,
  );
  const totalUsd = totalIncludingFees * usdPriceRaw;

  const addFundsLink = paymentCurrency?.address
    ? `https://jumper.exchange/?toChain=${rendererChain?.id}&toToken=${paymentCurrency?.address}`
    : `https://jumper.exchange/?toChain=${rendererChain?.id}`;

  const fetchPath = useCallback(
    (
      paymentCurrency: EnhancedCurrency | undefined,
      paymentTokens: EnhancedCurrency[],
    ) => {
      if (!tokenData || !token || paymentTokens.length === 0) {
        setOrder(undefined);
        return;
      }
      if (!isConnected) {
        setOrder(tokenData.stats?.floorAsk);
        return;
      }
      setIsFetchingPath(true);

      return wallet
        ?.buyListing({
          request: {
            collection: collection?.contractAddress,
            chainId: rendererChain?.id.toString(),
            paymentMethod: paymentCurrency?.address,
            tokenId: token,
            orderId: orderId,
            amount: quantity.toString(),
          },
          execute: false,
        })
        .then((response) => {
          response.steps?.steps?.forEach((step) => {
            if (step.action === StepAction.BUY_LISTING) {
              const stepItem = step.items?.find(
                (item) => item.data?.buyListings || item.data?.buyListing,
              );

              let itemOrder: Order | undefined = undefined;

              if (stepItem?.data?.buyListings) {
                itemOrder = stepItem.data.buyListings.items?.[0];
              } else if (stepItem?.data?.buyListing) {
                itemOrder = stepItem.data.buyListing.order;
              }

              setOrder(itemOrder);
              if (!paymentCurrency) {
                _setPaymentCurrency(itemOrder?.price?.currency);
              }
            }
          });
        })
        .finally(() => {
          setIsFetchingPath(false);
        });
    },
    [
      collection?.contractAddress,
      isConnected,
      orderId,
      rendererChain?.id,
      token,
      tokenData,
      wallet,
    ],
  );

  const setPaymentCurrency: typeof _setPaymentCurrency = useCallback(
    (
      value:
        | EnhancedCurrency
        | ((
            prevState: EnhancedCurrency | undefined,
          ) => EnhancedCurrency | undefined)
        | undefined,
    ) => {
      trackEvent('buy_modal_payment_currency_set');
      if (typeof value === 'function') {
        _setPaymentCurrency((prevState) => {
          const newValue = value(prevState);
          if (newValue?.address !== paymentCurrency?.address) {
            fetchPath(newValue, paymentTokens)?.catch((err) => {
              if (
                err?.statusCode === 400 &&
                err?.message?.includes('Price too high')
              ) {
                _setPaymentCurrency(prevState);
              }
            });
          }
          return newValue;
        });
      } else {
        if (value?.address !== paymentCurrency?.address) {
          _setPaymentCurrency(value);
          fetchPath(value, paymentTokens)?.catch((err: any) => {
            if (
              err?.statusCode === 400 &&
              err?.message?.includes('Price too high')
            ) {
              _setPaymentCurrency(paymentCurrency);
            }
          });
        }
      }
    },
    [paymentCurrency, fetchPath, paymentTokens],
  );

  useEffect(() => {
    if (token || orderId) {
      fetchPath(paymentCurrency, paymentTokens);
    }
  }, [fetchPath, token, orderId]);

  const buyToken = useCallback(async () => {
    trackEvent('buy_modal_checkout_button_clicked');
    let activeWalletChainId = wallet?.chainId;
    if (rendererChain?.id !== activeWalletChainId) {
      activeWalletChainId = (
        await switchChainAsync({
          chainId: rendererChain?.id as number,
        })
      ).id;
    }

    if (rendererChain?.id !== activeWalletChainId) {
      const error = new Error(`Mismatching chainIds`);
      setTransactionError(error);
      throw error;
    }

    if (!token && !orderId) {
      const error = new Error('Missing token or order');
      setTransactionError(error);
      throw error;
    }

    setBuyStep(BuyStep.Approving);
    return wallet
      ?.buyListing({
        request: {
          collection: collection?.contractAddress,
          chainId: rendererChain?.id.toString(),
          paymentMethod: paymentCurrency?.address,
          tokenId: token,
          orderId: orderId,
        },
        onBefore: async (steps, step, item) => {
          setSteps(steps as EnhancedSteps);
          const executableSteps = steps.steps?.filter(
            (step) => step.items && step.items.length > 0,
          );
          let stepCount = executableSteps?.length;
          let currentStepItem = item;
          const currentStepIndex = executableSteps?.indexOf(step);
          const currentStep = step;
          if (currentStepItem) {
            setStepData({
              totalSteps: stepCount as number,
              stepProgress: currentStepIndex as number,
              currentStep: currentStep as EnhancedStep,
              currentStepItem,
            });
          }
        },
        onAfter: async (steps, step, item, output) => {
          logInfo('Buy token onAfter', { item, output });
          setSteps(steps as EnhancedSteps);
          const executableSteps = steps.steps?.filter(
            (step) => step.items && step.items.length > 0,
          );
          let stepCount = executableSteps?.length;
          let currentStepItem = item;
          const currentStepIndex = executableSteps?.indexOf(step);
          const currentStep = step;
          if (currentStepItem) {
            setStepData({
              totalSteps: stepCount as number,
              stepProgress: currentStepIndex as number,
              currentStep: currentStep as EnhancedStep,
              currentStepItem: {
                ...currentStepItem,
                txHashes: [
                  {
                    txHash: output as TxHash,
                    chainId: rendererChain?.id as number,
                  },
                ],
              },
            });
          }
        },
      })
      .then(() => {
        trackEvent('buy_modal_transaction_success');
        setBuyStep(BuyStep.Complete);
      })
      .catch((error: Error) => {
        trackEvent('buy_modal_transaction_failed');
        logError('Buy token error', { error });
        if (error && error?.message && error?.message.includes('ETH balance')) {
          setHasEnoughCurrency(false);
        } else {
          setTransactionError(error);
          fetchPath(paymentCurrency, paymentTokens);
        }
        setBuyStep(BuyStep.Checkout);
        setStepData(null);
        setSteps(null);
      });
  }, [
    isConnected,
    wallet,
    rendererChain?.id,
    token,
    orderId,
    collection?.contractAddress,
    fetchPath,
    paymentCurrency,
    paymentTokens,
  ]);

  useEffect(() => {
    if (!order && !isFetchingPath) {
      setBuyStep(BuyStep.Unavailable);
    } else {
      setBuyStep(BuyStep.Checkout);
    }
  }, [order, isFetchingPath]);

  useEffect(() => {
    let totalFees = 0n;

    if (
      paymentCurrency?.currencyTotalRaw &&
      paymentCurrency.currencyTotalRaw > 0n
    ) {
      let currencyTotalRawMinusRelayerFees = paymentCurrency?.currencyTotalRaw;

      // if relayer fees, subtract from currencyTotalRaw
      if (buyResponseFees?.relayer?.amount?.raw) {
        const relayerFees = BigInt(buyResponseFees?.relayer?.amount?.raw ?? 0);

        currencyTotalRawMinusRelayerFees -= relayerFees;
      }

      if (feesOnTopBps && feesOnTopBps.length > 0) {
        const fees = feesOnTopBps.reduce((totalFees, feeOnTop) => {
          const [_, fee] = feeOnTop.split(':');
          return (
            totalFees +
            (BigInt(fee) * currencyTotalRawMinusRelayerFees) / 10000n
          );
        }, 0n);
        totalFees += fees;
        setFeeOnTop(fees);
      } else if (feesOnTopUsd && feesOnTopUsd.length > 0 && usdPriceRaw) {
        const fees = feesOnTopUsd.reduce((totalFees, feeOnTop) => {
          const [_, fee] = feeOnTop.split(':');
          const atomicFee = BigInt(fee);
          const convertedAtomicFee =
            atomicFee * BigInt(10 ** paymentCurrency?.decimals!);
          const currencyFee = convertedAtomicFee / usdPriceRaw;
          return totalFees + currencyFee;
        }, 0n);
        totalFees += fees;
        setFeeOnTop(fees);
      } else {
        setFeeOnTop(0n);
      }

      setTotalIncludingFees(paymentCurrency?.currencyTotalRaw + totalFees);
      setAverageUnitPrice(paymentCurrency?.currencyTotalRaw / BigInt(quantity));
    } else {
      setTotalIncludingFees(0n);
      setAverageUnitPrice(0n);
    }
  }, [
    feesOnTopBps,
    feesOnTopUsd,
    usdPriceRaw,
    feeOnTop,
    quantity,
    paymentCurrency,
    buyResponseFees,
  ]);

  useEffect(() => {
    if (
      paymentCurrency?.balance != undefined &&
      totalIncludingFees != undefined &&
      BigInt(paymentCurrency?.balance) < totalIncludingFees
    ) {
      setHasEnoughCurrency(false);
    } else {
      setHasEnoughCurrency(true);
    }
  }, [totalIncludingFees, paymentCurrency]);

  useEffect(() => {
    if (quantityRemaining > 0 && quantity > quantityRemaining) {
      setQuantity(quantityRemaining);
    }
  }, [quantityRemaining, quantity]);

  return (
    <>
      {children({
        loading: !token || !order || (!(paymentTokens.length > 0) && !!order),
        isFetchingPath,
        tokenData: tokenData as Token,
        collection,
        quantityAvailable: quantityRemaining || 1,
        paymentCurrency,
        paymentTokens,
        totalIncludingFees,
        averageUnitPrice,
        feeOnTop,
        buyResponseFees,
        buyStep,
        transactionError,
        hasEnoughCurrency,
        addFundsLink,
        feeUsd,
        totalUsd,
        usdPrice,
        balance: paymentCurrency?.balance
          ? BigInt(paymentCurrency.balance)
          : undefined,
        address: address,
        blockExplorerBaseUrl,
        blockExplorerBaseName,
        steps,
        stepData,
        quantity,
        isConnected: Boolean(address),
        isOwner,
        setPaymentCurrency,
        setQuantity,
        setBuyStep,
        buyToken,
      })}
    </>
  );
});
