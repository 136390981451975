import { styled } from '@stitches.config';

const Button = styled('button', {
  outline: 'none',
  fontWeight: 600,
  fontSize: 15,
  textWrap: 'nowrap',
  lineHeight: 1,
  fontFamily: '$button',
  transition: 'all 250ms linear',
  gap: '$space$2',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  $$focusColor: '$colors$gray12',
  '&:focus-visible': {
    boxShadow: '0 0 0 2px $$focusColor',
  },
  '&:disabled': {
    // backgroundColor: '$btnDisabledBg !important',
    // border: '1px solid $btnDisabledBg !important',
    // color: '$btnDisabledText !important',
    opacity: 0.5,
  },
  whiteSpace: 'nowrap',
  variants: {
    color: {
      white: {
        backgroundColor: 'white',
        color: 'black',
      },
      primary: {
        background: '$btnPrimaryBg',
        color: '$btnPrimaryColor',
        border: '1px solid $btnPrimaryBg',
      },
      secondary: {
        backgroundColor: '$btnSecondaryBg',
        color: '$btnSecondaryColor',
        border: '1px solid $btnSecondaryBg',
      },
      tabSelected: {
        backgroundColor: '$tabSelectedBg',
      },
      gray3: {
        backgroundColor: '$gray3',
        color: '$gray12',
      },
      gray4: {
        backgroundColor: '$gray4',
        color: '$gray12',
      },
      secondaryAndPrimaryOnHover: {
        backgroundColor: '$btnSecondaryBg',
        color: '$textColor',
        '&:hover': {
          background: '$btnPrimaryBg',
          color: '$btnPrimaryColor',
        },
      },
      transparent: {
        backgroundColor: 'transparent',
        color: '$textColor',
      },
      ghostBrand: {
        backgroundColor: 'transparent',
        color: '$brandColor',
        border: '1px solid $ghostBrandBorder',
      },
      ghost: {
        backgroundColor: '$ghostBg',
        color: '$ghostText',
        border: '1px solid $borderColor',
      },
      ghostAndPrimaryOnHover: {
        backgroundColor: '$ghostBg',
        color: '$ghostText',
        border: '1px solid $borderColor',
        '&:hover': {
          background: '$btnPrimaryBg',
          color: '$btnPrimaryColor',
        },
      },
      fancy: {
        backgroundColor: '$fancyBg',
        backdropFilter: 'blur(15px)',
        color: '$fancyColor',
      },
      error: {
        backgroundColor: 'transparent',
        border: '1px solid $red4',
        color: '$textError',
      },
      errorNoBorder: {
        backgroundColor: 'transparent',
        color: '$textError',
      },
      disconnect: {
        backgroundColor: 'transparent',
        border: '1px solid $borderColor',
        color: '$red11',
      },
      styless: {
        all: 'unset !important',
        cursor: 'pointer !important',
        display: 'inline-block !important',
      },
      xplore: {
        backgroundColor: 'transparent',
        border: '1px solid $borderColor',
      },
    },
    corners: {
      square: {
        borderRadius: '$radiusNone',
      },
      rounded: {
        borderRadius: '$radiusSmall',
      },
      roundedLarge: {
        borderRadius: '$radiusMedium',
      },
      pill: {
        borderRadius: 99999,
      },
      circle: {
        borderRadius: '99999px',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    size: {
      none: {},
      normal: {
        h: 38,
        borderRadius: '$radiusSmall',
        px: 16,
      },
      small: {
        h: 32,
        borderRadius: '$radiusSmall',
        px: 12,
        fontSize: 16,
        fontWeight: 600,
      },
      large: {
        h: 44,
        borderRadius: '$radiusMedium',
        fontSize: 16,
        fontWeight: 600,
        px: 16,
      },
      xLarge: {
        h: 50,
        borderRadius: '$radiusMedium',
        fontSize: 18,
        fontWeight: 500,
        px: 16,
      },
      normalSquare: {
        h: 38,
        w: 38,
        borderRadius: '$radiusSmall',
        fontSize: 16,
        fontWeight: 600,
      },
      smallSquare: {
        h: 32,
        w: 32,
        borderRadius: '$radiusSmall',
        fontSize: 16,
        fontWeight: 600,
      },
      largeSquare: {
        h: 42,
        w: 42,
        borderRadius: '$radiusMedium',
      },
      mobileCardAction: {
        width: '100%',
        borderRadius: '$radiusMedium',
        fontSize: 16,
        fontWeight: 500,
        w: '100%',
        h: 32,
        justifyContent: 'center',
      },
      xLargeXplore: {
        h: 50,
        borderRadius: '$radiusMedium',
        fontSize: 16,
        fontWeight: 500,
        px: 16,
      },
    },
  },
  compoundVariants: [
    {
      size: 'xs',
      corners: 'circle',
      css: {
        height: 40,
        width: 40,
        p: 0,
      },
    },
    {
      size: 'small',
      corners: 'circle',
      css: {
        height: 44,
        width: 44,
        p: 0,
      },
    },
    {
      size: 'medium',
      corners: 'circle',
      css: {
        height: 44,
        width: 44,
        p: 0,
      },
    },
    {
      size: 'large',
      corners: 'circle',
      css: {
        height: 52,
        width: 52,
        p: 0,
      },
    },
  ],
  defaultVariants: {
    color: 'primary',
    corners: 'rounded',
    size: 'normal',
  },
});

export default Button;
