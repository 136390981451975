import React, {
  FC,
  useEffect,
  useState,
  useCallback,
  ReactNode,
  useMemo,
} from 'react';
import * as allChains from 'viem/chains';
import {
  Currency,
  Order,
  StepAction,
  Token,
} from '@api/orderbook_api/v1/types.pb';
import { useCoinConversion } from '@hooks';
import { useAccount, useSwitchChain } from 'wagmi';
import useCollectionTokens from '@hooks/useCollectionTokens';
import { EnhancedStep, EnhancedStepItem, EnhancedSteps } from '@types';
import { useSignals } from '@preact/signals-react/runtime';
import { sSelectedCollection } from '@signals/collection';
import { useWallet } from '@hooks/useWallet';
import { logError, logInfo } from '@utils/logger';
import { int } from '@api/utils';

export enum AcceptBidStep {
  Checkout,
  Auth,
  ApproveMarketplace,
  Finalizing,
  Complete,
  Unavailable,
}

export type AcceptBidTokenData = {
  tokenId: string;
  collectionId: string;
  bidOrders?: NonNullable<Order>[];
};

export type EnhancedAcceptBidTokenData = Required<AcceptBidTokenData> & {
  tokenData?: Token;
};

export type AcceptBidPrice = {
  netAmount: number;
  amount: number;
  currency: Currency;
  royalty: number;
  marketplaceFee: number;
  feesOnTop: number;
};

export type AcceptBidStepData = {
  steps: EnhancedSteps;
  currentStep: EnhancedStep;
  currentItem: EnhancedStep['items'][0];
  output?: { txHashes: { txHash: string; chainId: number }[] }[];
};

type ChildrenProps = {
  loading: boolean;
  tokensData: EnhancedAcceptBidTokenData[];
  acceptBidStep: AcceptBidStep;
  transactionError?: Error | null;
  txHash: string | null;
  usdPrices: Record<string, ReturnType<typeof useCoinConversion>[0]>;
  prices: AcceptBidPrice[];
  address?: string;
  stepData: AcceptBidStepData | null;
  acceptBid: () => void;
  setAcceptBidStep: React.Dispatch<React.SetStateAction<AcceptBidStep>>;
};

type Props = {
  tokens: AcceptBidTokenData[];
  children: (props: ChildrenProps) => ReactNode;
};

export const AcceptBidModalRenderer: FC<Props> = ({ tokens, children }) => {
  useSignals();
  const [stepData, setStepData] = useState<AcceptBidStepData | null>(null);
  const [prices, setPrices] = useState<AcceptBidPrice[]>([]);
  const [acceptBidStep, setAcceptBidStep] = useState<AcceptBidStep>(
    AcceptBidStep.Checkout,
  );
  const [transactionError, setTransactionError] = useState<Error | undefined>();
  const [txHash, setTxHash] = useState<string | null>(null);
  const collection = sSelectedCollection.value;
  const { wallet } = useWallet();
  const { address, chainId } = useAccount();

  const { switchChainAsync } = useSwitchChain();
  let { chain: activeWalletChain } = useAccount();

  const wagmiChain: allChains.Chain | undefined = Object.values({
    ...allChains,
  }).find(({ id }) => chainId === id);

  const blockExplorerBaseUrl =
    wagmiChain?.blockExplorers?.default?.url || 'https://etherscan.io';

  const [isFetchingBidPath, setIsFetchingBidPath] = useState(false);
  const [bidOrders, setBidOrders] = useState<Order[] | undefined>();

  const {
    data: tokensData,
    mutate: mutateTokens,
    isValidating: isFetchingTokenData,
  } = useCollectionTokens(
    tokens && tokens.length > 0
      ? {
          tokenIds: tokens.map((token) => token.tokenId),
          collection: collection?.contractAddress,
          chainId: collection?.chainId,
          pageSize: tokens.length.toString(),
        }
      : undefined,
    {
      revalidateFirstPage: false,
    },
  );

  const enhancedTokens = useMemo(() => {
    if (!tokensData || !tokensData.length || isFetchingTokenData) return [];
    return tokensData.reduce((tokenList, tokenData) => {
      tokenList.push({
        tokenId: tokenData.tokenId as string,
        tokenData,
        collectionId: tokenData.collection?.contractAddress as string,
        bidOrders:
          tokens.find((token) => token.tokenId === tokenData.tokenId)
            ?.bidOrders || [],
      });
      return tokenList;
    }, [] as EnhancedAcceptBidTokenData[]);
  }, [tokensData, tokens, isFetchingTokenData]);

  const fetchBidsPath = useCallback(
    (tokens: AcceptBidTokenData[]) => {
      setIsFetchingBidPath(true);

      const orderIds: string[] = tokens?.reduce((items, token) => {
        if (token.bidOrders) {
          items = items.concat(token.bidOrders.map(({ id }) => id as string));
        }
        return items;
      }, [] as string[]);

      wallet
        ?.acceptOffer({
          request: {
            collection: collection?.contractAddress,
            chainId: collection?.chainId,
            orderId: orderIds[0],
            tokenId: tokens[0].tokenId,
            paymentMethod: tokens[0].bidOrders?.[0]?.paymentMethod,
          },
          execute: false,
        })
        .then(({ steps }) => {
          steps.steps?.forEach((step) => {
            if (step.action === StepAction.ACCEPT_OFFER) {
              step.items?.forEach((item) => {
                if (item.data?.acceptOffers?.items) {
                  setBidOrders(item.data.acceptOffers.items);
                }
              });
            }
          });
          setAcceptBidStep(AcceptBidStep.Checkout);
          setIsFetchingBidPath(false);
        })
        .catch((e: Error) => {
          const error = new Error(e.message);
          logError(
            'Error fetching offer information',
            error.message,
            error.stack,
          );
          setAcceptBidStep(AcceptBidStep.Unavailable);
          setIsFetchingBidPath(false);
        });
    },
    [wallet, collection?.contractAddress, collection?.chainId],
  );

  useEffect(() => {
    fetchBidsPath(tokens);
  }, [tokens, fetchBidsPath]);

  const currencySymbols = useMemo(
    () =>
      Array.from(
        enhancedTokens.reduce((symbols, { bidOrders }) => {
          bidOrders.forEach(({ price }) => {
            if (price?.currency?.symbol) {
              symbols.add(price.currency.symbol);
            }
          });
          return symbols;
        }, new Set() as Set<string>),
      ).join(','),
    [enhancedTokens],
  );

  const conversions = useCoinConversion(
    currencySymbols.length > 0 ? 'USD' : undefined,
    currencySymbols,
  );

  const usdPrices = useMemo(
    () =>
      conversions.reduce((map, price) => {
        map[price.symbol] = price;
        return map;
      }, {} as ChildrenProps['usdPrices']),
    [conversions],
  );

  const acceptBid = useCallback(async () => {
    setTransactionError(undefined);
    if (!wallet) {
      const error = new Error('Missing a wallet/signer');
      setTransactionError(error);
      throw error;
    }

    if (activeWalletChain && chainId !== activeWalletChain?.id) {
      activeWalletChain = await switchChainAsync({
        chainId: chainId as number,
      });
    }

    if (int(collection?.chainId) !== activeWalletChain?.id) {
      const error = new Error(`Mismatching chainIds`);
      setTransactionError(error);
      logError('Error accepting bid', error.message, {
        collectionChain: collection?.chainId,
        walletChain: activeWalletChain?.id,
      });
      throw error;
    }

    if (!bidOrders) {
      const error = new Error('Missing bids to accept');
      setTransactionError(error);
      throw error;
    }

    setAcceptBidStep(AcceptBidStep.ApproveMarketplace);

    const orderIds = bidOrders.map(({ id }) => id as string);

    let hasError = false;

    wallet
      .acceptOffer({
        request: {
          chainId: collection?.chainId,
          collection: collection?.contractAddress,
          orderId: orderIds[0],
          tokenId: tokens[0].tokenId,
          paymentMethod: tokens[0].bidOrders?.[0]?.price?.currency?.address,
        },
        onBefore: async (steps, step, item) => {
          setStepData({
            steps: steps as EnhancedSteps,
            currentStep: step as EnhancedStep,
            currentItem: item as EnhancedStepItem,
            output: undefined,
          });

          const currentStep = step;
          if (currentStep.action === StepAction.AUTHORIZE_OFFER) {
            setAcceptBidStep(AcceptBidStep.Auth);
          } else if (
            currentStep.action === StepAction.APPROVE_NFT_CONTRACT ||
            currentStep.action === StepAction.ACCEPT_OFFER
          ) {
            setAcceptBidStep(AcceptBidStep.ApproveMarketplace);
          }
        },
        onAfter: async (steps, step, item, output) => {
          if (!steps || hasError) return;
          setStepData({
            steps: steps as EnhancedSteps,
            currentStep: step as EnhancedStep,
            currentItem: item as EnhancedStepItem,
            output: [
              {
                txHashes: [
                  {
                    txHash: output as string,
                    chainId: int(collection?.chainId),
                  },
                ],
              },
            ],
          });

          setBidOrders(item.data?.acceptOffers?.items);

          const currentStep = step;

          if (currentStep.action === StepAction.ACCEPT_OFFER) {
            if (output !== undefined) {
              setAcceptBidStep(AcceptBidStep.Finalizing);
            } else {
              setAcceptBidStep(AcceptBidStep.ApproveMarketplace);
            }
          }
        },
      })
      .then(() => {
        setAcceptBidStep(AcceptBidStep.Complete);
      })
      .catch((e: Error) => {
        logError('Error accepting bid', e.message, e.stack);
        hasError = true;
        setTransactionError(e);
        setAcceptBidStep(AcceptBidStep.Checkout);
        setStepData(null);
        fetchBidsPath(tokens);
        mutateTokens();
      });
  }, [
    wallet,
    collection?.chainId,
    bidOrders,
    collection?.contractAddress,
    fetchBidsPath,
    tokens,
    mutateTokens,
  ]);

  useEffect(() => {
    if (bidOrders && bidOrders.length > 0) {
      const prices: Record<string, AcceptBidPrice> = bidOrders.reduce(
        (map, { price }) => {
          const netAmount = price?.netAmount?.decimal || 0;
          const currency = price?.currency;
          const amount = price?.amount?.decimal || 0;
          let royalty = 0;
          let marketplaceFee = 0;

          if (currency && currency.symbol) {
            const referralFee = 0;
            if (!map[currency.symbol]) {
              map[currency.symbol] = {
                netAmount: netAmount - referralFee,
                amount,
                currency: {
                  address: currency.address,
                  symbol: currency.symbol,
                  decimals: currency.decimals,
                },
                royalty,
                marketplaceFee,
                feesOnTop: referralFee,
              };
            } else if (map[currency.symbol]) {
              map[currency.symbol].netAmount += netAmount - referralFee;
              map[currency.symbol].amount += amount;
              map[currency.symbol].royalty += royalty;
              map[currency.symbol].marketplaceFee += marketplaceFee;
              map[currency.symbol].feesOnTop += referralFee;
            }
          }
          return map;
        },
        {} as Record<string, AcceptBidPrice>,
      );

      setPrices(Object.values(prices));
      if (acceptBidStep === AcceptBidStep.Unavailable) {
        setAcceptBidStep(AcceptBidStep.Checkout);
      }
    } else if (!isFetchingBidPath) {
      setPrices([]);
      setAcceptBidStep(AcceptBidStep.Unavailable);
    }
  }, [bidOrders, isFetchingBidPath, acceptBidStep]);

  return (
    <>
      {children({
        loading: isFetchingBidPath || isFetchingTokenData,
        tokensData: enhancedTokens,
        acceptBidStep,
        transactionError,
        txHash,
        usdPrices,
        prices,
        address,
        acceptBid,
        setAcceptBidStep,
        stepData,
      })}
    </>
  );
};
