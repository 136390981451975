import { Collection, Token } from '@api/orderbook_api/v1/types.pb';
import { getCollection } from '@signals/collection';

export const areAddressesEqual = (a?: string, b?: string) => {
  if (!a || !b) {
    return false;
  }
  return a?.toLowerCase() === b?.toLowerCase();
};

interface Props {
  address?: string;
  collection?: Collection;
  token?: Token;
}

export const getTopCollectionOffer = ({ address, collection }: Props) => {
  let collectionOffer = collection?.stats?.topOffer;

  const isCollectionOfferOwner =
    address && areAddressesEqual(address, collectionOffer?.maker);

  if (isCollectionOfferOwner) {
    collectionOffer = collection?.stats?.topOfferNext;
  }

  return collectionOffer;
};

export const getTopOffer = ({ address, collection, token }: Props) => {
  const isTokenOwner =
    address && areAddressesEqual(address, token?.ownerAddress);

  let collectionOffer = collection?.stats?.topOffer;

  const isCollectionOfferOwner =
    address && areAddressesEqual(address, collectionOffer?.maker);

  if (isTokenOwner && isCollectionOfferOwner) {
    collectionOffer = collection?.stats?.topOfferNext;
  }

  let tokenOffer = token?.stats?.topOffer;

  const isTokenOfferOwner =
    address && areAddressesEqual(address, tokenOffer?.maker);

  if (isTokenOwner && isTokenOfferOwner) {
    tokenOffer = undefined;
  }

  if (collectionOffer?.price && tokenOffer?.price) {
    return (collectionOffer.price?.amount ?? 0) <
      (tokenOffer.price?.amount ?? 0)
      ? collectionOffer
      : tokenOffer;
  } else if (collectionOffer?.price) {
    return collectionOffer;
  } else if (tokenOffer?.price) {
    return tokenOffer;
  }
};

export const getTopOfferForToken = (token?: Token, address?: string) => {
  if (!token) {
    return undefined;
  }
  const collection = getCollection(token.collection?.contractAddress);

  return getTopOffer({
    address,
    collection,
    token,
  });
};
