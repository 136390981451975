import { GA4 } from 'react-ga4/types/ga4';
import * as Bowser from 'bowser';
import * as Sentry from '@sentry/react';
import mixpanel from 'mixpanel-browser';
import ReactGA, { ReactGAImplementation } from 'react-ga4';
import * as Cronitor from '@cronitorio/cronitor-rum';
import { logInfo, logCritical, logError } from '@/utils/logger';
import { AllEvents, AnalyticsEventName } from './types';

const biEventId = process.env.NEXT_PUBLIC_BIEVENT_TRACK_ID;
const biEventRoute = `/${biEventId}/track`;
const shouldReportToAppEvents =
  typeof window !== 'undefined' && !!process.env.NEXT_PUBLIC_USE_MIXPANEL_PROXY;

const CRONITOR_SITE_ID = process.env.NEXT_PUBLIC_CRONITOR_ID;

if (typeof window !== 'undefined' && CRONITOR_SITE_ID) {
  Cronitor.load(CRONITOR_SITE_ID);
}

/** ---------------------------------------------------------------------------
 * Events
 */

type EventsType = Record<string, EventConfig<string>>;

type GAEventConfig = Extract<
  Parameters<typeof ReactGA.event>[0],
  { action: string }
>;

export type EventConfig<Type> = Omit<GAEventConfig, 'action' | 'transport'> & {
  action: Type;
  beacon?: boolean;
  category: string;
};

export type TrackedEvent = keyof EventsType;

export const AnalyticsEvents = AllEvents.reduce<EventsType>((acc, event) => {
  acc[event.action] = event;
  return acc;
}, {});

export const trackEvent = (eventName: AnalyticsEventName, meta?: object) => {
  try {
    reportToGA(eventName, AnalyticsEvents);
    reportToAppEvents(eventName, meta);
    mixpanel.track(eventName, meta);
    if (typeof window !== 'undefined' && CRONITOR_SITE_ID) {
      Cronitor.track(eventName);
    }
    logInfo('Track Event', eventName);
  } catch (error) {
    logCritical('Unable to track event', eventName);
  }
};

export function trackPageView() {
  if (typeof window !== 'undefined' && CRONITOR_SITE_ID) {
    Cronitor.track('Pageview');
  }

  if (GOOGLE_ANALYTICS_MEASUREMENT_ID) {
    GoogleAnalytics.send('pageview');
  }

  if (shouldReportToAppEvents) {
    reportToAppEvents('pageview');
  }

  mixpanel.track('pageview');
}

let identity: string;

export function identifyUser(newIdentity: string | undefined) {
  if (!newIdentity) {
    return;
  }

  identity = newIdentity;

  if (shouldReportToAppEvents) {
    reportToAppEvents('userIdentity');
  }

  mixpanel.identify(newIdentity);
}

/** ---------------------------------------------------------------------------
 * Google Analytics
 */

export type InstallGAProps = {
  googleAnalyticsMeasurementId: string;
};

let GoogleAnalytics: GA4;

let GOOGLE_ANALYTICS_MEASUREMENT_ID: string;

export function installGA({ googleAnalyticsMeasurementId }: InstallGAProps) {
  GOOGLE_ANALYTICS_MEASUREMENT_ID = googleAnalyticsMeasurementId;

  if (GOOGLE_ANALYTICS_MEASUREMENT_ID) {
    logInfo('Installing GA');
    GoogleAnalytics = new ReactGAImplementation();
    GoogleAnalytics.initialize(GOOGLE_ANALYTICS_MEASUREMENT_ID);
  }
}

const reportToGA = (
  eventName: TrackedEvent,
  allEvents: EventsType = AnalyticsEvents,
  meta: object | undefined = undefined,
) => {
  if (GOOGLE_ANALYTICS_MEASUREMENT_ID) {
    try {
      const { action, beacon, category } = allEvents[eventName];

      GoogleAnalytics.event(
        {
          action,
          category,
          transport: beacon ? 'beacon' : 'xhr',
        },
        meta,
      );
    } catch (error) {
      console.error('unable to trigger', eventName);
      reportToSentry(error);
    }
  }
};

/** ---------------------------------------------------------------------------
 * App Events
 */

type RequestDataElement = {
  category: string;
  properties?: Partial<RequestDataElementProperties>;
  subCategory: string;
};

type RequestDataElementProperties = {
  $os: string;
  $browser: string;
  $current_url: string;
  $browser_version: number;
  $screen_height: number;
  $screen_width: number;
  mp_lib: string;
  $lib_version: string;
  $insert_id: string;
  time: number;
  distinct_id: string;
  $device_id: string;
  $initial_referrer: string;
  $initial_referring_domain: string;
  $search_engine: string;
  token: string;
  mp_sent_by_lib_version: string;
  identity: string;
};

const buffer: RequestDataElement[] = [];
let pending: ReturnType<typeof setTimeout> | undefined;
const BATCH_TIME = 150;
const DEVICE_ID_KEY = 'mktpver'; // purposely a little vague

const deviceId = (() => {
  if (typeof window === 'undefined') {
    return '';
  }

  const existing = window.localStorage.getItem(DEVICE_ID_KEY);

  if (!existing) {
    const data = new Uint16Array(16);
    crypto.getRandomValues(data);
    const hex = [...data].map((it) => it.toString(16)).join('');
    const id = `${hex.substring(0, 14)}-${hex.substring(
      16,
      30,
    )}-${hex.substring(31, 39)}-${hex.substring(40, 46)}-${hex.substring(
      47,
      62,
    )}`;

    window.localStorage.setItem(DEVICE_ID_KEY, id);
    return id;
  }

  return existing;
})();

const screenHeight = typeof window !== 'undefined' ? window.screen.height : 0;
const screenWidth = typeof window !== 'undefined' ? window.screen.width : 0;

function getProperties(): Partial<RequestDataElementProperties> {
  if (typeof window === 'undefined') {
    return {};
  }

  const {
    document: { referrer },
    location: { href },
    navigator: { userAgent },
  } = window;

  const initialReferringDomain = referrer.length
    ? new URL(referrer).hostname
    : undefined;

  const { browser, os } = Bowser.parse(userAgent);

  return {
    $browser_version: parseInt(browser.version ?? '', 10),
    $browser: browser.name,
    $current_url: href,
    distinct_id: `$device:${deviceId}`,
    $device_id: deviceId,
    $initial_referrer: referrer,
    $initial_referring_domain: initialReferringDomain,
    $os: [os.name, os.version, os.versionName].join(' '),
    $screen_height: screenHeight,
    $screen_width: screenWidth,
    time: new Date().getTime(),
    identity,
  };
}

function reportToAppEvents(
  eventName: TrackedEvent,
  meta: object | undefined = undefined,
) {
  if (shouldReportToAppEvents) {
    buffer.push({
      category: 'page_event',
      subCategory: eventName,
      properties: {
        ...getProperties(),
        ...meta,
      },
    });
    if (pending) {
      clearTimeout(pending);
    }
    pending = setTimeout(dispatchToAppEvents, BATCH_TIME);
  }
}

function dispatchToAppEvents() {
  const events = buffer.splice(0, buffer.length);
  try {
    fetch(biEventRoute, {
      body: window.btoa(JSON.stringify(events)),
      method: 'POST',
    });
  } catch (error) {
    console.error('unable to dispatch', events);
    reportToSentry(error);
  }
}

/** ---------------------------------------------------------------------------
 * Sentry
 */

export type InstallSentryProps = {
  sentryDsn: string;
  sentryEnvironment: string;
  tracesSampleRate?: number;
};

let SENTRY_DSN: string;
let SENTRY_ENVIRONMENT: string;

export function installSentry({
  sentryDsn,
  sentryEnvironment,
  tracesSampleRate = 0.1,
}: InstallSentryProps) {
  SENTRY_DSN = sentryDsn;
  SENTRY_ENVIRONMENT = sentryEnvironment;

  if (SENTRY_DSN) {
    logInfo('Installing Sentry');
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: SENTRY_ENVIRONMENT,
      tracesSampleRate,
    });
  }
}

export function reportToSentry(error: unknown) {
  if (!SENTRY_DSN) return '';

  let internalError: Error;
  if (error instanceof Error) {
    internalError = error;
  } else {
    internalError = new Error(error as string);
  }

  try {
    return Sentry.captureException(internalError);
  } catch (e) {
    return Sentry.captureException(e);
  }
}
