import { Collection } from '@api/orderbook_api/v1/types.pb';
import { Flex, Img, Text } from '@reservoir-kit-ui-overrides/primitives';
import { useMediaQuery } from 'react-responsive';

interface Props {
  collection?: Collection;
}

const largeScreenImgSize = 129;
const smallScreenImgSize = 93;

export const CollectionOpSuccessPreview = ({ collection }: Props) => {
  const isSmallDevice = useMediaQuery({ maxWidth: 600 });
  const imageSize = isSmallDevice ? smallScreenImgSize : largeScreenImgSize;
  return (
    <Flex
      direction='column'
      align='center'
      css={{ width: '100%', gap: '$4', overflow: 'hidden' }}
    >
      <Img
        src={collection?.config?.imageUrl}
        alt={collection?.name}
        css={{
          width: imageSize,
          height: imageSize,
          aspectRatio: '1/1',
          borderRadius: '$radiusLarge',
        }}
      />
      <Flex direction='column' align='center'>
        <Text style='subtitle1' color='subtle' ellipsify>
          {collection?.name}
        </Text>
      </Flex>
    </Flex>
  );
};
